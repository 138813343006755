import { getField, updateField } from 'vuex-map-fields';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

const getDefaultState = () => ({
  dataGrouped: false,
  groupBy: null,
  report: [],
  jobId: null,
  searching: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setResponseExpanded(state, { response, value }) {
    Vue.set(response, "expanded", value);
  },
}

export const actions = {
  init({ commit, dispatch }, jobId) {
    commit("resetState")
    commit('updateField', { path: 'jobId', value: jobId })
    dispatch('search')
  },
  changeView({ commit, dispatch, state }, view) {
    commit('updateField', { path: 'report', value: [] })
    commit('updateField', { path: 'groupBy', value: view })
    dispatch('search')
  },
  search({ commit, state }) {
    commit('updateField', { path: 'searching', value: true })
    this.$axios.get(`/data_api/jobs/${state.jobId}/experience`, { params: { group_by: state.groupBy } }).then(response => {
      if (state.groupBy !== null) {
        commit('updateField', { path: 'report', value: response.data.report.grouped })
        commit('updateField', { path: 'dataGrouped', value: true })
      } else {
        commit('updateField', { path: 'report', value: response.data.report })
        commit('updateField', { path: 'dataGrouped', value: false })
      }

      commit('updateField', { path: 'searching', value: false })

    })
  }
}