import { translate } from '~/assets/javascript/localization/Localization';
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { getField, updateField } from "vuex-map-fields";

const getEventsUrl = "/data_api/events/users";

AxiosWrapper.init();

const getDefaultState = () => ({
  events: [],
  sortOrder: [
    {
      field: 'created_at',
      sortField: 'created_at',
      direction: 'desc'
    }
  ],
  loadingMessage: translate('no_data_available'),
  loading: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      events: state.events
    } = getRequestedData );
  },
  removeEventAtIndex(state, index) {
    state.events.splice(index, 1);
  },
  updateField
}

export const actions = {
  async init({commit}) {
    commit("resetState")

    commit("updateField", { path: "loading", value: true });
    AxiosWrapper.get(getEventsUrl, null).then((response) => {
      commit("init", response.data);
      commit("updateField", { path: "loading", value: false });
    });
  },
  deleteAllEvents({commit, state}) {
    if (confirm(translate("delete_confirm"))) {
      commit("updateField", { path: "loading", value: true });
      AxiosWrapper.delete('/data_api/events/users/delete_all').then(() => {
        commit("updateField", { path: "loading", value: false });
        commit("updateField", { path: "events", value: []})
      });
    }
  },
  clearEvent({commit, state}, {clear_path, id}) {
    if (confirm(translate("delete_confirm"))) {
      commit("updateField", { path: "loading", value: true });

      var eventsObject = state.events.find((element) => {
        return element.id == id;
      });

      let index = state.events.findIndex(element => element.id == eventsObject.id );

      AxiosWrapper.delete(clear_path).then(() => {
        commit("updateField", { path: "loading", value: false });
        commit('removeEventAtIndex', index)
      })
    }
  }
}