export default {
  defaultParamsMap() {
    return {
      zone: "zone_ids",
      job_id: "job_ids",
      job_revision_id: "job_revision_ids",
      defect_code_model: "defect_code_model_ids",
      shift_description: "shift_description_ids",
      part_number: "part_numbers_constraint_ids",
      serial_number: "serial_numbers_constraint_ids",
      lot_number: "lot_numbers_constraint_ids",
      other1: "other1s_ids",
      other2: "other2s_ids",
      start_date: "start_date",
      end_date: "end_date",
      device: "device",
      job_status: "job_status",
      work_area_id: "work_area_ids",
      post_id: "post_ids"
    }
  },
  defaultRevertParamsMap() {
    return {
      zone_ids: "zone",
      job_ids: "job_id",
      job_revision_ids: "job_revision_id",
      defect_code_model_ids: "defect_code_model_id",
      shift_description_ids: "shift_description",
      part_numbers_constraint_ids: "part_number",
      serial_numbers_constraint_ids: "serial_number",
      lot_numbers_constraint_ids: "lot_number",
      other1s_ids: "other1",
      other2s_ids: "other2",
      start_date: "start_date",
      end_date: "end_date",
      device: "device",
      job_status: "job_status",
      work_area_ids: "work_area_id",
      post_ids: "post_id"
    }
  }
}