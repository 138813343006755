import { getField, updateField } from 'vuex-map-fields';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { cloneDeep } from 'lodash'
import gtmEvents from "~/assets/javascript/gtmEvents"

const getDefaultState = () => ({
  examResults: [],
  items_per_page: 10,
  page: 1,
  hasSearched: false,
  didSearchClick: false,
  paramsPath: '',
  user: null,
  showingChild: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initDataFromGet(state, getRequestData) {
    ({
      total: state.total,
      total_pages: state.total_pages,
    } = getRequestData);

    state.examResults = getRequestData.exam_results || []
    state.user = getRequestData.user
  },
  resetExamResult(state, { rowData, resetUser }) {
    rowData.has_reset = true
    rowData.reset_by_user = resetUser
  }
}

export const actions = {
  initByUser({commit, dispatch, rootState }, userId) {
    commit("updateField", { path: "examResults", value: [] })

    let params = AxiosWrapper.getSearchParams();

    gtmEvents.reportGenerated({
      type: "Exam Results",
      context: "By User"
    })

    dispatch("prepAndSearch", { paramsObject: { ...params, user_id: userId } })
  },
  init({ commit, dispatch }) {
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    let labels = {
      'exam': 'selectedExams',
      'job': 'selectedJobs',
      'user': 'selectedUsers',
      'tag': 'selectedTags'
    }

    if (params.search_by === 'exam') {
      labels = {
        'exam': 'selectedExams',
        'version': 'selectedVersions',
        'user': 'selectedUsers'
      }
    }

    commit('contentWithHeader/updateField', { path: 'selectsLabels', value: labels }, { root: true })
    commit('contentWithHeader/updateField', { path: 'showDates', value: false }, { root: true })

    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
    dispatch('contentWithHeader/update', null, { root: true })
  },
  async getCallForSearch({ commit, dispatch }, params) {
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'TrainingResultsSearch', pathWithParams);
    commit('updateField', { path: "paramsPath", value: AxiosWrapper.buildSearchUrl(params)})

    this.$axios.get('/data_api/training/exam_results/search', { params }).then(({ data }) => {
      commit('updateField', { path: 'hasSearched', value: true })
      commit('updateField', { path: 'didSearchClick', value: false })
      commit('initDataFromGet', data)
      dispatch("contentWithHeader/update", null, { root: true })
    })
  },
  async search({ dispatch, rootState, commit }) {
    commit("updateField", { path: "examResults", value: [] })

    let params = cloneDeep(rootState.selects.params)
    params['user_id'] = null

    gtmEvents.reportGenerated({
      type: "Exam Results"
    })

    dispatch("prepAndSearch", {paramsObject: params})
  },
  async prepAndSearch({ dispatch, rootState, state }, {paramsObject, searchBy}) {
    let params = {
      ...ConfigHelper.mapParams(paramsObject, ConfigHelper.defaultRevertParamsMap()),
      items_per_page: state.items_per_page,
      page: state.page,
      search_by: (searchBy ? searchBy : rootState['selects']['searchBy'])
    }

    dispatch("getCallForSearch", params)
  },
  prep({rootState, state, commit}) {
    let paramsObject = cloneDeep(rootState.selects.params)
    paramsObject['user_id'] = null

    let params = {
      ...ConfigHelper.mapParams(paramsObject, ConfigHelper.defaultRevertParamsMap()),
      items_per_page: state.items_per_page,
      page: state.page,
      search_by: rootState['selects']['searchBy']
    }

    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'TrainingResultsSearch', pathWithParams);
    commit('updateField', { path: "paramsPath", value: AxiosWrapper.buildSearchUrl(params) })
  }
}