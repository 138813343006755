import { render, staticRenderFns } from "./EdnMultiselect.vue?vue&type=template&id=1b547a58&lang=pug"
import script from "./EdnMultiselect.vue?vue&type=script&lang=js"
export * from "./EdnMultiselect.vue?vue&type=script&lang=js"
import style0 from "./EdnMultiselect.vue?vue&type=style&index=0&id=1b547a58&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports