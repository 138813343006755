import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { compact } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  customEmbeds: []
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateCustomEmbed(state, { customEmbed }) {
    let index = state.customEmbeds.findIndex(c => c.id === customEmbed.id)

    if (index > -1) {
      state.customEmbeds.splice(index, 1, customEmbed)
    } else {
      state.customEmbeds.unshift(customEmbed)
    }
    // Vue.set(state.customEmbeds, customEmbed.id, customEmbed)

    state.customEmbeds = compact(state.customEmbeds)
  },
  removeCustomEmbed(state, customEmbedId) {
    let index = state.customEmbeds.findIndex(c => c.id === customEmbedId)

    state.customEmbeds.splice(index, 1)
  },
  updateField,
}

export const actions = {
  init({ commit }) {
    commit("resetState")

    this.$axios.get(`/data_api/custom_embeds`)
      .then(({ data }) => {
        commit('updateField', { path: 'customEmbeds', value: data })
      })
  },
  getEmbed({}, embedId) {
    return this.$axios.get(`/data_api/custom_embeds/${embedId}`)
  },
  newCustomEmbed({ commit }) {
    let customEmbed = { id: 0, label: null, code: null }
    commit('updateCustomEmbed', { customEmbed })
  },
  saveCustomEmbed({ commit }, customEmbed) {
    let object = { id: customEmbed.id, label: customEmbed.label, code: customEmbed.code }

    AxiosWrapper.postOrPatch('/data_api/custom_embeds', { custom_embed: object })
      .then(response => {
        commit('updateCustomEmbed', { customEmbed: response.data.custom_embed })
        if (object.id == 0) { commit('removeCustomEmbed', object.id) }
      })
  },
  deleteCustomEmbed({ commit }, customEmbed) {
    AxiosWrapper.delete(`/data_api/custom_embeds/${customEmbed.id}`)
      .then(() => {
        commit('removeCustomEmbed', customEmbed.id)
      }
    );
  },
}