import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'
import { forOwn } from 'lodash'
import { toList, formatDate } from "~/components/ContentWithHeader/searchFieldsSummaryFunctions"

const getDefaultState = () => ({
  headerComponentName: null,
  showFilters: false,
  labelValues: {},
  isScrolledDown: false,
  selectsLabels: {
    'zone': 'selectedZones',
    'job': 'selectedJobs',
    'job_revision': 'selectedJobRevisions',
  },
  showDates: true
})

export const state = getDefaultState

export const getters = {
  getField,
  labelValues(state) {
    return state.labelValues
  }
}

export const mutations = {
  updateField,
  setLabelValues(state, newLabelValues) {
    state.labelValues = newLabelValues
  },
  resetLabels(state) {
    state.selectsLabels = getDefaultState().selectsLabels
  },
}

export const actions = {
  update({ rootState, commit, rootGetters, state }, callback) {
    let labelValues = {}

    forOwn(state.selectsLabels, (v, k) => {
      labelValues[translate(k)] = toList(rootState.selects[v])
    })

    if (state.showDates) {
      labelValues[translate('fromm')] = formatDate(rootGetters['datetime/getStartDate']),
      labelValues[translate('to')] = formatDate(rootGetters['datetime/getEndDate'])
    }

    if (callback) labelValues = callback(labelValues)

    commit('setLabelValues', labelValues)
  }
}

