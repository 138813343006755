import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'

const getDefaultState = () => ({
  zonesWithJobs: []
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setZonesWithJobs(state, zones) {
    state.zonesWithJobs = zones;
  },
  setCodesForModel(state, {model, codes}) {
    model.codes = codes
  }
}

export const getters = {
  zonesWithJobs(state) { return state.zonesWithJobs }
}

export const actions = {
  getCodesForModel({commit}, model) {
    if (model.codes.length === 0) {
      AxiosWrapper.get(`/data_api/suspect_code_models/${model.id}/suspect_codes`).then(response => {
        commit("setCodesForModel", { model: model, codes: response.data.codes })
      })
    }
  },
  init({ state, commit, dispatch }) {
    commit("resetState")

    AxiosWrapper.init(this.$axios)

    return AxiosWrapper.get('/data_api/index_for_customer')
      .then((response) => {
        commit('setZonesWithJobs', response.data.zones)
      })
  }
}