import Vue from "vue"

import StringListCell from "~/components/table/StringListCell";
Vue.component("StringListCell", StringListCell)
import QuestionHeader from "~/components/Questions/Header"
Vue.component("QuestionHeader", QuestionHeader)
import NavigationHeader from "~/components/JobRevisions/NavigationHeader"
Vue.component("NavigationHeader", NavigationHeader)
import DowntimeHeader from '~/components/JobRevisions/downtime/Header'
Vue.component("DowntimeHeader", DowntimeHeader)
import BinFormHeader from "~/components/Bins/BinFormHeader"
Vue.component("BinFormHeader", BinFormHeader)
import ByPartNumberHeader from '~/components/JobRevisions/ByPartNumberHeader'
Vue.component("ByPartNumberHeader", ByPartNumberHeader)
import JobRevisionHeader from '~/components/common/JobRevisionHeader';
Vue.component("JobRevisionHeader", JobRevisionHeader)
import ShiftSummaryHeader from '~/components/JobRevisions/ShiftSummaryHeader'
Vue.component("ShiftSummaryHeader", ShiftSummaryHeader)
import SuspectCodeModelsHeader from "~/components/JobRevisions/SuspectCodeModelsHeader";
Vue.component("SuspectCodeModelsHeader", SuspectCodeModelsHeader);
import ReferencePicturesHeader from '~/components/JobRevisions/ReferencePicturesHeader'
Vue.component("ReferencePicturesHeader", ReferencePicturesHeader)
import ShiftsHeader from '~/components/JobRevisions/ShiftsHeader'
Vue.component("ShiftsHeader", ShiftsHeader)
import ImportVerificationWorkItemsHeader from "~/components/Importers/ImportVerificationWorkItemsHeader"
Vue.component("ImportVerificationWorkItemsHeader", ImportVerificationWorkItemsHeader)
import PTSHeader from "~/components/PayableTimeSummary/PTSHeader";
Vue.component("PTSHeader", PTSHeader)