import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { translate } from '~/assets/javascript/localization/Localization'
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  summaryReportHits: [],
  availableSuspectCodes: [],
  hasSearched: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
  },
  async search({ dispatch, rootGetters, rootState }) {
    gtmEvents.reportGenerated({
      type: "Heatmaps"
    })

    let params = {
      ...ConfigHelper.mapParams(rootState.selects.params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'HeatmapAnswerSearch', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async getCallForSearch({ dispatch, commit }, params) {
    AxiosWrapper.get('/data_api/heatmaps/answer_search', { params }).then(({data}) => {
      commit("updateField", {
        path: "hasSearched",
        value: true
      });
      dispatch("setResponseData", data);
      dispatch("contentWithHeader/update", labelValues => {
        delete labelValues[translate('job_revisions')]
        return labelValues
      }, {root: true})
    })
  },
  async setResponseData({commit}, data) {
    commit("updateField", {
      path: "summaryReportHits",
      value: data.summary_report_hits
    });
    commit("updateField", {
      path: "availableSuspectCodes",
      value: data.available_suspect_codes
    });
  }
}