import AxiosWrapper from "~/assets/javascript/AxiosWrapper"
import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'
import ModalHelper from "~/components/ModalHelper"

const getDefaultState = () => ({
  showModal: false,
  licenceZoneId: null,
  allZones: null,
  licenceId: null,
  zonedLicenses: [],
  nonZonedLicences: [],
  isUserSuperuser: null,
  permissions: null,
  loadingMessage: translate('loading'),
  sortOrder: [{
      field: '__component:device-qr-code',
      sortField: 'grouping',
      direction: 'asc'
  }]
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setShowModal(state, value) {
    state.showModal = value
  },
  setLicenceId(state, id) {
    state.licenceId = id
  },
  setInitData(state, data) {
    state.allZones = data.all_zones;
    state.licenceZoneId = data.licence_zone_id;
    if (state.licenceZoneId == null) {
      state.licenceZoneId = (state.allZones[0]).id
    }
  },
  setUnassignedLicencesData(state, data) {
    state.nonZonedLicences = data.non_zoned_licenses;
    state.isUserSuperuser = data.is_user_superuser;
    state.zonedLicenses = data.zoned_licences;
    if (state.zonedLicenses.length == 0) state.loadingMessage = translate('error_loading')
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async openModal({ commit }, id) {
    commit('setLicenceId', id)
    AxiosWrapper.get(`/data_api/devices/assign_zone?licence_id=${id}`).then(({ data }) => {
      commit('setInitData', data)
      ModalHelper.addShowParamToUrl('show_assign_zone', id, 'Assign Zone')
      commit("setShowModal", true)
    })
  },
  async closeModal({ commit }) {
    ModalHelper.removeShowParamFromUrl('show_assign_zone', 'Unassigned Licences')
    commit('setLicenceId', null)
    commit("setShowModal", false)
  },
  async submitButtonPressed({ state }, router) {
    ModalHelper.removeShowParamFromUrl('show_assign_zone', 'Unassigned Licences')
    AxiosWrapper.patch(`/data_api/devices/update_licence?licence_id=${state.licenceId}`, { zone_id: state.licenceZoneId }).then(router.go())
  },
  async initUnassignedLicences({ dispatch, commit }) {
    AxiosWrapper.get(`/data_api/devices/unassigned`).then(({ data }) => {
      commit('setUnassignedLicencesData', data)
      let params = AxiosWrapper.getSearchParams()
      if (Object.keys(params).includes('show_assign_zone')) dispatch('openModal', params['show_assign_zone'])
    })
  }
}