import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';
import { cloneDeep } from 'lodash'

export const getDefaultState = () => ({
  canUserCreateQuestions: false,
  canUserCreateExpansions: false,
  questionnairesUrl: null,
  loadedTabs: [],
  group: null,
  questionnaire: {
    file_store_attributes: {}
  },
  symbols: [],
  optionsForDefectCodeModels: {},
  initialized: false,
  removedMapping: [],
  newColourMapping: {
    score: 0,
    symbol: "==",
    name: "equals",
    selectedSymbol: { value: "==", name: translate("equals") },
    colour: null
  }
})

export const state = getDefaultState

export const getters = {
  getField,
  breadcrumbs(state) {
    if (state.group) {
      return [
        { text: translate('questionnaire_groups'), path: '/audit/questionnaire_groups' },
        { text: translate('questionnaires'), path: `/audit/questionnaire_groups/${state.group['id']}/questionnaires` },
        { text: translate('questionnaire') },
      ]
    }
    return []
  }
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  addFile(state, file) {
    Vue.set(state.questionnaire.file_store_attributes, "file", file);
  },
  removeFile(state) {
    state.questionnaire.file_store_attributes._destroy = true
    state.questionnaire.file_store_attributes.file_url = null
  },
  addLoadedTab(state, tabIndex) {
    state.loadedTabs.push(tabIndex)
  },
  setCanUserCreateQuestions(state, value) {
    state.canUserCreateQuestions = value
  },
  setCanUserCreateExpansions(state, value) {
    state.canUserCreateExpansions = value
  },
  addColourMapping(state) {
    state.questionnaire.score_colour_code_attributes.colour_mappings_attributes.push({...state.newColourMapping})

    state.newColourMapping = {
      score: 0,
      symbol: "==",
      name: "equals",
      colour: null,
      selectedSymbol: { value: "==", name: translate("equals") }
    }
  },
  removeColourMapping(state, index) {
    let mapping = state.questionnaire.score_colour_code_attributes.colour_mappings_attributes[index]
    mapping["_destroy"] = true

    if (mapping.id) {
      state.removedMapping.push(mapping)
    }

    state.questionnaire.score_colour_code_attributes.colour_mappings_attributes.splice(index, 1)
  },
  updateSymbol(state, {index, symbol}) {
    let mapping = state.questionnaire.score_colour_code_attributes.colour_mappings_attributes[index]
    mapping.selectedSymbol = symbol
    mapping.symbol = symbol.value
    mapping.name = symbol.name

    state.questionnaire.score_colour_code_attributes.colour_mappings_attributes.splice(index, 1, mapping)
  },
  init(state, {questionnaire, options_for_defect_code_models, symbols}) {
    state.optionsForDefectCodeModels = options_for_defect_code_models;
    state.symbols = symbols
    state.optionsForDefectCodeModels = [
      {
        name: I18n.translate('none'),
        defect_code_models: [{ name: I18n.translate('please_select'), id: null }]
      },
      ...state.optionsForDefectCodeModels.map(e => {
        return {
          name: e[0], defect_code_models: e[1].map(dc => {
            return { name: dc[0], id: dc[1] }
          })
        }
      })
    ]

    state.questionnaire = questionnaire
    state.group = questionnaire.group
    state.initialized = true;
    if (state.questionnaire.score_colour_code_attributes) {
      if (state.questionnaire.score_colour_code_attributes.colour_mappings_attributes) {
        state.questionnaire.score_colour_code_attributes.colour_mappings_attributes.forEach((c) => {
          c.selectedSymbol = state.symbols.find(s => s.value == c.symbol)
        })
      }
    }
  },
  updateField
}

export const actions = {
  save({state, dispatch}) {
    const auditQuestionnaireUrl = "/data_api/audit/questionnaires";

    let questionnaireToSubmit = cloneDeep(state.questionnaire)

    if (state.questionnaire.score_colour_code_attributes) {
      questionnaireToSubmit.score_colour_code_attributes.colour_mappings_attributes = [...state.questionnaire.score_colour_code_attributes.colour_mappings_attributes,
      ...state.removedMapping]
    }

    return AxiosWrapper.postOrPatch(auditQuestionnaireUrl, {
      audit_questionnaire: questionnaireToSubmit,
      id: state.questionnaire.id
    }).then(() => {
      this.removedMapping = []

      dispatch("init", state.questionnaire.id)

      return new Promise((resolve, reject) => { resolve() })
    });
  },
  init({ commit }, questionnaireId) {
    commit("resetState")
    this.$axios.get(`/data_api/audit/questionnaires/${questionnaireId}`)
      .then(response => {
        commit('init', response.data)
      })
  }
}