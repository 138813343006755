import { getField, updateField } from 'vuex-map-fields';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

const getDefaultState = () => ({
  examResult: null
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setResponseExpanded(state, {response, value}) {
    Vue.set(response, "expanded", value);
  },
}

export const actions = {
  init({ commit, dispatch }, examResultId) {
    commit("resetState")

    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    let labels = {
      'exam': 'selectedExams',
      'job': 'selectedJobs',
      'user': 'selectedUsers'
    }

    if (params.search_by === 'exam') {
      labels = {
        'exam': 'selectedExams',
        'version': 'selectedVersions',
        'user': 'selectedUsers'
      }
    }

    commit('contentWithHeader/updateField', { path: 'selectsLabels', value: labels }, { root: true })
    commit('contentWithHeader/updateField', { path: 'showDates', value: false }, { root: true })

    dispatch('contentWithHeader/update', null, { root: true })

    this.$axios.get(`/data_api/training/exam_results/${examResultId}`).then((response) => {
      commit("updateField", { path: "examResult", value: response.data.exam_result })
    })


  },
}