import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'

import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  shiftDescriptions: {},
  currentUser: {},
  lessees: [],
  zones: [],
  saveInProgress: false,
  timeFrames: [],
  maximumDailyHours: null,
  billableHour: 0.25
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateShiftDescription(state, { shiftDescription }) {
    Vue.set(state.shiftDescriptions, shiftDescription.id, shiftDescription)
  },
  removeShiftDescription(state, shiftDescriptionId) {
    Vue.delete(state.shiftDescriptions, shiftDescriptionId)
  },
  addTimeFrame(state, newTimeFrame) {
    state.timeFrames.push(newTimeFrame)
  }
}

const prepTimeAttribute = function(number) {
  return String(number).padStart(2, "0");
};

export const actions = {
  async init({ commit }) {
    commit("resetState")

    this.$axios.get(`/data_api/shift_descriptions`)
      .then(({data}) => {
        commit('updateField', { path: 'lessees', value: data.lessees })
        commit('updateField', { path: 'zones', value: data.zones })
        commit('updateField', { path: 'currentUser', value: data.current_user })
        commit('updateField', { path: 'shiftDescriptions', value: { ...data.shift_descriptions } })
        commit('updateField', { path: 'timeFrames', value: data.time_frames.data })
        commit('updateField', { path: 'maximumDailyHours', value: data.maximum_daily_hours })
        commit('updateField', { path: 'billableHour', value: data.next_hour })
      })
  },
  createTimeFrame({ commit }, newTimeFrame) {
    let timeFrame = {
      from_time:
        prepTimeAttribute(newTimeFrame.fromHours) +
        ":" +
        prepTimeAttribute(newTimeFrame.fromMinutes) +
        newTimeFrame.fromMeridiem,
      to_time:
        prepTimeAttribute(newTimeFrame.toHours) +
        ":" +
        prepTimeAttribute(newTimeFrame.toMinutes) +
        newTimeFrame.toMeridiem
    };
    return this.$axios.post("/data_api/time_frames/", timeFrame)
      .then(response => {
        commit("addTimeFrame", response.data.data)
        return response;
      })
  },
  newShiftDescription({ commit, state }) {
    let shiftDescription = { id: 0, name: null, lessee_id: state.lessees.current.id }
    commit('updateShiftDescription', { shiftDescription })
  },
  submitShiftDescription({ commit, dispatch }, shiftDescription) {
    commit("updateField", { path: "saveInProgress", value: true })
    shiftDescription.time_frame_ids = shiftDescription.time_frames.map(tf => tf.id);
    delete shiftDescription.timeframes;
    return AxiosWrapper.postOrPatch('/data_api/shift_descriptions', { shift_description: shiftDescription })
      .then(response => {
        commit('updateShiftDescription', { shiftDescription: response.data })
        dispatch('updateZoneAssignments')
        if (shiftDescription.id == 0) { commit('removeShiftDescription', shiftDescription.id) }
        commit("updateField", { path: "saveInProgress", value: false })
      })
  },
  deleteShiftDescription({ commit, dispatch }, { shiftDescription }) {
    AxiosWrapper.delete(`/data_api/shift_descriptions/${shiftDescription.id}`)
      .then(response => {
        commit('removeShiftDescription', shiftDescription.id)
        dispatch('updateZoneAssignments')
      }
      );
  },
  updateZoneAssignments({ commit }) {
    AxiosWrapper.get(`/data_api/shift_descriptions/get_zone_assignments`)
      .then(response => {
        commit('updateField', { path: 'zones', value: response.data.zones })
      })
  },
}