import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import ListMethods from '~/components/lists/commonListMethods'
import cloneDeep from 'lodash/cloneDeep'
import { updateField, getField } from 'vuex-map-fields'
import Vue from 'vue'
const getDefaultState = () => ({
  allowMultipleSelection: false,
  useDocumentsAsOptions: false,
  options: [],
  originalOptions: []
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question)
  },
  updateOriginalOptions(state){
    state.originalOptions = cloneDeep(state.options)
  },
  updateWithMultiSelectOptionsAndParams(state, params){
    params.allow_multiple_selection = state.allowMultipleSelection
    params.use_documents_as_options = state.useDocumentsAsOptions

    let options = cloneDeep(state.options)
    ListMethods.indexToPositions(options)
    ListMethods.addDestroyedItems(state.originalOptions, options)
    params.options_attributes = options
  },
  updateOptionCriteria(state, {optionIndex, criteria}) {
    Vue.set(state.options[optionIndex], 'criteria_html', criteria)
  },
  updateField
}

export const getters = {
  getField,
}

export const actions = {
  async init({ dispatch}, params) {
    dispatch('questions/editQuestions/init', { params, questionControllerName: 'audit_multi_select_question', name: 'multiSelect' }, { root: true })
  },
  async configureInitState({ commit, dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', null, { root: true })
    commit('updateOriginalOptions')
  },
  async configureParams({ commit }, params) {
    commit("questions/editQuestions/setWeightAttributesOn", params, { root: true });
    commit('updateWithMultiSelectOptionsAndParams',params)
  }
}