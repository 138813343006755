export default function ({ redirect, route, store, app }) {
  let normalizedPath = route.path.replace(/[0-9]+/g, "numberId")
  switch (normalizedPath) {
    case "/":
      if (store.getters["login/isCustomer"]) {
        return redirect("/jobs/index_for_customer")
      } else {
        return redirect("/jobs")
      }
      break;
    case "/scheduled_questionnaires":
      if (store.getters["login/isCustomer"]) {
        app.$toast.error(I18n.t("unauthorized"), { duration: 10000 })
        return redirect("/")
      }
      break;
    case "/superuser_only":
      if (!store.getters["login/isSuperuser"]) {
        app.$toast.error(I18n.t("unauthorized"), { duration: 10000 })
        return redirect("/");
      }
      break;
    case "/left_early_reasons":
      if (!store.getters["login/isTimeSheetAdministrator"]) {
        app.$toast.error(I18n.t("unauthorized"), { duration: 10000 })
        return redirect("/");
      }
      break;
    case "/jobs/numberId":
      return redirect(route.path + "/edit")
      break;
  }
}