<template lang="pug">
  modal(v-if="showLoginModal",
        :description="translate('enter_password_left_off')"
        :modalTitle="translate('safe_logged_out')",
        wrapDescription,
        maxWidth="20rem",
        overflow="hidden")
    template(slot="closeAction")
      a.text-nowrap(href="#", @click.prevent="logout") {{ 'logout' | translate }}
    .d-flex.flex-column.align-items-stretch
      .d-flex.justify-content-between
        label.font-weight-bold(for="password") {{ 'password' | translate }}
        label
          nuxt-link(to='/login/forgot_password', target="_blank") {{ 'forgot_password?' | translate }}
      .mb-2(:class="errorClassFor('password')")
        input.password.form-control(ref="password",
                                    data-cy='password',
                                    type="password",
                                    name="password",
                                    tabindex="2",
                                    class="input",
                                    :placeholder="'password' | translate",
                                    v-model="password",
                                    @keyup.enter="submit")
        .invalid(v-show="$v.password.$error") {{ 'incorrect_password_entered' | translate }}
      input.btn.btn-block.btn-primary(@click.prevent="submit",
                                      data-cy='submit',
                                      type='submit',
                                      :value="'log_in' | translate",
                                      tabindex="4")
</template>

<script>
import Modal from "~/components/common/Modal";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: { Modal },
  validations: {
    password: { required, minLength: minLength(7) }
  },
  data() {
    return {
      password: ""
    };
  },
  computed: {
    email() {
      return this.user ? this.user.email : "";
    },
    ...mapFields("login", ["user", "showLoginModal"])
  },
  methods: {
    ...mapActions("login", ["loginAgain", "logout"]),
    submit() {
      this.$v.password.$touch();

      if (!this.$v.password.$error) {
        this.loginAgain({
          email: this.email,
          password: this.password,
          accept_nda: true
        }).then(() => {
          this.password = "";
          this.$v.password.$reset();
          this.showLoginModal = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/colors.scss";
.hasError {
  input {
    border-color: $red;
  }

  label {
    color: $red4;
  }

  .invalid {
    color: $red4;
    width: 100%;
  }
}
</style>
