<template lang="pug">
  .d-flex.justify-content-between.align-items-end.container
    div
      Breadcrumbs(:breadcrumbs="breadcrumbs",
                :truncateTo="25",
                v-if="!isScrolledDown")
      h1 {{ questionHeaderName | translate }}
    .d-flex
      nuxt-link.btn.btn-outline-light(:to="backUrl") {{ "back" | translate }}
      button.btn.btn-primary(@click.prevent="$eventBus.$emit('questionForm-SaveChangesClicked')",
                              data-cy="saveQuestionButton") {{ "save_changes" | translate }}
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('questions/editQuestions',['questionHeaderName', 'questionnaireId']),
    ...mapFields('header',['isScrolledDown']),
    backUrl() {
      return `/audit/questionnaires/${this.questionnaireId}/edit/questions`
    },
    breadcrumbs() {
      return [
        { text: this.translate('questions'), path: `/audit/questionnaires/${this.questionnaireId}/edit/questions`},
        { text: this.translate('question') }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
    padding: 0px;
    margin: 0px;
}
</style>

