import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { translate } from '~/assets/javascript/localization/Localization';
import { addDestroyedItems } from '~/components/lists/commonListMethods'
import { debounce, cloneDeep } from "lodash";

import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  logs: [],
  jobRevisionId: AxiosWrapper.getNextPathNameElement("job_revisions"),
  jobId: AxiosWrapper.getNextPathNameElement("jobs"),
  jobRevision: {},
  logTimeDifference: [],
  reasons: [],
  originalReasons: [],
  reasonsAttributes: [],
  loadingMessage: translate("no_data_available"),
  saveMode: false,
  invalidName: true,
  disableList: false,
  sortOrder: [
    {
      field: "updated_at",
      sortField: "updated_at",
      direction: "asc"
    }
  ],
  saveInProgress: false
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateDescription(state, { getters, newReasonDescription, id }) {
    let reason = getters.reasons.find(t => {
      return t.id === id
    })
    reason.clonedObject.description = newReasonDescription
  },
  removeReason(state, createdReasonId) {
    Vue.delete(state.reasons, createdReasonId)
  },
  editMode(state, { rowData }) {
    Vue.set(rowData, 'clonedObject', cloneDeep(rowData))
    rowData.is_editing = true
    Vue.set(state.reasons, rowData.id, rowData);
  },
  cancel(state, { rowData }) {
    Vue.delete(rowData, 'clonedObject')
    rowData.is_editing = false
  }
}

export const actions = {
  async init({ dispatch, commit }) {
    commit("resetState")

    dispatch('initLogs');
    dispatch('initReasons');
  },
  async initLogs({ commit, state }) {
    AxiosWrapper.get(`/data_api/job_revisions/${state.jobRevisionId}/downtime/logs`).then(
      response => {
        commit("updateField", { path: "logs", value: response.data.logs })
        commit("updateField", { path: "logTimeDifference", value: response.data.log_time_difference })
      }
    );
  },
  async initReasons({ commit }) {
    AxiosWrapper.get(`/data_api/downtime/reasons`).then(
      response => {
        let reasons = response.data.reasons
        commit("updateField", { path: "reasons", value: reasons })
        commit("updateField", { path: "originalReasons", value: reasons })
      }
    )
  },
  updateDescription({ commit, getters }, { newReasonDescription, id }) {
    commit("updateDescription", { getters, newReasonDescription, id })
  },
  updateAllReasons({ dispatch, state }) {
    let reasons = cloneDeep(state.reasons)
    addDestroyedItems(state.originalReasons, reasons)
    return dispatch("submitReasons", reasons)
  },
  submitReasons({ commit }, reasons) {
    let data = {
      downtime_reason: {
        downtime_reasons_attributes: reasons
      }
    }

    commit("updateField", { path: "disableList", value: true })
    return this.$axios.patch(`/data_api/downtime/reasons/update_all`, data)
      .then((response) => {
        commit("updateField", { path: "reasons", value: response.data.reasons })
        commit("updateField", { path: "originalReasons", value: response.data.reasons })
        commit("updateField", { path: "disableList", value: false })
      })
  },
  deleteReason({ commit }, item) {
    commit("updateField", { path: "disableList", value: true })
    return this.$axios.delete(`/data_api/downtime/reasons/${item.id}`)
      .then(response => {
        commit("removeReason", item.id)
        commit("updateField", { path: "disableList", value: false })
      })
  },
  edit({ commit }, payload) {
    commit("editMode", payload);
  },
  cancel({ commit }, payload) {
    let { rowData } = payload;

    if (rowData.id == 0) {
      commit('removeReason', 0)
    } else {
      commit('cancel', payload)
    }
  }
}