import AxiosWrapper from "~/assets/javascript/AxiosWrapper";

const getDevicesUrl = '/data_api/devices/search';

AxiosWrapper.init();

import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  devices: [],
  loading: false,
})

export const state = getDefaultState

export const getters = {
  getField,
  devices(state) {
    return state.devices
  },
  loading(state) {
    return state.loading
  }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      serialized_devices: state.devices
    } = getRequestedData);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  deleteDevice(state, { id, groupId }) {
    var zonesDevicesObject = state.devices.find(function (element) {
      return element.groupData.id == groupId;
    });
    let index = zonesDevicesObject.data.findIndex(device => device.id == id);

    zonesDevicesObject.data.splice(index, 1);
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    return AxiosWrapper.get(getDevicesUrl, { params: {
      assigned: true
    } }).then(response => {
        commit("init", response.data);

        return new Promise((resolve, reject) => resolve() )
      }
    );
  },
  removeDevice({ commit, state }, rowData) {
    let deleteUrl = `/data_api/devices/${rowData.id}`
    AxiosWrapper.delete(deleteUrl).then(() => {
      commit("setLoading", false);
      commit("deleteDevice", rowData);
    });
  }
}