import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import groupBy from 'lodash/groupBy'

const getDefaultState = () => ({
  workAreas: {},
  zones: {},
  workAreaFormData: {},
  showWorkAreaModal: false,
  postFormData: {},
  showPostModal: false,
  showAssignWorkAreaToJobModal: false,
  assigningWorkArea: {},
  showAssignPostToJobModal: false,
  assigningPost: {},
  afterDelete: (
    function () {
      return true;
    })
})

export const state = getDefaultState

export const getters = {
  getField,
  workAreas: state => { return groupBy(state.workAreas, 'zone_id') }
}

export const mutations = {
  editWorkArea(state, workArea) {
    state.workAreaFormData = {
      id: workArea.id,
      name: workArea.name,
      zone_id: workArea.zone_id
    };
    state.showWorkAreaModal = true;
  },
  newWorkArea(state) {
    state.workAreaFormData = { zone_id: null, name: "" };
    state.showWorkAreaModal = true;
  },
  newPost(state, workArea) {
    state.postFormData = { name: "", work_area_id: workArea.id };
    state.showPostModal = true;
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateWorkArea(state, { workArea }) {
    Vue.set(state.workAreas, workArea.id, workArea)
  },
  removeWorkArea(state, workAreaId) {
    Vue.delete(state.workAreas, workAreaId)
  },
  updatePost(state, { post }) {
    Vue.set(state.workAreas[post.work_area_id].posts, post.id, post)
    Vue.set(state.posts, post.id, post)
  },
  addPostToWorkArea(state, { post, workArea }) {
    Vue.set(workArea.posts, post.id, post)
  },
  removePost(state, {post, workArea}) {
    Vue.delete(state.workAreas[post.work_area_id].posts, post.id)

    let parsedobj = JSON.parse(JSON.stringify(state.workAreas[post.work_area_id].posts))
    state.afterDelete(workArea, Object.keys(parsedobj).length)
  },
  assignWorkAreaToJob(state, workArea) {
    state.showAssignWorkAreaToJobModal = true;
    state.assigningWorkArea = workArea;
  },
  assignPostToJob(state, post) {
    state.showAssignPostToJobModal = true;
    state.assigningPost = post;
  },
  updateJobIds(state, { assignedObject, jobIds }) {
    assignedObject.job_ids = jobIds;
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/work_areas`)
      .then(response => {
        commit('updateField', { path: 'workAreas', value: response.data.work_areas || {} })
        commit('updateField', { path: 'posts', value: { ...response.data.posts || {} } })
        commit('updateField', { path: 'zones', value: response.data.zones || {} })
      })
  },
  submitWorkArea({ commit, state }) {
    AxiosWrapper.postOrPatch('/data_api/work_areas', { work_area: state.workAreaFormData })
      .then(response => {
        commit('updateWorkArea', { workArea: response.data })
      })
  },
  deleteWorkArea({ commit }, workArea) {
    this.$axios.delete(`/data_api/work_areas/${workArea.id}`)
      .then(() => commit('removeWorkArea', workArea.id));
  },
  submitPost({ commit, state }) {
    AxiosWrapper.postOrPatch('/data_api/posts', { post: state.postFormData })
      .then(response => {
        commit('updatePost', { post: { ...response.data, job_ids: response.data.jobs } })
      })
  },
  deletePost({ commit }, {post, workArea}) {
    this.$axios.delete(`/data_api/posts/${post.id}`)
      .then(() => commit("removePost", {post, workArea}))
  }
}