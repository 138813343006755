import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  isDisabled:false,
  cameraSettingsObject:{
    use_image: true,
    image_count_min: 1,
    image_count_max: 1,
    use_audio: false,
    audio_max: 10,
    use_video: false,
    video_max: 10
  },
  isUserSuperuser: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initializeCameraSettings(state, settings){
    state.cameraSettingsObject = settings
  },
  setDisabled(state, disable){
    state.isDisabled = disable
  }
}

export const getters = {
  getField,
}

export const actions = {
}