import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  what_is_header: null,
  index_header: null,
  new_mask_label: null,
  new_field_mask_path: null,
  masks: null,
  job: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      what_is_header: state.what_is_header,
      index_header: state.index_header,
      new_mask_label: state.new_mask_label,
      new_field_mask_path: state.new_field_mask_path,
      masks: state.masks,
      job: state.job
    } = responseData)
  },
  removeMask(state, indexValue){
    state.masks.splice(indexValue, 1)
  }
}

export const getters = {
  getField,
}

export const actions = {
  init({ commit }, params) {
    return this.$axios.get(`/data_api/barcode/jobs/${params.job_id}/${params.mask_type}/work_item_field_masks`).then(({ data })  => commit('initialGetCompleted', data))
  },
  deleteMask({ state, commit }, { params, id }){
    this.$axios.delete(`/data_api/barcode/jobs/${state.job.id}/${params.mask_type}/work_item_field_masks/` + id)
      .then(() => {
        let index = state.masks.findIndex((mask) =>  mask.id == id)
        commit('removeMask', index)
      })
  }
}