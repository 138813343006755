import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import groupBy from 'lodash/groupBy'

const getDefaultState = () => ({
  dashboardPanels: {},
  userPanels: {},
  templateTypes: [],
  lessees: {},
  showEditModal: false,
  formData: null
})

export const state = getDefaultState

export const getters = {
  getField,
  dashboardPanels: state => { return groupBy(state.dashboardPanels, 'type') },
  userPanels: state => { return groupBy(state.userPanels, 'user_name') }
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateDashboardPanel(state, { dashboardPanel }) {
    Vue.set(state.dashboardPanels, dashboardPanel.id, dashboardPanel)
  },
  removeDashboardPanel(state, dashboardPanelId) {
    Vue.delete(state.dashboardPanels, dashboardPanelId)
  },
  setFormDataToEdit(state, dashboardPanel){
    state.formData = {
      id: dashboardPanel.id,
      name: dashboardPanel.name,
      type: dashboardPanel.type,
      lessee_id: dashboardPanel.type == 'global_templates' ? null : state.lessees.current.id
    }

    state.showEditModal = true
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/dashboard/panels`)
      .then(response => {
        commit('updateField', { path: 'dashboardPanels', value: { ...response.data.dashboard_panels } })
        commit('updateField', { path: 'userPanels', value: response.data.user_panels })
        commit('updateField', { path: 'lessees', value: response.data.lessees })
        commit('updateField', { path: 'templateTypes', value: response.data.template_types })
      })
  },
  newDashboardPanel({ commit, state }, templateType) {
    let dashboardPanel = { id: 0, name: null, type: templateType, lessee_id: templateType == 'global_templates' ? null : state.lessees.current.id }
    commit('updateField', {path:'formData', value: dashboardPanel})
    commit('updateField', {path:'showEditModal', value:true})
    // commit('updateDashboardPanel', { dashboardPanel })
  },
  submitDashboardPanel({ commit, state, dispatch }, { dashboardPanel }) {
    AxiosWrapper.postOrPatch('/data_api/dashboard/panels', { dashboard_panel: dashboardPanel })
      .then(response => {
        commit('updateDashboardPanel', { dashboardPanel: response.data })
        commit('updateField', { path: 'showEditModal', value: false })
        commit('updateField', { path: 'formData', value: null })
      })
  },
  deleteDashboardPanel({ commit, dispatch }, { dashboardPanel }) {
    AxiosWrapper.delete(`/data_api/dashboard/panels/${dashboardPanel.id}`)
      .then(response => {
        commit('removeDashboardPanel', dashboardPanel.id)
      }
      );
  },
  copyDashboardPanel({ commit }, { dashboardPanel }) {
    AxiosWrapper.post(`/data_api/dashboard/panels/${dashboardPanel.id}/copy`, { dashboard_panel: dashboardPanel })
      .then(response => {
        commit('updateDashboardPanel', { dashboardPanel: response.data })
      })
  },
  editDashboardPanel({commit}, { dashboardPanel }){
    commit('setFormDataToEdit', dashboardPanel)
  }
}