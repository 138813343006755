import { getField, updateField } from "vuex-map-fields";
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import UrlManipulator from "~/assets/javascript/UrlManipulator";
import moment from "moment"
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { translate } from '~/assets/javascript/localization/Localization';
import { cloneDeep, unionWith, isEqual } from 'lodash'
import gtmEvents from "~/assets/javascript/gtmEvents"

const getSampleData = function () {
  return {
    activities: {
      0: [
        {
          id: 0,
          from: moment("Jan 1, 2001, 9:00 AM").toISOString(),
          to: moment("Jan 1, 2001, 10:00 AM").toISOString(),
          job_identifier: "HTR3021923901",
          role: "inspector",
          zone_name: "CCP1",
          regular_hours: "1.00",
          overtime_hours: "1.00"
        },
        {
          id: 1,
          from: moment("Jan 1, 2001, 9:00 AM").toISOString(),
          to: moment("Jan 1, 2001, 10:00 AM").toISOString(),
          job_identifier: "HTR3021923901",
          role: "inspector",
          downtime_reason: "No Parts",
          zone_name: "CCP1",
          regular_hours: "1.00",
          overtime_hours: "0.00"
        }
      ],
      1: [
        {
          id: 2,
          from: moment("Jan 1, 2001, 9:00 AM").toISOString(),
          to: moment("Jan 1, 2001, 10:00 AM").toISOString(),
          job_identifier: "COOL",
          role: "inspector",
          zone_name: "CCP1",
          regular_hours: "1.00",
          overtime_hours: "1.00"
        },
        {
          id: 3,
          from: moment("Jan 1, 2001, 9:00 AM").toISOString(),
          to: moment("Jan 1, 2001, 10:00 AM").toISOString(),
          job_identifier: "NICEEE",
          role: "inspector",
          downtime_reason: "No Parts",
          zone_name: "CCP1",
          regular_hours: "1.00",
          overtime_hours: "0.00"
        }
      ],
    },
    periods: [
      {
        weekstart: moment("Jan 27, 2019").toISOString(),
        employees: [
          {
            full_name: "Cool Beanerinos",
            id: 0,
            regular_hours: "5.00",
            overtime_hours: "3.00",
            time_sheets: [
              {
                id: 0,
                date_for: moment("Friday, May 24, 2019").toISOString(),
                regular_hours: "2.20",
                overtime_hours: "3.00",
                shift_description: {
                  name: "Shift A",
                  time_frame: {
                    from_time: moment("9:00 AM, Jan 1, 2001").toISOString(),
                    to_time: moment("5:00 PM, Jan 1, 2001").toISOString(),
                  }
                },
                late_reason: "Dog eat my car keys",
                early_reason: "Dog eat my car keys",
                activities: [0, 1]
              }
            ]
          },
          {
            full_name: "Cool Beanerinos",
            id: 1,
            regular_hours: "5.00",
            overtime_hours: "3.00",
            time_sheets: [
              {
                id: 1,
                date_for: moment("Friday, May 24, 2019").toISOString(),
                regular_hours: "2.20",
                overtime_hours: "3.00",
                shift_description: {
                  name: "Shift A",
                  time_frame: {
                    from_time: moment("9:00 AM, Jan 1, 2001").toISOString(),
                    to_time: moment("5:00 PM, Jan 1, 2001").toISOString(),
                  }
                },
                late_reason: "Dog eat my car keys",
                early_reason: "Dog eat my car keys",
                activities: [2, 3]
              }
            ]
          },
        ]
      }
    ]
  }
}

const getDefaultState = () => ({
  searched: false,
  testing: false,
  activities: [],
  periods: [
    {
      weekstart: null,
      employees: [
        {
          full_name: null,
          id: null,
          regular_hours: null,
          overtime_hours: null,
          time_sheets: [
            {
              id: null,
              date_for: null,
              regular_hours: null,
              overtime_hours: null,
              shift_description: {
                name: null,
                time_frame: {
                  from_time: null,
                  to_time: null
                }
              },
              late_reason: null,
              early_reason: null,
              activities: []
            }
          ]
        }
      ]
    }
  ]
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  addActivities(state, { activities }) {
    let presentActivities = cloneDeep(state.activities)

    state.activities = unionWith(presentActivities, activities, isEqual)
  },
  addPeriods(state, periods) {
    state.periods =  periods.map(period => {
      return {
        weekstart: period.weekstart,
        employees: period.employees.map(rowData => {
          return {
            ...rowData,
            subtable: {
              showHeader: false,
              fieldsDef: [
                {
                  dataClass: "bottomBorder",
                  name: "__component:TimeSheetDateCell",
                  title: translate("date")
                },
                {
                  dataClass: "bottomBorder",
                  name: "__component:ShiftDescriptionCell",
                  title: translate("shift_description")
                },
                {
                  dataClass: "bottomBorder",
                  name: "__component:TimeSheetExcuseCell"
                },
                {
                  dataClass: "bottomBorder",
                  name: "regular_hours"
                },
                {
                  dataClass: "bottomBorder",
                  name: "overtime_hours"
                },
              ],
              passedData: rowData.time_sheets,
              hasSearch: false,
              hasPagination: false,
              detailComponent: "ActivitiesTableDetailRow",
              hasDetailsChevron: false
            }
          }
        })
      }
    })

  }
}

export const actions = {
  init({ dispatch }) {
    let params = UrlManipulator.getSearchParams();

    dispatch("selects/initForReportPage", {
      params,
      paramsMap: ConfigHelper.defaultParamsMap(),
      config: {
        users: {
          multiple: true,
        },
        reset: {
          onReset() {
            return ["users"];
          }
        },
        startUp: {
          onSelect: ["users"]
        }
      }
    },
      { root: true });
  },
  searchEmployees({ commit, state, rootGetters, rootState }) {
    commit('updateField', { path: "periods", value: [] })
    commit('updateField', { path: "activities", value: [] })

    if (state.testing) {
      commit("addPeriods", getSampleData().periods)
    } else {
      let params = cloneDeep(rootState.selects.params)

      gtmEvents.reportGenerated({
        type: "Payable Hours Search"
      })

      params = {
        ...ConfigHelper.mapParams(params, ConfigHelper.defaultRevertParamsMap()),
        ...rootGetters['datetime/getStartAndEndDate']
      }

      let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
      history.replaceState(history.state, 'PayableHoursSearch', pathWithParams);

      this.$axios.get(`/hrs/data_api/time_sheets/payable_search`, { params }).then(({ data }) => {
        commit("addPeriods", data.periods)
        commit('updateField', { path: "searched", value: true })
      })
    }

  },
  loadActivities({ commit, state }, timeSheetId) {
    if (state.testing) {
      let activities = getSampleData().activities[timeSheetId]
      commit("addActivities", { activities })
    } else {
      this.$axios.get(`/hrs/data_api/time_sheets/${timeSheetId}/activities`).then(({ data }) => {
        commit("addActivities", { activities: data.activities })
      })
    }


  }
}