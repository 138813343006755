import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import gtmEvents from "~/assets/javascript/gtmEvents"
AxiosWrapper.init();

const getDefaultState = () => ({
  results: [],
  pass_count: null,
  fail_count: null,
  total: null,
  total_pages: null,
  loaded_job: null,
  paramsPath: '',
  params: {
    items_per_page: 40,
    page: 1
  },
  hasSearched: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  searchCompleted(state, getRequestData) {
    ({
      results: state.results,
      fail_count: state.fail_count,
      pass_count: state.pass_count,
      total: state.total,
      total_pages: state.total_pages,
      items_per_page: state.params.items_per_page,
      page: state.params.page,
      loaded_job: state.loaded_job
    } = getRequestData)

    state.params.page = getRequestData.page

    if (state.results) {
      state.results.forEach(result => {
        result['questionnaire_result_path'] = `/questionnaires/${result.questionnaire_id}/audit/questionnaire_results/${result.id}`
      });
    }
  },
  updateParamsPath(state, path) {
    state.paramsPath = path
  }
}

export const getters = {
  getField,
  resultsPresent(state) {
    if (state.results) {
      return (state.results.length !== 0)
    } else {
      return false
    }
  },
  csvDownloadPath(state) {
    return `/data_api/download_csv/questionnaire_results${state.paramsPath}`
  }
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")

    let params = AxiosWrapper.getSearchParams()

    dispatch("selects/initForReportPage", { params, paramsMap: ConfigHelper.defaultParamsMap() }, { root: true });
  },
  async initWithResults({ dispatch, state, commit }, { job_id, job_revision_id, questionnaire_id }) {
    let urlParams = AxiosWrapper.getSearchParams();

    let params = {
      ...urlParams,
      job: ((job_id != "") ? job_id : null),
      job_revision: ((job_revision_id != "") ? job_revision_id : null),
      audit_questionnaire: ((questionnaire_id != "") ? questionnaire_id : null),
    }

    commit("updateField", { path: "params", value: { ...state.params, ...params } })

    dispatch("getCallForSearch", params)
  },
  async searchForQuestionnaireResults({ dispatch, state, rootState, rootGetters }) {
    gtmEvents.reportGenerated({
      type: "Questionnaire Results"
    })

    let params = {
      ...ConfigHelper.mapParams(rootState.selects.params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      ...state.params
    }

    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'QuestionnaireResults', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get('/data_api/questionnaire_results_search', { params }).then(({ data }) => {
      commit('updateField', {
        path: 'hasSearched',
        value: true
      });
      commit('updateParamsPath', AxiosWrapper.buildSearchUrl(params))
      commit('searchCompleted', data);
      dispatch("contentWithHeader/update", null, { root: true })
    })
  },
  setPageAndThenGetPage({ dispatch, commit }, page) {
    commit('updateField', { path: "params.page", value: page });
    dispatch('searchForQuestionnaireResults');
  },
  changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'params.items_per_page',
      value: itemsPerPage
    });
    dispatch('searchForQuestionnaireResults');
  },
}