import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from "vuex-map-fields";
import moment from "moment";

const getDefaultState = () => ({
  kind: null,
  zone_id: null,
  job_id: null,
  time_sheet_id: null,
  role: null,
  role_id: null,
  from: null,
  to: null,
  paid: null,
  paid_overtime: null,
  billed: null,
  downtime_reason: null,
  temp_job_name: null,
  history: [],
  comment: "",
  other_activity_description: "",
  deleted: null,
  has_custom_job: false,
  has_custom_downtime_reason: false,
  id: null,
  roles: [],
  time_sheet_approved: false,
  is_approved: true,
  downtimeReasons: []
})

const hasCustomDowntime = function (state) {
  state.has_custom_downtime_reason = !state.downtimeReasons.includes(state.downtime_reason) && state.downtime_reason !== null
}
export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  updateHasCustomDowntime(state) {
    hasCustomDowntime(state)
  },
  init(state, newActivity = {}) {
    Object.assign(state, newActivity)
    state.has_custom_job = Boolean(state.temp_job_name)
    hasCustomDowntime(state)
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  }
}

export const actions = {
  approveActivity({commit, state}, id) {
    commit("updateField", { path: 'is_approved', value: !state.is_approved })

    return AxiosWrapper.patch(`/hrs/data_api/activities/${id}/approve`)
  },
  getRoles({ commit }) {
    return this.$axios.get(`/hrs/data_api/activities/tablet_roles`).then(({ data }) => {
      commit('updateField', { path: "roles", value: data.roles })
    })
  },
  getDowntimeReasons({ commit }) {
    return this.$axios.get(`/hrs/data_api/activities/downtime_reasons`).then(({ data }) => {
      commit('updateField', { path: "downtimeReasons", value: data.reasons })
    })
  },
  init({ state, commit, dispatch }, { activityId = null, timeSheetId = null }) {
    commit("resetState")
    let initPromise;
    if (activityId) {
      initPromise = Promise.all([
        this.$makeApiRequest("initActivity", { activityId })
          .then(({ data }) => commit("init", data.data.attributes)),
        dispatch('getRoles'),
        dispatch('getDowntimeReasons')
          .then(() => commit('updateHasCustomDowntime')),
      ])
    } else {
      let timePromise = this.$makeApiRequest("initNewActivity", { timeSheetId })
        .then(({ data }) => {
          commit("init", data.data.attributes);
        })
      initPromise = Promise.all(
        [
          timePromise,
          dispatch('getRoles'),
          dispatch('getDowntimeReasons').then(() => {
            commit('updateHasCustomDowntime')
          }),
        ])
    }

    return initPromise.then(() => {
      let params = { zone_ids: state.zone_id, job_ids: state.job_id }
      Object.keys(params).forEach(key => {
        if (!params[key]) delete params[key]
      })
      return dispatch("selects/init", { params }, { root: true });
    })
  },
  save({ state, commit, rootState }, timeSheetId) {
    commit('updateField', { path: 'zone_id', value: rootState.selects.params.zone_ids })
    if (state.has_custom_job) {
      commit('updateField', { path: 'job_id', value: null })
    } else {
      commit('updateField', { path: 'job_id', value: rootState.selects.params.job_ids })
      commit('updateField', { path: "temp_job_name", value: null })
    }

    return AxiosWrapper.postOrPatch(`/hrs/data_api/activities`, {
      activity: {
        time_sheet_id: timeSheetId,
        kind: state.kind,
        zone_id: state.zone_id,
        job_id: state.job_id,
        role_id: state.role_id,
        to: state.to,
        from: state.from,
        paid: state.paid,
        paid_overtime: state.paid_overtime,
        billed: state.billed,
        downtime_reason: state.downtime_reason,
        temp_job_name: state.temp_job_name,
        comment: state.comment,
        other_activity_description: state.other_activity_description,
        deleted: state.deleted,
        id: state.id,
        is_approved: state.is_approved,
        time_sheet_approved: state.time_sheet_approved
      }
    })
  },
  remove({ state }) {
    return this.$axios.delete(`/hrs/data_api/activities/${state.id}`, { params: { comment: state.comment } })
  },
}