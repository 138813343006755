<template lang="pug">
  #root.d-flex.flex-column
    .header
      template(v-if='isLoggedIn')
        CustomerNav(v-if="isCustomer")
        DefaultNav(v-else)
      template(v-else)
        LoginNav

    .container.body
      LoginModal
      nuxt
    .footer
      Footer
</template>

<script>
import CustomerNav from "~/components/CustomerNav";
import DefaultNav from "~/components/DefaultNav";
import LoginNav from "~/components/LoginNav";
import LoginModal from "~/components/LoginModal";
import Footer from "~/components/Footer";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: { CustomerNav, DefaultNav, Footer, LoginNav, LoginModal },
  computed: {
    ...mapGetters("login", [
      "isLoggedIn",
      "isCustomer",
      "supportUrl",
      "firstLoggedIn"
    ])
  },
  methods: {
    ...mapMutations("login", ["setFirstLoggedIn"]),
    ...mapActions("notifications", ["registerNotifications"]),
    showToast() {
      let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

      if (this.firstLoggedIn && isIE11) {
        this.$toast.info(I18n.t("please_move_from_ie11"), {
          position: "bottom-center",
          fitToScreen: true,
          fullWidth: true,
          className: "test",
          containerClass: "testContainer",
          action: {
            text: I18n.t("ok"),
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        });
        this.setFirstLoggedIn(false);
      }
    }
  },
  created() {
    this.showToast();
    if (this.firstLoggedIn) {
      this.registerNotifications(this);
    }
  },
  updated() {
    this.showToast();
    if (this.firstLoggedIn) {
      this.registerNotifications(this);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/colors.scss";
#root {
  height: 100vh;
}

.body {
  flex-grow: 1;
}
</style>