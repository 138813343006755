import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import uniq from 'lodash/uniq'
import snakeCase from 'lodash/snakeCase'
import cloneDeep from 'lodash/cloneDeep'
import {times, compact} from 'lodash'
import Moment from 'moment'
import 'moment-timezone'
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import ParamsHelper from '~/assets/audit/scheduled_questionnaires/ParamsHelper.js'

const getDefaultState = () => ({
  scheduledQuestionnaire: {
    selectedQuestionnaire: null,
    selectedJob: null,
    selectedConstraint: null,
    selectedSerialNumberConstraint: null,
    selectedLotNumberConstraint: null,
    selectedOther1Constraint: null,
    selectedOther2Constraint: null,
    selectedShiftDescription: null,
    selectedDepartment: null,
    selectedUser: null,
    selectedUserConstraint: null,
    selectedRole: null,
    selectedType: null,
    recurring_schedule_attributes: {type: null,
                                    month: null}
  },
  selectedDay: null,
  selectedMonth: null,
  jobs: [],
  shiftDescriptions: [],
  users: [],
  departmentUsers: [],
  roles: [],
  rolesForSelect: [],
  usersForSelect: [],
  departments: [],
  questionnaires: [],
  zone: null,
  partNumberConstraints: [],
  serialNumberConstraints: [],
  lotNumberConstraints: [],
  other1Constraints: [],
  other2Constraints: [],
  searchParamsPath: '',
  params: {},
  scheduleType: "single",
  repeatOptions: [],
  editingSeries: false,
  monthsOfYear: compact(translate("date.month_names")).map((d, index) => {
                    return { value: index + 1, label: d }
                }),
  weekdays: translate("date.day_names").map((d, index) => {
              return { value: translate("date.day_names", { locale: "en" })[index].toLowerCase(), label: d }
            })
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      shift_descriptions: state.shiftDescriptions,
      jobs: state.jobs,
      users: state.users,
      roles: state.roles,
      departments: state.departments,
      questionnaires: state.questionnaires,
      department_users: state.departmentUsers,
      zone: state.zone,
      repeat_options: state.repeatOptions
    } = getRequestData)

    state.scheduledQuestionnaire = {
      ...state.scheduledQuestionnaire,
      ...getRequestData.scheduled_questionnaire
    }
    state.usersForSelect = state.users

    let loadedParams = AxiosWrapper.getSearchParams();

    state.params = loadedParams
    state.searchParamsPath = AxiosWrapper.buildSearchUrl(state.params);
  },
  setSelectedOptions(state) {
    if (state.scheduledQuestionnaire.recurring_schedule_attributes.type === null) {
      state.scheduledQuestionnaire.selectedType = state.repeatOptions[0]
      state.scheduledQuestionnaire.recurring_schedule_attributes.type = state.repeatOptions[0].value
    } else {
      let theType = state.scheduledQuestionnaire.recurring_schedule_attributes.type
      state.scheduledQuestionnaire.selectedType = state.repeatOptions.find(o => o.value === theType)
      state.scheduleType = 'recurring'
    }

    if (state.scheduledQuestionnaire.recurring_schedule_attributes.month) {
      let month = state.scheduledQuestionnaire.recurring_schedule_attributes.month
      state.selectedMonth = state.monthsOfYear.find(o => o.value === month)
    }

    if (state.scheduledQuestionnaire.recurring_schedule_attributes.day_of_week) {
      let day = state.scheduledQuestionnaire.recurring_schedule_attributes.day_of_week.toLowerCase()
      state.selectedDay = state.weekdays.find(o => o.value === day)
    }

    state.scheduledQuestionnaire.selectedDepartment = state.departments.find(d =>
      d.id === state.scheduledQuestionnaire.department_id)

    if (state.scheduledQuestionnaire.role_id !== null) {
      if (state.scheduledQuestionnaire.department_id === null) {
        state.rolesForSelect = state.roles

        state.scheduledQuestionnaire.selectedDepartment = {
          id: 'any',
          name: translate("any"),
          value: "Department"
        }
      } else {
        let rolesForDepartment = state.roles.filter(r => r.department_ids.includes(
          state.scheduledQuestionnaire.department_id))

        state.rolesForSelect = rolesForDepartment
      }

      state.scheduledQuestionnaire.selectedRole = state.rolesForSelect.find(r =>
        r.id === state.scheduledQuestionnaire.role_id)
    }

    if (state.scheduledQuestionnaire.user_id !== null) {
      if (state.scheduledQuestionnaire.department_id !== null) {
        state.usersForSelect = state.departmentUsers
      } else if (state.scheduledQuestionnaire.role_id !== null) {
        let usersForRole = state.departmentUsers.filter(u => u.role_ids.includes(
          state.scheduledQuestionnaire.role_id))

        state.usersForSelect = usersForRole
      } else {
        state.usersForSelect = state.users
      }

      state.scheduledQuestionnaire.selectedUser = state.usersForSelect.find(u =>
        u.id === state.scheduledQuestionnaire.user_id)
    }

    state.scheduledQuestionnaire.selectedShiftDescription = state.shiftDescriptions.find(sd =>
      sd.id === state.scheduledQuestionnaire.shift_description_id)

    if (state.scheduledQuestionnaire.job_id !== null) {
      state.scheduledQuestionnaire.selectedJob = state.jobs.find(j =>
        j.id === state.scheduledQuestionnaire.job_id)

      state.partNumberConstraints = state.scheduledQuestionnaire.selectedJob.part_number_constraints
      state.serialNumberConstraints = state.scheduledQuestionnaire.selectedJob.serial_number_constraints
      state.lotNumberConstraints = state.scheduledQuestionnaire.selectedJob.lot_number_constraints
      state.other1Constraints = state.scheduledQuestionnaire.selectedJob.other1_constraints
      state.other2Constraints = state.scheduledQuestionnaire.selectedJob.other2_constraints

      state.scheduledQuestionnaire.selectedConstraint = state.partNumberConstraints.find(c =>
        c.id === state.scheduledQuestionnaire.constraint_id)
      state.scheduledQuestionnaire.selectedSerialNumberConstraint = state.serialNumberConstraints.find(c =>
        c.id === state.scheduledQuestionnaire.serial_number_constraint_id)
      state.scheduledQuestionnaire.selectedLotNumberConstraint = state.lotNumberConstraints.find(c =>
        c.id === state.scheduledQuestionnaire.lot_number_constraint_id)
      state.scheduledQuestionnaire.selectedOther1Constraint = state.other1Constraints.find(c =>
        c.id === state.scheduledQuestionnaire.other1_constraint_id)
      state.scheduledQuestionnaire.selectedOther2Constraint = state.other2Constraints.find(c =>
        c.id === state.scheduledQuestionnaire.other2_constraint_id)
      state.scheduledQuestionnaire.selectedUserConstraint = state.usersForSelect.find(u =>
        u.id === state.scheduledQuestionnaire.user_constraint_id)
    }

    if (state.params.job_id && state.scheduledQuestionnaire.job_id === null) {
      state.scheduledQuestionnaire.selectedJob = state.jobs.find(j =>
        j.id === parseInt(state.params.job_id))
    }

    if (state.params.shift_description_id && state.scheduledQuestionnaire.id === null) {
      state.scheduledQuestionnaire.selectedShiftDescription = state.shiftDescriptions.find(sd =>
        sd.id === parseInt(state.params.shift_description_id))

      state.scheduledQuestionnaire.shift_description_id = state.params.shift_description_id
    }

    if (state.params.user_id && state.scheduledQuestionnaire.id === null) {
      state.scheduledQuestionnaire.selectedUser = state.usersForSelect.find(u =>
        u.id === parseInt(state.params.user_id))

      state.scheduledQuestionnaire.user_id = state.params.user_id
    }
  },
  updateUserConstraint(state, value) {
    state.scheduledQuestionnaire.user_constraint_id = value.id
    state.scheduledQuestionnaire.selectedUserConstraint = value
  },
  updateScheduledQuestionnaireState(state, value) {
    if (value.type) {
      state.scheduledQuestionnaire[`${snakeCase(value.type)}_id`] = value.id
      state.scheduledQuestionnaire[`selected${value.type}`] = value

      if (value.type === 'Job') {
        state.scheduledQuestionnaire.selectedSerialNumberConstraint = null
        state.scheduledQuestionnaire.selectedLotNumberConstraint = null
        state.scheduledQuestionnaire.selectedOther1Constraint = null
        state.scheduledQuestionnaire.selectedOther2Constraint = null
        state.scheduledQuestionnaire.selectedConstraint = null

        state.scheduledQuestionnaire.constraint_id = null
        state.scheduledQuestionnaire.serial_number_constraint_id = null
        state.scheduledQuestionnaire.lot_number_constraint_id = null
        state.scheduledQuestionnaire.other1_constraint_id = null
        state.scheduledQuestionnaire.other2_constraint_id = null

        state.partNumberConstraints = state.scheduledQuestionnaire.selectedJob.part_number_constraints
        state.serialNumberConstraints = state.scheduledQuestionnaire.selectedJob.serial_number_constraints
        state.lotNumberConstraints = state.scheduledQuestionnaire.selectedJob.lot_number_constraints
        state.other1Constraints = state.scheduledQuestionnaire.selectedJob.other1_constraints
        state.other2Constraints = state.scheduledQuestionnaire.selectedJob.other2_constraints

        let rand = Math.floor(Math.random() * state.partNumberConstraints.length)
        let constraint = state.partNumberConstraints[rand]

        if (constraint) {
          state.scheduledQuestionnaire.selectedConstraint = constraint
          state.scheduledQuestionnaire.constraint_id = constraint.id
        }

        if (state.serialNumberConstraints.length > 0) {
          rand = Math.floor(Math.random() * state.serialNumberConstraints.length)
          constraint = state.serialNumberConstraints[rand]

          state.scheduledQuestionnaire.selectedSerialNumberConstraint = constraint
          state.scheduledQuestionnaire.serial_number_constraint_id = constraint.id
        }

        if (state.lotNumberConstraints.length > 0) {
          rand = Math.floor(Math.random() * state.lotNumberConstraints.length)
          constraint = state.lotNumberConstraints[rand]

          state.scheduledQuestionnaire.selectedLotNumberConstraint = constraint
          state.scheduledQuestionnaire.lot_number_constraint_id = constraint.id
        }

        if (state.other1Constraints.length > 0) {
          rand = Math.floor(Math.random() * state.other1Constraints.length)
          constraint = state.other1Constraints[rand]

          state.scheduledQuestionnaire.selectedOther1Constraint = constraint
          state.scheduledQuestionnaire.other1_constraint_id = constraint.id
        }

        if (state.other2Constraints.length > 0) {
          rand = Math.floor(Math.random() * state.other2Constraints.length)
          constraint = state.other2Constraints[rand]

          state.scheduledQuestionnaire.selectedOther2Constraint = constraint
          state.scheduledQuestionnaire.other2_constraint_id = constraint.id
        }
      }

      if (value.type === 'Role') {
        state.scheduledQuestionnaire.selectedUser = null
        state.scheduledQuestionnaire.user_id = null

        if (value.id) {
          let usersForRole = state.departmentUsers.filter(u => u.role_ids.includes(value.id))

          state.usersForSelect = usersForRole
        } else {
          state.usersForSelect = state.departmentUsers
        }
      }

      if (value.type === 'Department') {
        state.scheduledQuestionnaire.selectedRole = null
        state.scheduledQuestionnaire.role_id = null
        state.scheduledQuestionnaire.selectedUser = null
        state.scheduledQuestionnaire.user_id = null

        if (value.id) {
          state.usersForSelect = []

          if (value.id === 'any') {
            state.rolesForSelect = state.roles
            state.usersForSelect = state.departmentUsers

          } else {
            let rolesForDepartment = state.roles.filter(r => r.department_ids.includes(value.id))

            state.rolesForSelect = rolesForDepartment
          }
        } else {
          state.usersForSelect = state.users
          state.rolesForSelect = []
        }
      }
    }
  },
  updateSelectedUsers(state, value) {
    state.selectedUsers = value
  },
  updateType(state, theNewType) {
    state.scheduledQuestionnaire.selectedType = theNewType
    state.scheduledQuestionnaire.recurring_schedule_attributes.type = theNewType.value


    switch(theNewType.value) {
      case 'Audit::Recurring::DailySchedule':
        break;
      case 'Audit::Recurring::WeeklySchedule':
        break;
      case 'Audit::Recurring::MonthlySchedule':
        break;
      case 'Audit::Recurring::YearlySchedule':
        break;
      default:
        break;
    }
  },
  updateMonth(state, month) {
    state.selectedMonth = month
    state.scheduledQuestionnaire.recurring_schedule_attributes.month = month.value
  },
  updateDayOfWeek(state, dayOfWeek) {
    state.selectedDay = dayOfWeek
    state.scheduledQuestionnaire.recurring_schedule_attributes.day_of_week = dayOfWeek.value
  },
  updateDay(state, day) {
    state.scheduledQuestionnaire.recurring_schedule_attributes.day = day
  },
  changeAt(state, newDate) {
    Moment(state.scheduledQuestionnaire.recurring_schedule_attributes.at, "hh:mm:ssZ").utcOffset(-(state.zone.time_zone_offset)).format()
  },
  updateQuestionnaire(state, questionnaireId) {
    state.scheduledQuestionnaire.questionnaire_id = questionnaireId
  },
  updateField
}

export const getters = {
  isLoaded(state) {
    return state.zone && state.scheduledQuestionnaire
  },
  minDate(state) {
    if (Moment(state.scheduledQuestionnaire.audit_at).isBefore(Moment().format())) {
      return state.scheduledQuestionnaire.audit_at
    } else {
      return Moment().format()
    }
  },
  maxDate(getters) {
    return Moment(getters.minDate).add('2', 'years').format()
  },
  isRecurring(state) {
    return state.scheduleType == 'recurring'
  },
  daysOfMonth(state) {
    let dayArray = []

    let date = state.scheduledQuestionnaire.audit_at

    if (state.scheduledQuestionnaire.recurring_schedule_attributes.month) {
      date = Moment().month(state.scheduledQuestionnaire.recurring_schedule_attributes.month).format("YYYY-MM-DD")
    }

    return times(Moment(date).daysInMonth(), num => dayArray.push(num))
  },
  isDaily(state) {
    return state.scheduledQuestionnaire.recurring_schedule_attributes.type == "Audit::Recurring::DailySchedule"
  },
  isWeekly(state) {
    return state.scheduledQuestionnaire.recurring_schedule_attributes.type == "Audit::Recurring::WeeklySchedule"
  },
  isMonthly(state) {
    return state.scheduledQuestionnaire.recurring_schedule_attributes.type == "Audit::Recurring::MonthlySchedule"
  },
  isYearly(state) {
    return state.scheduledQuestionnaire.recurring_schedule_attributes.type == "Audit::Recurring::YearlySchedule"
  },
  canEditSchedule(state) {
    if ((state.scheduledQuestionnaire.recurring_schedule_id !== null &&
        state.editingSeries) || state.scheduledQuestionnaire.recurring_schedule_id === null) {
      return true
    }

    return false
  },
  getField
}

export const actions = {
  async init({ commit, state, dispatch }, { id, zoneId }) {
    commit("resetState")

    Moment.locale(I18n.currentLocale())

    let params = {}
    let url = `/data_api/audit/scheduled_questionnaires/new`

    if (id) {
      url = `/data_api/audit/scheduled_questionnaires/${id}/edit`
    } else {
      params = { zone_id: zoneId }
    }

    AxiosWrapper.get(url, { params: params }).then(response => {
      commit('init', response.data)
      let selectsParams = AxiosWrapper.getSearchParams();
      let paramsMap = ParamsHelper.defaultParamsMap();

      if (state.scheduledQuestionnaire.questionnaire_id !== null) {
        selectsParams["questionnaire_ids"] = state.scheduledQuestionnaire.questionnaire_id
      } else {
        selectsParams["questionnaire_ids"] = selectsParams.questionnaire_id
        commit("updateField", {path: "scheduledQuestionnaire/questionnaire_id", value: selectsParams.questionnaire_id})
      }

      if (state.scheduledQuestionnaire.job_id !== null) {
        selectsParams["job_ids"] = state.scheduledQuestionnaire.job_id
      } else {
        selectsParams["job_ids"] = selectsParams.job_id
        commit("updateField", { path: "scheduledQuestionnaire/job_id", value: selectsParams.job_id })
      }

      if (state.scheduledQuestionnaire.zone_id !== null) {
        selectsParams["zone_ids"] = state.scheduledQuestionnaire.zone_id
      } else {
        selectsParams["zone_ids"] = selectsParams.zone_id
        commit("updateField", {path: "scheduledQuestionnaire/zone_id", value: selectsParams.zone_id})
      }

      if (state.scheduledQuestionnaire.work_area_id !== null) {
        selectsParams["work_area_ids"] = state.scheduledQuestionnaire.work_area_id
      } else {
        selectsParams["work_area_ids"] = selectsParams.work_area_id
        commit("updateField", {path: "scheduledQuestionnaire/work_area_id", value: selectsParams.work_area_id})
      }

      if (state.scheduledQuestionnaire.post_id !== null) {
        selectsParams["post_ids"] = state.scheduledQuestionnaire.post_id
      } else {
        selectsParams["post_ids"] = selectsParams.post_id
        commit("updateField", {path: "scheduledQuestionnaire/post_id", value: selectsParams.post_id})
      }

      dispatch("selects/init", {
        params: selectsParams, paramsMap, config: {
          reset: {
            onReset(state) {
              return ["questionnaires"];
            }
          },
          startUp: {
            onSelect: ["questionnaires"]
          },
          questionnaires: {
            required: [],
            onInit: ['zone_ids', 'questionnaire_group_ids']
          }} }, { root: true });

      commit('setSelectedOptions')

      if (state.scheduledQuestionnaire.selectedJob && state.scheduledQuestionnaire.constraint_id === null) {
        commit('updateScheduledQuestionnaireState', state.scheduledQuestionnaire.selectedJob)
      }
    })
  },
  updateScheduledQuestionnaire({ commit }, value) {
    commit('updateScheduledQuestionnaireState', value)
  },
  save({ dispatch, state, getters, rootState }, vueRouter) {
    if (state.scheduledQuestionnaire.department_id === 'any') {
      state.scheduledQuestionnaire.department_id = null
    }

    let params = {
      audit_scheduled_questionnaire: cloneDeep(state.scheduledQuestionnaire)
    }

    params.audit_scheduled_questionnaire.recurring_schedule_attributes.at = Moment(`${state.scheduledQuestionnaire.recurring_schedule_attributes.at}${state.zone.time_zone_offset}`, "YYYY-MM-DDThh:mm:ssZ").utc().format()

    if (state.scheduledQuestionnaire.recurring_schedule_attributes.day_of_week) {
      params.audit_scheduled_questionnaire.recurring_schedule_attributes.day_of_week = state.scheduledQuestionnaire.recurring_schedule_attributes.day_of_week.toLowerCase()
    }

    if (state.scheduleType == 'recurring' && getters.canEditSchedule) {
      params["recurring"] = true
    } else if (state.scheduleType != 'recurring' && getters.canEditSchedule) {
      params["recurring_to_single"] = true
      params.audit_scheduled_questionnaire.recurring_schedule_id = null
      params.audit_scheduled_questionnaire.recurring_schedule_attributes = null
    }


    AxiosWrapper.postOrPatch(`/data_api/audit/scheduled_questionnaires`, params).then(response => {
      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })

      vueRouter.push(`/scheduled_questionnaires${state.searchParamsPath}`)
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true })
    })
  }
}