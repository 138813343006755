import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  back_path: null,
  inspectionId: null,
  jobRevisionId: null,
  create_redirect_path: null,
  observation_correction:{
    reason: null,
    observation: null
  },
  previous_corrections: [],
  checklist_inspection_id: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      back_path: state.back_path,
      create_redirect_path: state.create_redirect_path,
      previous_corrections: state.previous_corrections,
      checklist_inspection_id: state.checklist_inspection_id,
      observation_correction: state.observation_correction,
    } = responseData)
  }
}

export const getters = {
  getField,
}

export const actions = {
  init({ state, commit, dispatch }) {
    let params = {
      inspection_id: state.inspectionId,
      job_revision_id: state.jobRevisionId
    }

    let baseUrl = `/data_api/checklist/inspection_observation_corrections/new`

    AxiosWrapper.get(baseUrl, { params }).then(function (response) {
      commit('initialGetCompleted', response.data);
    })
  },
  saveCorrection({ state, commit, dispatch }) {

    let searchParams = AxiosWrapper.getSearchParams()
    searchParams.checklist_inspection_id = state.checklist_inspection_id
    searchParams.reason = state.observation_correction.reason
    searchParams.observation = state.observation_correction.observation

    let params = { checklist_inspection_observation_correction : searchParams }

    let postUrl = `/data_api/checklist/inspection_observation_corrections`
    return AxiosWrapper.postOrPatch(postUrl, params).then(response => {
      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true })
    })
  }
}