import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  back_path: null,
  verification_results: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      back_path: state.back_path,
      verification_results: state.verification_results
    } = responseData)
  }
}

export const getters = {
  getField,
}

export const actions = {
  init({ state, commit, dispatch }) {
    let binId = AxiosWrapper.getNextPathNameElement('bins');
    AxiosWrapper.get(`/data_api/bins/${binId}/show_barcode_verification_results`).then(function (response) {
      commit('initialGetCompleted', response.data);
    })
  }
}