import { getField, updateField } from 'vuex-map-fields'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';

const getDefaultState = () => ({
  job: null,
  permissions: {},
  jobRevision: null,
  passCount: 0,
  failCount: 0,
  warnCount: 0,
  results: {}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  getRequestCompleted(state, getRequestData){
    ({
      job: state.job,
      permissions: state.permissions,
      jobRevision: state.jobRevision,
      passCount: state.passCount,
      failCount: state.failCount,
      warnCount: state.warnCount,
      results: state.results
    } = getRequestData)
  }
}

export const actions = {
  async init({ commit, dispatch }, revisionId) {
    return AxiosWrapper.get(`/data_api/job_revisions/${revisionId}/training/exam_results`).then(response => {
      commit('getRequestCompleted', response.data)
      dispatch('jobRevision/setJobRevision', response.data.jobRevision, { root: true })
    })
  }
}