import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  can_edit_questionnaire: false,
  questionnaire_id: null,
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    state.can_edit_questionnaire = responseData.can_edit_questionnaire
  },
  setQuestionnaireId(state, questionnaireId) {
    state.questionnaire_id = questionnaireId
  }
}

export const getters = {
  getField,
  getNewQuestionUrl: (state) => (questionName) => {
    return `/audit/questionnaires/${state.questionnaire_id}/edit/questions/${questionName}/new`
  },
  backUrl(state) {
    return `/audit/questionnaires/${state.questionnaire_id}/edit/questions`
  }
}

export const actions = {
  init({ commit }, questionnaire_id) {
    commit('setQuestionnaireId', questionnaire_id)
    this.$axios.get(`/data_api/audit/questionnaires/${questionnaire_id}/get_paths_for_new_question`).then(({ data }) => {
      commit('initialGetCompleted', data);
    })
  }
}