export default {
  init({row_id, gaugeId}) {
    return this.axios.get(
                            "/data_api/dashboard/rows/"
                              + row_id
                              + '/gauges/'
                              + gaugeId
                              + '/edit',
                            { row_id, id: gaugeId }
                          )
  }
}