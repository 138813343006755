import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, { getRequestData, jobId = null, jobRevisionId = null }) {
    ({
      grouped: state.groupedSuspectCodeModels,
      zone_id: state.zoneId,
      global: state.globalSuspectCodeModels,
      permissions: state.permissions
    } = getRequestData);

  },
  updateField,
}

export const actions = {
  async init({ commit }, { jobId = null, jobRevisionId = null }) {
    commit('resetState')

    AxiosWrapper.get('/data_api/suspect_code_models', {
      params: {
        job_id: jobId,
        job_revision_id: jobRevisionId
      }
    }).then(response => {
      commit('init', { getRequestData: response.data, jobId, jobRevisionId })
    })
  },
}