import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  questionnaires: [],
  checkedQuestionnaires: []
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  updateQuestionnaires(state, questionnaires) {
    state.questionnaires = questionnaires
    state.checkedQuestionnaires = state.questionnaires.map(q => q.id)
  }
}

export const actions = {
  loadQuestionnaires({ commit }) {
    this.$makeApiRequest("loadJobQuestionnaires").then(response => {
      commit("updateQuestionnaires", response.data.questionnaires.data)
    })
  },
  assignQuestionnairesToJob({ commit, state }) {
    return this.$makeApiRequest("assignQuestionnairesToJob", {
      questionnaireIds: state.checkedQuestionnaires
    }).then(response => {
      commit("updateQuestionnaires", response.data.questionnaires.data)
    });
  },
  removeQuestionnaire({ commit, state }, questionnaireId) {
    let filteredQuestionnaires = state.questionnaires.filter(q => q.id != questionnaireId);
    let questionnaireIds = filteredQuestionnaires.map(q => q.id);
    this.$makeApiRequest("assignQuestionnairesToJob", { questionnaireIds }).then(() => {
      commit("updateQuestionnaires", filteredQuestionnaires)
    })
  }
}