import { translate } from '~/assets/javascript/localization/Localization';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from "vuex-map-fields";

const url = '/data_api/activity_logs/for_user/'
AxiosWrapper.init();

const getDefaultState = () => ({
  userActivityLogs: [],
  userName: null,
  is_initializing: true,
  loadingMessage: translate('no_data_available'),
  total_pages: 0,
  total: 0,
  params: {
    page: 1,
    items_per_page: 75,
  },
  activityLogs: [],
  sortOrder: [
    {
      field: 'referrer',
      sortField: 'referrer',
      direction: 'desc'
    }
  ],
  id: AxiosWrapper.getNextPathNameElement("for_user"),
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      user_activity_logs: state.userActivityLogs,
      user_name: state.userName,
      total_pages: state.total_pages,
      total: state.total,
    } = getRequestData);
  },
  updateField,
}

export const actions = {
  async init({ dispatch, commit }) {
    commit("resetState")

    dispatch('setStoreParamsWithUrlParams')
    dispatch('getPage');
  },
  setStoreParamsWithUrlParams({ commit, state }) {
    let mergedParams = { ...state.params, ...AxiosWrapper.getSearchParams() }

    commit('updateField', { path: "params", value: mergedParams });
  },
  async getPage({ commit, state }) {
    let params = state.params;

    let stringify = AxiosWrapper.buildPathWithSearchParams(params)
    history.replaceState(history.state, 'User Logs', stringify)

    AxiosWrapper.get(url + state.id, { params })
      .then(function (response) {
        AxiosWrapper.log(response.data);
        commit('init', response.data);
        commit('updateField', {
          path: 'is_initializing',
          value: false
        });
      })
  },
  setPageAndThenGetPage({ dispatch, commit, state }, page) {
    //Not ideal.  If you find a better way let me know -LK.
    //If you remove the initialized params won't work.
    if (state.is_initializing == false) {
      commit('updateField', { path: "params.page", value: page });
      dispatch('getPage');
    }
  },
  changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'params.items_per_page',
      value: itemsPerPage
    });
    dispatch('getPage');
  }
}