
import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import GaugeHelper from '~/components/Dashboard/helpers/GaugeHelper.js'
import cloneDeep from 'lodash/cloneDeep'
import UrlManipulator from '~/assets/javascript/UrlManipulator'

const showUrl = '/data_api/dashboard/panels/'

AxiosWrapper.init();

const getDefaultState = () => ({
  panel: {},
  rows: [],
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  addRow(state, row) {
    state.rows.push(row)
  },
  addGauge(state, { row, oldGauge, newGauge }) {
    const idx = state.rows.indexOf(row)
    const idxForGauge = state.rows[idx].gauges.indexOf(oldGauge);

    Vue.set(state.rows[idx].gauges, idxForGauge, newGauge);
  },
  deleteGauge(state, { row, gauge }) {
    const idx = state.rows.indexOf(row)
    state.rows[idx].gauges.splice(state.rows[idx].gauges.indexOf(gauge), 1);
  },
  deleteNewGauge(state, row) {
    const idx = state.rows.indexOf(row)

    let idxForGauge = state.rows[idx].gauges.findIndex(gauge => !gauge.hasOwnProperty('id'))
    if (idxForGauge > -1) {
      state.rows[idx].gauges.splice(idxForGauge, 1);
    }
  },
  deleteRow(state, row) {
    const idx = state.rows.indexOf(row)
    state.rows.splice(idx, 1);
  },
  init(state, getRequestData) {
    state.rows = getRequestData.rows
    state.panel = getRequestData
  }
}

export const actions = {
  async init({ commit, dispatch, state }, panelId) {
    commit("resetState")

    if (panelId === undefined) {
      panelId = AxiosWrapper.getNextPathNameElement('panels')
    }
    AxiosWrapper.get(showUrl + panelId)
      .then(response => {
        commit('init', response.data)
        dispatch('addEmptyGauges');
      })
  },
  addEmptyGauges({ commit, state }) {
    let currentRows = cloneDeep(state.rows)
    GaugeHelper.update(currentRows);

    commit("updateField", { path: "rows", value: currentRows });
  },
  async addRow({ state, commit, dispatch }) {
    let createRowUrl = `/data_api/dashboard/panels/${state.panel.id}/rows`

    AxiosWrapper.post(createRowUrl).then(response => {
      commit('addRow', response.data);
      dispatch('addEmptyGauges');
    });
  },
  async deleteRow({ commit, state }, row) {
    let deleteUrl = `/data_api/dashboard/panels/${state.panel.id}/rows/${row.id}`

    AxiosWrapper.delete(deleteUrl).then(response => {
      commit('deleteRow', row);
    });
  },
  addNewGauge({ commit, dispatch, state }, { row, gauge }) {
    const createdGaugeUrl = `/data_api/dashboard/rows/${row.id}/gauges`
    const flattenedGauge = UrlManipulator.flattenObject(gauge);

    GaugeHelper.addFilterTo(flattenedGauge)

    AxiosWrapper.postOrPatch(createdGaugeUrl, { gauge: flattenedGauge }).then(response => {
      commit('addGauge', { row, oldGauge: gauge, newGauge: response.data })
      dispatch('addEmptyGauges');
    });
  },
  deleteGauge({ dispatch, commit, state }, { row, gauge }) {
    let deleteGaugeUrl = `/data_api/dashboard/rows/${row.id}/gauges/${gauge.id}`

    AxiosWrapper.delete(deleteGaugeUrl).then(response => {
      commit("deleteGauge", { row, gauge });
      commit("deleteNewGauge", row);

      dispatch('addEmptyGauges');
    });
  }
}