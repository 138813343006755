import AxiosWrapper from '~/assets/javascript/AxiosWrapper';

const getBase = `/data_api/dashboard/devices`


const getDefaultState = () => ({
  job_id: null,
  device_identifier_param: "",
  embed_frame_html: ""
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      embed_frame_html: state.embed_frame_html,
    } = getRequestData);
  },
  initParams(state) {
    var params = AxiosWrapper.getSearchParams()

    state.device_identifier_param = params.device
    state.job_id = AxiosWrapper.getNextPathNameElement('jobs')
  },
}

export const getters = {
  splashPageUrl(state) {
    return `/dashboard/devices?job=${state.job_id}&device=${state.device_identifier_param}`
  },
  embedFrameHtml(state) {
    return state.embed_frame_html
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    commit('resetState')
    commit('initParams')

    AxiosWrapper.get(`${getBase}/jobs/${state.job_id}/embed?device=${state.device_identifier_param}`).then(function (response) {
      commit('init', response.data);
    })
  }
}