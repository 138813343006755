import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';

const getDefaultState = () => ({
  severities: {},
  showNewModal: false,
  lessees:{}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateSeverity(state, { severity }) {
    Vue.set(state.severities, severity.id, severity)
  },
  removeSeverity(state, severityId) {
    Vue.delete(state.severities, severityId)
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/severities`)
      .then(response => {
        commit('updateField', { path: 'severities', value: { ...response.data.severities } })
        commit('updateField', { path: 'currentUser', value: response.data.current_user })
        commit('updateField', { path: 'lessees', value: response.data.lessees })
      })
  },
  submitSeverity({ commit, state }, { severity }) {
    AxiosWrapper.postOrPatch('/data_api/severities', { severity: severity })
      .then(response => {
        commit('updateSeverity', { severity: response.data })
        if (severity.id == 0) { commit('removeSeverity', severity.id) }
      })
  },
  deleteSeverity({ commit }, { severity, vueInstance }) {
    AxiosWrapper.delete(`/data_api/severities/${severity.id}`)
      .then(response => {
        if (response.data === true){
          commit('removeSeverity', severity.id)
        }else{
          vueInstance.$toast.error(translate('cant_delete_severity'), { duration: 10000 })
        }
      }
      );
  },
}