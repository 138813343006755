<template lang="pug">
  nav.navbar.navbar-expand-md.navbar-dark.bg-dark
    slot
    nuxt-link.navbar-brand.d-flex.align-items-center.p-0(to='/')
      img(src="/edn_logo_white.png" height="35" alt="logo")

    button.navbar-toggler(data-toggle="collapse",
                          data-target="#navbarLinks",
                          aria-control="#navbarLinks",
                          :aria-label="translate('toggle_navigation')")
      span.navbar-toggler-icon
    #navbarLinks.collapse.navbar-collapse
      ul.navbar-nav(id="navigation-options", data-cy='navbarLinks')
        li.nav-item
          nuxt-link.nav-link(to='/jobs/index_for_customer',
                            data-cy='jobs') {{ 'jobs' | translate }}
        li.nav-item
          nuxt-link.nav-link(to='/reports/shifts',
                            data-cy='shiftsSearch') {{ 'shifts' | translate }}
        li.nav-item
          nuxt-link.nav-link(to='/reports/stats',
                            data-cy='statsSearch') {{ 'stats' | translate }}
        li.nav-item
          nuxt-link.nav-link(to='/reports/ichart',
                            data-cy='ichart') {{ 'ichart' | translate }}
        li.nav-item
          nuxt-link.nav-link(to='/reports/questionnaire_results',
                            data-cy='questionnaireResultsSearch') {{ 'questionnaire_results' | translate }}

      SessionMenu

</template>

<script>
import SessionMenu from "~/components/SessionMenu.vue";

export default {
  components: { SessionMenu }
}
</script>

<style lang="scss" scoped>
@import '~/assets/stylesheets/navbar.scss';

</style>
