<template lang="pug">
  multiselect(v-model="selectedUsers",
                  :options="users",
                  label="name",
                  :searchable="true",
                  track-by="name",
                  :multiple="multiple",
                  :closeOnSelect="!multiple",
                  :disabled="users.length == 0",
                  :placeholder="'select' | translate",
                  @input="selected('users')",
                  dataCy="usersSelect",
                  inputName="selectedUsers",
                  :validator="validator")
</template>

<script>
  import Multiselect from '~/components/EdnMultiselect.vue'
  import { createNamespacedHelpers } from 'vuex'
  import { createHelpers } from 'vuex-map-fields';
  const { mapActions, mapMutations } = createNamespacedHelpers('selects');
  const { mapFields } = createHelpers({
    getterType: 'selects/getField',
    mutationType: 'selects/updateField',
  });

  export default {
    name: "UsersSelect",
    components: {
      Multiselect
    },
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      query_param: {
        type: String
      },
      required: {
        type: Array
      },
      onInit: {
        type: Array
      },
      validator: {
        type: Object
      },
      onSelect: {
        type: Array
      }
    },
    created() {
      let config = {
                      query_param: this.query_param,
                      required: this.required,
                      onInit: this.onInit,
                      onSelect: this.onSelect,
                      multiple: this.multiple
                    }

      this.registerComponent({incoming: 'users',config: config});
    },
    computed:{
      ...mapFields([
          'users',
          'selectedUsers',
          ])
    },
    methods: mapActions(['selected','registerComponent'])
  }
</script>
