import AxiosWrapper from '~/assets/javascript/AxiosWrapper';

const get = `/data_api/dashboard/devices`
const dashboardBasePath = `/dashboard/devices/jobs/`

AxiosWrapper.init();

const getDefaultState = () => ({
  has_embedded: false,
  job_id: null,
  device_identifier_param: "",
  language: ""
})

export const state = getDefaultState

export const mutations = {
  setLocale(state, locale) {
    state.language = locale
    I18n.locale = locale
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      has_embedded: state.has_embedded
    } = getRequestData);
  },
  initParams(state) {
    var params = AxiosWrapper.getSearchParams()

    AxiosWrapper.log(params);

    state.device_identifier_param = `?device=${params.device}`
    state.job_id = params.job
  }
}

export const getters = {
  hasEmbedded(state) {
    return state.has_embedded
  },
  requestAssistancePath(state) {
    return `${dashboardBasePath}${state.job_id}/request_assistance${state.device_identifier_param}&locale=${I18n.locale}`
  },
  questionnaireResultsPath(state) {
    return `${dashboardBasePath}${state.job_id}/questionnaires${state.device_identifier_param}&locale=${I18n.locale}`
  },
  ichartPath(state) {
    return `${dashboardBasePath}${state.job_id}/ichart${state.device_identifier_param}&locale=${I18n.locale}`
  },
  statsPath(state) {
    return `${dashboardBasePath}${state.job_id}/stats${state.device_identifier_param}&locale=${I18n.locale}`
  },
  controlChartPath(state) {
    return `${dashboardBasePath}${state.job_id}/instruction_search${state.device_identifier_param}&locale=${I18n.locale}`
  },
  verificationWorkItemsPath(state) {
    return `${dashboardBasePath}${state.job_id}/verification_work_items${state.device_identifier_param}&locale=${I18n.locale}`
  },
  embedPath(state) {
    return `${dashboardBasePath}${state.job_id}/embed${state.device_identifier_param}&locale=${I18n.locale}`
  },
  jobPath(state){
    return `/jobs/${state.job_id}/edit`
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    commit('initParams')

    AxiosWrapper.get(get + window.location.search).then(function (response) {
      commit('init', response.data)
    })
  }
}