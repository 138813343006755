import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import './translations.js'

Vue.use(Vue2Filters)

Vue.filter('translate', function (translationKey, options = {}) {
  return I18n.t(translationKey, options);
});

Vue.filter('addLocale', function (text) {
  return `${text} (${I18n.currentLocale()})`;
});

const Locale = {
  methods: {
    translate(phrase, options = {}) {
      return I18n.t(`${phrase}`, options);
    },
    translateFields(fieldDef = {}) {
      return fieldDef.map(field => {
        if (field["keepOriginalTitle"]) {
          return {
            ...field
          }
        } else {
          return {
            ...field,
            title: field.title ? translate(field.title) : ''
          }
        }
      })
    }
  }
}


function translate(phrase, options = {}) {
  return I18n.t(`${phrase}`, options);
};

export { translate }

export default {
  install(Vue, options) {
    Vue.mixin(Locale)
  }
}