import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  back_path: null,
  changes_view_title_header: null,
  viewable_changes: null,
  total_pages: null,
  total: null,
  items_per_page: null,
  page: null,
  params:{
    page: 1,
    total_pages: null,
    total: null,
    items_per_page: 10,
  }
})

export const state = getDefaultState

export const mutations = {
  updateField,
  getCallCompleted(state, responseData) {
    ({
      back_path: state.back_path,
      changes_view_title_header: state.changes_view_title_header,
      viewable_changes: state.viewable_changes
    } = responseData)

    state.params.total_pages = responseData.total_pages
    state.params.total = responseData.total,
    state.params.items_per_page = responseData.items_per_page,
    state.params.page = responseData.page
  }
}

export const getters = {
  getField,
}

export const actions = {
  init({ state, commit, dispatch }) {
    let modelName = AxiosWrapper.getNextPathNameElement('show');
    let modelId = AxiosWrapper.getNextPathNameElement(modelName);
    AxiosWrapper.get(`/data_api/changes/show/${modelName}/${modelId}`, { params: state.params }).then(function (response) {
      commit('getCallCompleted', response.data);
    })
  },
  setPageAndThenGetPage({ dispatch, commit, state }, page) {
    commit('updateField', { path: "params.page", value: page });
    dispatch('init');
  },
  changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'params.items_per_page',
      value: itemsPerPage
    });
    dispatch('init');
  }
}