import Vue from 'vue'
import AxiosWrapper from "~/assets/javascript/AxiosWrapper"
import Helper from "~/components/common/Libraries/Helper"
import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'
import ModalHelper from "~/components/ModalHelper"

const getDefaultState = () => ({
  showCopyModal: false,
  zones: null,
  libraryId: null,
  copyHeader: null,
  copyUrl: null,
  paramName: '',
  successUrl: ''
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setShowCopyModal(state, value) {
    state.showCopyModal = value
  },
  setCommonData(state, { data, id }) {
    state["zones"] = data.zones
    state["libraryId"] = id
    state["copyHeader"] = data.copy_header
  },
  setRecipientLibrariesData(state, id) {
    state["copyUrl"] = `/data_api/recipient_libraries/${id}/copy_to_zone`
    state["paramName"] = 'recipient_library'
    state["successUrl"] = '/recipient_libraries'
  },
  setDowntimeLibrariesData(state, id) {
    state["copyUrl"] = `/data_api/downtime/libraries/${id}/copy_to_zone`;
    state["paramName"] = 'downtime_library'
    state["successUrl"] = '/downtime/libraries'
  },
  checkBoxToggled(state, { value, zone }) {
    Vue.set(zone, 'selected', value)
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async openCopyModal({ dispatch, commit }, { id, isDowntime }) {
    const getUrl = Helper.getUrl(isDowntime, id)
    AxiosWrapper.get(getUrl).then(({ data }) => {
      dispatch('setLibraryBasedData', { id, isDowntime })
      commit('setCommonData', { data, id })
      commit("setShowCopyModal", true)
    })
  },
  async setLibraryBasedData({ commit }, { id, isDowntime }) {
    if (isDowntime) {
      commit('setDowntimeLibrariesData', id)
      ModalHelper.addShowParamToUrl('show_copy_downtime', id, 'Copy Downtime')
    } else {
      commit('setRecipientLibrariesData', id)
      ModalHelper.addShowParamToUrl('show_copy_recipient', id, 'Copy Recipient')
    }
  },
  async closeCopyModal({ commit }) {
    ModalHelper.removeLibrariesParams()
    commit("setShowCopyModal", false)
  },
  async copyButtonPressed({ state }, router) {
    ModalHelper.removeLibrariesParams()
    const params = Helper.createCopyParams(state)
    AxiosWrapper.patch(state.copyUrl, params).then(router.go())
  },
  async showCopyModalIfPresentInUrl({ dispatch }) {
    let params = AxiosWrapper.getSearchParams()
    if (Object.keys(params).includes('show_copy_downtime'))
      dispatch('openCopyModal', { id: params['show_copy_downtime'], isDowntime: true });
    if (Object.keys(params).includes('show_copy_recipient'))
      dispatch('openCopyModal', { id: params['show_copy_recipient'], isDowntime: false })
  },
  async deleteLibrary({ state }, { zone, library }) {
    if (confirm(translate("are_you_sure"))) {
      AxiosWrapper.delete(library["delete_path"], { id: library["id"] }).then(() => {
        let libraries = zone["libraries"]
        libraries.splice(libraries.indexOf(library), 1)
      }).catch(this.defaultError);
    }
  },
}