<template lang="pug">
  some-layout
</template>

<script>

import SomeLayout from '~/components/Layouts/DefaultLayout.vue'

export default {
  components: { SomeLayout },
  middleware: ['sessionHandler', 'pageRedirections']
}
</script>