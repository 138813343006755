import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';
export const state = () => ({
  expansionTitle: '',
  questionnaireId: null,
  expansionId: null,
})

export const getters = {
  getField,
  breadcrumbs(state) {
    let base = [{ text: translate('expansions'), path: `/audit/questionnaires/${state.questionnaireId}/edit/expansions`}]

    if (state.expansionId == 'new') base.push({ text: translate('new_expansion') })
    else base.push({ text: translate('expansion') })

    return base
  }
}

export const mutations = {
  setExpansionTitle(state, value) {
    state.expansionTitle = value
  },
  setIds(state, params) {
    state.questionnaireId = params.id
    state.expansionId = params.expansion_id
  },
  updateField
}

export const actions = { }