export default {
  defaultParamsMap() {
    return {
      zone: "zone_ids",
      job: "job_ids",
      job_revision: "job_revision_ids",
      show_unfinished: "show_unfinished",
      page: "page",
      items_per_page: "items_per_page",
      start_date: "start_date",
      end_date: "end_date",
      show_edit_shift: "show_edit_shift",
      editing_shift_id: "editing_shift_id",
      job_status: "job_status",
      work_area: "work_area_ids",
      post: "post_ids"
    }
  },
  defaultRevertParamsMap() {
    return {
      zone_ids: "zone",
      job_ids: "job",
      job_revision_ids: "job_revision",
      show_unfinished: "show_unfinished",
      page: "page",
      items_per_page: "items_per_page",
      start_date: "start_date",
      end_date: "end_date",
      show_edit_shift: "show_edit_shift",
      editing_shift_id: "editing_shift_id",
      job_status: "job_status",
      work_area_ids: "work_area",
      post_ids: "post"
    }
  },
}