import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';

const getDefaultState = () => ({
  gaugeTypes: [
    {
      label: translate("answer_breakdown"),
      value: "Dashboard::Gauges::AnswersBreakdown"
    },
    {
      label: translate("corrective_action"),
      value: "Dashboard::Gauges::CorrectiveAction"
    },
    {
      label: translate("corrective_action_resolution"),
      value: "Dashboard::Gauges::CorrectiveActionResolution"
    },
    {
      label: translate("compliance"),
      value: "Dashboard::Gauges::Compliance"
    },
    {
      label: translate("embed_link"),
      value: "Dashboard::Gauges::Embed"
    },
    {
      label: translate("scheduled_compliance"),
      value: "Dashboard::Gauges::ScheduledCompliance"
    },
    {
      label: translate("audit_score"),
      value: "Dashboard::Gauges::AuditScore"
    },
    {
      label: translate("audits_performed"),
      value: "Dashboard::Gauges::AuditsPerformed"
    }
  ]
})

export const state = getDefaultState

export const getters = { getField }
export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  }
}