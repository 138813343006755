import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  questionnaireGroupBundle: {attachment: null},
  csv: null,
  bundles: []
})

export const state = getDefaultState

export const getters = {
  bundles: state => state.bundles,
  questionnaireGroupBundle: state => state.questionnaireGroupBundle,
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      new_bundle: state.questionnaireGroupBundle,
      bundles: state.bundles,
    } = getRequestData);
  },
  attachFile(state, file) {
    state.questionnaireGroupBundle.attachment = file
    state.csv = file
  },
  attachCsvToBundle(state) {
    state.questionnaireGroupBundle.attachment = state.csv
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get('/data_api/importers/bundles/audit/questionnaire_groups').then(response => {
      commit('init', response.data)
    })
  },
  deleteBundle({ commit }, bundle) {
    AxiosWrapper.delete(`/data_api/importers/bundles/${bundle.id}`)
      .then(AxiosWrapper.redirectSuccess)
  },
  createAll({ commit }, bundle) {
    AxiosWrapper.post(`/data_api/importers/bundles/audit/questionnaire_groups/${bundle.id}/create_all`)
      .then(AxiosWrapper.redirectSuccess)
  },
  deleteAll({ commit }) {
    AxiosWrapper.delete('/data_api/importers/bundles/audit/questionnaire_groups/destroy_all').then(AxiosWrapper.redirectSuccess)
  }
}