import invert from 'lodash/invert'

export default {
  defaultParamsMap() {
      return {
        zone_id: "zone_ids",
        job_id: "job_ids",
        work_area_id: "work_area_ids",
        post_id: "post_ids",
        questionnaire_group_id: "questionnaire_group_ids",
        questionnaire_id: "questionnaire_ids",
        shift_description_id: "shift_description_ids",
        user_id: "user_ids",
        incomplete: "incomplete",
        page: "page",
        items_per_page: "items_per_page",
        start_date: "start_date",
        end_date: "end_date",
        part_number: "part_numbers_constraint_ids",
        serial_number: "serial_numbers_constraint_ids",
        lot_number: "lot_numbers_constraint_ids",
        other1: "other1s_ids",
        other2: "other2s_ids",
        exam_id: "exam_ids",
        version: "versions",
      }
    },
    defaultRevertParamsMap() {
      return invert(this.defaultParamsMap())
    },
}