<template lang="pug">
  .d-flex.flex-row.breadcrumbs
    template(v-for="(crumb, index) in truncatedBreadcrumbs")
      template(v-if="crumb.path")
        template(v-if="crumb.pathCollection")
          nuxt-link.mr-1(:to="crumb.path") {{ crumb.text }}
          .btn.btn-link.mr-1.dropdown-toggle.p-0(data-toggle="dropdown")
          .dropdown-menu
            nuxt-link.dropdown-item(v-for="(collectionCrumb, index) in crumb.pathCollection", :key="index" :to="collectionCrumb.path") {{ collectionCrumb.text }}
        nuxt-link.mr-1(v-else, :to="crumb.path") {{ crumb.text }}
        span.mr-1(v-if="isNotLastItem(index)") /
      span(v-else) {{ crumb.text }}

</template>

<script>
import cloneDeep from "lodash/cloneDeep"
export default {
  props: ["breadcrumbs", "truncateTo"],
  computed: {
    truncatedBreadcrumbs() {
      if (!this.truncateTo) return this.breadcrumbs

      return cloneDeep(this.breadcrumbs).map(bc => {
        if (bc['pathCollection']) {
          bc['pathCollection'].forEach(p => {
            if (p['text'].length > this.truncateTo)
              p['text'] = `${p['text'].substring(0, this.truncateTo)}...`;
          })
        }

        if (bc['text'] && bc['text'].length > this.truncateTo)
          bc['text'] = `${bc['text'].substring(0, this.truncateTo)}...`;

        return bc
      })
    }
  },
  methods: {
    isNotLastItem(index) {
      return index != this.truncatedBreadcrumbs.length - 1
    }
  }
}
</script>
