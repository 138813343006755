<template lang="pug">
  div
    Breadcrumbs(:breadcrumbs="breadcrumbs",
              :truncateTo="25",
              v-if="!isScrolledDown && !isCustomer")
    h1 {{ 'shift_summary' | translate }}
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('header',['isScrolledDown']),
    ...mapFields('job',['job']),
    ...mapFields('jobRevision', ['jobRevision', 'jobRevisionBreadcrumbs']),
    ...mapGetters('jobRevision', ['getRevisionBreadcrumb']),
    ...mapGetters('login',['isCustomer']),
    breadcrumbs() {
      if (this.jobRevision) return [
        ...this.jobRevisionBreadcrumbs,
        { text: this.translate('shifts'), path: `/jobs/${this.job['id']}/job_revisions/${this.jobRevision.id}/shifts` },
        { text: this.translate('shift_summary') },
      ]
      return []
    }
  }
};
</script>

