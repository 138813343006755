import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import flattenDeep from 'lodash/flattenDeep'
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js';
import ParamsHelper from '~/assets/audit/scheduled_questionnaires/ParamsHelper.js'

const getDefaultState = () => ({
  scheduled_questionnaires: [],
  all_scheduled_questionnaires: [],
  searchInProgress: false,
  total: null,
  total_pages: null,
  page: 1,
  items_per_page: 10,
  incomplete: true,
  searchParamsPath: '',
  addScheduledUrl: '',
  iCalUrl: '',
  zoneIdPresent: false,
  showRemove: false,
  scheduleToRemove: null,
  deleteInProgress: false,
  removeSeries: "false",
  calendarUrl: "",
  showCalendarFeed: false,
  zone: null
})

export const state = getDefaultState

const searchUrl = '/data_api/audit/scheduled_questionnaires/search';

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  searchCompleted(state, getRequestData) {
    ({
      scheduled_questionnaires: state.scheduled_questionnaires,
      zone: state.zone,
      calendar_url: state.calendarUrl,
      total_pages: state.total_pages,
      total: state.total,
      items_per_page: state.items_per_page,
      page: state.page
    } = getRequestData)

    state.all_scheduled_questionnaires = flattenDeep(state.scheduled_questionnaires.map(sq => {
      return sq.data
    }))
  },
  removeScheduledQuestionnaireAtIndex(state, rowId) {
    let outerIndex = -1
    let innerIndex = -1

    state.scheduled_questionnaires.some((sq, index) => {
      innerIndex = sq.data.findIndex(s => s.id === rowId)

      if (innerIndex > -1) {
        outerIndex = index
        return true
      }
    })

    if (innerIndex > -1) {
      state.scheduled_questionnaires[outerIndex].data.splice(innerIndex, 1);

      if (state.scheduled_questionnaires[outerIndex].data.length === 0) {
        state.scheduled_questionnaires.splice(outerIndex, 1);
      }
    }
  },
  setZoneIdPresent(state, value) {
    state.zoneIdPresent = value;
  },
  toggleDeleteInProgress(state) {
    state.deleteInProgress = !state.deleteInProgress
  },
}

export const getters = {
  getField,
  loadingMessage(state) {
    return translate('loading')
  }
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")

    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ParamsHelper.defaultParamsMap();
    dispatch("selects/init", { params, paramsMap }, { root: true });
    dispatch("datetime/init", { params, isReportPage: true }, { root: true });
    dispatch("setNonSelectParams", params);
  },
  async setNonSelectParams({ commit }, params) {
    if (Object.getOwnPropertyNames(params).length !== 0) {
      if (params.incomplete == 'true') {
        commit('updateField', { path: 'incomplete', value: true });
      } else {
        commit('updateField', { path: 'incomplete', value: false });
      }
      if (params.items_per_page) {
        commit('updateField', { path: 'items_per_page', value: parseInt(params.items_per_page) });
      }
      if (params.page) {
        commit('updateField', { path: 'page', value: parseInt(params.page) });
      }
    }
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get(searchUrl, { params }).then((response) => {
      commit('searchCompleted', response.data);
      dispatch('setZoneIdBoolIfPresent', params);
    })
  },
  async resetPaginationIfZonesChanged({ commit, rootState }) {
    let urlParams = AxiosWrapper.getSearchParams();
    if (`${urlParams.zone_id}` != rootState.selects.params.zone_ids) {
      commit('updateField', { path: 'page', value: 1 });
      commit('updateField', { path: 'items_per_page', value: 10 });
    }
  },
  async injectDateTimeParamsIfPresent({ rootGetters }, params) {
    if (rootGetters['datetime/getStartDate']) {
      params['start_date'] = rootGetters['datetime/getStartDate']
    }
    if (rootGetters['datetime/getEndDate']) {
      params['end_date'] = rootGetters['datetime/getEndDate']
    }
  },
  async createAndInjectUrl({ rootGetters }, params) {
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'ScheduledAuditsSearch', pathWithParams);
  },
  async searchForScheduledAudits({ state, dispatch, rootGetters }) {
    dispatch("resetPaginationIfZonesChanged");

    let params = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], ParamsHelper.defaultRevertParamsMap()),
      incomplete: state.incomplete,
      page: state.page,
      items_per_page: state.items_per_page,
    }

    let paramsForUrl = {...params, ...rootGetters["selects/prefixedCustomValues"]}

    dispatch("injectDateTimeParamsIfPresent", params);
    dispatch("buildLinkUrls", paramsForUrl);
    dispatch("createAndInjectUrl", paramsForUrl)
    dispatch("getCallForSearch", params);
  },
  async buildLinkUrls({ commit, state }, params) {
    commit('updateField', {
      path: "searchParamsPath",
      value: AxiosWrapper.buildSearchUrl(params)
    });
    commit('updateField', {
      path: "addScheduledUrl",
      value: `/scheduled_questionnaires/new/${params.zone_id}` + state.searchParamsPath
    });
    commit('updateField', {
      path: "iCalUrl",
      value: `/admin_api/scheduled_questionnaires/generate_calendar_ical` + state.searchParamsPath
    });
  },
  async setPageAndThenGetPage({ dispatch, commit, state }, page) {
    if (state.searchInProgress == false) {
      commit('updateField', { path: "page", value: page });
      dispatch('searchForScheduledAudits');
    }
  },
  async changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'items_per_page',
      value: itemsPerPage
    });
    dispatch('searchForScheduledAudits');
  },
  async cancelRemoveSchedule({ commit }) {
    commit('updateField', { path: "showRemove", value: false });
    commit('updateField', { path: "scheduleToRemove", value: null });
  },
  async removeScheduled({ commit }, { rowData }) {
    commit('updateField', { path: "showRemove", value: true });
    commit('updateField', { path: "scheduleToRemove", value: rowData });
  },
  async deleteScheduled({ state, commit, dispatch }, { rowData }) {
    commit('toggleDeleteInProgress')

    let idToRemove = null

    if (state.scheduleToRemove === null) {
      commit('updateField', { path: "removeSeries", value: 'false' });
      idToRemove = rowData.id
    } else {
      idToRemove = state.scheduleToRemove.id
    }

    AxiosWrapper.delete(`/data_api/audit/scheduled_questionnaires/${idToRemove}`,
      { params: { recurring: state.removeSeries } }).then(() => {
        commit('toggleDeleteInProgress')
        commit('updateField', { path: "showRemove", value: false });
        commit('updateField', { path: "scheduleToRemove", value: null });

        if (state.removeSeries === 'true') {
          dispatch('searchForScheduledAudits')
        } else {
          commit('removeScheduledQuestionnaireAtIndex', idToRemove)
          if (state.scheduled_questionnaires.length === 0) {
            dispatch('setPageAndThenGetPage', state.page - 1);
          }
        }

        commit('updateField', { path: "removeSeries", value: 'false' });
    })
  },
  async setZoneIdBoolIfPresent({ commit }, params) {
    if (params.zone_id || params.zone_ids) {
      commit("setZoneIdPresent", true);
    } else {
      commit("setZoneIdPresent", false);
    }
  },
  async setLoader({ commit }, value) {
    commit("updateField", {
      path: "searchInProgress",
      value: value
    })
  }
}