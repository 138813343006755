import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import uniq from 'lodash/uniq'
import { getField, updateField } from 'vuex-map-fields';
import cloneDeep from 'lodash/cloneDeep'
import ListMethods from '~/components/lists/commonListMethods';

const getDefaultState = () => ({
  suspectCodeModel: {
    defect_codes_attributes: [],
    name: ""
  },
  zonesForSelect: [],
  currentLesseeId: null,
  shouldShowAddInModal: true,
  jobRevisionId: null,
  jobId: null,
  importCsvFile: null,
  copyAndPaste: '',
  convertToTemplate: false,
  permissions: {},
  originalCodes: [],
  showAddModal: false,
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, { data, jobId = null, jobRevisionId = null }) {
    state.zonesForSelect = data.zones;
    state.currentLesseeId = data.current_lessee_id
    state.permissions = data.permissions

    if (data.suspect_code_model) {
      state.suspectCodeModel = data.suspect_code_model
    } else {
      state.suspectCodeModel = {
        name: '',
        id: null,
        defect_codes_attributes: [],
        zone_ids: [],
        job_ids: [],
        global: false,
        lessee_id: state.currentLesseeId
      }
    }

    state.originalCodes = cloneDeep(state.suspectCodeModel.defect_codes_attributes)

    state.jobId = jobId
    state.jobRevisionId = jobRevisionId

    if (state.jobRevisionId || state.jobId) {
      state.shouldShowAddInModal = false
    }
  },
  attachFile(state, file) {
    state.importCsvFile = file
  },
  resetFileAndCopyPaste(state) {
    state.copyAndPaste = ''
    state.importCsvFile = null
    state.showAddModal = false
  },
  resetDatasource(state) {
    state.suspectCodeModel = {}
    state.convertToTemplate = false
  },
  addImageToCode(state, { index, image }) {
    state.suspectCodeModel.defect_codes_attributes[index].reference_pictures_attributes.push(image);
  },
  updateReferencePicture(state, { codeIndex, pictureIndex, pictureObject }) {
    state.suspectCodeModel.defect_codes_attributes[codeIndex].reference_pictures_attributes[pictureIndex] = pictureObject
  },
  deleteReferencePicture(state, { codeIndex, pictureIndex }) {
    let picture = state.suspectCodeModel.defect_codes_attributes[codeIndex].reference_pictures_attributes[pictureIndex]
    if (picture.id) picture._destroy = true;
    else state.suspectCodeModel.defect_codes_attributes[codeIndex].reference_pictures_attributes.splice(pictureIndex, 1)
  },
  removeAllCodes(state) {
    state.suspectCodeModel.defect_codes_attributes = []
  },
  addSuspectCodes(state, newSuspectCodes) {
    newSuspectCodes = newSuspectCodes.map(c => ({
                                                  reference_pictures_attributes: [],
                                                  name: c
                                                })
                                          )
    state.suspectCodeModel.defect_codes_attributes = newSuspectCodes
                                                      .concat(state.suspectCodeModel.defect_codes_attributes)
  },
  hideAddModal(state) {
    state.showAddModal = false
  },
  updateField
}

export const getters = {
  hasJobOrJobRevision(state, getters, rootState, rootGetters) {
    return state.jobId !== null || state.jobRevisionId !== null
  },
  hasJobRevision(state, getters, rootState, rootGetters) {
    return state.jobRevisionId !== null
  },
  hasJob(state, getters, rootState, rootGetters) {
    return state.jobId !== null
  },
  hasId(state) {
    if (state.suspectCodeModel) {
      return state.suspectCodeModel.id
    } else {
      return false
    }
  },
  getField
}

let hasDuplicates = function (suspectCodes) {
  suspectCodes = suspectCodes.map(dc => dc.toLowerCase().trim())

  return suspectCodes.some(x => {
    return suspectCodes.filter(y => x === y).length > 1
  })
}

let hasReachedMax = function (suspectCodes) {
  return suspectCodes.length > 100
}

export const actions = {
  async init({ commit }, { id, jobId, jobRevisionId }) {
    commit('resetState')

    let url = `/data_api/suspect_code_models/new`
    if (id) url = `/data_api/suspect_code_models/${id}/edit`

    this.$axios.get(url).then(response => {
      commit('init', { data: response.data, jobId, jobRevisionId })
    })
  },
  addImagesToSuspectCode({commit}, {images, index}) {
    images.forEach((file) => {
      let reader = new FileReader();

      reader.onload = (e) => {
        let fileObject =  {
                            index,
                            sample_quality: false,
                            picture: file,
                            url: e.target.result,
                            _destroy: false
                          }

        commit('addImageToCode', {index, image: fileObject})
      };

      reader.readAsDataURL(file);
    });
  },
  closeAdd({ commit }, vueInstance) {
    commit('resetFileAndCopyPaste')
    vueInstance.$emit('close')
  },
  importCSV({ state, commit }, vueInstance) {
    AxiosWrapper.post(`/data_api/suspect_code_models/${state.suspectCodeModel.id}/import`,
      { file: state.importCsvFile }).then(response => {
        let responseSuspectCodes = response.data.suspect_codes

        if (hasDuplicates(responseSuspectCodes) || hasReachedMax(responseSuspectCodes)) {
          if (hasReachedMax(responseSuspectCodes)) vueInstance.$toast.error(vueInstance.translate('max_suspect_codes'));
          if (hasDuplicates(responseSuspectCodes)) vueInstance.$toast.error(vueInstance.translate('duplicate_codes_csv'));
          commit('resetFileAndCopyPaste')
        } else {
          commit('addSuspectCodes', responseSuspectCodes)
        }
        commit('resetFileAndCopyPaste')
      }).catch(error => {
        vueInstance.$toast.error(error)
      })
  },
  addCopyAndPaste({ commit, state }, vueInstance) {
    let newSuspectCodes = uniq(state.copyAndPaste.replace(/(\r\n|\n)/g, ",").split(",").filter(s => s !== ''))
    if (hasReachedMax(newSuspectCodes)) vueInstance.$toast.error(vueInstance.translate('max_suspect_codes'));
    else {
      commit('addSuspectCodes', newSuspectCodes)
      commit('hideAddModal')
    }
  },
  close({ commit }, vueInstance) {
    commit('resetDatasource')
    vueInstance.$emit('close', {})
  },
  save({ commit, state, dispatch, getters }, routerOrVueInstance) {
    let suspectCodeModel = cloneDeep(state.suspectCodeModel);

    ListMethods.indexToPositions(suspectCodeModel.defect_codes_attributes)
    ListMethods.addDestroyedItems(cloneDeep(state.originalCodes), suspectCodeModel.defect_codes_attributes)

    AxiosWrapper.postOrPatch(`/data_api/suspect_code_models`, {
                                                                suspect_code_model: suspectCodeModel,
                                                                convert_to_template: state.convertToTemplate,
                                                                job_id: state.jobId,
                                                                job_revision_id: state.jobRevisionId
                                                              })
    .then(response => {
      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
      if (getters.hasJobOrJobRevision) {
        dispatch('job/refreshJob', { jobId: state.jobId }, { root: true })
        if (getters.hasId && state.convertToTemplate) {
          routerOrVueInstance.$emit('close', {
            suspectCodeModel: response.data.suspect_code_model,
            lesseeId: response.data.lessee_id
          })
        } else if (getters.hasId) {
          routerOrVueInstance.$emit('close', {
            suspectCodeModel: response.data.suspect_code_model,
          })
        } else {
          routerOrVueInstance.$emit('close', { suspectCodeModel: response.data.suspect_code_model })
        }

        commit('resetDatasource')
      } else {
        routerOrVueInstance.push('/suspect_code_models')
      }
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true })
    })
  }
}