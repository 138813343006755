import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  device: {},
  deviceAttributes: {},
  currentUserIsSuperuser: false,
  jobs: [],
  deviceId: AxiosWrapper.getNextPathNameElement("devices"),
  JSONData: {}
})

export const state = getDefaultState

export const getters = {
  getField,
  device(state) {
    return state.device;
  },
  permissions(state) {
    return state.permissions;
  },
  deviceAttributes(state) {
    return state.deviceAttributes;
  },
  jobs(state) {
    return state.jobs;
  },
  JSONData(state) {
    return state.JSONData;
  },
  currentUserIsSuperuser(state) {
    return state.currentUserIsSuperuser;
  }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      device: state.device,
      device_attributes: state.deviceAttributes,
      jobs: state.jobs,
    } = getRequestedData);
  },
  setJSONData(state, JSONData) {
    state.JSONData = JSONData;
  },
}

export const actions = {
  async init({ state, commit, dispatch, rootGetters }, loader) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/devices/${state.deviceId}/test`, { id: state.deviceId }).then(response => {
      commit("init", response.data);
      commit("updateField", { path: "currentUserIsSuperuser", value: rootGetters["login/isSuperuser"] })
      dispatch("generateZoneJSONGraph", loader);
    });
  },
  generateZoneJSONGraph({ state, commit }, loader) {
    let zoneJSONurl = process.env.baseUrl + state.deviceAttributes.generate_zone_json_path

    AxiosWrapper.put(zoneJSONurl).then((response) => {
      commit("setJSONData", JSON.parse(response.request.responseText));
    })
  },
  handleTabChange({ state }, tabIndex) {
    if (tabIndex == 0) {
      history.pushState("test", "Test", `/devices/${state.deviceId}/test`);
    } else {
      history.pushState(history.state, "show_zone_json", AxiosWrapper.buildSearchUrl({ show_zone_json: true }));
    }
  }
}