<template lang="pug">
  .input-group.d-flex.flex-nowrap.date-time-field
    datetime(:value="value",
            v-bind="$attrs",
            @input="dateChanged"
            :type="type",
            input-class="form-control",
            class="theme-primary",
            ref="datetime",
            :disabled="disabled",
            value-zone="local")
    .input-group-append
      button.input-group-text(@click.prevent="setDateTimeClicked",
                              :disabled="disabled",
                              v-if="dateIsEmpty || !canBeEmpty",
                              key="empty")
        .fal(:class="{['fa-calendar-alt']: !requiresOnlyTime, ['fa-clock']: requiresOnlyTime}")
      button.input-group-text(@click.prevent="dateChanged('')",
                              :disabled="disabled",
                              v-else,
                              key="notEmpty")
        .fal.fa-times
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  components: {
    Datetime
  },
  props: {
    requiresTime: {
      type: Boolean,
      default: false
    },
    requiresOnlyTime: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    canBeEmpty: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dateIsEmpty() {
      return this.value ? this.value === "" : true;
    },
    type() {
      if (this.requiresOnlyTime) {
        return "time"
      } else if (this.requiresTime) {
        return "datetime"
      } else {
        return "date";
      }
    }
  },
  methods: {
    dateChanged(event) {
      this.$emit("input", event);
    },
    setDateTimeClicked() {
      this.$refs["datetime"].$el.children[0].click();
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/stylesheets/vue_datetime.scss";
</style>

<style lang="scss" scoped>
@import "~/assets/stylesheets/colors.scss";
.input-group-text {
  background-color: $white;
  border-left: none;
}
</style>