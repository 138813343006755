import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
import moment from 'moment'

let prepareFailureParams = function (state, payload) {
  let failure = state.failure;
  let data = {
    audit_answers_failure: {
      user_id: failure.user.id,
      suspect_code_is_other: Boolean(failure.suspect_code.isOther),
      suspect_code: failure.suspect_code.isOther ? failure.suspect_code.otherCode : failure.suspect_code.name,
      quantity: failure.quantity,
      failure_code_model_id: failure.suspect_code_model.id,
      control_reject_tag: failure.control_reject_tag,
      verified: failure.verified,
      repaired: failure.repaired,
      scrap: failure.scrap,
      recycle: failure.recycle,
      released: failure.released,
      file_stores_attributes: failure.files
    }
  }

  if (payload.newAttachment) data.audit_answers_failure.attachment = payload.newAttachment;

  return data;
}

const getDefaultState = () => ({
  bin: null,
  job: null,
  failure: {},
  suspectCodeModels: [],
  suspectCodes: [],
  users: [],
  currentUser: {},
  initialized: false,
  plan: null,
  permissions: {},
  showLoader: false,
  otherSuspectCodeOption: {
    name: I18n.t('other'),
    isOther: true,
    otherCode: ''
  },
})

export const state = getDefaultState

export const getters = {
  additionalFiles: state => {
    if (state.failure.files) return state.failure.files.filter(f => !f._destroy);
    return [];
  },
  // newComment: state => ({
  //   parent_id: state.defect.id,
  //   parent_type: "Failure",
  //   user_id: state.currentUser.id,
  //   lookupId: new Date().getTime()
  // }),
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitialData(state, data) {
    state.failure = data.failure;
    state.suspectCodeModels = data.suspect_code_model_options;
    state.suspectCodes = data.suspect_code_options;
    state.users = data.users;
    state.currentUser = data.current_user;
    if (data.plan) state.plan = data.plan;
    state.initialized = true;
    state.permissions = data.permissions;
    state.newPlanData = data.new_plan_data
  },
  removeFile(state, file) {
    let files = state.failure.files;
    Vue.set(file, '_destroy', 1)
    let tobeDeletedfile = {
      _destroy: 1,
      id: file.id
    }
    files.splice(files.indexOf(file), 1, tobeDeletedfile);
  },
  addFile(state, file) {
    let newFile = { file: file, is_image: true, name: file.name, medium_url: window.URL.createObjectURL(file) };
    state.failure.files.push(newFile);
  },
  updateField
}

export const actions = {
  init({ commit }, defectId) {
    commit("resetState")
    let failureId = AxiosWrapper.getNextPathNameElement("failures");
    AxiosWrapper.get(`/data_api/audit/answers/failures/${failureId}/edit`).then(({data}) => {
      commit('setInitialData', data)
    })
  },
  initNew({ commit }, binId) {
    commit("resetState")

    AxiosWrapper.get('/data_api/defects/new', { params: { bin_id: binId } })
      .then(response => commit('setInitialData', response.data))
  },
  updateFailure({ state, commit }, payload) {
    commit("updateField", {path: "showLoader", value: true})
    AxiosWrapper.patch(`/data_api/audit/answers/failures/${state.failure.id}`,prepareFailureParams(state, payload)).then(() => {
      AxiosWrapper.redirectSuccess
    })
  },
  createDefect({ commit, state }, payload) {
    let data = prepareDefectParams(state, payload);
    data.bin_id = state.bin.id;
    return AxiosWrapper.post('/data_api/defects', data)
  },
  createCorrectiveActionPlan({ commit, state }, description) {
    const date = new Date()

    let newPlanObject = {
      lookupId: date.getTime(),
      in_progress: true,
      current_user_id: state.currentUser.id,
      expected_resolution_date: moment().format("YYYY-MM-DD"),
      user_id: state.currentUser.id,
      description: state.newPlanData.description,
      department_id: null,
      champion_id: null,
      severity_id: null,
      tasks: [],
      can_be_verified_by_id: null,
      failure_id: state.newPlanData.failure.id,
      failure: state.newPlanData.failure,
      defect: state.newPlanData.defect,
      answer_id: state.newPlanData.answer_id,
      champions: [],
      'anything_in_progress?': true,
      'in_progress?': true,
      'is_overdue?': false
    }

    state.plan = newPlanObject;
  },
  saveChangesClicked({commit}) {

  }
}