<template lang="pug">
  .edn-multiselect(:class="errorClassFor(inputName)",v-else)
    multiselect.ednMultiselect(v-bind="$attrs",
                                :value="value",
                                @input="onInput",
                                @select="onSelect",
                                selectLabel="✓",
                                deselectLabel="×",
                                :group-selection="groupSelection",
                                :group-values="groupValues",
                                :group-label="groupLabel",
                                @tag="addTag",
                                :taggable="taggable",
                                :name="inputName",
                                :data-cy="dataCy",
                                :disabled="disabled")
      div(slot="caret",
          slot-scope="slotProps",
          @mousedown.prevent.stop="slotProps.toggle()",
          :class="{caretAndSearch: searchable, caretOnly: !searchable}")
        i.fal.fa-search(v-if="searchable")
        i.fas.fa-caret-down
    .errors(v-show="hasErrorsFor(inputName)")
      .invalid(v-for="text in errorText(inputName, showAllValidationMessages)",
                :data-cy="`validation-message-${inputName}`") {{ text }}
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      required: false
    },
    showAllValidationMessages: {
      default: true
    },
    inputName: {
      type: String,
      default: ""
    },
    dataCy: {
      type: String,
      default: ""
    },
    groupSelection: {
      type: Boolean,
      default: false
    },
    groupLabel: {
      type: String,
      default: ""
    },
    groupValues: {
      type: String,
      default: ""
    },
    searchable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(event) {
      this.$emit("input", event);
    },
    onSelect(event) {
      this.$emit("select", event);
    },
    addTag(tag) {
      this.$emit("tag", tag);
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/stylesheets/custom.scss";

.ednMultiselect {
  background: none !important;
  .caretAndSearch {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 0;
    width: 40px;
    cursor: pointer;
    padding: 10px 50px 0 0;
    :first-child {
      margin-right: 10px;
      color: $gray-600;
    }
  }

  .caretOnly {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 0;
    width: 20px;
    cursor: pointer;
    padding: 10px 0 0 0;
  }

  .multiselect__select {
    height: $input-height !important;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single,
  .multiselect__option,
  .multiselect__placeholder {
    margin-bottom: 0;
    font-family: Open Sans !important;
    font-weight: 400 !important;
    font-size: 0.85rem !important; //todo: that's font base
    color: $gray-800 !important;
    background: none;
  }
  .multiselect__placeholder,
  .multiselect__input {
    padding: 2px 0px 0px 4px;
  }
  .multiselect__tags {
    min-height: $input-height !important;
    border-radius: $input-border-radius !important;
    border: $input-border-width solid $input-border-color;
    padding: 5px 40px 0px 8px !important;

    .multiselect__tag {
      color: color-yiq(theme-color("dark"));
      margin-bottom: 0px;
      background: $primary !important;
      font-size: 0.8rem !important;
      padding: 4px 26px 5px 10px !important;

      .multiselect__tag-icon:hover {
        background: darken($primary, 10) !important;
      }
    }
  }
  .multiselect__tags:focus-within {
    border-color: $primary !important;
  }
  .multiselect__option--highlight {
    background-color: $primary !important;
    color: $white !important;
    &.multiselect__option--selected {
      font-weight: normal !important;
      background-color: $danger !important;
    }
    &.multiselect__option--selected::after {
      font-weight: normal !important;
      background: none !important;
      font-size: 1.2rem !important;
    }
    &::after {
      font-weight: normal !important;
      background: none !important;
      font-size: 1rem !important;
    }
  }
  .multiselect__option--selected {
    font-weight: normal !important;
    background-color: $gray-100 !important;
  }
}

.is-error {
  background-color: $red;
}

.field-error {
  border-color: $red;
}

.help.field-error {
  color: $red;
}
</style>
