import Vue from 'vue'
import Vuelidate from "vuelidate";
import get from 'lodash/get';
import compact from 'lodash/compact';
import errorTexts from '~/assets/javascript/ErrorTexts.js';
import flattenDeep from 'lodash/flattenDeep'

export default function () {
  Vue.use(Vuelidate)
  Vue.config.optionMergeStrategies.validations = function (toVal, fromVal) {
    if (!toVal) return fromVal
    if (!fromVal) return toVal

    let toObj = typeof toVal === "function" ? toVal() : toVal
    let fromObj = typeof fromVal === "function" ? fromVal() : fromVal

    return { ...toObj, ...fromObj }
  }

  Vue.mixin({
    props: ['validator'],
    data() {
      return {
        submitted: false
      }
    },
    methods: {
      errorText(name = this.inputName, showAll = false) {
        let instance = this.validator || this.$v;
        let inputValidation = get(instance, name)
        if (inputValidation) {
          let validationTypes = compact(
            flattenDeep(
              Object.entries(inputValidation.$params).map((array) => {
                if (array[1]) {
                  if (array[1].$sub) {
                    return array[1].$sub
                  } else {
                    return array[1]
                  }

                } else {
                  return null
                }
              })))

          let texts = compact(
            validationTypes.map((validationType) => {
              if (!inputValidation[validationType.type]) {
                return this.errorTextForType(validationType)
              }
            }))

          if (showAll) {
            return texts
          } else {
            return [texts[texts.length - 1]]
          }
        } else {
          return ''
        }
      },
      errorTextForType(validation) {
        return errorTexts(validation)
      },
      errorClassFor(inputName) {
        return { 'input': true, 'hasError': this.hasErrorsFor(inputName) }
      },
      hasErrorsFor(inputName) {
        let instance = this.validator || this.$v
        if (instance && get(instance, inputName)) {
          return get(instance, inputName).$error
        } else {
          return false
        }
      },
    }
  })
}