import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import groupBy from 'lodash/groupBy'

const getDefaultState = () => ({
  documents: {},
  documentTypes: {},
  currentUser: {},
  jobId: AxiosWrapper.getNextPathNameElement('jobs'),
  loading: true,
  showModal: false,
  formData: null,
})

export const state = getDefaultState

export const getters = {
  getField,
  documents: state => { return groupBy(state.documents, 'type') }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateDocument(state, { document }) {
    Vue.set(state.documents, document.id, document)
  },
  removeDocument(state, documentId) {
    Vue.delete(state.documents, documentId)
  },
}

export const actions = {
  loadDocuments({ commit, state }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/documents?job_id=${state.jobId}`)
      .then(response => {
        commit('updateField', { path: 'documents', value: { ...response.data.documents } })
        commit('updateField', { path: 'documentTypes', value: response.data.document_types })
        commit('updateField', { path: 'currentUser', value: response.data.current_user })
        commit('updateField', { path: 'loading', value: false })
      })
  },
  submitDocument({ commit, state }, { document }) {
    commit('updateField', { path: 'loading', value: true })
    AxiosWrapper.postOrPatch('/data_api/documents', { document: document })
      .then(response => {
        commit('updateDocument', { document: response.data })
        commit('updateField', { path: 'loading', value: false })
        commit('updateField', {path:'showModal', value:false})
        commit('updateField', {path:'formData', value: null})
      })
  },
  deleteDocument({ commit }, { document }) {
    commit('updateField', { path: 'loading', value: true })
    AxiosWrapper.delete(`/data_api/documents/${document.id}`)
      .then(response => {
        commit('removeDocument', document.id)
        commit('updateField', { path: 'loading', value: false })
      });
  },
}