import { translate } from '~/assets/javascript/localization/Localization';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { addDestroyedItems, indexToPositions } from '~/components/lists/commonListMethods'
import { hasDuplicates, hasReachedMax } from '~/assets/javascript/util'
import uniq from 'lodash/uniq'

import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  job_id: AxiosWrapper.getNextPathNameElement("jobs"),
  job: {},
  constraintType: null,
  jobConstraintsAttributes: [],
  isUserSuperuser: false,
  originalConstraints: null,
  confirmingCsvRequest: false,
  copyAndPaste: '',
  importCsvFile: null,
  showList: true,
})

export const state = getDefaultState

export const getters = {
  getField,
  exportUrl(state) {
    if (state.constraintType != null) {
      return `/data_api/download_csv/constraints?job=${state.job_id}&type=${state.constraintType}`
    }
  },
  deleteAllUrl(state) {
    if (state.constraintType != null) {
      return `/data_api/jobs/${state.job_id}/constraints/destroy_all?type=${state.constraintType}`
    }
  },
  constraintLabel(state) {
    if (state.constraintType != null) {
      let label = '';

      switch(state.constraintType) {
        case "PartNumberConstraint":
          label = (state.job.part_number_label == "" ? translate("part_number") : state.job.part_number_label)
          break;
        case "SerialNumberConstraint":
          label = (state.job.serial_number_label == "" ? translate("serial_number") : state.job.serial_number_label);
          break;
        case "LotNumberConstraint":
          label = (state.job.lot_number_label == "" ? translate("lot_number") : state.job.lot_number_label);
          break;
        case "Other1Constraint":
          label = (state.job.other1_label == "" ? translate("other1") : state.job.other1_label);
          break;
        case "Other2Constraint":
          label = (state.job.other2_label == "" ? translate("other2") : state.job.other2_label);
          break;
        default:
          label = state.job.constraintType;
      }
      return label;
    }
  }
}

export const mutations = {
  updateField,
  addConstraints(state, constraints) {
    constraints.forEach(c => {
      state.jobConstraintsAttributes.push({value: c, type: state.constraintType})
    })
  },
  resetFileAndCopyPaste(state) {
    state.copyAndPaste = ''
    state.importCsvFile = null
    state.showList = true
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, data) {
    ({
      job: state.job,
      constraints_attributes: state.jobConstraintsAttributes,
      constraints_attributes: state.originalConstraints,
      type: state.constraintType,
      is_user_superuser: state.isUserSuperuser
    } = data);
  },
  deleteAllConstraints(state) {
    state.jobConstraintsAttributes = []
  },
}

export const actions = {
  async init({ commit }, params) {
    commit("resetState")
    let url = `/data_api/jobs/${params.job_id}/constraints_index/${params.constraint_type}`
    return this.$axios.get(url)
    .then(({ data }) => {
      commit('init', data)
    })
  },
  addCopyAndPaste({ commit, state }, vueInstance) {
    let newItems = uniq(state.copyAndPaste.replace(/(\r\n|\n)/g, ",").split(",").filter(s => s !== ''))
    if (hasReachedMax(newItems)) vueInstance.$toast.error(vueInstance.translate('max_items'));
    else {
      commit('addConstraints', newItems)
      commit('updateField', { path: "showList", value: true })
    }
  },
  closeAdd({ commit }) {
    commit('resetFileAndCopyPaste')
  },
  importCSV({ state, commit }, vueInstance) {
    AxiosWrapper.post("/data_api/importers/imports/import_items", { file: state.importCsvFile }).then(response => {
        let responseItems = response.data.items

      if (hasDuplicates(responseItems) || hasReachedMax(responseItems)) {
        if (hasReachedMax(responseItems)) vueInstance.$toast.error(vueInstance.translate('max_items'));
        if (hasDuplicates(responseItems)) vueInstance.$toast.error(vueInstance.translate('duplicate_items_csv'));
          commit('resetFileAndCopyPaste')
        } else {
          commit('addConstraints', responseItems)
        }
        commit('resetFileAndCopyPaste')
      }).catch(error => {
        vueInstance.$toast.error(error)
      })
  },
  async deleteAll({ getters }) {
    if (confirm(translate("are_you_sure"))) {
      return AxiosWrapper.delete(getters.deleteAllUrl)
    } else {
      return Promise.reject()
    }
  },
  async saveConstraints({ state, commit }) {
    let constraintAttributes = state.jobConstraintsAttributes.map(constraint => ({
                                                                    ...constraint,
                                                                    type: state.constraintType,
                                                                  }))

    indexToPositions(constraintAttributes)

    addDestroyedItems(state.originalConstraints, constraintAttributes)

    let data = {
          job: {
            ...state.job,
            constraints_attributes: constraintAttributes
          }
        }

    return this.$axios.put(`/data_api/jobs/${state.job_id}/update_job_with_constraints`, data)
  },
}