import Vue from 'vue';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';

AxiosWrapper.init();

const getDefaultState = () => ({
  dependentPlans: [],
  allPlans: [],
  departments: [],
  severities: [],
  users: [],
  permissions: {}
})

export const state = getDefaultState

export const mutations = {
  updateField,
  init(state, { departments, severities, users, permissions}) {
    state.permissions = permissions
    state.severities = severities
    state.departments = departments
    state.users = users
    state.dependentPlans = []
    state.allPlans = []

    state.departments = [{translated_name:translate('none'),id: null}, ...state.departments]
    state.severities = [{ translated_name: translate('none'), id: null }, ...state.severities]
  },
  addDependentPlan(state, {plans, parent}) {
    state.dependentPlans.push({parent: parent, plans: [...plans]})
    state.allPlans = [...state.allPlans, ...plans]
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setChampions(state, {planId, champions}) {
    let index = state.allPlans.findIndex(p => {
      if (p.id) {
        return p.id === planId
      } else {
        return p.lookupId === planId
      }
    })

    state.allPlans[index].champions = champions
  },
  setSavedPlan(state, {planId, plan}) {
    let index = state.allPlans.findIndex(p => {
      if (p.id) {
        return p.id === planId
      } else {
        return p.lookupId === planId
      }
    })

    state.allPlans.splice(index, 1, plan)

    let planIndex = -1

    let dpIndex = state.dependentPlans.findIndex(dp => {
      planIndex = dp.plans.findIndex(p => {
        if (p.id) {
          return p.id === planId
        } else {
          return p.lookupId === planId
        }
      })

      return planIndex > -1
    })

    if (dpIndex > -1 && planIndex > -1) {
      state.dependentPlans[dpIndex].plans.splice(planIndex, 1, plan)
    }
  },
  addNewPlanToParent(state, { dependentPlanIndex, plan }) {
    state.dependentPlans[dependentPlanIndex].plans.push(plan)
    state.allPlans.push(plan)
  },
  removePlan(state, { dependentPlanIndex, plan }) {
    let allPlanIndex = state.allPlans.findIndex(p => {
      if (plan.lookupId) {
        return p.lookupId === plan.lookupId
      } else {
        return p.id === plan.id
      }
    })

    state.allPlans.splice(allPlanIndex, 1)

    let planIndex = state.dependentPlans[dependentPlanIndex].plans.findIndex(p => {
      if (plan.lookupId) {
        return p.lookupId === plan.lookupId
      } else {
        return p.id === plan.id
      }
    })

    state.dependentPlans[dependentPlanIndex].plans.splice(planIndex, 1)
  },
  attachCommentToPlan(state, {plan, comment}) {
    plan.all_comments.unshift(comment)
  },
  attachTaskToPlan(state, { planIndex, taskIndex, task }) {
    if (taskIndex > -1) {
      state.allPlans[planIndex].tasks.splice(taskIndex, 1, task)
    } else {
      state.allPlans[planIndex].tasks.unshift(task)
    }
  },
  attachSubtaskToTask(state, { task, subtaskIndex, subtask }) {
    if (subtaskIndex > -1) {
      task.subtasks.splice(subtaskIndex, 1, subtask)
    } else {
      task.subtasks.unshift(subtask)
    }
  },
  removeSubtaskFromTask(state, {task, subtaskIndex}) {
    task.subtasks.splice(subtaskIndex, 1)
  },
  removeTaskFromPlan(state, {plan, taskIndex}) {
    plan.tasks.splice(taskIndex, 1)
  },
  attachVerificationToPlan(state, { verification, planIndex }) {
    Vue.set(state.allPlans[planIndex], 'verification_questionnaire', verification)
  },
  removeVerification(state, { planIndex }) {
    state.allPlans[planIndex].verification_questionnaire = null
  },
  attachReplyToParent(state, { parent, comment}) {
    parent.replies.unshift(comment)
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  }
}

export const getters = {
  getField,
  planIndex(state) {
    return planId => {
      return state.allPlans.findIndex(p => {
        if (p.id) {
          return p.id === planId
        } else {
          return p.lookupId === planId
        }
      })
    }
  },
  canChange(state) {
    return (plan) => {
      return state.permissions.can_edit || (state.permissions.is_corrective_action_specialist && plan.original_champion_id === state.permissions.user_id)
    }
  },
  taskIndex(state) {
    return (plan, taskId) => {
      return plan.tasks.findIndex(t => {
        if (t.id) {
          return t.id === taskId
        } else {
          return t.lookupId === taskId
        }
      })
    }
  },
  subtaskIndex(state) {
    return (task, subtaskId) => {
      return task.subtasks.findIndex(st => {
        if (st.id) {
          return st.id === subtaskId
        } else {
          return st.lookupId === subtaskId
        }
      })
    }
  },
  dependentPlansIndex(state) {
    return parent => {
      return state.dependentPlans.findIndex(dp => dp.parent.id === parent.id)
    }
  },
  champions(state) {
    return plan => {
      var collection = []
      let index = state.allPlans.findIndex(p => p.id === plan.id)

      if (index > -1) {
        if (state.allPlans[index].champions.length > 0) {
          collection = state.allPlans[index].champions
        } else {
          collection = [[state.users[0], state.users[1]]]
        }

        collection = collection.map(e=>{
          return {role: e[0], champions: e[1] }
        })

        collection = [...collection, ...[{role: state.users[0], champions: state.users[1]}]]
      }

      collection = [{role: translate('none'), champions:[{id:null, name:translate('please_select')}]},...collection]

      collection = collection.filter(c => c.role !== undefined )

      return collection
    }

  }
}

export const actions = {
  removeTask({ commit, getters, state }, { plan, taskId }) {
    let taskIndex = getters.taskIndex(plan, taskId)

    commit('removeTaskFromPlan', { plan: plan, taskIndex: taskIndex })
  },
  removeSubtask({commit, getters, state}, {task, subtaskId}) {
    let subtaskIndex = getters.subtaskIndex(task, subtaskId)

    commit('removeSubtaskFromTask', {task: task, subtaskIndex: subtaskIndex})
  },
  removeVerificationOnPlan({commit, getters}, planId) {
    commit('removeVerification', { planIndex: getters.planIndex(planId) })
  },
  addCommentToPlan({commit, getters, state}, {comment, planId}) {
    commit('attachCommentToPlan', {
                                    plan: state.allPlans[getters.planIndex(planId)],
                                    comment: comment
                                  })
  },
  addCommentToTask({ commit, getters, state }, { comment, taskId, planId }) {
    let plan = state.allPlans[getters.planIndex(planId)]

    commit('attachCommentToPlan', {
      plan: plan.tasks[getters.taskIndex(plan, taskId)],
      comment: comment
    })
  },
  addTaskToPlan({ commit, getters, state }, { task, planId, taskId }) {
    commit('attachTaskToPlan', {
      planIndex: getters.planIndex(planId),
      taskIndex: getters.taskIndex(state.allPlans[getters.planIndex(planId)], taskId),
      task: task
    })
  },
  addSubtaskToTask({commit, getters, status}, { task, subtaskId, subtask  }) {
    let subtaskIndex = getters.subtaskIndex(task, subtaskId)
    commit('attachSubtaskToTask', { task: task, subtask: subtask, subtaskIndex: subtaskIndex})
  },
  addCommentToSubtask({ commit, getters, state }, { subtask, comment }) {
    commit('attachCommentToPlan', {
      plan: subtask,
      comment: comment
    })
  },
  addReplyToComment({ commit, getters, state }, { parent, comment }) {
    commit("attachReplyToParent", {parent: parent, comment: comment})
  },
  addVerificationQuestionnaireToPlan({ commit, getters, state }, { verification, planId }) {
    commit('attachVerificationToPlan', {
      verification: verification,
      planIndex: getters.planIndex(planId),
    })
  },
  async addPlans({commit}, {plans, parent}) {
    commit("addDependentPlan", {plans, parent})
  },
  async grabChampions({commit, state, getters}, planId) {
    let plan = state.allPlans[getters.planIndex(planId)]

    AxiosWrapper.get(`/data_api/audit/answers/${plan.answer_id}/champions`,
                      { params: { plan_id: plan.id,
                                  department_id: plan.department_id
                                } }).then(({ data }) => {
      commit('setChampions', {planId: planId, champions: data})
    })
  }
}