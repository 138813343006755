export const hasDuplicates = function (arrayOfStrings) {
  arrayOfStrings = arrayOfStrings.map(dc => dc.toLowerCase().trim())

  return arrayOfStrings.some(x => {
    return arrayOfStrings.filter(y => x === y).length > 1
  })
}

export const hasReachedMax = function (array, max=250) {
  return array.length > max
}

export const produceUniqueId = function(prefix) {
  return `${prefix}-${new Date().getTime()}`
}
