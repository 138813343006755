<template lang="pug">
  error(:error="error")
</template>

<script>

import Error from '~/components/Layouts/Error.vue'

export default {
  layout: "empty",
  props: {
    error: {
      default: () => ({})
    }
  },
  components: { Error },
  middleware: ['sessionHandler']
}
</script>