import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  searchResultJSON: {},
  sortOrder: [{
    field: 'compliance_percent',
    sortField: 'compliance_percent',
    direction: 'desc'
  }],
  summaryReportsFields: [
    {
      name: "__component:questionnaire-stats-summary-reports-zone-cell",
      title: I18n.t("zone")
    },
    {
      name: "compliance_percent",
      title: translate("compliance_percent"),
      sortField: "compliance_percent"
    }
  ],
  summaryReportsChartOptions: {
    maximumX: 120,
    xLabel: `${translate("percent")} (%)`,
    colourRanges: ["blue"],
    yLookupKey: "name",
    xLookupKey: "compliance_percent"
  },
  searchImmediately: false,
  hasSearched: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
  },
  async searchWithZone({commit, dispatch}, zoneId) {
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();

    params = {...params, zone: zoneId}

    dispatch("selects/init", { params, paramsMap }, { root: true });
    dispatch("search");
  },
  async search({ dispatch, rootGetters }) {
    gtmEvents.reportGenerated({
      type: "Questionnaire Stats"
    })
    let paramsForSearch = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }
    let paramsForUrl = {...paramsForSearch, ...rootGetters["selects/prefixedCustomValues"]}
    history.replaceState(history.state, 'QuestionnaireStatsSearch', AxiosWrapper.buildSearchPathWithSanitized(paramsForUrl));
    dispatch("getCallForSearch", paramsForSearch)
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get(`/data_api/questionnaire_stats_search`, { params }).then((response) => {
      commit("updateField", {
        path: "searchResultJSON",
        value: response.data
      });
      commit("updateField", {
        path: "hasSearched",
        value: true
      });
      dispatch("contentWithHeader/update", null, {root: true})
    })
  }
}