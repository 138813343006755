import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  questionnaires_back_path: '',
  good_reference_create_path: '',
  contact_report_create_path: '',
  job_revision_create_path: '',
  bin_create_path: '',
  defect_create_path: ''
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      questionnaires_back_path: state.questionnaires_back_path,
      good_reference_create_path: state.good_reference_create_path,
      contact_report_create_path: state.contact_report_create_path,
      job_revision_create_path: state.job_revision_create_path,
      bin_create_path: state.bin_create_path,
      defect_create_path: state.defect_create_path
    } = responseData)
  }
}

export const getters = {
  getField,
}

export const actions = {
  async init({ commit }) {
    let questionnaireGroupId = AxiosWrapper.getNextPathNameElement('questionnaire_groups');
    AxiosWrapper.get(`/data_api/audit/questionnaire_groups/${questionnaireGroupId}/get_paths_for_new_questionnaire`).then(function (response) {
      commit('initialGetCompleted', response.data);
    })
  },
  createGoodReferenceTemplate({state}){
    AxiosWrapper.post(state.good_reference_create_path)
  },
  createContactReportTemplate({state}){
    AxiosWrapper.post(state.contact_report_create_path)
  },
  createJobRevisionNew({state}){
    AxiosWrapper.post(state.job_revision_create_path)
  },
  createBinNew({state}){
    AxiosWrapper.post(state.bin_create_path)
  },
  createNewDefect({state}){
    AxiosWrapper.post(state.defect_create_path)
  }
}