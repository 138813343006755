import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField,getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  pastObservationsAmount: null,
  observationArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  controlChartInstructions: null,
  specMax: null,
  specMin: null,
  nominal: null,
  upperControlLimit: null,
  lowerControlLimit: null,
  useRule1Beyond3Sigma: true,
  useRule2Beyond2Sigma: true,
  useRule3Beyond1Sigma: true,
  useRule4BeyondConsecutiveBeyondNominal: true,
  useRule5Trending: true,
  limitId: null,
  instructionId: null
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question).then(QuestionsHelper.setControlChartInstructions(state))
  },
  attachInstuctionsToParams(state, {params, rootState }) {
    const unitOfMeasure = rootState['questions']["editQuestions"]["unitOfMeasure"]
    params["checklistControlChartInstructionAttributes"] = {
      id: state.instructionId,
      unit_of_measure: unitOfMeasure,
      limit_attributes:{
        id: state.limitId,
        spec_max: state.specMax,
        spec_min: state.specMin,
        nominal: state.nominal,
        upper_control_limit: state.upperControlLimit,
        lower_control_limit: state.lowerControlLimit,
        use_rule1_beyond3_sigma: state.useRule1Beyond3Sigma,
        use_rule2_beyond_2_sigma: state.useRule2Beyond2Sigma,
        use_rule3_beyond_1_sigma: state.useRule3Beyond1Sigma,
        use_rule4_beyond_consecutive_beyond_nominal: state.useRule4BeyondConsecutiveBeyondNominal,
        use_rule5_trending: state.useRule5Trending,
      }
    }
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({dispatch}, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_control_chart_question', name: 'controlChart'}, { root: true })
  },
  async configureInitState({ dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', true, { root: true })
  },
  async configureParams({ dispatch, commit, rootState }, params) {
    commit("attachInstuctionsToParams", { params, rootState })
    dispatch("questions/editQuestions/configureCommonParams", params, { root: true })
  }
}