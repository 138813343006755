import { updateField, getField } from "vuex-map-fields"
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import moment from "moment"
import { cloneDeep } from 'lodash'
import { translate } from '~/assets/javascript/localization/Localization';
import gtmEvents from "~/assets/javascript/gtmEvents"

const getDefaultState = () => ({
  timesheet: {
    justified_early: null,
    overtime_reason: null,
    justified_late: null,
    activities: [],
    total_overtime: null,
    total_regular: null,
    downtime: null,
    time_frame_start: null,
    time_frame_end: null,
    arrived_late_reason: null,
    custom_left_early_reason: null,
    left_early_reason_id: null,
    total_downtime: null,
    total_overtime: null,
    total_regular: null,
    total_downtime_granular: null,
    total_overtime_granular: null,
    total_regular_granular: null,
    shift_name: null,
    user_name: null,
    date_for: null,
    status: null,
    recipients: [],
    id: null,
    created_at: null,
    clock_in: null,
    clock_out: null
  },
  timesheets: [],
  roles: [],
  jobsWithWorkedHours: {
    totals: {
      total_downtime_granular: null,
      total_regular_granular: null,
      total_overtime_granular: null
    },
    jobHours: []
  },
  statusOptions: [
    { name: translate("not_submitted"), value: "Not Submitted" },
    { name: translate("pending_billing_approval"), value: "Pending Billing Approval" },
    { name: translate("pending_approval"), value: "Pending Approval" },
    { name: translate("approved"), value: "Approved" }
  ],
  filterDeleted: true,
  jobHoursDateRange: null,
  shouldReset: true,
  requestCsvUrl: '',
  selectedStatus: null,
  hideFilters: true,
  loaded: false
})

const createUpdateableTimeSheet = function (timeSheet) {
  let sheet = cloneDeep(timeSheet);
  let activities = sheet.activities.filter(a => !a.deleted);
  if (activities.length > 0 && sheet.date_for) delete sheet.date_for;
  return sheet;
}

export const state = getDefaultState

export const mutations = {
  initTimeSheetsData(state, getRequestData) {
    ({
      time_sheets: state.timesheets,
    } = getRequestData);
  },
  initTimeSheetsByJobData(state, getRequestData) {
    ({
      time_sheets: state.jobsWithWorkedHours,
    } = getRequestData);
  },
  restoreState(state, newState) {
    Object.assign(state, newState);
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  toggleActivityApproval(state, id) {
    let activity = state.timesheet.activities.find(a => a.id === id);
    activity.approved = !activity.approved;
  },
  approveAllActivities(state) {
    state.timesheet.activities.forEach(a => {
      if (!a.deleted) a.approved = true;
    })
  },
  updateField
}

export const getters = {
  leftEarly(state) {
    return state.timesheet.left_early_reason_id !== null ||
            (state.timesheet.custom_left_early_reason !== null &&
              state.timesheet.custom_left_early_reason !== "") ||
      moment.parseZone(state.timesheet.clock_out).diff(moment.parseZone(state.timesheet.time_frame_end)) < 0;
  },
  arrivedLate(state) {
    return (state.timesheet.arrived_late_reason !== null &&
             state.timesheet.arrived_late_reason !== "") ||
      moment.parseZone(state.timesheet.clock_in).diff(moment.parseZone(state.timesheet.time_frame_start)) > 0;
  },
  hasOverlappingActivities(state) {
    let overlapFound, date;
    let activities = state.timesheet.activities.filter(a => !a.deleted);
    activities.forEach(x => {
      date = moment(x.from);
      activities.forEach(y => {
        if (y.id !== x.id &&
          date.diff(y.from, "seconds") >= 0 &&
          date.diff(y.to, "seconds") < 0) overlapFound = true;
      })
    })

    return overlapFound
  },
  getField
}

export const actions = {
  initTimeSheet({ dispatch, commit }, timeSheetId) {
    let promises = []

    promises.push(dispatch("leftEarlyReasons/loadReasons", null, { root: true }))
    promises.push(this.$makeApiRequest("initTimeSheet", { timeSheetId }).then(({ data }) => {
      commit("updateField", { path: "timesheet", value: data.time_sheet.data.attributes })
    }))

    return Promise.all(promises)
  },
  initEdit({ rootState, dispatch }) {
    let params = cloneDeep(rootState.selects.params)
    let paramsMap = ConfigHelper.defaultParamsMap();

    return dispatch('getRoles').then(() => {
      return dispatch("selects/init", {
        params, paramsMap, config: {
          reset: {
            onReset(state) {
              return ["users", "zones", "shiftDescriptions"];
            }
          },
          startUp: {
            onSelect: ["users", "zones", "shiftDescriptions"]
          }
        }
      }, { root: true })
    })
  },
  initByJob({ dispatch, commit, state }) {
    if (state.shouldReset) {
      commit('resetState')
    }

    let params = AxiosWrapper.getSearchParams();

    if (Object.keys(params).includes("deleted")) {
      commit("updateField", { path: "filterDeleted", value: params.deleted === "false" });
    }

    let paramsMap = ConfigHelper.defaultParamsMap();

    if (Object.keys(params).includes("status")) {
      commit("updateField", { path: "selectedStatus", value: state.statusOptions.find(s => s.value === params.status) });
    } else {
      commit("updateField", { path: "selectedStatus", value: state.statusOptions.find(s => s.value === "Approved") });
    }

    dispatch("selects/initForReportPage", {
      params, paramsMap, config: {
        reset: {
          onReset(state) {
            return ["zones", "shiftDescriptions"];
          }
        },
        startUp: {
          onSelect: ["zones", "shiftDescriptions"]
        }
      }
    }, { root: true });
  },
  init({ dispatch, commit, state }) {
    if (state.shouldReset) {
      commit('resetState')
    }

    let params = AxiosWrapper.getSearchParams();

    if (Object.keys(params).includes("deleted")) {
      commit("updateField", { path: "filterDeleted", value: params.deleted === "false" });
    }

    if (Object.keys(params).includes("status")) {
      commit("updateField", { path: "selectedStatus", value: state.statusOptions.find(s => s.value === params.status) });
    }

    if (params.end_date === undefined && params.start_date === undefined) {
      let endDate = new Date();
      params.start_date = moment(endDate).subtract(1, 'weeks').format('YYYY-MM-DD');
      params.end_date = moment(endDate).format('YYYY-MM-DD');
    }

    let paramsMap = ConfigHelper.defaultParamsMap();

    dispatch('getRoles').then(() => {
      dispatch("selects/initForReportPage", {
        params, paramsMap, config: {
          reset: {
            onReset(state) {
              return ["users", "zones", "shiftDescriptions"];
            }
          },
          startUp: {
            onSelect: ["users", "zones", "shiftDescriptions"]
          }
        }
      }, { root: true }).then(() => {
        dispatch("search")
        commit("updateField", { path: "hideFilters", value: true })
      });
    })
  },
  getRoles({ commit }) {
    return this.$axios.get(`/hrs/data_api/activities/tablet_roles`).then(({ data }) => {
      commit('updateField', { path: "roles", value: data.roles })
    })
  },
  getRollCall({commit}) {
    this.$axios.get(`/hrs/data_api/time_sheets/roll_call`).then(({ data }) => {
      commit("updateField", { path: "timesheets", value: data.periods })
      commit("updateField", { path: "loaded", value: true })
    })
  },
  searchByJob({ state, commit, rootState, rootGetters }) {
    commit("updateField", {
      path: "jobsWithWorkedHours", value: {
        totals: {
          total_downtime_granular: null,
          total_regular_granular: null,
          total_overtime_granular: null
        },
        jobHours: []
      }
    })

    let params = cloneDeep(rootState.selects.params)

    gtmEvents.reportGenerated({
      type: "Time Sheets By Job"
    })

    params = {
      ...ConfigHelper.mapParams(params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      by_job: true
    }

    if (state.filterDeleted) {
      params.deleted = false;
    } else {
      params.deleted = true
    }

    if (state.selectedStatus) {
      params.status = state.selectedStatus.value;
    } else {
      delete params.status;
    }

    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);

    commit('updateField', { path: "requestCsvUrl", value: '/data_api/download_csv/time_by_job_summary' + AxiosWrapper.buildSearchUrl(params) })
    history.replaceState(history.state, 'TimeSheetsByJobSearch', pathWithParams);

    this.$axios.get(`/hrs/data_api/time_sheets/search`, { params }).then(({ data }) => {
      commit('initTimeSheetsByJobData', data)

      let start = rootState.datetime.start_date;
      let end = rootState.datetime.end_date;

      if (start) start = moment(start).format("dddd, MMMM DD, YYYY");
      if (end) end = moment(end).format("dddd, MMMM DD, YYYY");

      if (start && end) {
        if (start === end) {
          commit('updateField', { path: 'jobHoursDateRange', value: `${start}` })
        } else {
          commit('updateField', { path: 'jobHoursDateRange', value: `${start} - ${end}` })
        }
      }
    })
  },
  search({ state, commit, rootState, rootGetters }) {
    let params = cloneDeep(rootState.selects.params)

    gtmEvents.reportGenerated({
      type: "Time Sheets"
    })

    params = {
      ...ConfigHelper.mapParams(params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }

    if (state.filterDeleted) {
      params.deleted = false;
    } else {
      params.deleted = true
    }

    if (state.selectedStatus) {
      params.status = state.selectedStatus.value
    } else {
      delete params.status
    }

    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'TimeSheetsSearch', pathWithParams);

    this.$axios.get("/hrs/data_api/time_sheets/search", { params }).then(({ data }) => {
      commit('initTimeSheetsData', data)
    })
  },
  saveTimeSheet({ state }, newTimeSheetObject) {
    let time_sheet = createUpdateableTimeSheet({ activities: state.timesheet.activities,
                                                 ...newTimeSheetObject });
    return AxiosWrapper.postOrPatch('/hrs/data_api/time_sheets', { time_sheet });
  },
  approveAllActivities({ commit, state }) {
    commit("approveAllActivities")

    return AxiosWrapper.patch(`/hrs/data_api/time_sheets/${state.timesheet.id}/approve_all`)
  },
  approveActivity({ commit }, activityId) {
    commit("toggleActivityApproval", activityId)
    return AxiosWrapper.patch(`/hrs/data_api/activities/${activityId}/approve`)
  },
  updateTimeSheet({ state }) {
    let timeSheet = createUpdateableTimeSheet(state.timesheet);
    return this.$makeApiRequest("updateTimeSheet", { timeSheet })
  },
  submitToBilling({ state }, recipients) {
    return AxiosWrapper.patch(`/hrs/data_api/time_sheets/${state.timesheet.id}/submit_to_billing`, { recipients: recipients })
  },
  grantBillingApproval({ state, commit }) {
    return this.$axios.patch(`hrs/data_api/time_sheets/${state.timesheet.id}/grant_billing_approval`)
      .then(({ data }) => commit("updateField", { path: "timesheet.status", value: data.time_sheet_status }))
  }
}