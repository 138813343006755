const hrsUrlPrefix = "/hrs/data_api/"
const timeSheetUrlPrefix = `${hrsUrlPrefix}time_sheets/`

const DesignAwareGateway = function (axios) {
  function selectCase() {
    return window.location.pathname.split("/")[1] === "design" ? "design" : "default"
  }

  // Don't put any logic in "default" functions or I will hunt you down ᕕ༼✿•̀︿•́༽ᕗ -PS
  this.requests = {
    initTimeSheet: {
      default({ timeSheetId }) {
        return axios.get(`${timeSheetUrlPrefix}${timeSheetId}`)
      },
      design({ id }) {
        console.log("init time sheet in design", id)
        return Promise.resolve()
      }
    },
    deleteTimeSheet: {
      default({ timeSheetId }) {
        return axios.delete(`${timeSheetUrlPrefix}${timeSheetId}`)
      },
      design({ timeSheetId }) {
        window.alert(`DESIGN: Deleted time sheet with id: ${timeSheetId}`)
        return Promise.resolve()
      }
    },
    updateTimeSheet: {
      default({ timeSheet }) {
        return axios.patch(`${timeSheetUrlPrefix}${timeSheet.id}`, { time_sheet: timeSheet })
      },
      design({ timeSheet }) {
        console.log("TimeSheet Data", timeSheet)
        window.alert("Design: Updated time sheet. Data is in console.")
        return Promise.resolve()
      }
    },
    loadLeftEarlyReasons: {
      default() {
        return axios.get("/hrs/data_api/left_early_reasons")
      },
      design() {
        return Promise.resolve({
          data: {
            "reasons": {
              "data": [
                {
                  "id": "499",
                  "type": "index",
                  "attributes": {
                    "id": 499, "description": "CD - Cool Dogs",
                    justifiable: true
                  }
                },
                {
                  "id": "500",
                  "type": "index",
                  "attributes": {
                    "id": 500, "description": "BB - Baked Beans",
                    justifiable: false
                  }
                }
              ]
            }
          }
        })
      }
    },
    initNewActivity: {
      default({ timeSheetId }) {
        return axios.get(`/hrs/data_api/time_sheets/${timeSheetId}/activities/new`)
      },
      design({ timeSheetId }) {
        return Promise.resolve({
          data: {
            data: {
              id: null,
              type: "activity",
              attributes: {
                id: null,
                kind: null,
                other_activity_description: null,
                from: "2019-06-20T00:05:00.000-04:00",
                to: "2019-06-20T01:05:00.000-04:00",
                paid: null,
                paid_overtime: null,
                billed: null,
                device: null,
                total_hours: null,
                downtime_reason: null,
                zone_id: null,
                role_id: 10,
                overtime_hours: null,
                regular_hours: null,
                job_id: null,
                temp_job_name: null,
                comment: null,
                is_approved: false,
                time_sheet_approved: false,
                deleted: false,
                history: []
              }
            }
          }
        })
      }
    },
    initActivity: {
      default({ activityId }) {
        return axios.get(`/hrs/data_api/activities/${activityId}`)
      },
      design() {
        return Promise.resolve({
          data: {
            "data": {
              "id": "31",
              "type": "activity",
              "attributes": {
                "id": 31, "kind": "job",
                "other_activity_description": "",
                "from": "2019-06-19T23:02:00.000-04:00",
                "to": "2019-06-20T00:05:00.000-04:00",
                "paid": true, "paid_overtime": true, "billed": true, "device": null, "total_hours": 1.0, "downtime_reason": "undefined",
                "zone_id": 305, "role_id": 16, "overtime_hours": 0.0, "regular_hours": 1.0, "job_id": 1717, "temp_job_name": "",
                "comment": "",
                "is_approved": false, "time_sheet_approved": false, "deleted": false, "history": [{
                  "id": 601445, "action": "updated",
                  "date": "2019-06-26 14:46:16",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "to",
                    "old": "2019-06-20 01:05:00",
                    "new": "2019-06-20 00:05:00"
                  }]
                }, {
                  "id": 601444, "action": "updated",
                  "date": "2019-06-26 14:46:00",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "to",
                    "old": "2019-06-20 02:05:00",
                    "new": "2019-06-20 01:05:00"
                  }]
                }, {
                  "id": 601443, "action": "updated",
                  "date": "2019-06-26 14:45:46",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "from",
                    "old": "2019-06-19 22:02:00",
                    "new": "2019-06-19 23:02:00"
                  }]
                }, {
                  "id": 601442, "action": "updated",
                  "date": "2019-06-26 14:08:15",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "from",
                    "old": "2019-06-19 23:02:00",
                    "new": "2019-06-19 22:02:00"
                  }, {
                    "field": "to",
                    "old": "2019-06-20 00:05:00",
                    "new": "2019-06-20 02:05:00"
                  }]
                }, {
                  "id": 601441, "action": "updated",
                  "date": "2019-06-26 14:07:28",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "from",
                    "old": "2019-06-19 22:00:00",
                    "new": "2019-06-19 23:02:00"
                  }, {
                    "field": "to",
                    "old": "2019-06-20 02:00:00",
                    "new": "2019-06-20 00:05:00"
                  }]
                }, {
                  "id": 601402, "action": "updated",
                  "date": "2019-06-20 10:43:12",
                  "user": {
                    "name": "Test, Admin",
                    "id": 53754
                  }, "comment": "Not Approved",
                  "updated_fields": [{
                    "field": "approved_by_id",
                    "old": "Test, Admin",
                    "new": null
                  }]
                }, {
                  "id": 601400, "action": "updated",
                  "date": "2019-06-19 13:47:07",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "from",
                    "old": "2019-06-19 23:00:00",
                    "new": "2019-06-19 22:00:00"
                  }]
                }, {
                  "id": 601399, "action": "updated",
                  "date": "2019-06-19 13:46:35",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "to",
                    "old": "2019-06-20 00:00:00",
                    "new": "2019-06-20 02:00:00"
                  }]
                }, {
                  "id": 601398, "action": "created",
                  "date": "2019-06-19 13:28:38",
                  "user": {
                    "name": "Salmers, Patrick",
                    "id": 53116
                  }, "comment": "",
                  "updated_fields": [{
                    "field": "id",
                    "old": null, "new": 31
                  }, {
                    "field": "kind",
                    "old": null, "new": "job"
                  }, {
                    "field": "from",
                    "old": null, "new": "2019-06-19T23:00:00.000-04:00"
                  }, {
                    "field": "to",
                    "old": null, "new": "2019-06-20T00:00:00.000-04:00"
                  }, {
                    "field": "paid",
                    "old": null, "new": true
                  }, {
                    "field": "paid_overtime",
                    "old": null, "new": true
                  }, {
                    "field": "billed",
                    "old": null, "new": true
                  }, {
                    "field": "downtime_reason",
                    "old": null, "new": "undefined"
                  }, {
                    "field": "zone_id",
                    "old": null, "new": 305
                  }, {
                    "field": "role_id",
                    "old": null, "new": 16
                  }, {
                    "field": "job_id",
                    "old": null, "new": 1717
                  }, {
                    "field": "created_at",
                    "old": null, "new": "2019-06-19T13:28:38.039-04:00"
                  }]
                }]
              }
            }
          }
        })
      }
    }
  }

  this.makeRequest = function (name, params) {
    return this.requests[name][selectCase()](params);
  }.bind(this);

  return this;
}



export default ({ $axios }, inject) => {
  const gateway = new DesignAwareGateway($axios)
  inject("makeApiRequest", gateway.makeRequest)
}