export default {
  defaultParamsMap() {
    return {
      zone: "zone_ids",
      job: "job_ids",
      shift_descriptions: "shift_description_ids",
      start_date: "start_date",
      end_date: "end_date",
      constraint_type: "constraint_type",
      constraints: "constraints"
    }
  },
  defaultRevertParamsMap() {
    return {
      zone_ids: "zone",
      job_ids: "job",
      shift_description_ids: "shift_descriptions",
      start_date: "start_date",
      end_date: "end_date",
      constraint_type: "constraint_type",
      constraints: "constraints"
    }
  },
}