import { getField, updateField } from "vuex-map-fields";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { translate } from '~/assets/javascript/localization/Localization';
import cloneDeep from "lodash/cloneDeep";

AxiosWrapper.init();

const getDefaultState = () => ({
  questionnaireId: null,
  formData: null,
  championTemplateFormData: null,
  allAvailableQuestionsIds: [],
  params: {
    champion_id: null,
    question_ids: [],
  },
  loadingMessage: translate('no_data_available'),
  saveInProgress: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  init(state, getRequestData) {
    state.formData = getRequestData.form_data;
  },
  defaultToFirstChampion(state) {
    if (state.formData.options_for_champions_select.length > 0) {
      state.params.champion_id = state.formData.options_for_champions_select[0].champions[0].id;
    }
  },
  extractAllQuestionsIds(state) {
    state.allAvailableQuestionsIds = state.formData.questions.map(question => question.id);
  },
  addIdToQuestionnaireIdsParam(state, id) {
    state.params.question_ids.push(id);
  },
  removeIdFromQuestionnaireIdsParam(state, id) {
    let index = state.params.question_ids.indexOf(id);
    state.params.question_ids.splice(index, 1);
  },
  setQuestionnaireIdsParamTo(state, value) {
    let clonedValue = cloneDeep(value);
    state.params.question_ids = clonedValue;
  },
}

export const actions = {
  async init({ state, dispatch, commit }, questionnaireId) {
    commit('resetState')
    commit('updateField', {path: "questionnaireId", value: questionnaireId})

    AxiosWrapper.get(`/data_api/audit/questionnaires/${state.questionnaireId}/questions/assign_templates`, { questionnaire_id: state.questionnaireId }).then(response => {
      commit("init", response.data);
      commit("defaultToFirstChampion");
      commit("extractAllQuestionsIds");
      if (state.params.champio_id) dispatch("getChampionTemplateFormData");
    });
  },
  async getChampionTemplateFormData({ state, commit }) {
    AxiosWrapper.get(`/data_api/template/audit/corrective_action/champions/${state.params.champion_id}/get_champion_template`).then(response => {
      commit("updateField", {
        path: "championTemplateFormData",
        value: response.data
      });
    })
  },
  selectQuestionWorkFlow({ state, commit }, { select, id }) {
    if (select) {
      commit("addIdToQuestionnaireIdsParam", id);
    } else {
      commit("removeIdFromQuestionnaireIdsParam", id);
    }
  },
  async selectAllQuestionsWorkflow({ state, commit }, { select }) {
    if (select) {
      commit("setQuestionnaireIdsParamTo", state.allAvailableQuestionsIds);
    } else {
      commit("setQuestionnaireIdsParamTo", []);
    }
  },
  async applyChampionTemplate({ state }) {
    console.log("APPLLLYEE")
    AxiosWrapper.patch(`/data_api/audit/questionnaires/${state.questionnaireId}/questions/apply_templates`, { template: state.params }).then(response => {
    });
  },
}