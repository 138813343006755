import UrlManipulator from '~/assets/javascript/UrlManipulator'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { translate } from '~/assets/javascript/localization/Localization'
import { updateField, getField } from 'vuex-map-fields'
import gtmEvents from "~/assets/javascript/gtmEvents"

const getDefaultState = () => ({
  zonesWithJobs: [],
  selectedJob: null,
  partNumber: '',
  startDate: '',
  endDate: '',
  jobsWithBins: [],
  totalPages: 0,
  page: 1,
  totalBins: 0,
  currentBins: 0,
  binsPerPage: 75,
  confirmingCsvRequest: false,
  activitiesImageUrl: '',
  searchParams: '',
  hasSearched: false,
  requestUrl: ''
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setSearchParams(state, params) {
    state.searchParams = params
  },
  setSearchResults(state, searchResponse) {
    state.jobsWithBins = searchResponse.jobs_with_bins;
    state.totalPages = searchResponse.total_pages;
    state.totalBins = searchResponse.total_bins;
    state.currentBins = searchResponse.current_bins;
    state.page = searchResponse.page || 1;
    state.binsPerPage = searchResponse.items_per_page || 75;
  },
  setConfirmingCsvRequestToCompleteCsv(state) {
    state.confirmingCsvRequest = true
    state.requestUrl = '/data_api/download_csv/complete_work_item_search' + state.searchParams;
  },
  setConfirmingCsvRequestToCsv(state) {
    state.confirmingCsvRequest = true
    state.requestUrl = '/data_api/download_csv/bin_search' + state.searchParams;
  },
  setConfirmingCsvRequestTo(state, value) {
    state.confirmingCsvRequest = value
  },
  updateField
}

export const getters = {
  requestCsvUrl(state) {
    return state.requestUrl
  },
  getField
}

export const actions = {
  init({ commit, dispatch }) {
    commit('resetState')

    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();

    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
  },
  async search({ dispatch, rootGetters, state }) {
    gtmEvents.reportGenerated({
      type: "Work Items"
    })

    let paramsForSearch = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      items_per_page: state.binsPerPage,
      page: state.page
    }

    let paramsForUrl = {...paramsForSearch, ...rootGetters["selects/prefixedCustomValues"]}

    history.replaceState(history.state, 'BinSearch', UrlManipulator.buildSearchPathWithSanitized(paramsForUrl));

    dispatch("getCallForSearch", paramsForSearch)
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get('/data_api/bin_search', { params }).then(({data}) => {
      commit("setSearchParams", AxiosWrapper.buildSearchUrl(params))
      commit('setSearchResults', data)
      commit("updateField", {path: 'hasSearched', value: true})
      dispatch("contentWithHeader/update", labelValues => {
        delete labelValues[translate('job_revisions')]
        return labelValues
      }, {root: true})
    })
  },
  async changePage({ dispatch, commit }, page) {
    commit('updateField', { path: 'page', value: page });
    dispatch('search');
  },
  async changeBinsPerPage({ dispatch, commit }, binsPerPage) {
    commit('updateField', { path: 'binsPerPage', value: binsPerPage });
    dispatch('search');
  }
}