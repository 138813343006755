import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

const getBase = `/data_api/dashboard/devices`

const getDefaultState = () => ({
  available_questionnaires: null,
  job_id: null,
  device: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initDataFromGet(state, getRequestData) {
    ({
      available_questionnaires: state.available_questionnaires,
    } = getRequestData);
  },
}

export const getters = {
  getField,
  splashPageUrl(state) {
    return `/dashboard/devices?job=${state.job_id}&device=${state.device}`
  },
}

export const actions = {
  async init({ commit, state }) {
    commit('resetState')
    let loadedParams = AxiosWrapper.getSearchParams();
    commit('updateField', { path: 'job_id', value: AxiosWrapper.getNextPathNameElement('jobs')})
    commit('updateField', {path:'device', value: loadedParams.device})

    var getUrlItem = `${getBase}/jobs/${state.job_id}/questionnaires`;

    this.$axios.get(getUrlItem, { params: loadedParams }).then(function (response) {
      commit('initDataFromGet', response.data);
    })
  },
}