import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  cameraSetting: {}
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question).then(QuestionsHelper.setCameraSettings(state))
  },
  setCameraAttributes(state, {params, attributes}){
    params.cameraSettingAttributes = attributes
  },
  updateField
}

export const getters = {
  getField,
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', { params, questionControllerName: 'audit_camera_capture_question', name: 'cameraCapture' }, { root: true })
  },
  async configureInitState({ commit, dispatch, state }) {
    dispatch('questions/editQuestions/configueCommonInit', null, { root: true })
    commit('cameraSettings/initializeCameraSettings', state.cameraSetting, { root: true })
  },
  async configureParams({ commit,dispatch, rootState}, params) {
    commit("questions/editQuestions/setWeightAttributesOn", params, {root: true});
    commit('setCameraAttributes', { params, attributes:rootState.cameraSettings.cameraSettingsObject})
  }
}