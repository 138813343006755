<template lang="pug">
  #root.d-flex.flex-column
    .header
      nav.navbar.navbar-expand-md.navbar-dark.bg-dark
        .navbar-brand.d-flex.align-items-center.p-0
          img(src="/edn_logo_white.png" height="35" alt="logo")

    .container.body
      nuxt

    .footer.container
      a(:href='supportUrl', target="_blank") {{ 'contact_us' | translate }}
      p {{ 'patent_pending' | translate }}
      p {{ 'copyright' | translate }}
      p {{ 'copyright2' | translate }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters('login', ['supportUrl'])
}
</script>

<style lang="scss" scoped>
@import '~/assets/stylesheets/navbar.scss';
@import "~/assets/stylesheets/colors.scss";

#root {
  height: 100vh;
}

.body {
  flex-grow: 1;
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: $gray7;
  a, p {
    margin-bottom: 0.25rem;
  }
}
</style>