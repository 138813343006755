<template lang="pug">
  ul.navbar-nav.ml-auto(id="session-menu")
    li.nav-item
      nuxt-link#activities.nav-link(to='/events/users')
        .fal.fa-bell
    li.nav-item.dropdown.d-flex
      .nav-link.align-self-center(id="helpDropdown",
                                  role="button",
                                  data-toggle="dropdown",
                                  aria-haspopup="true",
                                  aria-expanded="false")
        .fal.fa-question-circle
      .dropdown-menu.dropdown-menu-right(aria-labelledby="helpDropdown")
        a#documentation.dropdown-item(href='https://edatanow.sharepoint.com/:f:/g/documentation/EvN_47K_5W1Jn5wIHFbmO0UBvAXNHyyuKW8LvpcV6c6VSA') {{ 'documentation' | translate }}
        a#support.dropdown-item(:href='supportUrl') {{ 'support' | translate }}

    li.nav-item.dropdown
      .nav-link.dropdown-toggle(id="loginDropdown", role="button", data-toggle="dropdown", aria-haspopup="true", aria-expanded="false") {{ userLastFirst }}
      .dropdown-menu.dropdown-menu-right(aria-labelledby="loginDropdown")
        .dropdown-item#logoutButton(@click.prevent="logout") {{ 'logout' | translate }}
        nuxt-link#lesseeSelection.dropdown-item(to='/lessee_selection', v-if="isSuperuser || hasMultipleLessees",
                                :data-id="`${lessee.id}`") {{ lessee.name }}
        nuxt-link#changeLocale.dropdown-item(to='/change_locale') {{ currentLanguage }}
        nuxt-link#userSettings.dropdown-item(:to="`/users/${user.id}/edit`", v-if="!isCustomer") {{ 'my_user_settings' | translate }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: mapGetters("login", [
    "userLastFirst",
    "currentLanguage",
    "supportUrl",
    "lessee",
    "user",
    "hasMultipleLessees",
    "isSuperuser",
    "isCustomer"
  ]),
  methods: {
    ...mapActions("login", ["logout"])
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/navbar.scss";
</style>
