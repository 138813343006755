import Qs from 'qs'

export default {
  getNextPathNameElement(current) {
    //http://localhost:3000/activity_logs/for_user/74
    //getNextPathNameElement("for_user") returns 74.
    //This documentation only exists because I can't create a reasonable method name :(

    let arrayPathNameArray = window.location.pathname.split("/");
    let indexOf = arrayPathNameArray.indexOf(current);
    return arrayPathNameArray[indexOf + 1];
  },
  flattenObjectWithArrayOfObjects(object) {
    let returnable = {};

    Object.keys(object).forEach(key => {
      if (Array.isArray(object[key])) {
        let paramArray = [];
        object[key].forEach(element => {
          paramArray.push(element.value);
        });
        returnable[key] = paramArray;
      } else {
        returnable[key] = object[key];
      }
    });

    return returnable;
  },
  flattenObject(object) {
    let params = {};

    Object.keys(object).forEach(function (key) {
      if (Object(object[key]) === object[key] && !Array.isArray(object[key])) {
        params[key] = object[key].value;
      } else if (Array.isArray(object[key])) {
        if (key == 'instruction') {
          const instructionList = object[key].map(item => item.value);
          params[key] = instructionList;
        } else {
          params[key] = object[key];
        }
      } else {
        params[key] = object[key];
      }
    });
    return params;
  },
  flattenAndbuildPathWithSearchParams(object) {
    let params = this.flattenObject(object);
    return { url: this.buildPathWithSearchParams(params), params };
  },
  buildPathWithSearchParams(object, path = window.location.pathname) {
    return path + this.buildSearchUrl(object);
  },
  getSearchParams() {
    return Qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  },
  buildSearchUrl(object) {
    let paramsString = this.UrlParams(object);
    return paramsString === "" ? "" : "?" + this.UrlParams(object);
  },
  UrlParams(object) {
    return Qs.stringify(object, { arrayFormat: "brackets" });
  },
  santizeEmptyPropsFrom(params) {
    var propNames = Object.getOwnPropertyNames(params);
    for (var i = 0; i < propNames.length; i++) {
      var propName = propNames[i];
      if (params[propName] === null || params[propName] === undefined) {
        delete params[propName];
      }
    }

    return params
  },
  buildSearchPathWithSanitized(params) {
    let sanitizedParams = this.santizeEmptyPropsFrom(params);
    return this.buildPathWithSearchParams(sanitizedParams);
  }
};
