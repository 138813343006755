import Vue from 'vue';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';
import cloneDeep from 'lodash/cloneDeep'

AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  tags: {},
  saveMode: false,
  allowableZones: {},
  loadingMessage: translate('no_data_available'),
  sortOrder: [
    {
      field: 'updated_at',
      sortField: 'grouping',
      direction: 'desc'
    }
  ],
  formData: null,
  showModal: false
})

export const state = getDefaultState

export const getters = {
  getField,
  tags: state => { return Object.values(state.tags) }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateTag(state, { createdTag, zone = null }) {
    Vue.set(state.tags, createdTag.groupId, zone)
  },
  removeTag(state, rowData) {
    let tags = state.tags[rowData.groupId].data
    const index = tags.findIndex(item => item.id === rowData.id);
    state.tags[rowData.groupId].data.splice(index, 1)
  },
  editExistingTag(state, { rowData }){
    state.formData = {
      name: rowData.name,
      zone_id: rowData.groupId,
      id: rowData.id
    }
    state.showModal = true
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/tags`)
      .then(response => {
        let tags = { ...response.data.tags }
        commit('updateField', { path: 'tags', value: tags });
        commit('updateField', { path: 'allowableZones', value: response.data.allowableZones });
      });
  },
  submit({ commit, state, dispatch }) {
    commit('updateField', {path:'saveMode', value: true})

    AxiosWrapper.postOrPatch("/data_api/tags", {tag:state.formData}).then(response =>{
      let createdTag = { ...response.data.tag }
      commit('updateField', { path: 'saveMode', value: false })
      dispatch('updateTag', createdTag)
    })

    commit('updateField', {path:"showModal", value:false})
  },
  updateTag({ state, commit }, createdTag) {
    var zone = cloneDeep(state.tags[createdTag.groupId])

    if (zone){
      let tags = state.tags[createdTag.groupId].data
      const index = tags.findIndex(item => item.id === createdTag.id);

      if (index !== -1){
        zone.data.splice(index, 1, createdTag)
      }else{
        zone.data.push(createdTag)
      }

    }else{
      let zoneIndex = state.allowableZones.findIndex(a => a.id === createdTag.groupId)

      zone = {
        id: createdTag.groupId,
        groupName: state.allowableZones[zoneIndex].name,     // "PQPD (Production)"
        groupData: {
          id: createdTag.groupId,                                     // 220
          name: state.allowableZones[zoneIndex].name         // "PQPD (Production)"
        },
        data: [
        ],
      }

      zone.data.splice(0, 1, createdTag)
    }

    commit('updateTag', { createdTag, zone })
  },
  deleteTag({ commit, state }, { rowData }) {
    AxiosWrapper.delete(`/data_api/tags/${rowData.id}`)
      .then(() => {
        commit('removeTag', rowData)
      })
  },
  editTag({ commit }, payload) {
    commit('editExistingTag', payload )
  }
}