import { getField, updateField } from 'vuex-map-fields';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';
import { forEach } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

const showUrl = '/data_api/dashboard/panels/'

const requestParam = { params: { addRequest: false } }

AxiosWrapper.init();

const getDefaultState = () => ({
  panel: {},
  rows: [],
  loading: false,
  loadingMessage: translate('no_data_available'),
  sortOrderForSummaryTable: [
    {
      field: 'answer_summaries_count',
      sortField: 'answer_summaries_count',
      direction: 'desc'
    }
  ],
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  updateGauge(state, { rowIdx, gaugeIdx, gauge }) {
    Vue.set(state.rows[rowIdx].gauges, gaugeIdx, gauge);
  },
  init(state, getRequestData) {
    state.rows = getRequestData.rows
    state.panel = getRequestData
  },
  formatAnswersBreakdownTableData(state, { formattedData, tableData, complianceBy }) {
    tableData.forEach(dataItem => {
      if (complianceBy === 'pass_fail') {
        dataItem.compliance = `${dataItem.compliance} %`
      } else {
        dataItem.compliance_by_weighted_score = `${dataItem.compliance_by_weighted_score}`
      }

      formattedData.push(dataItem)
    })
  }
}

export const actions = {
  async initPanel({ commit }) {
    commit('resetState')
    let panelId = AxiosWrapper.getNextPathNameElement('panels');
    AxiosWrapper.get(showUrl + panelId)
      .then(response => {
        commit('init', response.data)
      })
  },

  async showGaugeLoader({ commit }, { rowIdx, gaugeIdx, gauge }) {
    let newGauge = cloneDeep(gauge)
    delete newGauge.data;
    delete newGauge.errors;
    delete newGauge.hasNoData;

    commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
  },

  async initGauge({ dispatch, commit, state }, payload) {
    switch (payload.gauge.type) {
      case 'Dashboard::Gauges::AnswersBreakdown':
        dispatch('initAnswerBreakdown', payload);
        break;
      case 'Dashboard::Gauges::CorrectiveAction':
        dispatch('initCorrectiveAction', payload);
        break;
      case 'Dashboard::Gauges::CorrectiveActionResolution':
        dispatch('initCorrectiveActionResolution', payload);
        break;
      case 'Dashboard::Gauges::Compliance':
        dispatch('initCompliance', payload);
        break;
      case 'Dashboard::Gauges::ScheduledCompliance':
        dispatch('initScheduledCompliance', payload);
        break;
      case 'Dashboard::Gauges::AuditScore':
        dispatch('initAuditScores', payload);
        break;
      case 'Dashboard::Gauges::AuditsPerformed':
        dispatch('initAuditsPerformed', payload);
        break;
      default:
        commit('updateGauge', {
          rowIdx: state.rows.indexOf(payload.row),
          gaugeIdx: payload.row.gauges.indexOf(payload.gauge),
          gauge: { ...payload.gauge, data: {} }
        })
    }
  },

  async initAnswerBreakdown({ state, commit, dispatch }, { row, gauge }) {
    const url = `/data_api/dashboard/rows/${row.id}/gauges/answers_breakdowns/${gauge.id}/breakdown`
    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);

        if (data === null || Object.keys(data).length === 0) {
          newGauge.hasNoData = true;
        } else {
          newGauge.data = {
            tableData: data.breakdowns,
            element: `answer-breakdown-${gauge.id}`,
            compliance_by: data.compliance_by
          }
        }
        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
      .catch(() => commit('updateGauge', {
        rowIdx: rowIdx,
        gaugeIdx: gaugeIdx,
        gauge: { ...gauge, hasErrors: true, errors: { message: translate('there_was_a_problem') } }
      }))
  },

  async initCompliance({ state, commit, dispatch }, { row, gauge }) {
    const url = `/data_api/dashboard/rows/${row.id}/gauges/compliances/${gauge.id}/daily`
    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);

        if (Object.keys(data).length === 0) {
          newGauge.hasNoData = true;
        } else {
          newGauge.data = {
            graphData: data.data,
            element: `cpd_graph_${gauge.id}`,
            meanScore: data.mean_score,
            title: gauge.title
          }
        }

        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
  },

  async initCorrectiveAction({ state, commit, dispatch }, { row, gauge }) {
    let url;
    let elementId;

    if (gauge.display_style === 'pie_chart') {
      elementId = `ca_breakdown_${gauge.id}`
      url = `/data_api/dashboard/rows/${row.id}/gauges/corrective_actions/${gauge.id}/counts`
    } else if (gauge.display_style === 'list') {
      elementId = `ca-detail-${gauge.id}`
      url = `/data_api/dashboard/rows/${row.id}/gauges/corrective_actions/${gauge.id}/activity_report`
    } else if (gauge.display_style === 'bar_chart') {
      elementId = `ca_bar_chart_${gauge.id}`
      url = `/data_api/dashboard/rows/${row.id}/gauges/corrective_actions/${gauge.id}/month_counts`
    }

    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);
        if (data.array_of_hashes) {
          if (data.array_of_hashes.length > 0 || Object.keys(data.array_of_hashes).includes("summaries_without_corrective_actions") || Object.keys(data.array_of_hashes).includes("summaries_with_corrective_actions")) {
            newGauge.data = {
              graphData: data.array_of_hashes,
              element: elementId,
              title: gauge.title
            }
          } else {
            newGauge.hasNoData = true;
          }
        } else if (Object.keys(data).length === 0 || data === null) {
          newGauge.hasNoData = true;
        } else {
          newGauge.data = {
            graphData: data,
            element: elementId,
            title: gauge.title
          }
        }
        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
  },

  async initCorrectiveActionResolution({ state, commit, dispatch }, { row, gauge }) {
    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })
    const url = `/data_api/dashboard/rows/${row.id}/gauges/corrective_action_resolutions/${gauge.id}/month_counts`

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);
        if (Object.keys(data).length === 0) {
          newGauge.hasNoData = true;
        } else {
          newGauge.data = {
            graphData: data,
            element: `car-graph-${gauge.id}`,
            title: gauge.title
          }
        }
        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
  },

  async initAuditScores({ state, commit, dispatch }, { row, gauge }) {
    const url = `/data_api/dashboard/rows/${row.id}/gauges/audit_scores/${gauge.id}/scores`

    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);

        if (data.scores.length === 0) {
          newGauge.hasNoData = true;
        } else {
          newGauge.has_download = true;
          newGauge.requestCsvUrl = `/data_api/download_csv/audit_score?gauge_id=${newGauge.id}`

          let scores = []
          forEach(data.scores, (value, key) => {
            scores.push({
              name: key,
              value: value
            })
          })

          newGauge.data = {
            graphData: scores,
            element: `sqc_graph_${gauge.id}`,
            title: gauge.title
          }

        }
        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
  },

  async initAuditsPerformed({ state, commit, dispatch }, { row, gauge }) {
    const url = `/data_api/dashboard/rows/${row.id}/gauges/audits_performed/${gauge.id}/report`

    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam).then(response => {
      let data = response.data;
      let newGauge = cloneDeep(gauge);

      if (data.report.length === 0) {
        newGauge.hasNoData = true;
      } else {
        let report = []
        forEach(data.report, (value, key) => {
          report.push({
            name: key,
            value: value
          })
        })

        newGauge.data = {
          graphData: report,
          element: `sqc_graph_${gauge.id}`,
          title: gauge.title
        }

        newGauge.hasNoData = true;
      }

      commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
    })
  },

  async initScheduledCompliance({ state, commit, dispatch }, { row, gauge }) {
    const url = `/data_api/dashboard/rows/${row.id}/gauges/scheduled_compliances/${gauge.id}/breakdowns`

    const rowIdx = state.rows.indexOf(row)
    const gaugeIdx = state.rows[rowIdx].gauges.indexOf(gauge);

    dispatch('showGaugeLoader', { rowIdx, gaugeIdx, gauge })

    AxiosWrapper.get(url, requestParam)
      .then(response => {
        let data = response.data;
        let newGauge = cloneDeep(gauge);


        if (Object.keys(data).length === 0) {
          newGauge.hasNoData = true;
        } else {
          newGauge.data = {
            graphData: data,
            element: `sqc_graph_${gauge.id}`,
            title: gauge.title
          }

        }
        commit('updateGauge', { rowIdx, gaugeIdx, gauge: newGauge })
      })
  },
  setAsPreffered({ commit, state }, { router }) {
    commit("updateField", { path: "loading", value: true })
    AxiosWrapper.put(`/data_api/dashboard/panels/${state.panel.id}/set_preferred`, { id: state.panel.id }).then(() => {
      router.go()
    })
  },

  deletePanel({ commit, state }) {
    if (confirm(translate('are_you_sure'))) {
      commit("updateField", { path: "loading", value: true })
      return AxiosWrapper.delete(`/data_api/dashboard/panels/${state.panel.id}`, { id: state.panel.id })
        .then(() => commit("login/removePanel", state.panel.id, { root: true }))
    } else {
      return Promise.reject();
    }
  }
}