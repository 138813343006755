import { getField, updateField } from "vuex-map-fields"
import Vue from "vue"
import AxiosWrapper from "~/assets/javascript/AxiosWrapper"

const createInitialState = () => ({
  alert: null,
  zones: [],
  users: []
}) 

export const state = createInitialState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  populateState: (state, data) => {
    state.alert = data.alert
    state.zones = data.zones
    state.users = data.users

    if (!state.alert.expiration_notification_days_before) {
      state.alert.expiration_notification_days_before = 1;
    }

    if (!state.alert.frequency_unit) state.alert.frequency_unit = "minute";
  },
  setSelectedExpiry: (state, canExpire) => {
    if (canExpire) {
      state.alert.can_expire = true;
      state.alert.send_expiration_notification = true;
    } else {
      state.alert.can_expire = false;
      state.alert.expiry_date = null;
      state.alert.send_expiration_notification = false;
    }
  },
  prepareAlertForSave: (state, { issuedBy }) => {
    state.alert.issued_by_id = issuedBy;
    if (state.alert.image.file) state.alert.image = state.alert.image.file;
  },
  updateAlertId: (state, id) => state.alert.id = id,
  setFrequencyUnit: (state, frequencyUnit) => {
    state.alert.frequency_unit = frequencyUnit;

    if (state.alert.frequency_unit === "hour") {
      state.alert.frequency = Math.round(state.alert.frequency / 60.0);
    } else {
      state.alert.frequency = Math.round(state.alert.frequency * 60.0);
    }
  },
  setAlertImage: ({ alert }, file) => {
    Vue.set(alert.image, "file", file);
  },
  resetState: (state) => Object.assign(state, createInitialState())
}

export const actions = {
  getAlert({ commit }, alertId) {
    commit("resetState")
    return this.$axios.get(`/data_api/alerts/${alertId}/edit`)
      .then(({ data }) => commit("populateState", data))
  },
  initNew({commit}, jobRevisionId) {
    return this.$axios.get("/data_api/alerts/new", { job_revision_id: jobRevisionId })
            .then(({data}) => {
              commit("populateState", data)
            })
  },
  save({ commit, state }, { issuedBy }) {
    commit("prepareAlertForSave", { issuedBy })

    AxiosWrapper.init(this.$axios)
    let request = AxiosWrapper.postOrPatch("/data_api/alerts", {alert: state.alert})

    return request.then(data => commit("updateAlertId", data.data.object.quality_alert.id))
  }
}