
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  canEdit: false
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  setCanEdit(state, value) {
    state.canEdit = value
  },
}

export const actions = { }