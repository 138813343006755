import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init()

const getDefaultState = () => ({
  verificationWorkItem: null,
  job: null,
  jobRevision: null,
  partNumber: null,
  serialNumber: null,
  lotNumber: null,
  other1: null,
  other2: null,
  quantity: null,
  notFound: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initializer(state, getRequestData) {
    ({
      verification_work_item: state.verificationWorkItem,
      job: state.job,
      job_revision: state.jobRevision,
    } = getRequestData)

    if (state.verificationWorkItem.part_number !== null){
      state.partNumber = state.verificationWorkItem.part_number
    }

    if (state.verificationWorkItem.serial_number !== null){
      state.serialNumber = state.verificationWorkItem.serial_number
    }

    if (state.verificationWorkItem.lot_number !== null){
      state.lotNumber = state.verificationWorkItem.lot_number
    }

    if (state.verificationWorkItem.other1 !== null){
      state.other1 = state.verificationWorkItem.other1
    }

    if (state.verificationWorkItem.other2 !== null){
      state.other2 = state.verificationWorkItem.other2
    }

    state.quantity = state.verificationWorkItem.quantity
    state.notFound = state.verificationWorkItem.not_found
  },
  updateVerificationItem(state){
    if (state.partNumber !== null){
      state.verificationWorkItem.part_number = state.partNumber
    }

    if (state.lotNumber !== null){
      state.verificationWorkItem.lot_number = state.lotNumber
    }

    if (state.serialNumber !== null){
      state.verificationWorkItem.serial_number = state.serialNumber
    }

    if (state.other1 !== null){
      state.verificationWorkItem.other1 = state.other1
    }

    if (state.other2 !== null){
      state.verificationWorkItem.other2 = state.other2
    }

    state.verificationWorkItem.quantity = state.quantity
    state.verificationWorkItem.not_found = state.notFound
  }
}

export const getters = {
  getField
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")

    let jobId = AxiosWrapper.getNextPathNameElement("jobs")
    let jobRevisionId = AxiosWrapper.getNextPathNameElement("job_revisions")
    let workItemId = AxiosWrapper.getNextPathNameElement("verification_work_items")

    AxiosWrapper.get(`/data_api/jobs/${jobId}/job_revisions/${jobRevisionId}/verification_work_items/${workItemId}/edit`).then(response => {
        commit('initializer', response.data)
      }).catch(error => {
        dispatch('errorMethods/defaultError', error, { root: true })
      })
  },
  save({ commit, dispatch, state }) {
    commit('updateVerificationItem')

    AxiosWrapper.postOrPatch(`/data_api/jobs/${state.job.id}/job_revisions/${state.jobRevision.id}/verification_work_items`, {
      verification_work_item: state.verificationWorkItem
    }).then(response => {
      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true })
    })
  }
}