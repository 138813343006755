const UNAUTHENTICATED_PATHS = [
  '/login',
  '/recover_password/',
  '/login/forgot_password/',
  '/saved_report/',
  '/dashboard/devices',
  '/view_all_shifts/',
  '/view_defect_by_description/',
  '/unauthorized',
  '/reset_training',
  '/design'
]

function isUnauthenticatedPath(path) {
  for (let p of UNAUTHENTICATED_PATHS) {
    if (path.indexOf(p) !== -1) return true;
  }

  return false;
}

export default function ({ store, route, redirect }) {
  return store.dispatch('login/hasSession').then((response) => {
    let hasSession = response.data.status
    if (!hasSession && store.getters["login/isLoggedIn"]) {
      store.commit('login/setShowLogin', true)
    } else if (!store.getters["login/isLoggedIn"]) {
      if (hasSession) {
        return store.dispatch('login/restoreSession').catch(() => redirect("/login"));
      } else if (!isUnauthenticatedPath(route.path)) {
        store.dispatch('login/logout', { pathToSave: route.fullPath })
      }
    }
  })

}