import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  job_id: null,
  device_id: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const getters = {
  getField,
  splashPageUrl(state) {
    return `/dashboard/devices?job=${state.job_id}&device=${state.device_id}`
  },
  dataApiUrlForVerificationStatsSearch(state) {
    return `/data_api/dashboard/devices/jobs/${state.job_id}/verification_work_items_search?device=${state.device_id}`
  },
  baseApiUrlForVerificationsIndex(state) {
    return `/data_api/dashboard/devices/jobs/${state.job_id}/verification_work_items?device=${state.device_id}`
  }
}

export const actions = {
  async init({ commit }) {
    commit('resetState')

    let jobIdItem = AxiosWrapper.getNextPathNameElement('jobs')
    commit('updateField', { path: 'job_id', value: jobIdItem });

    let loadedParams = AxiosWrapper.getSearchParams();
    commit('updateField', { path: 'device_id', value: loadedParams.device });
  }
}