const addDestroyedItems = function (originalList, modifiedList) {
  let currentListIds = modifiedList.map(x => x.id)

  originalList.forEach(x => {
    if (!currentListIds.includes(x.id)) {
      x._destroy = true;
      x.deleted = true
      modifiedList.push(x);
    }
  })
}

const indexToPositions = function (list) {
  list.forEach((item, index) => item.position = index)
}

export {addDestroyedItems, indexToPositions}

export default {addDestroyedItems, indexToPositions}