import { getField, updateField } from "vuex-map-fields"

export const state = () => ({
  mainHeaderName: "",
  subHeaderName: "",
  isScrolledDown: false,
  forceMainHeader: false
})

export const getters = {
  activeHeaderName(state) {
    if (state.forceMainHeader) return state.mainHeaderName
    if (state.isScrolledDown && state.subHeaderName) return state.subHeaderName
    else return state.mainHeaderName
  },
  getField
}

export const mutations = {
  updateField
}