import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import last from 'lodash/last'
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();

const getDefaultState = () => ({
  storeBaseUrl: "",
  storeApiUrl: "",
  searchInProgress: false,
  job_id: null,
  revision_id: null,
  job: null,
  job_revision: null,
  is_user_customer: null,
  is_user_superuser: null,
  is_user_administrator: null,
  available_states: null,
  users: null,
  yard_campaigns: null,
  defect_code_models: null,
  defect_codes: null,
  available_dispositions: null,
  target_range: null,
  paths_for_links: null,
  total_pages_of_work_items: null,
  total_pages_of_work_items_array: null,
  can_user_create_work_item: null,
  verification_work_items: null,
  total_number_of_work_items: null,
  transition_states: null,
  show_target_ranges_form: false,
  excluded_verification_work_items: null,
  checked_work_item_ids: [],
  showVerificationModal: false,
  allChecked: false,
  verificationHistoryLoaded: false,
  verificationHistory: null,
  apiErrors: null,
  job_warning_items: null,
  confirmingCsvRequest: false,
  params: {
    part_number: "",
    serial_number: "",
    lot_number: "",
    other1: "",
    other2: "",
    state: null,
    states: [],
    date: "",
    by_range: true,
    user_id: null,
    campaign_id: null,
    defect_code_model: null,
    defect_code: null,
    dispositions: [],
    items_per_page: 10,
    page: 1,
    show_excluded_defects: false,
    filter: 'active',
    context: null
  }
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  initDataFromGet(state, getRequestData) {
    ({
      job: state.job,
      job_revision: state.job_revision,
      is_user_customer: state.is_user_customer,
      is_user_superuser: state.is_user_superuser,
      is_user_administrator: state.is_user_administrator,
      available_states: state.available_states,
      available_dispositions: state.available_dispositions,
      target_range: state.target_range,
      paths_for_links: state.paths_for_links,
      can_user_create_work_item: state.can_user_create_work_item,
      verification_work_items: state.verification_work_items,
      total_pages_of_work_items: state.total_pages_of_work_items,
      total_number_of_work_items: state.total_number_of_work_items,
      transition_states: state.transition_states,
      job_warning_items: state.job_warning_items
    } = getRequestData);


    state.params.items_per_page = getRequestData.items_per_page;
    state.total_pages_of_work_items_array = Array.from(Array(state.total_pages_of_work_items).keys()).map(number => number + 1)

    // likely a params / search settings update check
    // if (state.target_range.ranges_present){
    //    state.show_target_ranges_form = false
    // }else{
    //    state.show_target_ranges_form = true
    // }

    state.params.states = state.available_states.map((object) => { return object.value; });
    state.params.dispositions = state.available_dispositions.map((object) => { return object.value; });
  },
  initLabelOptions(state, getRequestData) {
    state.users = [{ name: translate('all'), value: null }].concat(
      getRequestData.users.map((value, index) => {
        return value;
      })
    )

    state.yard_campaigns = [{ name: translate('all'), value: null }].concat(
      getRequestData.yard_campaigns.map((value, index) => {
        return value;
      })
    )

    state.defect_code_models = [{ name: translate('all'), value: null }].concat(
      getRequestData.defect_code_models.map((value, index) => {
        return value;
      })
    )

    state.defect_codes = [{ name: translate('all'), value: null }].concat(
      getRequestData.defect_codes.map((value, index) => {
        return value;
      })
    )
  },
  initFirstSelections(state) {
    state.params.user_id = state.users[0]
    state.params.campaign_id = state.yard_campaigns[0]
    state.params.defect_code_model = state.defect_code_models[0]
    state.params.defect_code = state.defect_codes[0]
  },
  searchRequestCompleted(state, getRequestData) {
    ({
      total_pages_of_work_items: state.total_pages_of_work_items,
      total_number_of_work_items: state.total_number_of_work_items,
      verification_work_items: state.verification_work_items,
      paths_for_links: state.paths_for_links
    } = getRequestData);

    state.searchInProgress = false
  },
  searchErrorsHandle(state, getRequestData) {
    state.apiErrors = getRequestData
    state.searchInProgress = false
  },
  setRangesToZero(state) {
    state.target_range.low = 0
    state.target_range.high = 0
  },
  updateYardItem(state, updateContentsPackage){
    let indexOfWorkItem = state.verification_work_items.findIndex(v => { return v.id === updateContentsPackage.findId })
    let itemToUpdate = state.verification_work_items[indexOfWorkItem]

    itemToUpdate.yard_verifications.splice(updateContentsPackage.indexToUpdate, 1, updateContentsPackage.verification)
    itemToUpdate.state = updateContentsPackage.stateToSet

    state.verification_work_items.splice(indexOfWorkItem, 1, itemToUpdate)
  },
  updateVerificationExclusionState(state, updatePack){
    let indexOfWorkItem = state.verification_work_items.findIndex(v => { return v.id === updatePack.workItem.id })

    let itemToUpdate = updatePack.verification
    let workItem = updatePack.workItem

    let indexOfVerification = workItem.yard_verifications.findIndex(v => { return v.id === itemToUpdate.id})

    workItem.yard_verifications.splice(indexOfVerification, 1, itemToUpdate)
    state.verification_work_items.splice(indexOfWorkItem, 1, workItem)
  },
  removeWorkItem(state, index){
    state.verification_work_items.splice(index, 1)
  }
}

export const getters = {
  getField,
  exportCSVUrl(state) {
    let params = AxiosWrapper.getSearchParams()

    if (state.job_id) {
      params['job_id'] = state.job_id
    }

    if (state.revision_id) {
      params['job_revision_id'] = state.revision_id
    }

    return `/data_api/download_csv/verification_work_items${AxiosWrapper.buildSearchUrl(params)}`
  },
  isActiveActive(state) {
    return state.params.filter === 'active'
  },
  isExcludedActive(state) {
    return state.params.filter === 'excluded'
  },
  showOrHideDefectsLabel(state) {
    return state.params.show_excluded_defects ? translate('hide_excluded_defects') : translate('show_excluded_defects')
  },
  totalPagesOfWorkItemsGreaterThanOne(state) {
    return state.totalPagesOfWorkItems > 1
  },
  lastPage(state) {
    return last(state.totalPagesOfWorkItemsArray)
  },
  translatedPassString() {
    return translate('pass')
  },
  translatedFailString() {
    return translate('fail')
  },
  formattedUpdatedSinceDate(state) {
    return state.params.date.split('T')[0];
  }
}

export const actions = {
  async init({ commit, state, dispatch }, {baseUrl, apiUrl, context}) {
    gtmEvents.reportGenerated({
      type: "Verification Work Items",
      context
    })

    commit("resetState")

    commit('updateField', { path: 'context', value: context })
    commit('updateField', { path: 'searchInProgress', value: true })

    commit('updateField', { path: 'job_id', value: AxiosWrapper.getNextPathNameElement('jobs') })
    if (AxiosWrapper.getNextPathNameElement('job_revisions') !== null) {
      commit('updateField', { path: 'revision_id', value: AxiosWrapper.getNextPathNameElement('job_revisions') })
    }

    let url = baseUrl || `/data_api/jobs/${state.job_id}/job_revisions/${state.revision_id}/verification_work_items`;
    commit('updateField', { path: 'storeBaseUrl', value: url });
    if (apiUrl) commit('updateField', { path: 'storeApiUrl', value: apiUrl });

    let loadedParams = AxiosWrapper.getSearchParams();
    let searchingParams = AxiosWrapper.flattenObject(loadedParams)
    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams)

    AxiosWrapper.get(state.storeBaseUrl, { params: searchingParams }).then(function (response) {
      commit('initDataFromGet', response.data)
      dispatch('jobRevision/setJobRevision', response.data.job_revision, { root: true })
      commit('initLabelOptions', response.data)
      commit('initFirstSelections')

      commit('updateField', { path: 'searchInProgress', value: false })
      dispatch('updateSearchSettings', response.data.search_params_used)
    })
  },
  excludedClicked({ state, commit, dispatch }) {
    if (!state.isExcludedActive) {
      commit('updateField', { path: 'params.filter', value: 'excluded' })
      dispatch('verificationWorkItemSearch')
    }
  },
  activeClicked({ state, commit, dispatch }) {
    if (!state.isActiveActive) {
      commit('updateField', { path: 'params.filter', value: 'active' })
      dispatch('verificationWorkItemSearch')
    }
  },
  getWorkItemsForPage({ state, commit, dispatch }, pageNumber) {
    commit('updateField', { path: 'params.page', value: pageNumber })
    dispatch('verificationWorkItemSearch')
  },
  changeNumberOfItemsPerPage({ state, commit, dispatch }, numberOfItems) {
    commit('updateField', { path: 'params.items_per_page', value: numberOfItems })
    dispatch('verificationWorkItemSearch')
  },
  verificationWorkItemSearch({ state, commit, dispatch }) {
    gtmEvents.reportGenerated({
      type: "Verification Work Items",
      context: state.context
    })

    if (state.storeApiUrl === "") {
      commit('updateField', { path: 'storeApiUrl', value: `/data_api/jobs/${state.job_id}/job_revisions/${state.revision_id}/verification_work_items/search` })
    }

    commit('updateField', { path: 'searchInProgress', value: true })

    let searchingParams = AxiosWrapper.flattenObject(state.params)
    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams)

    AxiosWrapper.get(state.storeApiUrl, { params: searchingParams }).then(function (response) {
      var pathWithParams = AxiosWrapper.buildPathWithSearchParams(searchingParams);
      history.replaceState(history.state, 'VerificationWorkItemSearch', pathWithParams);
      commit('searchRequestCompleted', response.data)
      dispatch('updateSearchSettings', response.data.search_params_used)
    }).catch(error => {
      commit('searchErrorsHandle', error.response.data)
    });
  },
  showTargetRanges({ state, commit, dispatch }) {
    commit('setRangesToZero')
    const newRangeUrl = `/data_api/target_ranges/new?job_revision_id=${state.job_revision.id}`
    AxiosWrapper.get(newRangeUrl, { job_revision_id: state.job_revision.id }).then(function (response) {
      commit('updateField', { path: 'show_target_ranges_form', value: true })
    })
  },
  removeTargetRange({ state, commit, dispatch }) {
    commit('updateField', { path: 'show_target_ranges_form', value: false })
    commit('updateField', { path: 'target_range.ranges_present', value: false })
    commit('setRangesToZero')

    if (state.target_range.id) {
      AxiosWrapper.delete(`/data_api/target_ranges/${state.target_range.id}`).catch(error => {
        commit('searchErrorsHandle', error.response.data)
      })
    }
  },
  saveTargetRanges({ state, dispatch, commit }) {
    commit('updateField', { path: 'searchInProgress', value: true })
    commit('updateField', { path: 'target_range.ranges_present', value: true })

    const targetRangeUrl = `/data_api/target_ranges`
    const targetRangeObject = {
      "job_revision_id": state.job_revision.id,
      "low": state.target_range.low,
      "high": state.target_range.high
    }

    AxiosWrapper.postOrPatch(targetRangeUrl, { target_range: targetRangeObject, id: state.target_range.id })
      .then(() => {
        AxiosWrapper.get(state.storeBaseUrl).then((success) => {
          commit('updateField', { path: 'target_range.id', value: success.data.target_range.id })
          commit('updateField', { path: 'searchInProgress', value: false })
        })
      }).catch(error => {
        commit('searchErrorsHandle', error.response.data)
      })
  },
  resetButtonPressed({ state, commit, dispatch }) {
    for (var key in state.params) {
      if (key == 'by_range') {
        commit('updateField', { path: 'params.by_range', value: true })
      } else if (key == 'states') {
        commit('updateField', { path: 'params.states', value: state.available_states.map((object) => { return object.value; }) })
      } else if (key == 'dispositions') {
        commit('updateField', { path: 'params.dispositions', value: state.available_dispositions.map((object) => { return object.value; }) })
      } else {
        if (state.params[key] instanceof Object) {
          commit('updateField', { path: `params.${key}`, value: {name: translate('all'), value: null} })
        } else {
          commit('updateField', { path: `params.${key}`, value: "" })
        }
      }
    }

    return new Promise((resolve, reject) => { resolve() })
  },
  restoreAll({ state, commit, dispatch }) {
    const restoreUrl = `/data_api/jobs/${state.job.id}/job_revisions/${state.job_revision.id}/verification_work_items/restore_all`
    commit('updateField', { path: 'searchInProgress', value: true })

    AxiosWrapper.patch(restoreUrl, { verification_work_item_ids: state.checked_work_item_ids }).then(() => {
      state.checked_work_item_ids.forEach(id => {
        let index = state.verification_work_items.findIndex(workItem => { return workItem.id == id })
        if (index > -1) {
          commit('removeWorkItem', index)
        }
      })

      commit('updateField', { path: 'searchInProgress', value: false })
      commit('updateField', { path: 'checked_work_item_ids', value: [] })
    }).catch(error => {
      commit('searchErrorsHandle', error.response.data)
    })
  },
  excludeAll({ state, dispatch, commit }) {
    const excludeUrl = `/data_api/jobs/${state.job.id}/job_revisions/${state.job_revision.id}/verification_work_items/exclude_all`

    if (confirm(translate('are_you_sure'))) {
      commit('updateField', { path: 'searchInProgress', value: true })
      AxiosWrapper.delete(excludeUrl, { params: { verification_work_item_ids: state.checked_work_item_ids } }).then(() => {
        state.checked_work_item_ids.forEach(id => {
          let index = state.verification_work_items.findIndex(workItem => { return workItem.id == id })
          if (index > -1) {
            commit('removeWorkItem', index)
          }
        })

        commit('updateField', { path: 'searchInProgress', value: false })
        commit('updateField', { path: 'checked_work_item_ids', value: [] })
      }).catch(error => {
        commit('searchErrorsHandle', error.response.data)
      })
    }
  },
  setTarget({ state, commit, dispatch }, item) {

    const setTargetUrl = `/data_api/target_ranges/set_target`
    commit('updateField', { path: 'searchInProgress', value: true })

    state.verification_work_items.forEach(workItem => {
      workItem.is_target = false
    })

    item.is_target = true
    AxiosWrapper.patch(setTargetUrl, { job_revision_id: state.job_revision.id, bin_id: item.id }).then(() => {
      commit('updateField', { path: 'searchInProgress', value: false })
    }).catch(error => {
      commit('searchErrorsHandle', error.response.data)
    })
  },
  excludeWorkItem({ state, commit, dispatch }, { item, index }) {
    const excludeUrl = `/data_api/jobs/${state.job.id}/job_revisions/${state.job_revision.id}/verification_work_items/${item.id}/exclude`
    if (confirm(translate('are_you_sure'))) {
      commit('updateField', { path: 'searchInProgress', value: true })
      AxiosWrapper.patch(excludeUrl, { job_id: state.job.id, job_revision_id: state.job_revision.id, id: item.id })
        .then(() => {
          commit('removeWorkItem', index)
          commit('updateField', { path: 'searchInProgress', value: false })
        })
        .catch(error => {
          commit('searchErrorsHandle', error.response.data)
        });
    }
  },
  excludeVerification({ state, commit, dispatch }, { item, verification, index }) {
    const excludeUrl = `/data_api/yard/verifications/${verification.id}/exclude`

    if (confirm(translate("are_you_sure"))) {
      commit('updateField', { path: 'searchInProgress', value: true })
      AxiosWrapper.patch(excludeUrl, {job_id: state.job.id, job_revision_id: state.job_revision.id})
        .then(response => {

          let updatePack = {
            indexValue: index,
            verification: response.data.verification,
            workItem: item
          }

          commit('updateVerificationExclusionState', updatePack)
          commit('updateField', { path: 'searchInProgress', value: false })
        })
    }
  },
  restoreVerification({ state, commit, dispatch }, { item, verification, index }) {
    const restoreUrl = `/data_api/yard/verifications/${verification.id}/restore`
    commit('updateField', { path: 'searchInProgress', value: true })

    AxiosWrapper.patch(restoreUrl, { job_id: state.job.id, job_revision_id: state.job_revision.id }).then(response => {

      let updatePack = {
        indexValue: index,
        verification: response.data.verification,
        workItem: item
      }

      commit('updateVerificationExclusionState', updatePack)
      commit('updateField', { path: 'searchInProgress', value: false })
    })
  },
  restoreWorkItem({ state, commit, dispatch }, { item, index }) {
    const restoreUrl = `/data_api/jobs/${state.job.id}/job_revisions/${state.job_revision.id}/verification_work_items/${item.id}/restore`
    commit('updateField', { path: 'searchInProgress', value: true })

    AxiosWrapper.patch(restoreUrl, {}).then(() => {
      commit('removeWorkItem', index)
      commit('updateField', { path: 'searchInProgress', value: false })
    }).catch(error => {
      commit('searchErrorsHandle', error.response.data)
    })
  },
  changeState({ state, dispatch, commit }, { item, verification, stateItem }) {

    let patchUrl = `/data_api/yard/verifications/${verification.id}/change_state`
    commit('updateField', { path: 'searchInProgress', value: true })

    AxiosWrapper.patch(patchUrl, { to: stateItem.raw_name }).then(data => {
      let index = item.yard_verifications.findIndex(v => { return v.id == verification.id })

      let updatePack = {
        findId: item.id,
        indexToUpdate: index,
        verification: data.data.verification,
        stateToSet: data.data.work_item_state
      }

      commit('updateYardItem', updatePack)
      commit('updateField', { path: 'searchInProgress', value: false })
    }).catch(error => {
      commit('searchErrorsHandle', error.response.data)
    })
  },
  verificationClicked({ commit }, verification) {
    commit('updateField', { path: 'showVerificationModal', value: true })
    commit('updateField', { path: 'verificationHistory', value: null })

    AxiosWrapper.get(`/data_api/yard/verifications/${verification.id}`).then(data => {
      commit('updateField', { path: 'verificationHistory', value: data.data.verification_history })
      commit('updateField', { path: 'verificationHistoryLoaded', value: true })
    }).catch(error => {
      commit('updateField', { path: 'verificationHistoryLoaded', value: false })
      commit('searchErrorsHandle', error.response.data)
    })
  },
  updatePathAfterDefault({ state }) {
    state.path_updated = true
    let searchingParams = AxiosWrapper.flattenObject(state.params)
    searchingParams = AxiosWrapper.copyAndDeleteNullProperties(searchingParams);
    var pathWithParams = AxiosWrapper.buildPathWithSearchParams(searchingParams);
    history.replaceState(history.state, 'YardSearch', pathWithParams);
  },
  setUserDefault({ state, dispatch, commit }) {
    commit('updateField', { path: 'params.user_id', value: state.users[0] })
    dispatch('updatePathAfterDefault');
  },
  setCampaignDefault({ state, dispatch, commit }) {
    commit('updateField', { path: 'params.campaign_id', value: state.yard_campaigns[0] })
    dispatch('updatePathAfterDefault');
  },
  setCodeModelDefect({ dispatch, commit, state }) {
    commit('updateField', { path: 'params.defect_code_model', value: state.defect_code_models[0] })
    dispatch('updatePathAfterDefault');
  },
  setCodeDefault({ state, commit, dispatch }) {
    commit('updateField', { path: 'params.defect_code', value: state.defect_codes[0] })
    dispatch('updatePathAfterDefault');
  },
  updateString({ commit }, { key, value }) {
    commit('updateField', { path: `params.${key}`, value })
  },
  updateMultiSelect({ commit, state, dispatch }, { key, value }) {
    if (key === 'user_id') {
      let userindex = state.users.map(e => { return e.value }).indexOf(Number(value));
      if (userindex !== -1) {
        commit('updateField', { path: 'params.user_id', value: state.users[userindex] })
      } else {
        dispatch('setUserDefault')
      }
    }
    if (key === 'compaign_id') {
      let campaignindex = state.yard_campaigns.map(e => { return e.value }).indexOf(Number(value));
      if (campaignindex !== -1) {
        commit('updateField', { path: 'params.campaign_id', value: state.yard_campaigns[campaignindex] })
      } else {
        dispatch('setCampaignDefault')
      }
    }
    if (key === 'defect_code_model') {
      let codemodelindex = state.defect_code_models.map(e => { return e.value }).indexOf(Number(value))
      if (codemodelindex !== -1) {
        commit('updateField', { path: 'params.defect_code_model', value: state.defect_code_models[codemodelindex] })
      } else {
        dispatch('setCodeModelDefault')
      }
    }
    if (key === 'defect_code') {
      let codeindex = state.defect_codes.map(e => { return e.value }).indexOf(Number(value))
      if (codeindex !== -1) {
        commit('updateField', { path: 'params.defect_code', value: state.defect_codes[codeindex] })
      } else {
        dispatch('setCodeDefault')
      }
    }
  },
  updateSearchSettings({ dispatch, state }, searchParamsUsed) {
    searchParamsUsed = AxiosWrapper.copyAndDeleteNullProperties(searchParamsUsed)
    Object.keys(searchParamsUsed).forEach(key => {
      let value = searchParamsUsed[key]
      if (Object(state.params[key]) === state.params[key] && !Array.isArray(value)) {
        dispatch('updateMultiSelect', { key, value })
      } else {
        dispatch('updateString', { key, value })
      }
    })
  },
}