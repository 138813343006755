<template lang="pug">
  .container
    .d-flex.justify-content-between
      h1 {{ "payable_hours_summary" | translate }}
      .d-flex
        SelectsPresetsControl(pageTitle="payable_hours_summary")
        button.btn.btn-outline-light(v-if="showFilters == false",
                                      @click="showFilters = true") {{ "show_filters" | translate }}
        button.btn.btn-outline-light(v-if="showFilters == true",
                                      @click="showFilters = false") {{ "hide_filters" | translate }}
    .row(v-show="showFilters")
      .col
        .form-group
          label.form-label(for="usersSelect") {{ "employee" | translate }}
          UsersSelect(:multiple="true")#usersSelect
      .col
        StartAndEndDate
        .d-flex.justify-content-end
          button.btn.btn-outline-light(@click="resetSelects") {{ "clear" | translate }}
          button.btn.btn-primary(@click="search") {{ "search" | translate }}
    .row(v-show="!showFilters")
      .col.d-flex
        label.mr-1 {{ "employee" | translate }}:
        span.text-nowrap.text-truncate {{ users }}
      .col.d-flex.justify-content-between
        .d-flex
          label.mr-1 {{ "fromm" | translate }}:
          span.text-nowrap.text-truncate {{ start_date | date }}
        .d-flex
          label.mr-1 {{ "to" | translate }}:
          span.text-nowrap.text-truncate {{ end_date | date }}

</template>

<script>
import SelectsPresetsControl from "~/components/ContentWithHeader/SelectsPresetsControl";
import UsersSelect from "~/components/common/Select/UsersSelect";
import StartAndEndDate from "~/components/StartAndEndDate";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import moment from "moment";

export default {
  components: { SelectsPresetsControl, UsersSelect, StartAndEndDate },
  data() {
    return {
      showFilters: true
    }
  },
  methods: {
    ...mapActions("payableTimeSummary", ["init", "searchEmployees"]),
    ...mapActions("selects", ["resetSelects"]),
    search() {
      this.showFilters = false

      this.searchEmployees()
    }
  },
  created() {
    this.init()
  },
  filters: {
    date(val) {
      return moment(val).format("L")
    }
  },
  computed: {
    ...mapFields("selects", ["selectedUsers"]),
    ...mapFields("datetime", ["start_date", "end_date"]),
    users() {
      if (this.selectedUsers.length == 0) return I18n.t("any");
      return this.selectedUsers.reduce((acc, curr, idx) => {
        if (idx > 0) return `${acc}; ${curr.name}`;
        return acc;
      }, this.selectedUsers[0].name)
    }
  }
}
</script>
