import throttle from 'lodash/throttle'
import loaderCss from "!raw-loader!~/assets/stylesheets/loader.css";

const EDNLoaderPlugin = function () {
  this.isVisible = false
  this.forceHide = false

  this.createLoaderElement = function () {
    let overlay = document.createElement('div')
    overlay.id = 'loader-overlay'
    overlay.classList.add('transparent')

    let spinner = document.createElement('div')
    spinner.id = 'loader-spinner'
    spinner.classList.add('transparent')

    overlay.appendChild(spinner)
    document.body.appendChild(overlay)
    return overlay;
  }

  this.throttledMakeVisible = throttle(function () {
    if (this.isVisible) {
      document.getElementById('loader-overlay').classList.remove("transparent")
      document.getElementById('loader-spinner').classList.remove("transparent")
    }
  }.bind(this), 1000, { trailing: true, leading: false })

  this.show = function () {
    if (!this.isVisible && !this.forceHide) {
      this.createLoaderElement()
      this.throttledMakeVisible()
      this.isVisible = true
    } else {
      this.hide()
    }
  }.bind(this)

  this.hide = function () {
    if (this.isVisible && !this.forceVisible) {
      document.getElementById('loader-overlay').remove()
      this.isVisible = false
    }
  }.bind(this);

  return this
}

let Loader = new EDNLoaderPlugin();

import Vue from 'vue'
Vue.use((Vue, options) => Vue.prototype.$loader = Loader)

export default ({ app }) => {
  let loaderStyle = document.createElement('style');
  loaderStyle.textContent = loaderCss
  document.head.appendChild(loaderStyle);
  app.$loader = Loader
}