import { getField, updateField } from "vuex-map-fields";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { translate } from '~/assets/javascript/localization/Localization';
import cloneDeep from 'lodash/cloneDeep'

AxiosWrapper.init();
const getUrl = "/data_api/audit/scheduled_questionnaire_notifications"

const getDefaultState = () => ({
  allData: {
    zones: [],
    send_email_for_auditors_path: null,
    send_email_for_zone_managers_path: null,
    is_user_superuser: false,
  },
  usersList: [],
  zone_id: null,
  user_id: null,
  saveInProgress: false,
})

export const state = getDefaultState

export const getters = {
  zones: state => cloneDeep(state.allData.zones),
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  init(state, getRequestData) {
    state.allData = getRequestData.all_data;
  },
  populateUsersList(state, getRequestData) {
    state.usersList = getRequestData.options_for_user_list;
  },
  removeNotification(state, rowData) {
    let zoneIndex = state.allData.zones.findIndex(z => {
      return z.id === rowData.zone_id
    })

    let notificationIndex = state.allData.zones[zoneIndex].notifications.findIndex(n => {
      return n.id === rowData.id
    })

    state.allData.zones[zoneIndex].notifications.splice(notificationIndex, 1)
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(getUrl).then(response => {
      commit("init", response.data)
    })
  },
  async getUsersList({ state, commit }) {
    let searchParams = AxiosWrapper.getSearchParams();
    commit("updateField", {
      path: "zone_id",
      value: parseInt(searchParams.zone_id)
    })
    AxiosWrapper.get(`/data_api/audit/scheduled_questionnaire_notifications/new?zone_id=${state.zone_id}`, { zone_id: state.zone_id }).then(response => {
      commit("populateUsersList", response.data)
      commit("updateField", {
        path: "user_id",
        value: state.usersList[0].id
      })
    })
  },
  async sendEmailToAuditors({ state, dispatch, commit }) {
    commit("updateField", {
      path: "saveInProgress",
      value: true
    })
    AxiosWrapper.put('/data_api/audit/scheduled_questionnaire_notifications/send_email_for_auditor')
  },
  async sendEmailToZoneManagers({ state, dispatch, commit }) {
    commit("updateField", {
      path: "saveInProgress",
      value: true
    })
    AxiosWrapper.put('/data_api/audit/scheduled_questionnaire_notifications/send_email_for_zone_managers')
  },
  async deleteNotification({ commit, state }, rowData) {
    if (confirm(translate('are_you_sure'))) {
      AxiosWrapper.delete(`/data_api/audit/scheduled_questionnaire_notifications/${rowData.id}`)
        .then(() => {
          commit("removeNotification", rowData)
        })
    }
  },
  async saveChanges({ state, dispatch, commit }) {
    commit("updateField", {
      path: "saveInProgress",
      value: true
    })
    AxiosWrapper.post('/data_api/audit/scheduled_questionnaire_notifications', {
      audit_scheduled_questionnaire_notification: {
        zone_id: state.zone_id,
        user_id: state.user_id
      }
    })
  },
}
