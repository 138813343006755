import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import flattenDeep from 'lodash/flattenDeep'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { translate } from '~/assets/javascript/localization/Localization';

const getDefaultState = () => ({
  groupedSuspectCodeModels: [],
  globalSuspectCodeModels: [],
  suspectCodeModels: [],
  suspectCodeModelsToShow: [],
  jobId: null,
  jobRevisionId: null,
  zoneId: null,
  dataGrouped: false,
  selectedSuspectCodeModels: [],
  currentSuspectCodeModels: [],
  showAssignQuestionnaires: false,
  selectedSuspectCodeModels: [],
  questionnairesForSelect: [],
  selectedQuestionnaires: [],
  suspectCodeModelsChecked: false,
  showDeleteModal: false,
  hasJobOrJobRevision: false,
  hasJob: false,
  hasJobRevision: false,
  showEditModal: false,
  suspectCodeModelsForSelect: [],
  showAssignSuspectCodeModels: false,
  hideStuff: true,
  currentView: 'zone',
  jobFilter: 'active',
  hasLoaded: false
})

export const state = getDefaultState

export const getters = {
  suspectCodeModelsIds: state => {
    return state.suspectCodeModels.map(scm => scm.id)
  },
  getField,
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, { getRequestData, jobId = null, jobRevisionId = null }) {
    ({
      grouped: state.groupedSuspectCodeModels,
      zone_id: state.zoneId,
      global: state.globalSuspectCodeModels,
    } = getRequestData);

    state.hasLoaded = true
    state.jobId = jobId
    state.jobRevisionId = jobRevisionId

    if (state.jobId !== null) {
      state.hasJob = true
    }

    if (state.jobRevisionId != null) {
      state.hasJobRevision = true
    }

    state.hasJobOrJobRevision = state.hasJob || state.hasJobRevision

    let flattenedGroup = flattenDeep(state.groupedSuspectCodeModels.map(o => o.data))

    state.suspectCodeModels = uniqBy([...flattenedGroup, ...state.globalSuspectCodeModels], "id")

    if (state.currentView === 'none' || state.hasJobOrJobRevision) {
      state.suspectCodeModelsToShow = state.suspectCodeModels
    } else {
      state.dataGrouped = true
      state.suspectCodeModelsToShow = state.groupedSuspectCodeModels
    }
  },
  populateSuspectCodeModels(state, response) {
    ({
      grouped: state.groupedSuspectCodeModels
    } = response);

    state.suspectCodeModels = uniqBy(flattenDeep(state.groupedSuspectCodeModels.map((object) => { return object.data })), "id")
    state.suspectCodeModelsToShow = state.groupedSuspectCodeModels
  },
  toggleEdit(state) {
    state.showEditModal = !state.showEditModal
  },
  toggleAssignQuestionnaires(state) {
    state.showAssignQuestionnaires = !state.showAssignQuestionnaires
  },
  toggleAssignSuspectCodeModels(state) {
    state.showAssignSuspectCodeModels = !state.showAssignSuspectCodeModels
  },
  toggleDelete(state) {
    state.showDeleteModal = !state.showDeleteModal
  },
  toggleView(state, view) {
    state.currentView = view

    if (state.currentView === 'none') {
      state.dataGrouped = false
      state.suspectCodeModelsToShow = state.suspectCodeModels
    } else if (state.currentView === 'zone') {
      state.dataGrouped = true
      state.suspectCodeModelsToShow = state.groupedSuspectCodeModels
    } else {
      state.dataGrouped = true
      state.suspectCodeModelsToShow = state.groupedSuspectCodeModels
    }
  },
  addToSelected(state, suspectCodeModels) {
    state.selectedSuspectCodeModels = uniq([...state.selectedSuspectCodeModels, ...suspectCodeModels])
  },
  removeAllSelected(state) {
    state.selectedSuspectCodeModels = []
  },
  removeSelected(state, suspectCodeModel) {
    let index = state.selectedSuspectCodeModels.findIndex(scm => { return scm.id === suspectCodeModel.id })

    state.selectedSuspectCodeModels.splice(index, 1)
  },
  updateQuestionnairesCount(state) {
    state.currentSuspectCodeModels[0].questionnaires_count = state.selectedQuestionnaires.length
  },
  assignSelectedQuestionnaires(state) {
    let id = state.currentSuspectCodeModels[0].id

    let result = flattenDeep(state.questionnairesForSelect.map(group => {
      let ids = compact(group.items.map(q => {
        if (q.defect_code_model_id == id) return q.value
      }))

      return ids
    }))

    state.selectedQuestionnaires = result
  },
  resetQuestionnairesForSelect(state) {
    state.questionnairesForSelect = []
  },
  resetSelectedQuestionnaires(state) {
    state.selectedQuestionnaires = []
  },
  toggleChecked(state, checked) {
    state.suspectCodeModelsChecked = state.selectedSuspectCodeModels.length > 0
  },
  setCurrentSuspectCodeModels(state, suspectCodeModel = null) {
    if (suspectCodeModel) {
      state.currentSuspectCodeModels = [suspectCodeModel]
    } else {
      state.currentSuspectCodeModels = state.selectedSuspectCodeModels
    }
  },
  updateCurrentSuspectCodeModels(state, updatedSuspectCodeModel) {
    Vue.set(state.currentSuspectCodeModels, 0, {
      ...state.currentSuspectCodeModels[0],
      ...updatedSuspectCodeModel
    })
  },
  changeSelectedQuestionnaire(state, questionnaire) {
    let subtitle = translate('suspect_code_model_display', {
      name: state.currentSuspectCodeModels[0].name
    })

    questionnaire.subtitle = subtitle
  },
  changeAllSelectedQuestionnaire(state) {
    let questionnaires = [...state.questionnairesForSelect]

    questionnaires.forEach(group => {
      group.items.forEach(q => {
        let subtitle = translate('suspect_code_model_display', {
          name: state.currentSuspectCodeModels[0].name
        })
        q.subtitle = subtitle
      })
    })

    state.questionnairesForSelect = questionnaires
  },
  revertAllSelectedQuestionnaire(state) {
    let questionnaires = [...state.questionnairesForSelect]

    questionnaires.forEach(group => {
      group.items.forEach(q => {
        q.subtitle = q.originalSubtitle
      })
    })

    state.questionnairesForSelect = questionnaires
  },
  revertSelectedQuestionnaire(state, questionnaire) {
    if (questionnaire) questionnaire.subtitle = questionnaire.originalSubtitle
  },
  populateQuestionnairesForSelect(state, { questionnaireGroups, currentQuestionnaires }) {
    state.questionnairesForSelect = []

    questionnaireGroups.forEach(group => {
      let questionnaires = group.questionnaires.map(q => {
        let defectCodeModelName = q.defect_code_model_name == '' ?
          translate('none') : q.defect_code_model_name
        let subtitle = translate('suspect_code_model_display', { name: defectCodeModelName })

        return {
          subtitle: subtitle,
          originalSubtitle: subtitle,
          defect_code_model_id: q.defect_code_model_id,
          label: q.name, value: q.id
        }
      })

      state.questionnairesForSelect = state.questionnairesForSelect.
        concat({
          collapsible: true,
          label: group.name,
          items: questionnaires
        })
    })
    state.selectedQuestionnaires = currentQuestionnaires;
  },
  removeSuspectCodeModel(state, suspectCodeModel) {
    let index = state.suspectCodeModels.findIndex(m => { return m.id === suspectCodeModel.id })

    state.suspectCodeModels.splice(index, 1)

    if (suspectCodeModel.global) {
      let index = state.globalSuspectCodeModels.findIndex(m => { return m.id === suspectCodeModel.id })

      state.globalSuspectCodeModels.splice(index, 1)
    } else {
      state.groupedSuspectCodeModels.forEach(group => {
        index = group.data.findIndex(m => {
          return m.id === suspectCodeModel.id
        })

        group.data.splice(index, 1)
      })
    }
  },
  populateSuspectCodeModelsForSelect(state, suspectCodeModels) {
    state.suspectCodeModelsForSelect = []

    suspectCodeModels.grouped.forEach(zone => {
      let suspectCodeModels = zone.data.map(scm => {
        return {
          label: scm.name,
          value: scm.id,
          object: scm
        }
      })

      state.suspectCodeModelsForSelect = state.suspectCodeModelsForSelect.concat(
        suspectCodeModels)
    })

    state.suspectCodeModelsForSelect = uniqBy(flattenDeep(state.suspectCodeModelsForSelect),
      "value")
  },
  addSelectedModelsToJob(state, removedIds = []) {
    let selectedSuspectCodeModels = state.suspectCodeModelsForSelect.filter(scm => {
      return state.selectedSuspectCodeModels.some(id => scm.value == id)
    }).map(scm => scm.object)

    state.suspectCodeModels = uniqBy([...state.suspectCodeModels,
    ...selectedSuspectCodeModels], 'id')

    state.suspectCodeModelsToShow = state.suspectCodeModels
  },
  setSelectedModels(state) {
    state.selectedSuspectCodeModels = state.suspectCodeModels.map(scm => scm.id)
  },
  resetSelectedModels(state) {
    state.selectedSuspectCodeModels = []
  },
  updateSuspectCodeModelLessee(state, { suspectCodeModel, lesseeId }) {
    let index = state.suspectCodeModels.findIndex(scm => scm.id == suspectCodeModel.id)

    if (lesseeId) {
      state.suspectCodeModels[index].name = suspectCodeModel.name
      state.suspectCodeModels[index].lessee_id = lesseeId
    }
  },
  addSuspectCodeModel(state, suspectCodeModel) {
    let index = state.suspectCodeModels.findIndex(scm => scm.id == suspectCodeModel.id)

    if (index < 0) {
      state.suspectCodeModels.push(suspectCodeModel)
    } else {
      state.suspectCodeModels[index].name = suspectCodeModel.name
    }
  },
  updateJobFilter(state, filter) {
    state.jobFilter = filter
  },
  updateField,
}

export const actions = {
  async init({ commit }, { jobId = null, jobRevisionId = null }) {
    commit('resetState')

    this.$axios.get('/data_api/suspect_code_models', {
      params: {
        job_id: jobId,
        job_revision_id: jobRevisionId
      }
    }).then(response => {
      commit('init', { getRequestData: response.data, jobId, jobRevisionId })
    })
  },
  jobFilterClicked({ commit, dispatch }, filter) {
    commit("updateJobFilter", filter)
    dispatch('getJobGroupedModels')
  },
  getJobGroupedModels({ commit, state }) {
    AxiosWrapper.get('/data_api/suspect_code_models', {
      params: {
        group_by: 'job',
        filter: state.jobFilter
      }
    }).then(response => {
      commit('populateSuspectCodeModels', response.data)
    })
  },
  changeView({ commit, dispatch, state }, view) {
    if (view === 'job') {
      dispatch('getJobGroupedModels')
    }

    if (state.currentView === 'job') {
      dispatch('init', { jobId: null, jobRevisionId: null })
    }

    commit('toggleView', view)
  },
  editClicked({ commit }, suspectCodeModel) {
    commit("setCurrentSuspectCodeModels", suspectCodeModel)
    commit('toggleEdit')
  },
  newClicked({ commit }) {
    commit("setCurrentSuspectCodeModels", { id: null })
    commit('toggleEdit')
  },
  deleteClicked({ commit, dispatch }, suspectCodeModel) {
    commit("setCurrentSuspectCodeModels", suspectCodeModel)

    if (suspectCodeModel.questionnaires_count == 0 && suspectCodeModel.job_ids.length == 0) {
      if (confirm(translate('delete_confirm'))) {
        dispatch('confirmDeleteClicked', suspectCodeModel)
      }
    } else {
      commit('toggleDelete')

      AxiosWrapper.get(`/data_api/suspect_code_models/${suspectCodeModel.id}`).then(response => {
        commit("updateCurrentSuspectCodeModels", response.data)
      })
    }
  },
  confirmDeleteClicked({ dispatch, commit, state }, suspectCodeModel) {
    AxiosWrapper.delete(`/data_api/suspect_code_models/${suspectCodeModel.id}`).then(response => {
      commit("removeSuspectCodeModel", suspectCodeModel)
      if (state.showDeleteModal) {
        commit('toggleDelete')
      }
      if (state.jobId) {
        dispatch("job/refreshJob", { jobId: state.jobId }, { root: true })
      }

      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
    })
  },
  removeClicked({ dispatch, commit, state }, suspectCodeModel) {
    AxiosWrapper.patch(`/data_api/suspect_code_models/${suspectCodeModel.id}/remove_job`,
      { job_id: state.jobId }).then(response => {
        commit("removeSuspectCodeModel", suspectCodeModel)
        dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
        if (state.jobId) {
          dispatch("job/refreshJob", { jobId: state.jobId }, { root: true })
        }

      })
  },
  updateSelectedQuestionnaires({ commit }, val) {
    commit("updateField", { path: "selectedQuestionnaires", value: val })
  },
  assignQuestionnaires({ commit }, { suspectCodeModel }) {
    commit("resetQuestionnairesForSelect")
    commit("setCurrentSuspectCodeModels", suspectCodeModel)
    commit("toggleAssignQuestionnaires")

    this.$axios.get("/data_api/suspect_code_models/assign_questionnaires",
      { params: { suspect_code_model_id: suspectCodeModel.id } })
      .then(({ data }) => {
        commit("populateQuestionnairesForSelect", { questionnaireGroups: data.questionnaire_groups,
                                                    currentQuestionnaires: data.current_questionnaires })
      })
  },
  saveAssignSuspectCodeModels({ dispatch, commit, state }) {
    AxiosWrapper.patch(`/data_api/jobs/${state.jobId}/assign_suspect_code_models`,
      { defect_code_model_ids: state.selectedSuspectCodeModels }).then(response => {
        commit('addSelectedModelsToJob', response.data.removed)
        commit('resetSelectedModels')
        dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
        commit('toggleAssignSuspectCodeModels')
        dispatch("job/refreshJob", { jobId: state.jobId }, { root: true })
      })
  },
  saveAssignQuestionnaires({ commit, dispatch, state }) {
    if (state.selectedQuestionnaires.length > 0) {
      AxiosWrapper.patch(`/data_api/suspect_code_models/
                            ${state.currentSuspectCodeModels[0].id}/update_questionnaires`,
        { questionnaire_ids: compact(state.selectedQuestionnaires) }).then(response => {
          commit("updateQuestionnairesCount")
          commit('toggleAssignQuestionnaires')
          commit('resetSelectedQuestionnaires')
          dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
        })
    } else {
      commit('toggleAssignQuestionnaires')
    }
  },
  convertToTemplateClicked({ commit, state, dispatch }, suspectCodeModel) {
    AxiosWrapper.post(`/data_api/suspect_code_models/${suspectCodeModel.id}/convert_to_template`, { job_id: state.jobId }).then(response => {
      commit('updateSuspectCodeModelLessee', { suspectCodeModel, lesseeId: response.data.lessee_id })
      dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true })
    })
  },
  questionnaireChecked({ commit }, { arguments: [checked, questionnaire] }) {
    if (checked) {
      commit('changeSelectedQuestionnaire', questionnaire)
    } else {
      commit('revertSelectedQuestionnaire', questionnaire)
    }
  },
  questionnaireCheckedAll({ commit }, { arguments: [checked] }) {
    if (checked) {
      commit('changeAllSelectedQuestionnaire')
    } else {
      commit('revertAllSelectedQuestionnaire')
    }
  },
  checkboxToggled({ commit }, { arguments: [checked, suspectCodeModel] }) {

    if (checked) {
      commit("addToSelected", [suspectCodeModel])
      commit("toggleChecked", checked)
    } else {
      commit("removeSelected", suspectCodeModel)
      commit("toggleChecked", checked)
    }
  },
  checkboxToggledAll({ commit, state }, { table, arguments: [checked] }) {
    if (checked) {
      if (table === 'global') {
        commit("addToSelected", state.globalSuspectCodeModels)
      } else {
        commit("addToSelected", state.suspectCodeModels)
      }

      commit("toggleChecked", checked)
    } else {
      commit("removeAllSelected")
      commit("toggleChecked", checked)
    }
  }
}