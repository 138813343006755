import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  back_path: null,
  mask_header: null,
  mask_name: null,
  available_modes: [],
  type: null,
  mask:{
    parent_id: null,
    parent_type: null,
    priority: null,
    mode: null,
    original_content: ''
  }
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      back_path: state.back_path,
      mask_header: state.mask_header,
      mask_name: state.mask_name,
      available_modes: state.available_modes,
      mask: state.mask,
      type: state.type
    } = responseData)
  },
  setModeSelectedValue(state){
    if (state.mask.mode === null){
      state.mask.mode = state.available_modes[0]
    }else{
      state.available_modes.forEach(mode=>{
        if (mode.value === state.mask.mode){
          state.mask.mode = mode
        }
      })
    }
  }
}

export const getters = {
  getField,
  shouldShowSimulator(state){
    let show = false

    if (state.mask.mode !== null){
      show = state.mask.mode.value === 'Mask::RegularExpression'
    }

    return show
  }
}

export const actions = {
  init({  commit }, routeParams) {
    let baseUrl = `/data_api/barcode/jobs/${routeParams.job_id}/${routeParams.mask_type}/work_item_field_masks/${routeParams.mask_id}`

    if (routeParams.mask_id !== 'new') baseUrl = baseUrl + `/edit`
    this.$axios.get(baseUrl).then(({ data }) => {
      commit('initialGetCompleted', data);
      commit('setModeSelectedValue')
    })
  },
  saveMask({ state, dispatch }, {routeParams, router}) {
    let flatState = AxiosWrapper.flattenObject(state.mask);
    flatState = AxiosWrapper.copyAndDeleteNullProperties(flatState)

    let params = { [state.type]: flatState }

    let postUrl = `/data_api/barcode/jobs/${routeParams.job_id}/${routeParams.mask_type}/work_item_field_masks`
    AxiosWrapper.postOrPatch(postUrl, params).then(() =>{
      router.push(`/jobs/${routeParams.job_id}/edit/barcode/${routeParams.mask_type}/work_item_field_masks`)
    })

  }
}