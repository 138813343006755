<template lang="pug">
  nav.navbar.navbar-expand-md.navbar-dark.bg-dark
    slot
    nuxt-link#ednLogo.navbar-brand.d-flex.align-items-center.p-0(to='/')
      img(src="/edn_logo_white.png" height="35" alt="logo")

    button.navbar-toggler(data-toggle="collapse",
                          data-target="#navbarLinks",
                          aria-control="#navbarLinks",
                          :aria-label="translate('toggle_navigation')")
      span.navbar-toggler-icon
    #navbarLinks.collapse.navbar-collapse
      ul.navbar-nav(id="navigation-options", data-cy='navbarLinks')
        li.nav-item.dropdown
          .nav-link.dropdown-toggle(id="dashboardDropdown",
                                      role="button",
                                      data-toggle="dropdown",
                                      aria-haspopup="true",
                                      aria-expanded="false") {{ 'dashboard' | translate }}
          .dropdown-menu(aria-labelledby="dashboardDropdown")
            nuxt-link.dropdown-item(v-for='panel in dashboards',
                                    :id="`linkToDashboard${panel.id}`"
                                    :key='panel.id',
                                    :to="`/dashboard/panels/${panel.id}/my`") {{ panel.name }}
            nuxt-link#linkToNewDashboard.dropdown-item(to='/dashboard/panels/new',
                                                      data-cy='createNewDashboard') {{ 'create_new_dashboard' | translate }}

        li.nav-item.dropdown
          .nav-link.dropdown-toggle(id="reportsDropdown",
                                    role="button",
                                    data-toggle="dropdown",
                                    aria-haspopup="true",
                                    aria-expanded="false") {{ 'reports' | translate }}
          .dropdown-menu(aria-labelledby="reportsDropdown")
            nuxt-link#statsReportsLink.dropdown-item(to='/reports/stats', data-cy='stats') {{ 'stats' | translate }}
            nuxt-link#ichartReportsLink.dropdown-item(to='/reports/ichart', data-cy='ichart') {{ 'ichart' | translate }}
            template(v-if="isAdministrator || isZoneManager || isRepairSpecialist || isCorrectiveActionSpecialist")
              nuxt-link#rejectStatsReportsLink.dropdown-item(to='/reports/reject_stats', data-cy='rejectStats') {{ 'reject_stats' | translate }}
              nuxt-link#workItemsReportsLink.dropdown-item(to='/reports/work_items', data-cy='workItems') {{ 'work_items' | translate }}
              nuxt-link#shiftsReportsLink.dropdown-item(to='/reports/shifts', data-cy='shifts') {{ 'shifts' | translate }}
              nuxt-link#atlas.dropdown-item(to='/reports/atlas', data-cy='atlas') {{ 'atlas' | translate }}
              template(v-if="!isViewOnly")
                nuxt-link#shiftsByDateReportsLink.dropdown-item(to='/reports/shift_by_date', data-cy='shiftsByDate') {{ 'shifts_by_date' | translate }}
              template(v-if="isAdministrator")
                nuxt-link#rejectSummaryReportsLink.dropdown-item(to='/reports/reject_summary',
                                        data-cy='sevenDaysRejectSummary') {{ 'seven_days_reject_summary' | translate }}
              nuxt-link#heatmapReportsLink.dropdown-item(to='/reports/heatmaps',
                                      data-cy='/heatmapAnswerSearch') {{ 'heatmap_answer_search' | translate }}
              nuxt-link#questionnaireStatsReportsLink.dropdown-item(to='/reports/questionnaire_stats',
                                      data-cy='questionnaireStats') {{ 'questionnaire_stats' | translate }}
              nuxt-link#questionnaireResultsReportsLink.dropdown-item(to='/reports/questionnaire_results',
                                      data-cy='questionnaireResults') {{ 'questionnaire_results' | translate }}
              nuxt-link#correctiveActionsReportsLink.dropdown-item(to='/reports/corrective_actions',
                                      data-cy='correctiveActionPlans') {{ 'corrective_actions' | translate }}
              template(v-if="isAdministrator")
                nuxt-link#trainingResultsReportsLink.dropdown-item(to='/reports/training_results',
                                        data-cy='trainingResults') {{ 'training_results' | translate }}
            template(v-if="isTimeSheetAdministrator || canApproveTimeSheets")
              nuxt-link#timeSheets.dropdown-item(to='/hrs/reports/time_sheets',
                                              data-cy='time-sheets') {{ 'timesheets' | translate }}
              nuxt-link#timeSheets-by-job.dropdown-item(to='/hrs/reports/time_sheets_by_job',
                                              data-cy='time-sheets-by-job') {{ 'billable_hours_summary' | translate }}
              nuxt-link#payable-hours-summary.dropdown-item(to='/hrs/reports/payable_hours_summary',
                                              data-cy='payable-hours-summary') {{ 'payable_hours_summary' | translate }}
              nuxt-link#roll-call.dropdown-item(to='/hrs/reports/roll_call',
                                              data-cy='roll-call') {{ 'roll_call' | translate }}


        li.nav-item
          nuxt-link#jobsLink.nav-link(to='/jobs', data-cy='jobs') {{ 'jobs' | translate }}

        li.nav-item.dropdown(v-if="isAdministrator || isZoneManager")
          .nav-link.dropdown-toggle(id="auditsDropdown",
                                    role="button",
                                    data-toggle="dropdown",
                                    aria-haspopup="true",
                                    aria-expanded="false") {{ 'questionnaires' | translate }}
          .dropdown-menu(aria-labelledby="auditsDropdown")
            nuxt-link#correctiveActionsAdmin.dropdown-item(to='/audit/corrective_action/plans/list',
                                    data-cy='myCorrectiveActions') {{ 'my_corrective_actions' | translate }}
            nuxt-link#questionnaireGroups.dropdown-item(to='/audit/questionnaire_groups',
                                    data-cy='auditGroups') {{ 'audit_groups' | translate }}
            nuxt-link#scheduled.dropdown-item(to='/scheduled_questionnaires',
                                    data-cy='scheduledAudits') {{ 'scheduled_audits' | translate }}
            nuxt-link#champions.dropdown-item(to='/template/audit/corrective_action/champions',
                                    data-cy='championTemplates') {{ 'champion_templates' | translate }}
            nuxt-link#schedyledNotifications.dropdown-item(to='/audit/scheduled_questionnaire_notifications',
                                    data-cy='forecastNotifications') {{ 'forecast_notifications' | translate }}

        li.nav-item(v-if="isCorrectiveActionSpecialist || isRepairSpecialist || isViewOnly")
          nuxt-link#correctiveActionsLink.nav-link(to='/audit/corrective_action/plans/list',
                                    data-cy='myCorrectiveActions') {{ 'my_corrective_actions' | translate }}

        li.nav-item.dropdown(v-if="isAdministrator || isZoneManager")
          .nav-link.dropdown-toggle(id="librariesDropdown",
                                    role="button",
                                    data-toggle="dropdown",
                                    aria-haspopup="true",
                                    aria-expanded="false") {{ 'libraries' | translate }}
          .dropdown-menu(aria-labelledby="librariesDropdown")
            nuxt-link#suspectCodeModels.dropdown-item(to='/suspect_code_models',
                                    data-cy='suspectCodeModels') {{ 'suspect_code_models' | translate }}
            nuxt-link#recipients.dropdown-item(to='/recipient_libraries',
                                    data-cy='recipient') {{ 'recipient' | translate }}
            nuxt-link#videos.dropdown-item(to='/library/assets/videos',
                                    data-cy='videos') {{ 'videos' | translate }}
            nuxt-link#tags.dropdown-item(to='/tags',
                                    data-cy='tags') {{ 'tags' | translate }}

        li.nav-item.dropdown(v-if="isAdministrator || isZoneManager")
          .nav-link.dropdown-toggle(id="adminDropdown",
                                    role="button",
                                    data-toggle="dropdown",
                                    aria-haspopup="true",
                                    aria-expanded="false") {{ 'admin' | translate }}
          .dropdown-menu(aria-labelledby="adminDropdown")
            nuxt-link#devices.dropdown-item(to='/devices',
                                    data-cy='devices') {{ 'devices' | translate }}
            nuxt-link#zones.dropdown-item(to='/zones',
                                    data-cy='zones') {{ 'zones' | translate }}
            nuxt-link#users.dropdown-item(to='/users',
                                    data-cy='users') {{ 'users' | translate }}
            nuxt-link#alerts.dropdown-item(to='/alerts',
                                    data-cy='alerts') {{ 'alerts' | translate }}
            nuxt-link#departments.dropdown-item(to='/departments',
                                    data-cy='departments') {{ 'departments' | translate }}
            nuxt-link#shiftDescriptions.dropdown-item(to='/shift_descriptions',
                                    data-cy='shiftDescriptions') {{ 'shift_descriptions' | translate }}
            template(v-if="isAdministrator")
              nuxt-link#severities.dropdown-item(to='/severities',
                                      data-cy='severities') {{ 'severities' | translate }}
              nuxt-link#exams.dropdown-item(to='/training/exams',
                                      data-cy='training') {{ 'exams' | translate }}
              nuxt-link#workAreas.dropdown-item(to='/work_areas',
                                      data-cy='workAreas') {{ 'work_areas' | translate }}
            template(v-if="isBillingAdministrator || isGlobalAdministrator || isSuperuser")
              nuxt-link#leftEarly.dropdown-item(to='/left_early_reasons') {{ 'left_early_reasons' | translate }}
            template(v-if="isSuperuser || isAdministrator")
              nuxt-link#dashboardPanels.dropdown-item(to='/dashboard/panels',
                                      data-cy='dashboardPanels') {{ 'dashboard_panels' | translate }}
            template(v-if="isSuperuser")
              nuxt-link#superuserOnly.dropdown-item(to='/superuser_only',
                                      data-cy='superuserOnly') {{ 'superuser_only' | translate }}
              nuxt-link#ranks.dropdown-item(to='/ranks',
                                      data-cy='ranks') {{ 'ranks' | translate }}
              nuxt-link#downtime.dropdown-item(to='/downtime/reasons',
                                               data-cy='downtime-reasons') {{ 'downtime_reasons' | translate }}
              nuxt-link.dropdown-item(v-for="customEmbed in customEmbeds",
                        :key="customEmbed.id",
                        :to="`/custom_embeds/${customEmbed.id}`") {{ customEmbed.label }}

      SessionMenu

</template>

<script>
import SessionMenu from "~/components/SessionMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: { SessionMenu },
  computed: mapGetters("login", [
    "dashboards",
    "customEmbeds",
    "isSuperuser",
    "isAdministrator",
    "isZoneManager",
    "isCorrectiveActionSpecialist",
    "isRepairSpecialist",
    "isViewOnly",
    "isTimeSheetAdministrator",
    "isBillingAdministrator",
    "isGlobalAdministrator",
    "canApproveTimeSheets",
  ]),
};
</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/custom.scss";
@import "~/assets/stylesheets/navbar.scss";
</style>
