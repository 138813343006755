<template lang="pug">
    div
      Breadcrumbs(:breadcrumbs="breadcrumbs",
                :truncateTo="25",
                v-if="!isScrolledDown")
      .d-flex
        h1(v-if="showTitle") {{ title | translate }}
        slot
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
export default {
  components: {
    Breadcrumbs
  },
  props: {
    title: String,
    showTitle: {
      default: true
    }
  },
  computed: {
    ...mapFields('header',['isScrolledDown']),
    ...mapFields('job',['job']),
    ...mapFields('jobRevision', ['jobRevision', 'jobRevisionBreadcrumbs']),
    ...mapGetters('jobRevision', ['getRevisionBreadcrumb']),
    breadcrumbs() {
      if (this.jobRevision) return [
        ...this.jobRevisionBreadcrumbs,
        { text: this.translate(this.title) },
      ]
      return []
    }
  }
};
</script>
