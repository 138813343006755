import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

let prepareDefectParams = function (state, payload) {
  let defect = state.defect;
  let data = {
    defect: {
      user_id: defect.user.id,
      suspect_code_is_other: Boolean(defect.suspect_code.isOther),
      suspect_code: defect.suspect_code.isOther ? defect.suspect_code.otherCode : defect.suspect_code.name,
      quantity: defect.quantity,
      defect_code_model_id: defect.suspect_code_model.id,
      control_reject_tag: defect.control_reject_tag,
      verified: defect.verified,
      repaired: defect.repaired,
      scrap: defect.scrap,
      recycle: defect.recycle,
      released: defect.released,
      file_stores_attributes: defect.files,
    }
  }
  if (payload.commentId) data.comment_id = payload.commentId;
  if (payload.newAttachment) data.defect.attachment = payload.newAttachment;

  return data;
}

const getDefaultState = () => ({
  bin: null,
  job: null,
  defect: {},
  suspectCodeModels: [],
  suspectCodes: [],
  users: [],
  currentUser: {},
  otherSuspectCodeOption: { name: I18n.t('other'), isOther: true, otherCode: '' },
  initialized: false,
  plan: null,
  permissions: {},
  index: -1
})

export const state = getDefaultState

export const getters = {
  binUrl: state => {
    if (state.bin && state.job)
      return `/jobs/${state.job.id}/job_revisions/${state.defect.job_revision_id}/bins/${state.bin.id}`;
    return "";
  },
  additionalFiles: state => {
    if (state.defect.files) return state.defect.files.filter(f => !f._destroy);
    return [];
  },
  newComment: state => ({
    parent_id: state.defect.id,
    parent_type: "Failure",
    user_id: state.currentUser.id,
    description: "",
    lookupId: new Date().getTime()
  }),
  selectableSuspectCodes(state) {
    if (state.defect.suspect_code_model) {
      let codes = state.suspectCodes.filter(sc => sc.suspect_code_model_id == state.defect.suspect_code_model.id);
      codes.push(state.otherSuspectCodeOption);
      return codes;
    } else {
      return []
    }
  },
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitialData(state, data) {
    state.bin = data.bin;
    state.job = data.job;
    state.defect = data.defect;
    state.suspectCodeModels = data.suspect_code_model_options;
    state.suspectCodes = data.suspect_code_options;
    state.users = data.users;
    state.currentUser = data.current_user;
    if (data.plan) state.plan = data.plan;
    state.initialized = true;
    state.permissions = data.permissions;
    state.newPlanData = data.new_plan_data
  },
  removeFile(state, file) {
    let files = state.defect.files;
    file._destroy = true;
    files.splice(files.indexOf(file), 1, file);
  },
  addFile(state, file) {
    let newFile = { file: file, is_image: true, name: file.name, medium_url: window.URL.createObjectURL(file) };
    state.defect.files.push(newFile);
  },
  updateField
}

export const actions = {
  init({ commit, dispatch, rootGetters, state }, defectId) {
    commit("resetState")
    commit('correctiveActionPlans/resetState', {}, { root: true })

    AxiosWrapper.get('/data_api/defects/' + defectId)
      .then(response => {
        commit('setInitialData', response.data)
        dispatch('jobRevision/setJobRevisionAndShift', {revision: response.data.job_revision, shift:response.data.shift} , { root: true })
        commit('correctiveActionPlans/init', { currentUser: state.currentUser,
                                                departments: response.data.allowable_departments,
                                                severities: response.data.allowable_severities,
                                                users: response.data.allowable_users_as_options,
                                                permissions: rootGetters['login/questionnaireResultsPermissions'] }, { root: true })

        let plans = state.plan === null ? [] : [state.plan]

        commit("correctiveActionPlans/addDependentPlan", { plans: plans, parent: state.defect }, { root: true})

        let index = rootGetters["correctiveActionPlans/dependentPlansIndex"](state.defect)

        commit("updateField", {path: "index", value: index})
      })
  },
  initNew({ commit, dispatch }, binId) {
    commit("resetState")

    AxiosWrapper.get('/data_api/defects/new', { params: { bin_id: binId } })
      .then(({data}) => {
        commit('setInitialData', data)
        dispatch('jobRevision/setJobRevisionAndShift', {revision: data.job_revision, shift: data.shift} , { root: true })
      })
  },
  updateDefect({ commit, state }, payload) {
    return AxiosWrapper.patch(`/data_api/defects/${state.defect.id}`, prepareDefectParams(state, payload))
  },
  createDefect({ commit, state }, payload) {
    let data = prepareDefectParams(state, payload);
    data.bin_id = state.bin.id;
    return AxiosWrapper.post('/data_api/defects', data)
  },
  planCreated({commit, state}, plan) {
    commit('updateField', { path: "plan", value: plan })
  },
  cancelCreate({commit, state}, plan) {
    commit("correctiveActionPlans/removePlan", { dependentPlanIndex: state.index, plan: plan }, { root: true})
    commit('updateField', { path: "plan", value: null})
  },
  createCorrectiveActionPlan({ commit, state }, description) {
    const date = new Date()

    let newPlanObject = {
      lookupId: date.getTime(),
      in_progress: true,
      current_user_id: state.currentUser.id,
      expected_resolution_date: moment().format("YYYY-MM-DD"),
      user_id: state.currentUser.id,
      description: state.newPlanData.description,
      department_id: null,
      champion_id: null,
      severity_id: null,
      tasks: [],
      can_be_verified_by_id: null,
      failure_id: state.newPlanData.failure.id,
      failure: state.newPlanData.failure,
      defect: state.newPlanData.defect,
      answer_id: state.newPlanData.answer_id,
      champions: [],
      'anything_in_progress?': true,
      'in_progress?': true,
      'is_overdue?': false
    }

    commit("updateField", {path: "plan", value: newPlanObject})

    commit("correctiveActionPlans/addNewPlanToParent", { dependentPlanIndex: state.index, plan: state.plan }, { root: true})
  }
}