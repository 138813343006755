import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  maximumNumber: '',
  upperFailBounds: '',
  upperWarnBounds: '',
  lowerWarnBounds: '',
  lowerFailBounds: '',
  minimumNumber: '',
  isWholeNumber: false
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question)
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_score_pass_fail_question', name: 'scorePassFail'}, { root: true })
  },
  async configureInitState({ dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', true, { root: true })
  },
  async configureParams({ dispatch }, params) {
    dispatch("questions/editQuestions/configureCommonParams", params, { root: true })
  }
}