import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  department: null,
  zone: null,
  roles: [],
  shiftDescriptions: []
})

export const state = getDefaultState

export const getters = {
  teams(state) {
    let roles = state.roles.filter(r => r.team_id);
    return roles.reduce((teams, role) => {
      teams[role.team_id] = {
        id: role.team_id,
        team_members: role.team_members,
        role_id: role.id,
        name: role.name
      }
      return teams
    }, {})
  },
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateTeamMembers(state, { id, members }) {
    let role = state.roles.find(r => r.team_id === id);
    role.team_members = members;
  },
  updateMember(state, { team, teamMember, updatedAttributes }) {
    team.team_members.splice(team.team_members.indexOf(teamMember), 1, updatedAttributes)
  },
  removeMember(state, { team, teamMember }) {
    let role = state.roles.find(r => r.team_id == team.id);
    role.team_members.splice(role.team_members.indexOf(teamMember), 1);
    if (role.team_members.length == 0) role.team_id = null;
  },
  createTeam(state, { roleId, teamData }) {
    let roleIndex = state.roles.findIndex(r => r.id === roleId);
    state.roles.splice(roleIndex, 1, {
      ...state.roles[roleIndex],
      team_members: teamData.team_members,
      team_id: teamData.id
    })
  },
  updateField
}

export const actions = {
  async init({ commit }, { zoneId, departmentId }) {
    commit("resetState")

    this.$axios.get(`/data_api/departments/${departmentId}`)
      .then(response => {
        commit('updateField', { path: 'department', value: response.data })
      })
    if (zoneId) {
      this.$axios.get(`/data_api/zones/${zoneId}`)
        .then(response => {
          commit('updateField', { path: 'zone', value: response.data })
        })
    } else {
      commit('updateField', { path: 'zone', value: null })
    }
    let getUrl = (zoneId ? `zones/${zoneId}/assign_zone_teams` : 'assign_teams_to_department')
    this.$axios.get(`/data_api/departments/${departmentId}/${getUrl}`)
      .then(response => {
        commit('updateField', { path: 'roles', value: response.data.roles })
      })
    this.$axios.get('/data_api/shift_descriptions')
      .then(response => {
        commit('updateField', { path: 'shiftDescriptions', value: Object.values(response.data.shift_descriptions) })
      })
  },
  createTeam({ commit }, { roleId, teamData }) {
    return this.$axios
      .post("/data_api/departments/teams", teamData)
      .then(response => {
        commit("createTeam", { roleId, teamData: response.data })
      });
  }
}