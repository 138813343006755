import { getField, updateField } from "vuex-map-fields";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import paramsHelper from '~/components/common/stores/backsheetsParamsHelper.js'
import isArray from 'lodash/isArray'
import gtmEvents from "~/assets/javascript/gtmEvents"

const CONSTRAINT_LABELS = {
  'partNumber': 'part_numbers_constraint_ids',
  'serialNumber': 'serial_numbers_constraint_ids',
  'lotNumber': 'lot_numbers_constraint_ids',
  'other1': 'other1s_ids',
  'other2': 'other2s_ids',
}

const CONSTRAINT_SELECTS = {
  'partNumber': 'selectedPartNumbers',
  'serialNumber': 'selectedSerialNumbers',
  'lotNumber': 'selectedLotNumbers',
  'other1': 'selectedOther1s',
  'other2': 'selectedOther2s',
}

const baseUrl = "/data_api/backsheets";

const getDefaultState = () => ({
  lockedJob: {},
  summaryReport: null,
  allConstraints: [],
  confirmingCsvRequest: false,
  constraintType: "SerialNumber",
})

export const state = getDefaultState

export const getters = {
  getField,
  jobSelected(state, getters, rootState) {
    let selectedJob = rootState.selects.selectedJobs

    if (selectedJob) {
      if (typeof selectedJob === 'object') {
        return Object.keys(selectedJob).length !== 0
      } else if (isArray(selectedJob)) {
        return selectedJob.length > 0
      }
    }

    return false
  },
  csvPath() {
    let params = AxiosWrapper.getSearchParams();

    return `/data_api/download_csv/backsheets${AxiosWrapper.buildSearchUrl(params)}`
  }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initOptionsFromUrl(state, {urlParams, rootState}) {
    if (Object.keys(urlParams).includes("constraint_type")) state.constraintType = urlParams.constraint_type;
    if (urlParams.constraints) {
      let constraintsForSelectsStore = urlParams.constraints.map(constraint => ({name: constraint, value: constraint}));
      rootState.selects[CONSTRAINT_SELECTS[state.constraintType]] = constraintsForSelectsStore;
    }
  }
}

export const actions = {
  async init({ commit, dispatch, rootState }) {
    commit('resetState')

    let params = AxiosWrapper.getSearchParams();
    commit('initOptionsFromUrl', { urlParams: params, rootState })
    dispatch("selects/init", { params, paramsMap: paramsHelper.defaultParamsMap() }, { root: true });
    dispatch("datetime/init", { params }, { root: true });

    if (Object.keys(params).length > 1) dispatch('search', params);
  },
  async applyPressed({ state, dispatch, rootState, rootGetters }) {
    let params = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], paramsHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      constraints: rootState.selects.params[CONSTRAINT_LABELS[state.constraintType]],
      constraint_type: state.constraintType
    }
    gtmEvents.reportGenerated({
      type: "Backsheets"
    })

    if(!isArray(params.shift_descriptions)) params.shift_descriptions = [params.shift_descriptions];
    if(!isArray(params.constraints)) params.constraints = [params.constraints];

    dispatch('search', params)
  },
  search({ dispatch, rootGetters }, params) {
    this.$axios.get(baseUrl, { params })
      .then(response => {
        dispatch('setResults', response.data);
        dispatch("setBrowserUrlWithSearchParams", {...params, ...rootGetters["selects/prefixedCustomValues"]});
      });
  },
  setResults({ commit }, results) {
    commit('updateField', {
      path: 'summaryReport',
      value: results.summary_report
    });
  },
  setBrowserUrlWithSearchParams(context, params) {
    history.replaceState(history.state, "Backsheets", AxiosWrapper.buildPathWithSearchParams(params));
  }
}