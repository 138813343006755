export default {
  getUrl(isDowntime, id) {
    if (isDowntime) return `/data_api/downtime/libraries/${id}/copy`
    else return `/data_api/recipient_libraries/${id}/copy`
  },
  createCopyParams(state) {
    const zoneIds = state.zones.filter(z => z.selected).map(z => z.id)

    let params = {}
    params[state.paramName] = {
      'zone_ids': zoneIds,
      'id': state.libraryId
    }

    return params
  }
}