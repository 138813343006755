
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  requests: [],
})

export const state = getDefaultState

export const getters = {
  getField,
  allRequestsComplete(state) {
    return state.requests.length == 0
  },
  requestsUnderway(state) {
    return state.requests.length > 0
  }
}

export const mutations = {
  updateField,
  addRequest(state, requestUrl) {
    let formattedUrl = requestUrl.replace(/^.*\/\/[^\/]+/, '')

    state.requests.push(formattedUrl)
  },
  completeRequest(state, requestUrl) {
    let formattedUrl = requestUrl.replace(/^.*\/\/[^\/]+/, '')
    let index = state.requests.indexOf(formattedUrl);
    if (index > -1)  state.requests.splice(index, 1);
  }
}