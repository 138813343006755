<template lang="pug">
  ul
    li(v-for="item in rowData[rowField]") {{ item }}
</template>

<script>
export default {
  props: ["rowData", "rowField"]
}
</script>

<style scoped>
ul {
  padding: 0;
  list-style: none;
}
</style>
