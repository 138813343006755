<template lang="pug">
  div
    Breadcrumbs(:breadcrumbs="breadcrumbs",
              :truncateTo="25",
              v-if="!isScrolledDown")
    h1 {{ 'where_do_you_want_to_go' | translate }}
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('contentWithHeader',['isScrolledDown']),
    ...mapFields('job',['job']),
    ...mapFields('jobRevision',['jobRevisionBreadcrumbs']),
    ...mapGetters('jobRevision', ['getRevisionBreadcrumb']),
    breadcrumbs() {
      return this.jobRevisionBreadcrumbs
    }
  }
};
</script>
