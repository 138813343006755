<template lang='pug'>
  transition(name='modal')
    .modal-component
      .modal-overlay(@click="overlayClicked")
        .modal-container-container.container-fluid.d-flex.flex-column.justify-content-center.align-items-center
          .modal-container.d-flex.flex-column(ref='modal', :style="modalStyle")
            .edn-modal-header(v-if="showHeader")
              .d-flex.justify-content-between
                h3.text-truncate {{ modalTitle }}
                slot(name="closeAction")
                  CloseButton(@click="$emit('close')")

              p(v-if="description", :class="{ 'text-truncate': !wrapDescription }") {{ description }}
            .edn-modal-body.loader-container(v-if="showLoader")
              .loader-spinner
            template(v-else)
              .edn-modal-body(:class="bodyClass")
                slot
              .edn-modal-footer.d-flex.justify-content-end.flex-wrap
                slot(name="actions")
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import CloseButton from "~/components/CloseButton";

export default {
  components: { CloseButton },
  props: {
    overflow: {
      default: "scroll"
    },
    dismissOutside: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String
    },
    description: {
      type: String
    },
    maxWidth: {
      type: String
    },
    minWidth: {
      type: String
    },
    wrapDescription: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    overlayClicked() {
      if (this.dismissOutside) this.$emit("close");
    }
  },
  created() {
    disableBodyScroll(this.$el);
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  computed: {
    showHeader() {
      return Boolean(this.modalTitle);
    },
    bodyClass() {
      return {
        ["pt-3"]: this.showHeader,
        overflowScroll: this.overflow === "scroll",
        overflowVisible: this.overflow === "visible"
      };
    },
    modalStyle() {
      let style = "";
      if (this.maxWidth) style = style + `max-width: ${this.maxWidth};`;
      if (this.minWidth) style = style + `min-width: ${this.minWidth};`;
      return style;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/custom.scss";
@import "~/assets/stylesheets/loader.css";

.modal-component {
  display: flex;
}

.modal-container-container {
  width: 100vw;
  height: 100vh;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black6;
  transition: opacity 0.3s ease;
}

.modal-container {
  background-color: $white;
  box-shadow: 0 2px 8px $black7;
  transition: all 0.3s ease;
  min-height: 0;
  max-height: 100vh;
  max-width: 100vw;
  width: auto;
  padding-top: $spacer;
  padding-bottom: $spacer;
}

@include media-breakpoint-down(md) {
  .modal-container {
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw !important;
    min-width: 0 !important;
  }

  .edn-modal-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding-bottom: $spacer;
    background-color: $white;
  }

  .edn-modal-body {
    padding-bottom: 5rem !important;
  }
}

.edn-modal-header {
  display: block;
  padding-bottom: 0;
  margin-right: $spacer;
  margin-left: $spacer;
  border-bottom: 1px solid $gray-400;

  p {
    margin-bottom: 0.5rem;
  }
}

.edn-modal-body {
  padding-bottom: 1rem;
  padding-right: $spacer;
  padding-left: $spacer;

  &.loader-container {
    min-width: 10rem;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;

    .loader-spinner {
      position: static;
      margin: 0;
    }
  }

  &.overflowScroll {
    overflow: auto;
  }

  &.overflowVisible {
    overflow: visible;
  }
}

.edn-modal-footer {
  padding-right: $spacer;
  padding-left: $spacer;
}


/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>