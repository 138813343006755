import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  passImageChoice: 'none',
  optionalImage: false,
  requiredImage: false,
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question).then(QuestionsHelper.setPassImageChoice(state))
  },
  setPassImageChoice(state, params) {
    params["image_choice"] = state.passImageChoice
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_pass_fail_question', name: 'passFail'}, { root: true })
  },
  async configureInitState({ dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', true, { root: true })
  },
  async configureParams({ dispatch, commit }, params) {
    commit("setPassImageChoice", params)
    dispatch("questions/editQuestions/configureCommonParams", params, { root: true })
  }
}