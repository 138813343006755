import { getField, updateField } from 'vuex-map-fields';
import Vue from "vue"
import cloneDeep from "lodash/cloneDeep"

const getDefaultState = () => ({
  barcodeVerification: {
    can_skip_verification: null,
    scan_mode: null,
    part_label: null,
    shipping_label: null,
    total_matched_part_labels: null,
    job_default_quantity: null,
  },
  mappings: [],
  barcodeMappingBeingEdited: null,
  partMask: null,
  shippingMask: null,
  gateway: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData) {
    ({
      work_item_label_verification: state.barcodeVerification,
      shipping_mask: state.shippingMask,
      part_mask: state.partMask,
      mappings: state.mappings
    } = responseData)

    if (!state.barcodeVerification.shipping_label)
      state.barcodeVerification.shipping_label = I18n.translate("shipping_label");
    if (!state.barcodeVerification.part_label)
      state.barcodeVerification.part_label = I18n.translate("part_label");
    if (!state.mappings)
      state.mappings = [];
  },
  resetState(state, gateway) {
    Object.assign(state, getDefaultState())
    state.gateway = gateway
  },
  editBarcodeMapping(state, id) {
    state.barcodeMappingBeingEdited = cloneDeep(state.mappings.find(b => b.id === id))
    state.barcodeMappingBeingEdited.originalLabel = state.barcodeMappingBeingEdited.work_item_label
  },
  newBarcodeMapping(state) {
    state.barcodeMappingBeingEdited = {
      work_item_label: "",
      acceptable_barcodes: []
    }
  },
  cancelBarcodeMapping(state) {
    state.barcodeMappingBeingEdited = null;
  },
  deleteBarcodeMapping(state, id) {
    state.mappings.splice(state.mappings.findIndex(m => m.id === id), 1);
  },
  updateMapping(state, mapping) {
    let index = state.mappings.findIndex(m => m.id === mapping.id);
    if (index >= 0) {
      state.mappings.splice(state.mappings.findIndex(m => m.id === mapping.id), 1);
      state.mappings.splice(index, 0, mapping);
    }
    else state.mappings.push(mapping);
  }
}

export const getters = {
  getField,
  labelName(state) {
    return state.barcodeVerification.label_name
  }
}

export const actions = {
  init({ commit, state }, { barcodeVerificationId, gateway }) {
    commit("resetState", gateway);
    state.gateway.initGet(barcodeVerificationId)
      .then(({ data }) => {
        commit('initialGetCompleted', data);
      })
  },
  saveBarcodeMapping({ state, commit }) {
    let mappingObject = {
      barcode_mapping: {
        ...state.barcodeMappingBeingEdited,
        work_item_label_verification_id: state.barcodeVerification.id
      }
    }

    return state.gateway.saveBarcodeMapping(mappingObject)
      .then(({ data }) => commit("updateMapping", data))
  },
  deleteBarcodeMapping({ state, commit }, id) {
    return state.gateway.deleteBarcodeMapping(id)
    .then(() => commit("deleteBarcodeMapping", id))
  },
  saveVerification({ state, commit }, {job_id, router}) {
    commit("updateField", { path: "barcodeVerification.deleted", value: false})
    let params = {
      barcode_label_verification: {
        ...state.barcodeVerification
      }
    }

    if (state.shippingMask.useMask) params.barcode_label_verification.shipping_label_mask_attributes = state.shippingMask;
    else if (state.shippingMask.id) params.barcode_label_verification.shipping_label_mask_attributes = { _destroy: true, id: state.shippingMask.id }
    if (state.partMask.useMask) params.barcode_label_verification.part_label_mask_attributes = state.partMask;
    else if (state.partMask.id) params.barcode_label_verification.part_label_mask_attributes = { _destroy: true, id: state.partMask.id }

    state.gateway.savePatch(params).then(() => {
      router.push(`/jobs/${job_id}/edit`)
    })
  }
}