export const getJobFormObject = (data) => {
  const allData = data['job']['data']
  delete data['job']
  return {
    ...allData['attributes'],
    id: allData['id'],
    ...data
  }
}

export const jobObjectForParam = (state, rootState) => {
  let workItemVerification = Object.keys(state.labels).map(k => state.labels[k]);
  workItemVerification = workItemVerification.filter(x => Boolean(x.barcode_verification_settings));
  workItemVerification = workItemVerification.map(x => ({
    _destroy: !x.barcode_verification_settings.verify_barcodes,
    deleted: !x.barcode_verification_settings.verify_barcodes,
    type: x.barcode_verification_settings.type,
    id: x.barcode_verification_settings.id,
    total_matched_shipping_labels: x.barcode_verification_settings.total_matched_shipping_labels,
    total_matched_part_labels: x.barcode_verification_settings.total_matched_part_labels,
    can_skip_verification: x.barcode_verification_settings.can_skip_verification
  }))

  return {
    job: {
      'is_yard_campaign': state.isYardCampaign,
      'allow_manual_barcode_entry': state.allowManualBarcodeEntry,
      'uses_heatmap': state.usesHeatmap,
      'primary_suspect_reporting_mode': state.primarySuspectReportingMode,
      'one_suspect_per_quadrant': state.oneSuspectPerQuadrant,
      'active': state.active,
      'use_qr_code_sign_in': state.useQrCodeSignIn,
      'zone_id': rootState.selects.selectedZones.value,
      'rank_id': rootState.selects.selectedRanks.value,
      'company_name': state.companyName,
      'closed_on': state.closedOn,
      'tag_list': state.currentTags.map(t => t.name).join(", "),
      'suspect_mode': state.suspectMode,
      'iframe_html': state.iFrameHtml,
      'camera_setting_attributes': state.cameraSettings,
      'identifier': state.identifier,
      'customer_identifier': state.customerIdentifier,
      'bin_label': state.labels.bin_label,
      'user_label': state.labels.user,
      'use_signed_in_users': state.labels.use_signed_in_users,
      'work_item_label_verifications_attributes': workItemVerification,
      'part_number_label': state.labels.part_number.label,
      'serial_number_label': state.labels.serial_number.label,
      'serial_number_optional': state.labels.serial_number.optional,
      'lot_number_label': state.labels.lot_number.label,
      'lot_number_optional': state.labels.lot_number.optional,
      'other1_label': state.labels.other1.label,
      'other1_optional': state.labels.other1.optional,
      'other2_label': state.labels.other2.label,
      'other2_optional': state.labels.other2.optional,
      'quantity_label': state.labels.quantity.label_text,
      'default_quantity': state.labels.quantity.default_quantity,
      'hide_quantity_label': state.labels.quantity.hide_quantity_label,
      'hide_ppm': state.hidePpm,
      'hide_inspection_stats': state.hideInspectionStats,
      'show_all_bins': state.showAllBins,
      'downtime_message': state.downtimeMessage,
      'location_acquisition_option_attributes': {
        'parent_id': state.locationAcquisitionOption.parent_id,
        'parent_type': state.locationAcquisitionOption.parent_type,
        'bin_gps_acquisition_option': state.locationAcquisitionOption.bin_gps_acquisition_option,
        'start_questionnaire_gps_acquisition_option': state.locationAcquisitionOption.start_questionnaire_gps_acquisition_option,
        'end_questionnaire_gps_acquisition_option': state.locationAcquisitionOption.end_questionnaire_gps_acquisition_option,
        'id': state.locationAcquisitionOption.id
      },
      'work_area_ids': state.workAreaIds,
      'post_ids': state.postIds,
    }
  }
}