import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue'

AxiosWrapper.init();

const getDefaultState = () => ({
  storeApiUrl: '/data_api/atlas',
  searchInProgress: false,
  storeLockJob: false,
  storeLockRevision: false,
  storePreselectedJob: null,
  storePreselectedJobRevision: null,
  bin_histories: [],
  selected_bin_ids: [],
  sending_email: "",
  report_details: [],
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  searchCompleted(state, getRequestData) {
    ({
      bin_histories: state.bin_histories,
      report_details: state.report_details
    } = getRequestData)

    state.searchInProgress = false
  },
  setAllHistoriesSelected(state){
    state.bin_histories.forEach((history)=>{
      history.selected = true
    })
  },
  setAllHistoriesUnselected(state){
    state.bin_histories.forEach((history) => {
      history.selected = false
    })
  },
  setHistorySelected(state, history){
    Vue.set(history, "selected", true)
    history.selected = true
  },
  setHistoryUnselected(state, history){
    Vue.set(history, "selected", false)
    history.selected = false
  }
}

export const getters = {
  getField,
  selectedBinIds(state) {
    var binIdArray = []

    state.bin_histories.forEach((history) => {
      if (history.selected === true) {
        binIdArray.push(history.bin_id);
      }
    })

    return binIdArray;
  },
  historiesPresent(state){
    return state.bin_histories.length !== 0
  },
  notSearchedYet(state){
    // return state.params.zone === null && state.params.job === null && state.params.job_revision === null && state.params.state === null
    return true
  },
  reportDataString(state){
    return JSON.stringify(state.report_details);
  }
}


export const actions = {
  async init({ state, commit, dispatch }) {
    commit('resetState')

    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
    // dispatch("searchIfParamsPresent", params);
  },
  async searchIfParamsPresent({ dispatch }, params) {
    if (Object.keys(params).length > 0) {
      dispatch("getCallForSearch", params)
    }
  },
  async prepAndSearch({ commit, state, dispatch, rootGetters }, paramsObject) {
    let params = {
      ...ConfigHelper.mapParams(paramsObject, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'AtlasSearch', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async search({ commit, state, dispatch, rootState }) {
    dispatch("prepAndSearch", rootState.selects.params)
  },
  async getCallForSearch({ commit }, params) {
    AxiosWrapper.get(`/data_api/atlas`, { params: params }).then((response) => {
      commit("searchCompleted",response.data);
    })
  },
  setLoader({ commit }, value) {
    commit("updateField", {
      path: "searchInProgress",
      value: value
    })
  },
  selectAll({state, commit, dispatch}){
    commit('setAllHistoriesSelected');
  },
  selectNone({state, commit, dispatch}){
    commit('setAllHistoriesUnselected');
  },
  emailLocations({commit, state, dispatch}){
    var emailUrl = 'data_api/send_locations'

    var selectedBins = []

    state.bin_histories.forEach(history=>{
      if (history.selected){
        selectedBins.push(history.bin_id)
      }
    })

    AxiosWrapper.patch(emailUrl, {bin_ids: selectedBins, email: state.sending_email}).then((data) =>{
      dispatch('errorMethods/defaultSaveSuccess', data, { root: true })
    }).catch(error =>{
      dispatch('errorMethods/defaultError', error, { root: true })
    });
  },
  updateSelectedHistoryItem({state,commit}, history) {
    if (history.selected === false) {
      commit('setHistorySelected', history);
    } else {
      commit('setHistoryUnselected', history);
    }
  }
}