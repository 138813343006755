import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import groupBy from 'lodash/groupBy'
import { translate } from '~/assets/javascript/localization/Localization';
AxiosWrapper.init();

import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  referencePictures: {},
  partImages: {},
  defectCodeModels: [],
  currentUser: {},
  job: {},
  jobRevision: {},
})

export const state = getDefaultState

export const getters = {
  getField,
  referencePictures: state => {
    return groupBy(state.referencePictures, 'category')
  }
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateReferencePicture(state, { referencePicture }) {
    Vue.set(state.referencePictures, referencePicture.id, referencePicture)
  },
  removeReferencePicture(state, referencePictureId) {
    Vue.delete(state.referencePictures, referencePictureId)
  },
  updatePartImage(state, { partImage }) {
    Vue.set(state.partImages, partImage.id, partImage)
  },
  removePartImage(state, partImageId) {
    Vue.delete(state.partImages, partImageId)
  },
  clearDefaultPartImages(state){
    for ( let id of Object.keys(state.partImages)){
      Vue.set(state.partImages[id], 'is_default', false)
    }
  },
  insertNoneOption(state){
    state.defectCodeModels = [{name: translate('none'), defect_codes:[{id: null, name: translate('none')}]}, ...state.defectCodeModels]
  }
}
export const actions = {
  async init({ state, commit, dispatch }, params){
    commit("resetState")

    let jobId = AxiosWrapper.getNextPathNameElement("jobs")
    let jobRevisionId = AxiosWrapper.getNextPathNameElement("job_revisions")

    return AxiosWrapper.get(`/data_api/jobs/${jobId}/job_revisions/${jobRevisionId}/reference_pictures`)
      .then( response => {
        commit('updateField', { path: 'job', value: { ...response.data.job } })
        commit('updateField', { path: 'jobRevision', value: { ...response.data.job_revision } })
        dispatch('jobRevision/setJobRevision', response.data.job_revision, { root: true })
        commit('updateField', {path: 'referencePictures', value: {...response.data.reference_pictures}})
        commit('updateField', {path: 'partImages', value: {...response.data.part_images}})
        commit('updateField', {path: 'defectCodeModels', value: response.data.defect_code_models})
        commit('insertNoneOption')
        commit('updateField', {path: 'currentUser', value: response.data.current_user})
      })
  },
  submitReferencePicture({ commit, state }, { referencePicture }) {
    AxiosWrapper.postOrPatch('/data_api/reference_pictures', { reference_picture: referencePicture })
      .then(response => {
        commit('updateReferencePicture', { referencePicture: response.data })
      }
    )
  },
  deleteReferencePicture({ commit }, { referencePicture }) {
    AxiosWrapper.delete(`/data_api/reference_pictures/${referencePicture.id}`)
      .then(response => {
        commit('removeReferencePicture', referencePicture.id)
      }
    );
  },
  submitPartImage({ commit, state }, { partImage }) {
    if (partImage.is_default) {commit('clearDefaultPartImages')}
    AxiosWrapper.postOrPatch('/data_api/part_images', { part_image: partImage })
      .then(response => {
        commit('updatePartImage', { partImage: response.data })
      }
    )
  },
  deletePartImage({ commit }, { partImage }) {
    AxiosWrapper.delete(`/data_api/part_images/${partImage.id}`)
      .then(response => {
        commit('removePartImage', partImage.id)
      }
    );
  },
}