import DatetimeHelper from '~/components/common/stores/DatetimeHelper.js'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  start_date: '',
  end_date: '',
  type: 'date'
})

export const state = getDefaultState

export const getters = {
  getField,
  getStartDate: state => {
    return DatetimeHelper.formattedDateString(state.start_date, state.type);
  },
  getEndDate: state => {
    return DatetimeHelper.formattedDateString(state.end_date, state.type);
  },
  getStartAndEndDate: state => {
    return {
      "start_date": DatetimeHelper.formattedDateString(state.start_date, state.type),
      "end_date": DatetimeHelper.formattedDateString(state.end_date, state.type)
    }
  },
  startDateIsEmpty(state) {
    return state.start_date == ''
  },
  endDateIsEmpty(state) {
    return state.end_date == ''
  }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setType(state, value) {
    state.type = value;
  },
  changeTypeToDatetime(state) {
    state.type = 'datetime';
  },
  setStartDateToEmpty(state) {
    state.start_date = '';
  },
  setEndDateToEmpty(state) {
    state.end_date = '';
  },
  setDatesToEmpty(state) {
    state.start_date = '';
    state.end_date = '';
  }
}

export const actions = {
  async init({ dispatch }, { params = {}, isReportPage = false }) {
    if (Object.keys(params).includes("start_date") || Object.keys(params).includes("end_date")) dispatch('setParams', params)
    else if (isReportPage) dispatch('setDefaultParams', false);
    else dispatch('setDefaultParams', true);
  },
  async setDefaultParams({ dispatch, state }, initializeAsEmpty) {
    if (initializeAsEmpty) dispatch("setEmptyDates")
    else {
      let endDate = new Date();
      let startDate = moment(endDate).subtract(3, 'months');
      if (state.type == 'date') {
        dispatch("setDates", { startDate, endDate })
      } else {
        dispatch("setDatesWithTime", { startDate, endDate })
      }
    }
  },
  async setParams({ commit }, params) {
    if (params.start_date) {
      commit("updateField", {
        path: "start_date",
        value: params.start_date
      })
    }
    if (params.end_date) {
      commit("updateField", {
        path: "end_date",
        value: params.end_date
      })
    }
  },
  setEmptyDates({ commit }) {
    commit("updateField", {
      path: "start_date",
      value: ''
    });
    commit("updateField", {
      path: "end_date",
      value: ''
    });
  },
  setDates({ commit }, { startDate, endDate }) {
    commit("updateField", {
      path: "start_date",
      value: moment(startDate).format('YYYY-MM-DD')
    });
    commit("updateField", {
      path: "end_date",
      value: moment(endDate).format('YYYY-MM-DD')
    });
  },
  setDatesWithTime({ commit }, { startDate, endDate }) {
    commit("updateField", {
      path: "start_date",
      value: moment(startDate).format()
    });
    commit("updateField", {
      path: "end_date",
      value: moment(endDate).format()
    });
  },
  setStartData({ commit }, start_date ) {
    commit("updateField", {
      path: "start_date",
      value: start_date
    });
  },
  setEndData({ commit }, end_date ) {
    commit("updateField", {
      path: "start_date",
      value: end_date
    });
  },
  setDatesToEmpty({ commit }) {
    commit('setDatesToEmpty')
  }
}