export default {
  addEditPermissionsTo(gauge) {
    switch (gauge.type) {
      case "Dashboard::Gauges::AnswersBreakdown":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.answerBreakdownEditPermissions()
        };
        break;
      case "Dashboard::Gauges::Compliance":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.complianceEditPermissions()
        };
        break;
      case "Dashboard::Gauges::CorrectiveAction":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.correctiveActionEditPermissions()
        };
        break;
      case "Dashboard::Gauges::CorrectiveActionResolution":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.correctiveActionResolutionEditPermissions()
        };
        break;
      case "Dashboard::Gauges::ScheduledCompliance":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.scheduledComplianceEditPermissions()
        };
        break;
      case "Dashboard::Gauges::Embed":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.embedEditPermissions()
        };
        break;
      case "Dashboard::Gauges::AuditScore":
        gauge.edit_permissions = {
          ...this.defaultPermissions(),
          ...this.auditScorePermissions()
        }
        break;
      case "Dashboard::Gauges::AuditsPerformed":
        gauge.edit_permissions = {
          ...this.auditsPerformedPermissions()
        }
        break;
      default:
        gauge.edit_permissions = this.defaultPermissions();
    }
  },
  defaultPermissions() {
    return {
      iframe_html: false,
      is_set_to_current_month: false,
      number_of_days_ago: false,
      show_limit: false,
      number_of_days_ahead: false,
      display_style: false,
      answer_breakdown_compliance_by: false,
      is_set_to_current_week: false,
      is_set_to_current_week_and_previous_week: false,
      number_of_months_ago: false,
      is_set_to_current_year: false,
      interval_kind: false,
      interval: false,
      show_empty_records: false,
      show_all_comments: false,
      zones: false,
      jobs: false,
      shift_descriptions: false,
      questionnaire_groups: false,
      questionnaires: false,
      score_calculation_mode: false,
      group_by: false
    };
  },
  answerBreakdownEditPermissions() {
    return {
      answer_breakdown_compliance_by: true,
      show_limit: true,
      ...this.commonDateRangePermissions(),
      ...this.commonFilterPermissions()
    };
  },
  complianceEditPermissions() {
    return {
      ...this.commonDateRangePermissions(),
      ...this.commonFilterPermissions()
    };
  },
  correctiveActionEditPermissions() {
    return {
      display_style: true,
      number_of_days_ahead: true,
      ...this.alternativeDateRangePermissions(),
      ...this.commonDateRangePermissions(),
      ...this.commonFilterPermissions(),
    };
  },
  correctiveActionResolutionEditPermissions() {
    return {
      ...this.alternativeDateRangePermissions(),
      ...this.commonFilterPermissions(),
    }
  },
  scheduledComplianceEditPermissions() {
    return {
      interval_kind: true,
      interval: true,
      show_empty_records: true,
      ...this.commonDateRangePermissions(),
      ...this.commonFilterPermissions(),
    }
  },
  embedEditPermissions() {
    return {
      iframe_html: true,
    }
  },
  auditScorePermissions() {
    return {
      display_style: true,
      score_calculation_mode: true,
      group_by: true,
      is_set_to_current_year: true,
      ...this.commonDateRangePermissions(),
      ...this.commonFilterPermissions(),
    }
  },
  alternativeDateRangePermissions() {
    return {
      is_set_to_current_year: true,
      number_of_months_ago: true,
    }
  },
  auditsPerformedPermissions() {
    return {
      is_set_to_current_month: true,
      is_set_to_current_year: true,
      number_of_days_ago: true,
      is_set_to_current_week_and_previous_week: false,
      group_by: true,
      ...this.commonFilterPermissions(),
    };
  },
  commonDateRangePermissions() {
    return {
      is_set_to_current_month: true,
      is_set_to_current_week: true,
      is_set_to_current_week_and_previous_week: true,
      number_of_days_ago: true
    };
  },
  commonFilterPermissions() {
    return {
      show_all_comments: true,
      zones: true,
      jobs: true,
      shift_descriptions: true,
      questionnaire_groups: true,
      questionnaires: true
    };
  }
};