import AxiosWrapper from "~/assets/javascript/AxiosWrapper";

AxiosWrapper.init();

const getDefaultState = () => ({
  logs: []
})

export const state = getDefaultState

export const getters = {
  logs(state) {
    return state.logs;
  },
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      logs: state.logs,
    } = getRequestedData);
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    let id = parseInt(AxiosWrapper.getSearchParams().licence_id);
    AxiosWrapper.get(`/data_api/licence_logs?licence_id=${id}`, { id }).then(function (response) {
      commit("init", response.data);
    });
  }
}
