import { getField, updateField } from "vuex-map-fields";

export const state = () => ({
  enableDesignHamburger: false,
  selectedOptions: [],
  selectedOptions2: [2, 3],
  selectedOptions3: [],
  selectedOptions4: [3],
  options: [1, 2, 3]
})

export const getters = {
  getField
}

export const mutations = {
  updateField
}