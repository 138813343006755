import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import cloneDeep from "lodash/cloneDeep";
import { createHelpers } from "vuex-map-fields";

const { getEditPanelField, updatePanelField } = createHelpers({
  getterType: "getEditPanelField",
  mutationType: "updatePanelField"
});

const getDefaultState = () => ({
  isEditingPanel: false,
  editingPanel: {
    name: null,
    lessee_id: null,
    panelRef: null
  },
  allLessees: [],
})

export const state = getDefaultState

export const mutations = {
  updatePanelField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setPanelName(state, {panel, new_name}) {
    Vue.set(panel, "name", new_name)
  },
}

export const getters = {
  getEditPanelField
}

export const actions = {
  setClonedPanel({commit, state, rootState}) {
    let panelCopy = cloneDeep(rootState.dashboard.my.panel);
    let editing = {
      name: panelCopy.name,
      lessee_id: panelCopy.lessee_id,
    };
    commit("updatePanelField", {
      path: "editingPanel",
      value: editing
    });
  },
  editPanelName({dispatch, commit, state}) {
    commit('resetState')
    dispatch("setClonedPanel")
    commit("updatePanelField", {
      path: "isEditingPanel",
      value: true
    })
  },
  cancelEditing({commit, state}) {
    let cleanEditing = {
      name: null,
      lessee_id: null,
    }
    commit("updatePanelField", {
      path: "editingPanel",
      value: cleanEditing
    });
    commit("updatePanelField", {
      path: "isEditingPanel",
      value: false
    })
  },
  saveEditedPanel({dispatch, commit, state, rootState}) {
    AxiosWrapper.patch(`/data_api/dashboard/panels/${rootState.dashboard.my.panel.id}`, {
      dashboard_panel: {
        name: state.editingPanel.name,
        lessee_id: state.editingPanel.lessee_id
      }
    }).then(() => {
      commit("setPanelName", {panel: rootState.dashboard.my.panel, new_name: state.editingPanel.name});
      dispatch("cancelEditing")
    })
  },
}