import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField,updateField } from 'vuex-map-fields';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import { translate } from '~/assets/javascript/localization/Localization';
import gtmEvents from "~/assets/javascript/gtmEvents"

const getDefaultState = () => ({
  summaryReportResults: [],
  csvPath: "",
  excelPath: "",
  paramsPath: '',
  confirmingCsvRequest: false,
  exportPath: '',
  selectedStatus: null,
  totalPages: 0,
  total: 0,
  params: {
    items_per_page: 75,
    page: 1,
  },
  statusOptions: [
    { name: translate("in_progress"), value: "in_progress" },
    { name: translate("overdue"), value: "overdue" },
    { name: translate("resolved"), value: "resolved" },
    { name: translate("verified"), value: "verified" }
  ],
  permissions: {
    fields: {
      can_view: true,
      can_edit: false,
      can_delete: false,
      can_create: false
    }
  },
  hasSearched: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  searchCompleted(state, getRequestData) {
    ({
      summary_report_results: state.summaryReportResults,
      excel_path: state.excelPath,
      csv_path: state.csvPath,
      total: state.total,
      total_pages: state.totalPages,
    } = getRequestData)

    if (Object.keys(getRequestData).includes("status")) {
      state.statusOptions.forEach(option => {
        if (getRequestData["status"] == option.value) {
          state.selectedStatus = option
        }
      });
    }
  },
  noResultsFound(state) {
    state.summaryReportResults = []
    state.csvPath = ""
    state.excelPath = ""
    state.paramsPath = ''
    state.totalPages = 0
    state.total = 0
  },
  flattenCorrectiveActionResults(state) {
    var flattenedResults = [];
    state.summaryReportResults.forEach(result => {
      var currentResult = result;
      if (currentResult["tasks"]) delete currentResult["tasks"];
      flattenedResults.push(currentResult);
      if (result["tasks"]) {
        result.tasks.forEach(task => {
          var currentTask = task;
          if (currentTask["subtasks"]) delete currentTask["subtasks"];
          flattenedResults.push(currentTask);
          if (task["subtasks"]) {
            task.subtasks.forEach(subtask => {
              flattenedResults.push(subtask);
            });
          }
        });
      }
    });
    state.summaryReportResults = flattenedResults;
  },
}

export const getters = {
  getField,
  summaryReportPresent(state) {
    return (state.summaryReportResults.length > 0)
  },
  fullCSVDownloadPath() {
    return () => {
      let params = AxiosWrapper.getSearchParams();

      return `/data_api/download_csv/plans${AxiosWrapper.buildSearchUrl(params)}&export_type=csv`
    }
  },
  fullExcelDownloadPath() {
    return () => {
      let params = AxiosWrapper.getSearchParams();

      return `/data_api/download_csv/plans${AxiosWrapper.buildSearchUrl(params)}&export_type=axlsx`
    }
  }
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true })
  },
  async search({ dispatch, state, rootGetters }) {
    gtmEvents.reportGenerated({
      type: "Corrective Actions"
    })
    let params = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      ...state.params,
    }

    if (state.selectedStatus) {
      params["status"] = state.selectedStatus.value
    }

    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'CorrectiveActionsSearch', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async getCallForSearch({ commit, dispatch }, params) {
    AxiosWrapper.get('/data_api/corrective_actions_search', { params }).then(({ data }) => {
      commit("updateField", {path: 'hasSearched', value: true})
      if (data.summary_report_results) {
        commit('searchCompleted', data);
        commit("flattenCorrectiveActionResults")

        commit('updateField', { path: "paramsPath", value: AxiosWrapper.buildSearchUrl(params)})
      } else {
        commit("noResultsFound")
      }
      dispatch("contentWithHeader/update", null, {root: true})
    })
  },
  setPageAndThenGetPage({ dispatch,commit }, page) {
    commit('updateField', {
      path: "params.page",
      value: page
    });
    dispatch('search');
  },
  changeNumberOfItemsPerPage({ commit,dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'params.items_per_page',
      value: itemsPerPage
    });
    dispatch('search');
  },
  generateCSV({getters, commit}) {
    commit('updateField', { path: "exportPath", value: getters['fullCSVDownloadPath']() })
    commit('updateField', { path: "confirmingCsvRequest", value: true})
  },
  generateXLSX({getters, commit}) {
    commit('updateField', { path: "exportPath", value: getters['fullExcelDownloadPath']() })
    commit('updateField', { path: "confirmingCsvRequest", value: true })
  }
}