import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import cloneDeep from 'lodash/cloneDeep'

AxiosWrapper.init();
import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  loginRolesTranslations: [],
  tabletRolesTranslations: [],
  message: null,
  formData: {}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateContent(state, {translation, newContent}) {
    translation.content = newContent
  },
  updateField
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/translations/roles/edit_all`)
      .then(response => {
        commit('updateField', { path: 'loginRolesTranslations', value: response.data.login_roles_translations });
        commit('updateField', { path: 'tabletRolesTranslations', value: response.data.tablet_roles_translations });
      });
  },
  save({ commit, state }, formData) {
    AxiosWrapper.put(`/data_api/translations/roles/update_all`, { translations: state.formData })
      .then(response => {
        commit('updateField', { path: 'message', value: response.data.message });
      })
  },
  addToFormData({ commit, state }, {translation, newContent}) {
    let formDataCopy = cloneDeep(state.formData)
    commit("updateContent", {translation, newContent})
    formDataCopy[translation.id] = translation;
    commit("updateField", { path: "formData", value: formDataCopy });
  }
}