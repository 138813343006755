import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';

const getBase = `/data_api/dashboard/devices`

const getDefaultState = () => ({
  back_to_questionnaire_path: null,
  questionnaire_result_path: null,
  questionnaire_result_show_only_fail_path: null,
  audit_name: null,
  audited_by: null,
  total_severity: null,
  total_score: null,
  total_audit_time: null,
  permissions: {},
  params: null,
  questionnaire_results_id: null,
  available_answers: [],
  questionnaire_id: null
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setState(state) {
    state.params = AxiosWrapper.getSearchParams();
    state.questionnaire_results_id = AxiosWrapper.getNextPathNameElement('questionnaire_results')
    state.questionnaire_id = state.params.questionnaire_id

    state.params['only_fail'] = false
  },
  initDataFromGet(state, getRequestData) {
    ({
      permissions: state.permissions,
      available_answers: state.available_answers,
      audit_name: state.audit_name,
      audited_by: state.audited_by
    } = getRequestData);

    state.total_severity = `${translate('total_severity')}: ${getRequestData.total_severity}`
    state.total_score = `${translate('total_score')}: ${getRequestData.total_score}`
    state.total_audit_time = `${translate('total_audit_time')} (${translate('hour_minute_second')}): ${getRequestData.total_audit_time}`

    var pathWithParams = AxiosWrapper.buildPathWithSearchParams(state.params);
    state.questionnaire_result_path = pathWithParams
    state.questionnaire_result_show_only_fail_path = pathWithParams + `&only_fail=true`

    state.back_to_questionnaire_path = getRequestData.back_to_questionnaire_path + `&questionnaire_id=${state.questionnaire_id}`
  },
}

export const getters = {
  getField,
  backComponentTitle() {
    return translate('back')
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    commit('resetState')
    commit("setState")

    var getUrlItem = `${getBase}/questionnaire_results/${state.questionnaire_results_id}/questionnaire_result`

    AxiosWrapper.get(getUrlItem, { params: state.params }).then(function (response) {
      var pathWithParams = AxiosWrapper.buildPathWithSearchParams(state.params);
      history.replaceState(history.state, 'QuestionnaireResult', pathWithParams);
      commit('initDataFromGet', response.data);
    })
  },
}