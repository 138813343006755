import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

const getBase = `/data_api/dashboard/devices`

const getDefaultState = () => ({
  job_id: null,
  device_identifier_param: "",
  department_options: [],
  selected_department: {},
  grouped_role_options: [],
  selected_role_ids: [],
  message_content: "",
  from_field: "",
  constraintOptions: [],
  selectedConstraintOption: null,
  showComment: false,
  store_errors: {}
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      department_options: state.department_options,
      constraint_options: state.constraintOptions
    } = getRequestData);

    state.selected_department = state.department_options[0]
  },
  initParams(state) {
    var params = AxiosWrapper.getSearchParams()

    state.device_identifier_param = params.device
    state.job_id = AxiosWrapper.getNextPathNameElement('jobs')
  },
  setGroupedRoles(state, getRequestData) {
    ({
      grouped_role_options: state.grouped_role_options,
    } = getRequestData);
  },
  setStoreErrorsItems(state, errors) {
    state.store_errors = errors;
  }
}

export const getters = {
  getField,
  splashPageUrl(state) {
    return `/dashboard/devices?job=${state.job_id}&device=${state.device_identifier_param}`
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    commit('resetState')
    commit('initParams')

    this.$axios.get(`${getBase}/jobs/${state.job_id}/request_assistance?device=${state.device_identifier_param}`).then(function (response) {
      commit('init', response.data);
      dispatch('loadGroupedRoleOptions');
    })
  },
  shouldShowComment({commit, state}, option) {
    if (option == I18n.t("other")) {
      commit("updateField", { path: "showComment", value: true })
      commit("updateField", { path: "message_content", value: "" })
    } else {
      commit("updateField", { path: "showComment", value: false })
      commit("updateField", { path: "message_content", value: option })
    }
  },
  loadGroupedRoleOptions({ commit, state }) {
    commit('updateField', { path: "selected_role_ids", value: [] });
    let roleSelection = `${getBase}/jobs/${state.job_id}/role_selection?department_id=${state.selected_department.id}&device=${state.device_identifier_param}`;
    this.$axios.get(roleSelection).then(function (response) {
      commit('setGroupedRoles', response.data)
    })
  },
  submitAssistanceRequest({ commit, state }) {
    let requestSendUrlBase = `${getBase}/jobs/${state.job_id}/send_request`

    var idOnlyCollection = []
    state.selected_role_ids.forEach(element => {
      idOnlyCollection.push(element.id);
    });

    let params = {
      device: state.device_identifier_param,
      from: state.from_field,
      content: state.message_content,
      role_ids: idOnlyCollection,
      department: state.selected_department.id
    }

    AxiosWrapper.get(requestSendUrlBase, { params }).catch((error) => {
      commit('setStoreErrorsItems', error.response.data);
    })
  }
}