import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  minTolerance: null,
  maxTolerance: null
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question)
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_tolerance_question', name: 'tolerance'}, { root: true })
  },
  async configureInitState({ dispatch }) {},
  async configureParams({ commit }, params) {
    commit('questions/editQuestions/setSuspectOnFailTo', false, { root: true })
    commit('questions/editQuestions/setWeightAttributesOn', params, {root: true})
  }
}