import cloneDeep from 'lodash/cloneDeep'
import snakeCase from 'lodash/snakeCase'

export default {
  isArray(obj) {
    return !!obj && obj.constructor === Array;
  },
  createResetList(state, type) {
    let reset = {
      list: [],
      pruneList() {
        this.list = this.list.filter((value, index, self) => self.indexOf(value) === index)
      },
      processOnSelect(state, type) {
        let currentOnSelects =  cloneDeep(state.config[type].onSelect)
        if(currentOnSelects.length > 0) {
          this.list = this.list.concat(currentOnSelects)
          currentOnSelects.forEach(dependentType => {
            this.processOnSelect(state, dependentType)
          })
        }
      }
    }
    reset.processOnSelect(state, type);
    reset.pruneList()
    var list = cloneDeep(reset.list);
    reset = null;
    return list
  },
  createQueue(state, type) {
    let queue = {
      typeSelected: type,
      level: 0,
      immediateParams: {},
      dependencies: [],
      reduceParams(params, paramsToUse) {
        let reducedParams = {}
        paramsToUse.forEach(function(element) {
          reducedParams[element] = params[element]
        });
        return reducedParams;
      },
      getQueryParam(state, type) {
        return state.config[type].query_param
      },
      getOldValues(state, type) {
        let queryParam = this.getQueryParam(state, type);
        return state.params[queryParam]
      },
      getParamsForInit({params, config}, type) {
        let paramsToUse = config[type].onInit
        return this.reduceParams(params, paramsToUse);
      },
      shouldPopulateSelect(state, type) {
        return state.config[type].conditionalDispatch(state)
      },
      buildParamForType(mixedParams, state, type, alwaysPopulate = false) {
        if(alwaysPopulate || this.shouldPopulateSelect(state, type)) {
          let oldValues = this.getOldValues(state, type);
          let params = this.getParamsForInit({ params: state.params, config: state.config }, type)
          params = {...params, type, oldValues: oldValues}
          const snakedType = snakeCase(type)
          mixedParams[snakedType] = params
        }
      },
      getParamsForLevel(state) {
        let mixedParams = {};
        var types = Object.keys(state.refreshQueue.currentLevel() || {})
        types.forEach(type => {
          this.buildParamForType(mixedParams, state, type, state.isProcessInitial);
        });
        return mixedParams;
      },
      currentLevel() {
        return this.dependencies[this.level]
      },
      oldValuesForType(type) {
        return this.currentLevel()[type]
      },
      getParamValue(state, type) {
        let paramForType = state.config[type].query_param
        return state.params[paramForType]
      },
      meetingRequirements(state, type) {
        return state.config[type].required.every(req => state.params[req])
      },
      canAdd(type) {
        let canAdd = this.meetingRequirements(state, type);
        if(canAdd) {
          this.dependencies.forEach(level => {
            var includes = Object.keys(level).includes(type)
            if(includes){
              canAdd = false
            }
          });
        }
        return canAdd
      },
      updateLevel(types, origLevel, index) {
        var level = cloneDeep(origLevel)

        types.forEach(dependentType =>{
          if(this.canAdd(dependentType)) {
            const currentValue = this.getParamValue(state, dependentType)
            level[dependentType] = currentValue
          }
        })
        this.dependencies[index] = level
      },
      isComplete(){
        return this.level + 1 === this.dependencies.length
      },
      getOnSelectDispatchQueue(state, type) {
        return state.config[type].onSelect
      },
      next() {
        this.level++;
      },
      buildNextLevel(types, index) {
        types.forEach(dependentType =>{
          this.processOnSelect(state, dependentType, queue, index + 1)
        })
      },
      processOnSelect(state, type, queue, index = 0){
        var origLevel = queue.dependencies[index]
        if(!origLevel) origLevel = {}
        let types = this.getOnSelectDispatchQueue(state, type);
        queue.updateLevel(types, origLevel, index);
        queue.buildNextLevel(types, index)
      },
      prune() {
        this.dependencies = this.dependencies.filter(level => Object.keys(level).length !== 0);
      }
    }

    if(this.isArray(type)){
      queue.updateLevel(type, {}, 0);
      queue.buildNextLevel(type, 0)
    }else{
      queue.processOnSelect(state, type, queue);
    }
    queue.prune();
    var cloneQueue = cloneDeep(queue);
    queue = null;

    return cloneQueue;
  },

}