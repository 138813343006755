import AxiosWrapper from "~/assets/javascript/AxiosWrapper";

AxiosWrapper.init();

import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  shift: null,
  job: null,
  jobRevision: null,
  binId: null,
  canEditBinForUser: false,
  saveInProgress: false,
})

export const state = getDefaultState

export const getters = {
  getField,
  shiftUrl(state) {
    if (state.shift) {
      return `/jobs/${state.job.id}/job_revisions/${state.jobRevision.id}/date_panel/${state.shift.id}`
    } else {
      return ''
    }
  },
  persisted() {
    return state.binId != undefined
  },
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")
  },
}