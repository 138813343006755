import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { translate } from '~/assets/javascript/localization/Localization';

AxiosWrapper.init();
import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  ndas: [],
  loadingMessage: translate("no_data_available"),
  invalidTitle: true,
  invalidFileName: true,
  saveMode: false,
  sortOrder: [
    {
      field: "created_at",
      sortField: "created_at",
      direction: "desc"
    }
  ],
  saveInProgress: false,
  formData:null,
  editingNda: null,
  showModal:false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  update(state,createdNda) {
    Vue.set(state.ndas, state.ndas.indexOf(state.editingNda), createdNda);
    state.editingNda = null
  },
  addNewNDAItem(state){
    state.formData = {
      title: null,
      filename: null,
      file: null
    }
    state.showModal = true
  },
  editExistingNDAItem(state, {ndaItem, rowData}){
    state.formData = {
      id: ndaItem.id,
      title: ndaItem.title,
      filename: ndaItem.filename,
      file: ndaItem.file
    }
    state.editingNda = rowData
    state.showModal = true
  },
  updateFormDataWithFile(state, file){
    state.formData.file = file
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/ndas`).then(response => {
      commit("updateField", { path: "ndas", value: response.data.ndas });
    });
  },
  async save({ commit}, formData) {
    commit('updateField', { path: 'saveMode', value: true })
    commit("updateField", { path: "saveInProgress", value: true })
    AxiosWrapper.postOrPatch("/data_api/ndas", {
      nda: formData
    }).then(response => {
      let createdNda = response.data.nda;
      commit("update", createdNda);
      commit("updateField", { path: "saveInProgress", value: false })
      commit('updateField', { path: 'saveMode', value: false })
    });
    commit('updateField', {path:'showModal', value:false})
    commit('updateField', { path: 'formData', value: null })
  },
  addNewNDA({commit}) {
    commit('addNewNDAItem')
  },
  editNDA({commit},{ndaItem,rowData}) {
    commit('editExistingNDAItem',{ndaItem,rowData})
  },
  addFile({commit}, file){
    commit('updateFormDataWithFile', file)
  }
}