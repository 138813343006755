import { translate } from '~/assets/javascript/localization/Localization'
import isArray from 'lodash/isArray'
import camelCase from 'lodash/camelCase'

export default {
  getSelectValues(selected, value = "value") {
    if (isArray(selected)) {
      let mapSelected = selected.map(x => x[value]);
      if (mapSelected.length === 0) return null
      if (mapSelected.length === 1) return mapSelected[0]
      return mapSelected
    } else if (selected == null) {
      return null
    }
    return selected[value]
  },
  createAllSelect(response, name = 'name') {
    this.createSelect(response, [{ label: translate("all"), value: '' }], name, includeAllAttributes);
  },
  createSelect(response, arrayToMerge = [], name = 'name') {
    let select = arrayToMerge.concat(response.map(x => {
      return { name: x.attributes[name], value: x.id, attributes: x.attributes }
    }));
    select.sort(function (a, b) {
      let nameA = a[name].toUpperCase();
      let nameB = b[name].toUpperCase();

      if (!isNaN(a[name])) {
        nameA = +a[name]
        nameB = +b[name]
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return select;
  },
  capitalizeType(type) {
    return type.charAt(0).toUpperCase() + type.slice(1)
  },
  getSelectedName(type) {
    let capitalizeType = this.capitalizeType(type)
    return `selected${capitalizeType}`;
  },
  getParamPath(state, type) {
    return `params.${state.config[type].query_param}`
  },
  getNamePath(type) {
    return `names.${type}`
  },
  selectedName(type) {
    return this.getNamePath(type)
  },
  selectedParam(state, type) {
    return this.getParamPath(state, type);
  },
  selectedValueName(state, type) {
    let selectedName = this.getSelectedName(type);
    let value = this.getSelectValues(state[selectedName], 'name')
    let path = this.getNamePath(type)

    return { path, value }
  },
  selectedValueParam(state, type) {
    let value = state.config[type].getValues(state)

    let path = this.getParamPath(state, type);
    return { path, value }
  },
  returnSelectOptions(state, type, oldValues, valueField = "value") {
    let values = []
    let selectList = state[type]

    if (state.config[type].hasOwnProperty('customIdentifier')) {
      valueField = state.config[type].customIdentifier
    }

    if (isArray(oldValues)) {
      if (oldValues.length === 0) return [];
      values = values.concat(oldValues)
    } else {
      if (oldValues == null) return [];
      values.push(oldValues)
    }

    return selectList.filter(x => {
      let valueAsInt = +x[valueField]
      let valueAsText = x[valueField] + ""

      return values.includes(valueAsInt) || values.includes(valueAsText)
    })
  },
  createCompressedParamsFrom(params) {
    if (Object.keys(params).length == 0) return null
    let compressedParams = {
      ids: {},
      ...params
    }

    Object.keys(compressedParams).forEach(param => {
      if (param != 'device') compressedParams[param]["onInit"] = []
      delete compressedParams[param]["type"]
      Object.keys(compressedParams[param]).forEach(key => {
        if (key.indexOf("_ids") > -1) {
          compressedParams["ids"][key] = compressedParams[param][key]
          compressedParams[param]["onInit"].push(key)
          if (compressedParams[param]["oldValues"] == null) delete compressedParams[param]["oldValues"]
          delete compressedParams[param][key]
        }
      });
    })

    return compressedParams
  },
  createFilteredParams(params, components) {
    const filteredParams = this.getFilteredParamsObject(params, components)
    if (Object.keys(params).includes('device')) return { ...filteredParams, device: params['device'] }
    else return filteredParams
  },
  getFilteredParamsObject(params, components) {
    const filtered = Object.keys(params)
      .filter(key => components.includes(camelCase(key).replace(/S$/, 's')))
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {})

    return filtered
  },
  getJobLabel(jobs, label) {
    let label_key = `${label}_label`

    let foundLabel = ""
    if (this.isObject(jobs) && this.hasAttributesKey(jobs)) foundLabel = jobs.attributes[label_key]
    else if (this.isArrayWithLengthOne(jobs) && this.hasAttributesKey(jobs, true)) foundLabel = jobs[0].attributes[label_key]

    if (foundLabel && foundLabel.length > 0) return foundLabel
    return translate(label)
  },
  hasAttributesKey(obj, array = false) {
    if (array) return obj[0].attributes != undefined
    else return obj["attributes"] != undefined
  },
  isObject(obj) {
    return obj && (typeof obj === 'object');
  },
  isArrayWithLengthOne(obj) {
    return obj && (isArray(obj)) && obj.length == 1;
  },
  containsValuesForLabel(state, label) {
    //ToDo: Compare this to hasLabel in ConfigHelper.js.  I think it checks for more.
    return state[label] != null && isArray(state[label]) && state[label].length > 0
  }
};
