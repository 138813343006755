<template lang="pug">
  .d-flex.justify-content-between(v-if="user")
    div
      Breadcrumbs(:breadcrumbs="breadcrumbs",
                :truncateTo="35",
                v-if="!isScrolledDown")
      h1 {{ title }}
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('header',['isScrolledDown']),
    ...mapFields('job',['job']),
    ...mapFields('jobRevision', ['jobRevision']),
    ...mapGetters('jobRevision', ['getRevisionBreadcrumb']),
    ...mapGetters('login', ['isCustomer']),
    ...mapFields('login', ['user']),
    title() {
      if (this.isCustomer) return this.translate('suspects')

      return `${this.job['labels']['bin_label']} ${this.translate('suspects')}`
    },
    breadcrumbs() {
      if (!this.isCustomer && this.jobRevision) {
        return [
          { text: this.translate('revisions'), path: `/jobs/${this.job['id']}/job_revisions`},
          { text: this.getRevisionBreadcrumb, path: `/jobs/${this.job['id']}/job_revisions/${this.jobRevision['id']}` },
          { text: this.title },
        ]
      }
      return []
    }
  }
};
</script>