<template lang="pug">
  .d-flex.justify-content-md-between.mb-2(v-if='job')
    div
      Breadcrumbs(:breadcrumbs="breadcrumbs",
                :truncateTo="25",
                v-if="!isScrolledDown")
      h1.m-0 {{ 'import' | translate }}
    .d-flex.align-items-center(v-if="isScrolledDown")
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"

export default {
  name: "ImportVerificationWorkItemsHeader",
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('header',['isScrolledDown']),
    ...mapGetters("verification_work_items/import", [
      "breadcrumbs",
      "description"
    ]),
    ...mapFields("verification_work_items/import", [
      "job",
      "jobRevision"
    ])
  }
}
</script>
