import AxiosWrapper from "~/assets/javascript/AxiosWrapper.js";
import { updateField, getField } from "vuex-map-fields";
import { Settings } from "luxon";
import cloneDeep from "lodash/cloneDeep";

export const state = () => ({
  languages: [],
  ndaUrl: "",
  storeErrors: {},
  user: null,
  walkMeIdentifier: null,
  locale: "",
  dashboards: [],
  customEmbeds: [],
  supportUrl: "",
  savedPath: null,
  firstLoggedIn: false,
  lessee: null,
  multipleLessees: false,
  selectsPresets: [],
  showLoginModal: false,
  shouldReloadOnLoginAgain: true
})

function deconstructSession(state, sessionData) {
  ({
    user: state.user,
    language: state.locale,
    languages: state.languages,
    support_url: state.supportUrl,
    dashboards: state.dashboards,
    selects_presets: state.selectsPresets,
    lessee: state.lessee,
    multipleLessees: state.has_multiple_lessees,
    custom_embeds: state.customEmbeds
  } = sessionData);

  if (state.user !== null && state.user !== undefined && state.lessee !== null && state.lessee !== undefined) {
    // state.walkMeIdentifier = "[" + state.lessee.name + "] id: " + state.user.id;
  }

}

export const getters = {
  getField,
  customEmbeds(state) {
    return state.customEmbeds
  },
  canApproveTimeSheets(state, getters) {
    return getters.user ? getters.user.can_approve_timesheets : false;
  },
  questionnaireResultsPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  shiftPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  binPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  devicePermissions(state, getters) {
    let perms = getters.defaultAdminOrZonePermissions;
    perms.can_view = getters.isAdminOrZone;
    return perms;
  },
  userPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  zonePermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  qualityAlertPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  suspectCodeModelPermissions(state, getters) {
    return getters.defaultAdminOrZonePermissions;
  },
  defaultAdminOrZonePermissions(state, getters) {
    return {
      can_edit: getters.isAdminOrZone,
      can_delete: getters.isAdminOrZone,
      can_create: getters.isAdminOrZone,
      is_corrective_action_specialist: getters.isCorrectiveActionSpecialist,
      user_id: getters.userId
    };
  },
  hasMultipleLessees(state) {
    return state.multipleLessees;
  },
  lessee(state) {
    return state.lessee || {};
  },
  user(state) {
    return state.user || {};
  },
  userId(state) {
    let user = state.user;
    return user ? user.id : null;
  },
  roles(state, getters) {
    return getters.user.roles || {};
  },
  hasDevice() {
    return AxiosWrapper.getSearchParams().device !== undefined
  },
  isCustomer(state, getters) {
    return getters.roles.is_customer;
  },
  isSuperuser(state, getters) {
    return getters.roles.is_superuser;
  },
  isAdministrator(state, getters) {
    return getters.roles.is_administrator || getters.roles.is_superuser;
  },
  isAdminOrZone(state, getters) {
    return getters.isAdministrator || getters.isZoneManager;
  },
  isZoneManager(state, getters) {
    return getters.roles.is_zone_manager;
  },
  isRepairSpecialist(state, getters) {
    return getters.roles.is_repair_specialist;
  },
  isCorrectiveActionSpecialist(state, getters) {
    return getters.roles.is_corrective_action_specialist;
  },
  isViewOnly(state, getters) {
    return getters.roles.is_view_only;
  },
  isTimeSheetAdministrator(state, getters) {
    return getters.roles.is_time_sheet_administrator;
  },
  isBillingAdministrator(state, getters) {
    return getters.roles.is_billing_administrator
  },
  isGlobalAdministrator(state, getters) {
    return getters.roles.is_global_administrator
  },
  userRoles(state, getters) {
    return {
      is_customer: getters.isCustomer,
      is_administrator: getters.isAdministrator,
      is_superuser: getters.isSuperuser,
      is_zone_manager: getters.isZoneManager,
      is_repair_specialist: getters.isRepairSpecialist,
      is_corrective_action_specialist: getters.isCorrectiveActionSpecialist,
      is_view_only: getters.isViewOnly,
      is_time_sheet_administrator: getters.isTimeSheetAdministrator,
      is_billing_administrator: getters.isBillingAdministrator,
      is_global_administrator: getters.isGlobalAdministrator
    };
  },
  currentLanguage(state) {
    return state.current_language;
  },
  languages(state) {
    return state.languages;
  },
  ndaUrl(state) {
    return state.ndaUrl;
  },
  storeErrors(state) {
    return state.storeErrors;
  },
  userName(state) {
    let user = state.user;
    return user ? `${user.first_name} ${user.last_name}` : null;
  },
  userLastFirst(state) {
    let user = state.user;
    return user ? `${user.last_name}, ${user.first_name}` : null;
  },
  isLoggedIn(state) {
    return Boolean(state.user);
  },
  isDevice(state) {
    var params = AxiosWrapper.getSearchParams();

    return params.device;
  },
  locale(state) {
    return state.locale;
  },
  currentLanguage(state) {
    return state.locale ? state.locale.long : "";
  },
  supportUrl(state) {
    return state.supportUrl;
  },
  dashboards(state) {
    return state.dashboards;
  },
  firstLoggedIn(state) {
    return state.firstLoggedIn;
  },
  isLoggedIn(state) {
    return Boolean(state.user);
  }
};

export const mutations = {
  updateField,
  init(state, getRequestData) {
    ({ nda_url: state.ndaUrl } = getRequestData);
    deconstructSession(state, getRequestData);
  },
  initState(state, data) {
    state.firstLoggedIn = true;
    deconstructSession(state, data);

    if (data.user) {
      let gtmData = {
        user_id: data.user.id,
        lessee_id: data.lessee.id,
        user_web_role: data.user.roles
      };

      Object.keys(data.user.roles).forEach(k => {
        if (data.user.roles[k] && gtmData.user_web_role !== "is_superuser") {
          gtmData.user_web_role = k;
        }
      })

      window.dataLayer.push(gtmData);
    }
  },
  setLocale(state, language) {
    state.locale = language;
    I18n.locale = state.locale.short;
    Settings.defaultLocale = I18n.locale;
  },
  setStoreErrors(state, errors) {
    state.storeErrors = errors;
  },
  setUserToNull(state) {
    state.user = null;
  },
  addDashboard(state, { id, name }) {
    state.dashboards.push({ id, name });
  },
  setFirstLoggedIn(state, value) {
    state.firstLoggedIn = value;
  },
  setShowLogin(state, value) {
    state.showLoginModal = value
  },
  addSelectsPreset(state, preset) {
    state.selectsPresets.push(preset);
  },
  deleteSelectsPreset(state, presetId) {
    state.selectsPresets = state.selectsPresets.filter(p => p.id !== presetId);
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  removePanel(state, panelId) {
    state.dashboards.splice(
      state.dashboards.findIndex(p => p.id === panelId),
      1
    )
  }
};

export const actions = {
  async init({ state, commit }) {
    if (this.$axios) AxiosWrapper.init(this.$axios);
    else AxiosWrapper.init();

    AxiosWrapper.get("/data_api/login").then(function (response) {
      commit("init", response.data);
      commit("setLocale", state.locale);
    });
  },
  resetTimer({ commit, getters }) {
    if (process.env.ENV === 'staging' || process.env.ENV === 'production') {
      if (this.sessionTimeoutID) clearTimeout(this.sessionTimeoutID);
      this.sessionTimeoutID = setTimeout(() => {
        if (getters["isLoggedIn"] && !state.setShowLogin) {
          commit("setShowLogin", true);
          commit("updateField", { path: "shouldReloadOnLoginAgain", value: false })
        }
      }, 1501000)
    }
  },
  restoreSession({ state, commit, dispatch }) {
    AxiosWrapper.init(this.$axios);
    return AxiosWrapper.get("/data_api/login/restore_session").then(
      ({ data }) => {
        commit("initState", data);
        commit("setLocale", state.locale);
        commit("selects/resetStateWithDefaultApi", null, { root: true })
      },
      response => {
        if (response.status) dispatch("logout");
      }
    );
  },
  loginAgain({ commit, state }, user) {
    return AxiosWrapper.patch('/data_api/login/go?relogin=true', user)
      .then(() => {
        if (state.shouldReloadOnLoginAgain) {
          window.location.reload();
        } else {
          commit("updateField", { path: "shouldReloadOnLoginAgain", value: true })
        }
      })
      .catch((error) => {
        if (error && error.response) commit('setStoreErrors', error.response.data);
        return Promise.reject(error)
      });
  },
  loginAs({ commit }, user) {
    return AxiosWrapper.patch('/data_api/login/go', user)
      .then(({ data }) => {
        commit("initState", data);
      })
      .catch(error => {
        if (error && error.response) {
          commit("setStoreErrors", error.response.data);
        }
      });
  },
  logout({ commit }, { pathToSave }) {
    AxiosWrapper.init(this.$axios);
    return AxiosWrapper.patch("/data_api/login/bye").then(() => {
      commit("initState", {});
      commit("updateField", { path: "savedPath", value: null });
      window.localStorage.clear();
      if (pathToSave) window.localStorage.setItem("savedPath", pathToSave);
      window.location.replace("/login") // To get rid of lingering store data and variables
    });
  },
  changeLanguage({ commit }, language) {
    AxiosWrapper.patch(`data_api/change_language/${language.id}`)
      .then(({ data }) => {
        commit('setLocale', data.language);
        history.replaceState(history.state, "Jobs", "/")
        window.location.reload();
      }
      );
  },
  recoverEmail({ commit }, email) {
    AxiosWrapper.patch("/data_api/send_recovery_email", { email: email }).catch(
      error => {
        commit("setStoreErrors", error.response.data);
      }
    );
  },
  hasSession() {
    AxiosWrapper.init(this.$axios);
    return AxiosWrapper.get("/data_api/login/has_session");
  },
  saveSelectsPreset({ commit, rootState, rootGetters }, newPresetName) {
    return this.$axios
      .post(`/data_api/selects_presets`, {
        selects_preset: {
          name: newPresetName,
          select_params: {
            ...cloneDeep(rootState.selects.params),
            ...rootGetters["selects/prefixedCustomValues"]
          },
          date_params: {
            start_date: rootState.datetime.start_date,
            end_date: rootState.datetime.end_date
          }
        }
      })
      .then(({ data }) => commit("addSelectsPreset", data));
  },
  deleteSelectsPreset({ commit }, presetId) {
    return this.$axios
      .delete(`/data_api/selects_presets/${presetId}`)
      .then(() => commit("deleteSelectsPreset", presetId));
  }
};
