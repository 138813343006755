import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'

const getDefaultState = () => ({
  questionnaires: null,
  can_user_create_questionnaires: null,
  can_user_edit_questionnaires: null,
  can_user_delete_questionnaires: null,
  questionnaires_scope_labels: null,
  questionnaires_configurations: null,
  questionnaire_group: null,
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setStateFromResponse(state, res) {
    Object.keys(state).forEach(key => state[key] = res[key])
  },
  deleteQuestionnaire(state, questionnaire) {
    state.questionnaires.splice(state.questionnaires.indexOf(questionnaire), 1)
  },
  setConfiguationDisbaledToTrue(state, questionnaire) {
    questionnaire.configuration.questionnaire_configuration.disabled = true
  },
  setActiveToYesOrNo(state) {
    state.questionnaires.forEach(q => {
      if (q.active) q.isActive = "Yes"
      else q.isActive = "No"
    })
  },
  setAccessToPublicOrPrivate(state) {
    state.questionnaires.forEach(q => {
      if (q.private) q.accessAttribute = "Private"
      else q.accessAttribute = "Public"
    })
  },
  attachAttributesToQuestionnaires(state) {
    let questionnaires = state.questionnaires
    for (const [i] of questionnaires.entries()) {
      questionnaires[i].auditScopeLabel = state.questionnaires_scope_labels[i]
      questionnaires[i].configuration = state.questionnaires_configurations[i]
    }
  },
  updateField
}

export const getters = {
  getField,
  newPath(state) {
    if (state.questionnaire_group) return `/audit/questionnaire_groups/${state.questionnaire_group.id}/questionnaires/new`
  },
  editQuestionnaireUrl: (state) => (id) => {
    return `/audit/questionnaires/${id}/edit`
  },
  showDisableRandomButton: (state) => (questionnaire) => {
    if (questionnaire.configuration) return !questionnaire.configuration.questionnaire_configuration.disabled;
    return false
  },
  breadcrumbs(state) {
    return [
      {text: translate('questionnaire_groups'), path: '/audit/questionnaire_groups'},
      {text: translate('questionnaires') }
    ]
  }
}

export const actions = {
  async init({ state, commit, dispatch }, params) {
    const questionnaireGroupId = params['id']
    AxiosWrapper.get(`/data_api/audit/questionnaire_groups/${questionnaireGroupId}/get_data_for_questionnaires_index`).then(({ data }) => {
      commit('setStateFromResponse', data)
      commit('setActiveToYesOrNo')
      commit('setAccessToPublicOrPrivate')
      commit('attachAttributesToQuestionnaires')
      dispatch('permutation/audit/questionnaireConfigurationForm/showRandomConfigComponentIfPresentInUrl', state.questionnaires, { root: true })
    })
  },
  async deleteQuestionnaire({ commit }, questionnaire) {
    if (confirm(translate("are_you_sure"))) {
      commit("deleteQuestionnaire", questionnaire)
      AxiosWrapper.delete(`/data_api/audit/questionnaires/${questionnaire.id}`)
    }
  },
  async disableRandomButtonPressed({ commit }, questionnaire) {
    let disableRandomConfigurationUrl = `/data_api/questionnaires/${questionnaire.id}/permutation/audit/questionnaire_configurations/disable`
    AxiosWrapper.delete(disableRandomConfigurationUrl).then(commit('setConfiguationDisbaledToTrue', questionnaire))
  },
  async questionnairePostionUpdated({ state }) {
    const updatePositionUrl = `/audit/questionnaire_groups/${state.questionnaire_group.id}/questionnaires/update_position`
    let updatedQuestionnairesList = {}
    state.questionnaires.forEach((questionnaire, index) => {
      let id = questionnaire.id;
      let positionObject = {}
      positionObject[id] = index
      updatedQuestionnairesList[index] = positionObject
    })
    AxiosWrapper.patch(updatePositionUrl, { positions: updatedQuestionnairesList })
  },
}