<template lang="pug">
  .d-flex.justify-content-between
    div
      Breadcrumbs(:breadcrumbs="breadcrumbs",
                :truncateTo="35",
                v-if="!isScrolledDown")
      h1 {{ title }}
    .d-flex.justify-content-end.align-items-center(v-if="true")
      button.btn.btn-primary(@click="$eventBus.$emit('binForm-SaveChangesClicked')") {{ translate('save_changes') }}
      button.btn.btn-primary.ml-2(@click="$eventBus.$emit('binForm-SaveChangesClicked', true)"
                                        v-if="!persisted") {{ translate('save_and_create_new')}}
</template>

<script>
import Breadcrumbs from "~/components/Breadcrumbs"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapFields('header',['isScrolledDown']),
    ...mapFields('bins/binForm', ['canEditBinForUser']),
    ...mapFields('job',['job']),
    ...mapFields('jobRevision', ['jobRevision', 'shift', 'jobRevisionBreadcrumbs']),
    ...mapGetters('jobRevision', ['getRevisionBreadcrumb', 'geShiftBreadcrumb']),
    ...mapGetters('bins/binForm', ['shiftUrl']),
    persisted() {
      return this.$route.params.bin_id != 'new'
    },
    title() {
      return this.job['labels']['bin_label']
    },
    breadcrumbs() {
      if (this.jobRevision && this.shift) {
        return [
          ...this.jobRevisionBreadcrumbs,
          { text: this.translate('shifts'), path: `/jobs/${this.job['id']}/job_revisions/${this.jobRevision['id']}/shifts` },
          { text: this.geShiftBreadcrumb, path: `/jobs/${this.job['id']}/job_revisions/${this.jobRevision['id']}/date_panel/${this.shift['id']}` },
          { text: this.title },
        ]

      }
      return []
    }
  }
};
</script>