import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  departments: {},
  zones: [],
  can_edit: null,
  lessees: {},
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateDepartment(state, { department }) {
    Vue.set(state.departments, department.id, department)
  },
  removeDepartment(state, departmentId) {
    Vue.delete(state.departments, departmentId)
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/departments`)
      .then(response => {
        commit('updateField', { path: 'departments', value: { ...response.data.departments } })
        commit('updateField', { path: 'zones', value: response.data.zones })
        commit('updateField', { path: 'can_edit', value: response.data.can_edit })
        commit('updateField', { path: 'lessees', value: response.data.lessees })
      })
  },
  submitDepartment({ commit, state, dispatch }, { department }) {
    AxiosWrapper.postOrPatch('/data_api/departments', { department })
      .then(response => {
        commit('updateDepartment', { department: response.data })
        if (department.id == 0) { commit('removeDepartment', department.id) }
        dispatch('updateZoneAssignments')
      })
  },
  deleteDepartment({ commit, dispatch }, { department }) {
    AxiosWrapper.delete(`/data_api/departments/${department.id}`)
      .then(response => {
        commit('removeDepartment', department.id)
        dispatch('updateZoneAssignments')
      }
      );
  },
  updateZoneAssignments({ commit }) {
    AxiosWrapper.get(`/data_api/departments/get_zone_assignments`)
      .then(response => {
        commit('updateField', { path: 'zones', value: response.data.zones })
      })
  },
}