import AxiosWrapper from "~/assets/javascript/AxiosWrapper";

AxiosWrapper.init();

const getDefaultState = () => ({
  qrCode: {},
})

export const state = getDefaultState

export const getters = {
  qrCode(state) {
    return state.qrCode;
  }
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      qr_code: state.qrCode,
    } = getRequestedData);
  },
}

export const actions = {
  async init({ commit }, id) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/devices/show_licence?licence_id=${id}`, { id })
      .then(function (response) {
        commit("init", response.data);
      });
  }
}