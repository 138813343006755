import { getField, updateField } from 'vuex-map-fields'
import { getJobFormObject } from '~/components/Jobs/common/helper'

export const state = () => ({
  job: {},
  jobSubHeader: null
})

export const getters = {
  getField,
}

export const mutations = {
  init(state, data) {
    state.job = data.job
    state.jobRevision = data.job_revision
    state.isCustomer = data.is_customer
  },
  updateField
}

export const actions = {
  async refreshJob({commit}, {jobId}) {
    return this.$axios.get(`/data_api/jobs/${jobId}`)
      .then(({data}) => {
        if (data.job) {
          const job = getJobFormObject(data)
          commit('updateField', { path: 'job', value: job })
        }
      })
  },
}