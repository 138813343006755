import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment'
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import ShiftsHelper from '~/components/common/stores/ShiftsHelper.js'
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();

const getDefaultState = () => ({
  storeApiUrl: '/data_api/shifts_search',
  job_revision_shifts: [],
  can_create_shift: false,
  can_edit_shift: false,
  view_only: false,
  can_edit_revision: false,
  total: null,
  total_pages: null,
  latest_shift_date: '',
  show_edit_shift: false,
  editing_shift_id: null,
  show_unfinished: false,
  items_per_page: 10,
  page: 1,
  hasSearched: false,
  isJobContext: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  initDataFromGet(state, getRequestData) {
    ({
      can_create_shift: state.can_create_shift,
      view_only: state.view_only,
      can_edit_shift: state.can_edit_shift,
      total: state.total,
      total_pages: state.total_pages,
      can_edit_revision: state.can_edit_revision,
    } = getRequestData);

    state.can_edit_revision = getRequestData.can_edit_revision || false
    state.can_create_shift = getRequestData.can_create_shift || false

    if (Array.isArray(getRequestData.job_revision_shifts)) {
      state.job_revision_shifts = getRequestData.job_revision_shifts;
      if (getRequestData.job_revision_shifts.latest_shift_date) {
        state.latest_shift_date = getRequestData.job_revision_shifts.latest_shift_date;
      }
    } else {
      state.job_revision_shifts = [getRequestData.job_revision_shifts];
    }
  },
  setShowUnfinishedFromSearchParams(state, searchParams) {
    if (searchParams.show_unfinished) {
      if (searchParams.show_unfinished === 'true') {
        state.show_unfinished = true
      } else {
        state.show_unfinished = false
      }
    }
  },
  setModalsFromParams(state, searchParams) {
    if (searchParams.show_edit_shift == "true") {
      state.show_edit_shift = true
    } else {
      state.show_edit_shift = false
    }

    if (searchParams.editing_shift_id) {
      state.editing_shift_id = searchParams.editing_shift_id
    }
  },
}

export const getters = {
  getField,
}

export const actions = {
  async init(
    {
      commit,
      dispatch,
      state
    },
    {
      startDate,
      jobId,
      jobRevisionId,
      autoSearch,
      context
    }) {
    commit('resetState')
    let params = AxiosWrapper.getSearchParams()

    if (jobRevisionId || jobId) {
      commit('updateField', { path: 'isJobContext', value: true })
      params.job = jobId != "" ? jobId : null
      params.job_revision = jobRevisionId != "" ? jobRevisionId : null
    }

    if (startDate && params.start_date === undefined) {
      params.start_date = startDate
      commit('datetime/setEndDateToEmpty', {}, { root: true })
    }

    dispatch("selects/initForReportPage", { params, paramsMap: ShiftsHelper.defaultParamsMap() }, { root: true });
    if (autoSearch) dispatch('getCallForSearch', {
      ...params,
      items_per_page: state.items_per_page,
      page: state.page,
      context
    })
  },
  async getCallForSearch({ commit, dispatch }, params) {
    gtmEvents.reportGenerated({
      type: "Shifts",
      context: params.context
    })
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'StatsSearch', pathWithParams);

    AxiosWrapper.get('/data_api/shifts_search', { params }).then(({ data }) => {
      commit('updateField', { path: 'hasSearched', value: true })
      commit("setShowUnfinishedFromSearchParams", params);
      commit('setModalsFromParams', params);
      commit('initDataFromGet', data)
      dispatch("contentWithHeader/update", null, { root: true })
    })
  },
  async search({ dispatch, rootState, state, rootGetters }, context) {
    let params = {
      ...ConfigHelper.mapParams(rootState.selects.params, ShiftsHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      show_edit_shift: state.show_edit_shift,
      editing_shift_id: state.editing_shift_id,
      show_unfinished: state.show_unfinished,
      items_per_page: state.items_per_page,
      page: state.page
    }

    dispatch("getCallForSearch", { ...params, context })
  },
  setPageAndThenGetPage({ dispatch, commit, state }, page) {
    commit('updateField', { path: "page", value: page });
    dispatch('search');
  },
  changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'items_per_page',
      value: itemsPerPage
    });
    dispatch('search');
  },
  createNewShift({ commit, state, dispatch }) {
    commit('updateField', { path: 'show_edit_shift', value: !state.show_edit_shift })
    dispatch('updateParams')
  },
  editExistingShift({ commit, state, dispatch }, shift) {
    commit('updateField', { path: 'editing_shift_id', value: String(shift) })
    commit('updateField', { path: 'show_edit_shift', value: !state.show_edit_shift })
    dispatch('updateParams')
  },
  toggleNewShiftModal({ commit, state, dispatch }) {
    commit('updateField', { path: 'show_edit_shift', value: !state.show_edit_shift })
    dispatch('updateParams')
  },
  toggleEditingShiftModal({ commit, state, dispatch }) {
    commit('updateField', { path: 'editing_shift_id', value: null })
    commit('updateField', { path: 'show_edit_shift', value: !state.show_edit_shift })
    dispatch('updateParams')
  },
  toggleEditingShiftAndRefresh({ commit, state, dispatch }) {
    commit('updateField', { path: 'show_edit_shift', value: !state.show_edit_shift })
    dispatch('updateParams')
    dispatch('search')
  },
  updateParams({ state, rootGetters, rootState }) {
    let paramsObject = {
      ...ConfigHelper.mapParams(rootState.selects.params, ShiftsHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      show_edit_shift: state.show_edit_shift,
      editing_shift_id: state.editing_shift_id,
      show_unfinished: state.show_unfinished,
      items_per_page: state.items_per_page,
      page: state.page
    }
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(paramsObject);
    history.replaceState(history.state, 'StatsSearch', pathWithParams);
  },
}