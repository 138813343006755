import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization'
import isArray from 'lodash/isArray'
import gtmEvents from "~/assets/javascript/gtmEvents"

AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  assignments: [],
  hasSearched: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async init({ commit, dispatch }) {
    commit("resetState")
    let params = AxiosWrapper.getSearchParams();
    let paramsMap = ConfigHelper.defaultParamsMap();
    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
  },
  async search({ dispatch, rootState, rootGetters }) {
    gtmEvents.reportGenerated({type: "Shifts By Date"})
    let params = {
      ...ConfigHelper.mapParams(rootState.selects.params, ConfigHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate']
    }
    let pathWithParams = AxiosWrapper.buildSearchPathWithSanitized(params);
    history.replaceState(history.state, 'ShiftsSearchByDate', pathWithParams);
    dispatch("getCallForSearch", params)
  },
  async getCallForSearch({ commit, dispatch, rootState }, params) {
    AxiosWrapper.get('/data_api/shift_search_by_date', { params }).then(({data}) => {
      if (data.assignment_results === undefined) {
        commit("updateField", {
          path: "assignments",
          value: []
        });
      } else {
        commit("updateField", {
          path: "assignments",
          value: data.assignment_results
        });
      }

      commit('updateField', {path: 'hasSearched', value: true})

      dispatch("contentWithHeader/update",
                labelValues => {
                  delete labelValues[translate('job_revisions')]
                  let selectedUsers = rootState.selects.selectedUsers
                  if (isArray(selectedUsers) && selectedUsers.length > 0) selectedUsers = selectedUsers[0]
                  if (selectedUsers && selectedUsers.name) labelValues[translate('users')] = selectedUsers.name
                  return labelValues
                },
                {root: true})
    })
  }
}