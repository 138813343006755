import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  config: {
    theme: "modern",
    plugins: [
      "uploadimage",
      "paste",
      "uploadpdf"
    ],
    toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link uploadimage uploadpdf",
    paste_as_text: true,
    uploadimage_hint: null,
    skin_url: "/tinymce"
  },
  canEdit: null,
  jobRevision: null,
  videos: [],
  imagePreview: null,
  workInstructionIdentifier: null,
  workInstructionHtml: null,
  workInstructionContent: null,
  showVideoModal: false,
  videoObject: {}
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ commit, state }) {
  },
  async submit({ state }) {
  },

  save({state}) {

  }
}