import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init()

const getDefaultState = () => ({
  started_at: '',
  ended_at: '',
  shift: null,
  loading: false,
  job: null,
  jobRevision: null,
  shiftDescriptions: null,
  showShiftForm: false
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestData) {
    ({
      shift: state.shift,
      job: state.job,
      job_revision: state.jobRevision,
      shift_descriptions: state.shiftDescriptions
    } = getRequestData)
  },
  initStartedAndEndedAt(state) {
    state.started_at = state.shift.started_at
    state.ended_at = state.shift.ended_at
  },
  attachStartedAndEndedAtToShift(state) {
    state.shift.started_at = state.started_at
    state.shift.ended_at = state.ended_at
  },
  toggleLoading(state) {
    state.loading = !state.loading
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ commit, dispatch }, {jobId, jobRevisionId, shiftId}) {
    // commit("resetState")
    commit('toggleLoading')

    let getUrl = `/data_api/jobs/${jobId}/job_revisions/${jobRevisionId}/shifts/${shiftId}/edit`

    AxiosWrapper.get(getUrl).then(response => {
        commit("init", response.data)
        commit('toggleLoading')
        commit('initStartedAndEndedAt')
        commit('updateField', {path: 'showShiftForm', value: true})
    })
  },
  save({ commit, dispatch, state }) {
    commit('toggleLoading')
    // commit('attachStartedAndEndedAtToShift')
    return AxiosWrapper.postOrPatch(`/data_api/jobs/${state.job.id}/job_revisions/${state.jobRevision.id}/shifts`, {
      shift: state.shift
    }).then(()=> {
      commit('updateField', {path: 'showShiftForm', value: false})
    })
  }
}