import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import compact from 'lodash/compact'
import flattenDeep from 'lodash/flattenDeep'
import { createHelpers } from 'vuex-map-fields';

const { getAssignJobsField, updateAssignJobsField } = createHelpers({
  getterType: 'getAssignJobsField',
  mutationType: 'updateAssignJobsField',
});

const getDefaultState = () => ({
  showAssignJobs: false,
  selectedJobs: [],
  jobsForSelect: null
})

export const state = getDefaultState

export const getters = {
  getAssignJobsField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  toggleAssignJobs(state) {
    state.showAssignJobs = !state.showAssignJobs
  },
  assignSelectedJobs(state, currentSuspectCodeModels) {
    let allJobIds = compact(flattenDeep(currentSuspectCodeModels.map(s => s.job_ids)))

    let result = allJobIds.filter(id => {
      return compact(currentSuspectCodeModels).every(scm => {
        if (scm.job_ids) {
          return scm.job_ids.includes(id)
        }

        return true
      })
    })

    state.selectedJobs = result
  },
  populateJobsForSelect(state, { zones, currentSuspectCodeModels }) {
    let zonesToSearch = zones.filter(zone => {
      return compact(currentSuspectCodeModels).every(scm => {
        if (scm.zone_ids && !scm.global) {
          return scm.zone_ids.includes(zone.id)
        }

        return true
      })
    })

    zonesToSearch.forEach(zone => {
      let jobs = zone.jobs.map(job => { return { label: job.name, value: job.id } })

      state.jobsForSelect = state.jobsForSelect.concat({
        collapsible: true,
        label: zone.name,
        items: jobs
      })
    })
  },
  addJobsToCurrentModels(state, currentSuspectCodeModels) {
    currentSuspectCodeModels.forEach(scm => {
      scm.job_ids = state.selectedJobs
    })
  },
  resetSelectedJobs(state) {
    state.selectedJobs = []
  },
  resetJobsForSelect(state) {
    state.jobsForSelect = []
  },
  updateAssignJobsField
}

export const actions = {
  updateSelectedJobs({ commit }, val) {
    commit('updateAssignJobsField', { path: 'selectedJobs', value: val})
  },
  assignJobSuspectCodeModels({ commit, state, rootState }) {
    commit('suspectCodeModelList/toggleAssignSuspectCodeModels', null, { root: true })
    commit('suspectCodeModelList/setSelectedModels', null, { root: true })

    AxiosWrapper.get('/data_api/suspect_code_models', {
      params:
        { zone_id: rootState.suspectCodeModelList.zoneId, global: true }
    }).then(response => {
      commit('suspectCodeModelList/populateSuspectCodeModelsForSelect', response.data, { root: true })
    })
  },
  assignJobs({ commit, state, rootState }, { suspectCodeModel }) {
    let params = {}

    if (suspectCodeModel) {
      commit("suspectCodeModelList/setCurrentSuspectCodeModels", suspectCodeModel, { root: true })
      params['suspect_code_model_id'] = suspectCodeModel.id
    } else {
      commit("suspectCodeModelList/setCurrentSuspectCodeModels", null, { root: true })
    }

    commit('toggleAssignJobs')
    commit('resetJobsForSelect')
    commit('assignSelectedJobs', rootState.suspectCodeModelList.currentSuspectCodeModels)

    AxiosWrapper.get('/data_api/suspect_code_models/assign_jobs', { params: params }).then(response => {
      commit('populateJobsForSelect', {
        zones: response.data, currentSuspectCodeModels:
          rootState.suspectCodeModelList.currentSuspectCodeModels
      })
    })
  },
  saveAssignJobs({ dispatch, commit, state, rootState }) {

    let selectedIds = rootState.suspectCodeModelList.currentSuspectCodeModels.map(s => s.id)

    AxiosWrapper.patch('/data_api/suspect_code_models/update_jobs',
      {
        suspect_code_model_ids: compact(selectedIds),
        job_ids: compact(state.selectedJobs)
      }).then(response => {
        commit('addJobsToCurrentModels', rootState.suspectCodeModelList.currentSuspectCodeModels)
        commit('toggleAssignJobs')
        commit('resetSelectedJobs')

        if (rootState.suspectCodeModelList.currentView === 'job') {
          dispatch('suspectCodeModelList/getJobGroupedModels', null, { root: true })
        } else {
        }

        dispatch('errorMethods/defaultSaveSuccess', response, { root: true })
      }).catch(error => {
      })
  },
}