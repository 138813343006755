import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import Moment from 'moment';

AxiosWrapper.init();
Vue.use(Vuex);

const getDefaultState = () => ({
  questionnaire_result: null,
  childQuestionnaireResults: [],
  presenter: null,
  answers: null,
  back_path: null,
  summary_path: null,
  departments: [],
  severities: [],
  champions: [],
  expected_resolution_date: '',
  description: '',
  department_id: null,
  severity_id: null,
  champion_id: null,
  can_be_verified_by_id: null,
  showInspectedAt: false,
  useCustomForExport: false,
  hasCustomMailMerge: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  initialGetCompleted(state, responseData){
    ({
      questionnaire_result: state.questionnaire_result,
      presenter: state.presenter,
      back_path: state.back_path,
      summary_path: state.summary_path,
      answers: state.answers,
      has_custom_mail_merge: state.hasCustomMailMerge,
      child_questionnaire_results: state.childQuestionnaireResults
    } = responseData)
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updatePresenterInspectedAt(state, inspectedAt){
    state.presenter.inspected_at = inspectedAt
    state.showInspectedAt = false
  },
  attachUpdatedPlanFields(state, planObject) {
    planObject.expected_resolution_date = Moment(state.expected_resolution_date).format('YYYY-MM-DD')
    planObject.description = state.description
    planObject.department_id = state.department_id;
    planObject.severity_id = state.severity_id
    planObject.champion_id = state.champion_id
    planObject.can_be_verified_by_id = state.can_be_verified_by_id
  },
  planCreatedWorkFlow(state, {answer, plan }) {
    answer.corrective_action_plans.splice(0, 1, plan)
  },
  addPlanToAnswer(state, {answer, newPlanObject}) {
    answer.corrective_action_plans.unshift(newPlanObject)
  },
  setChampions(state, newChampions) {
    state.champions = newChampions
  },
  cancelCreateForAnswer({ state, commit }, answer) {
    answer.corrective_action_plans.splice(0, 1)
  },
}

export const getters = {
  getField,
  auditedByUser(state){
    if (state.presenter !== null){
      if (state.presenter.user && state.presenter.user.user !== null) {
        return state.presenter.user.user
      } else {
        return null
      }
    }
    return null
  },
  questionnaireResultShowUrl(state) {
    let qr = state.questionnaire_result
    let questionnaireId = AxiosWrapper.getNextPathNameElement('questionnaires');
    let questionnaireResultId = AxiosWrapper.getNextPathNameElement('questionnaire_results');
    if (state.questionnaire_result) return `/jobs/${qr.job.id}/job_revisions/${qr.job_revision.id}/audit/questionnaires/${questionnaireId}/questionnaire_results/${questionnaireResultId}`
  },
  modalId(){
    let questionnaireResultId = AxiosWrapper.getNextPathNameElement('questionnaire_results');
    return `questionnaire-result-info-${questionnaireResultId}`
  },
  modalDataTarget(){
    let questionnaireResultId = AxiosWrapper.getNextPathNameElement('questionnaire_results');
    return `#questionnaire-result-info-${questionnaireResultId}`
  },
  inspectedAt(state){
    if (state.presenter !== null){
      return `${translate('inspected_at_with_date', { date: state.presenter.inspected_at })}`
    }
    return ""
  },
  auditedBy(state){
    let user = null

    if (state.presenter !== null) {
      if (state.presenter.user && state.presenter.user.user !== null) {
        user = state.presenter.user.user
      }
    }

    const fullName = user === null ? '' : user.full_name

    if (state.presenter !== null) {
      return `${translate('audited_by', { title: state.presenter.title, user: fullName })}`
    }

    return ""
  },
  totalSeverity(state){
    if (state.presenter !== null) {
      return `${translate('total_severity')}: ${state.presenter.total_severity}`
    }

    return ""
  },
  totalScore(state){
    if (state.presenter !== null) {
      return `${translate('total_score')}: ${state.presenter.total_score}`
    }
    return ""
  },
  totalAuditTime(state){
    if (state.presenter !== null) {
      return `${translate('total_audit_time')} (${translate('hour_minute_second')}): ${state.presenter.length_of_time_for_audit}`
    }

    return ""
  },
  totalResolveTime(state){
    if (state.presenter !== null) {
      return `${translate('time_to_resolve')} (${translate('hour_minute_second')}): ${state.presenter.time_to_resolve}`
    }

    return ""
  },
  workArea(state) {
    if (state.presenter !== null && state.presenter.work_area !== null) {
      return `${translate('work_area')}: ${state.presenter.work_area}`
    }

    return ""
  },
  post(state) {
    if (state.presenter !== null && state.presenter.post !== null) {
      return `${translate('post')}: ${state.presenter.post}`
    }

    return ""
  }
}

export const actions = {
  async init({ commit, dispatch, rootGetters }) {
    commit('resetState')

    let questionnaireResultId = AxiosWrapper.getNextPathNameElement('questionnaire_results');
    let searchParams = AxiosWrapper.getSearchParams()

    AxiosWrapper.get(`/data_api/audit/questionnaire_results/${questionnaireResultId}`, { params: searchParams }).then(function (response) {
      commit('initialGetCompleted', response.data);
      dispatch('jobRevision/setJobRevision', response.data.questionnaire_result.job_revision, { root: true })
      commit('correctiveActionPlans/init', {departments: response.data.allowable_departments,
                                            severities: response.data.allowable_severities,
                                            users: response.data.allowable_users_as_options,
                                            permissions: rootGetters['login/questionnaireResultsPermissions']
                                            }, { root: true })
    })
  },
  updateInspectedAt({commit}, inspectedAt){
    commit('updatePresenterInspectedAt', inspectedAt)
  }
}