import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  showEditModal: false,
  examData: null,
  exams: [],
  lessee_id: null,
  jobs: null,
  assignJobsForm: null,
  sortOrder: [
    {
      field: "name",
      sortField: "name",
      direction: "asc"
    }
  ],
  loadingMessage: "",
  loading: false,
  examHeaderTitle: "",
  isReadOnly: false,
  currentExamLastPublishedDate: null,
  currentExamLastPublishedId: null,
  currentExamId: null,
  currentExam: null
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  addExamToIndex(state, exam) {
    exam["jobs"] = []
    state.exams.unshift(exam)
  },
  removeExamFromIndex(state, exam) {
    state.exams.splice(state.exams.findIndex(e => e.id == exam.id), 1);
  },
  updateExam(state, exam) {
    const idx = state.exams.findIndex(e => e.id == exam.id);
    state.exams.splice(idx, 1, exam);
  },
  setPublishing(state, examId) {
    const idx = state.exams.findIndex(e => e.id == examId);

    state.exams[idx].publishing = true
  },
  updateField
}

export const actions = {}