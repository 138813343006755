import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  reportStatus: {},
  exportHistories: []
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateHistories(state, { tableName, exportHistories }) {
    Vue.set(state.exportHistories, tableName, exportHistories);
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/report_statuses`)
      .then(response => {
        commit('updateField', { path: 'reportStatus', value: response.data })
      })
  },
  loadExportHistories({ commit }, tableName) {
    let exportHistories = []
    commit('updateHistories', { tableName, exportHistories })
    AxiosWrapper.get(`/data_api/report_statuses/export_histories?table_name=${tableName}`)
      .then(response => {
        exportHistories = response.data.export_histories
        commit('updateHistories', { tableName, exportHistories })
      })
  }
}