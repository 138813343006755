import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import paramsHelper from '~/components/common/stores/statsSearchParamsHelper.js'
import { translate } from '~/assets/javascript/localization/Localization'
import gtmEvents from "~/assets/javascript/gtmEvents"
import moment from 'moment'

const isStatsSearchPage = function () {
  return window.location.href.indexOf("reports/stats") > -1
}

const getDefaultState = () => ({
  storeApiUrl: '/data_api/stats_search',
  suspects_by_shift: [],
  suspects_by_description: [],
  storeLockJob: false,
  storeLockRevision: false,
  total_parts_inspected: null,
  total_repaired: null,
  total_verified: null,
  total_scrap: null,
  total_recycle: null,
  total_released: null,
  total_suspects: null,
  total_good: null,
  ppm: null,
  reject_rate: null,
  inspected_by_part_number: [],
  inspected_suspects_by_part_number: [],
  ichart_url: null,
  can_generate_ichart: false,
  hide_inspection_stats: false,
  path_updated: false,
  jobRequirement: [],
  userRequirement: [],
  rejectTag: "",
  showAllBins: true,
  device: null,
  hasSearched: false
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    let newState = getDefaultState()
    Object.assign(state, newState)
  },
  updateField,
  searchCompleted(state, getRequestData) {
    ({
      suspects_by_shift: state.suspects_by_shift,
      suspects_by_description: state.suspects_by_description,
      can_generate_ichart: state.can_generate_ichart,
      total_parts_inspected: state.total_parts_inspected,
      total_repaired: state.total_repaired,
      total_verified: state.total_verified,
      total_scrap: state.total_scrap,
      total_recycle: state.total_recycle,
      total_released: state.total_released,
      total_suspects: state.total_suspects,
      total_good: state.total_good,
      ppm: state.ppm,
      reject_rate: state.reject_rate,
      inspected_by_part_number: state.inspected_by_part_number,
      inspected_suspects_by_part_number: state.inspected_suspects_by_part_number,
      ichart_link: state.ichart_url
    } = getRequestData);

    if (getRequestData.hide_inspection_stats !== undefined) {
      state.hide_inspection_stats = getRequestData.hide_inspection_stats;
    }
  },
  setNonSelectParams(state, params) {
    state.showAllBins = params["show_all_bins"] == "true"
    state.rejectTag = params["reject_tag"]
    if (params["device"]) state.device = params["device"]
  }
}

export const getters = {
  getField,
  summaryReportEmpty(state) {
    return state.inspected_by_part_number === null || state.inspected_by_part_number === undefined || state.inspected_by_part_number.length === 0
  },
  jobForStats: (state, getters, rootState) => {
    if (rootState.selects.selectedJobs != null) {
      if (rootState.selects.selectedJobs.length === 1 || rootState.selects.selectedJobs.attributes != undefined) {
        if (rootState.selects.selectedJobs.attributes != undefined) {
          return { attributes: rootState.selects.selectedJobs.attributes, id: rootState.selects.selectedJobs.value }
        }
        return { attributes: rootState.selects.selectedJobs[0].attributes, id: rootState.selects.selectedJobs[0].value }
      }
    }
  },
  jobRevisionForStats: (state, getters, rootState) => {
    if (rootState.selects.selectedJobRevisions != null) {
      if (rootState.selects.selectedJobRevisions.length === 1 || rootState.selects.selectedJobRevisions.attributes != undefined) {
        if (rootState.selects.selectedJobRevisions.attributes != undefined) {
          return { id: rootState.selects.selectedJobRevisions.value }
        }
        return { id: rootState.selects.selectedJobRevisions[0].value }
      }
    }
  }
}

export const actions = {
  async init({ commit, dispatch }, { apiUrl, jobId, jobRevisionId }) {
    commit('resetState')

    if (apiUrl) commit('updateField', { path: 'storeApiUrl', value: apiUrl })

    let params = AxiosWrapper.getSearchParams();
    if (jobId || jobRevisionId) params = {
      job: ((jobId != "") ? jobId : null),
      job_revision: ((jobRevisionId != "") ? jobRevisionId : null),
      ...params
    };

    commit("setNonSelectParams", params)

    dispatch("selects/initForReportPage", { params, paramsMap: paramsHelper.defaultParamsMap() }, { root: true });
  },
  async search({ dispatch, state, rootGetters }, context) {
    gtmEvents.reportGenerated({
      type: "Stats",
      context
    })

    let paramsForSearch = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], paramsHelper.defaultRevertParamsMap()),
      ...rootGetters['datetime/getStartAndEndDate'],
      reject_tag: state.rejectTag,
      show_all_bins: state.showAllBins
    }

    let paramsForUrl = { ...paramsForSearch, ...rootGetters["selects/prefixedCustomValues"] }

    dispatch("getCallForSearch", paramsForSearch)
    history.replaceState(history.state, 'StatsSearch', AxiosWrapper.buildSearchPathWithSanitized(paramsForUrl));
  },
  async getCallForSearch({ state, commit, dispatch }, params) {
    let headerCallback = function (labelValues) {
      delete labelValues[translate('zone')]
      return labelValues
    }

    this.$axios.get(state.storeApiUrl, { params })
      .then(({ data }) => {
        dispatch('contentWithHeader/update',
          !isStatsSearchPage() ? headerCallback : null,
          { root: true })
        commit('searchCompleted', data)
        commit('updateField', { path: 'hasSearched', value: true })
      })
  }
}