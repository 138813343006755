 export default {
  selectGaugeSize() {
    return [
      { label: "Small", value: 4 },
      { label: "Medium", value: 8 },
      { label: "Large", value: 12 }
    ];
  },
  getTotalColumnSizeUsed(gauges) {
    let reducer = (total, currentValue) => total + currentValue.number_of_columns;
    return gauges.reduce(reducer, 0);
  },
  calculateAvailableColumns(gauges) {
    return 12 - this.getTotalColumnSizeUsed(gauges);
  },
  calculateHowManyGaugesAreRequired(gauges) {
    return this.calculateAvailableColumns(gauges) / 4;
  },
  addFilterTo(gauge) {
    switch (gauge.type) {
      case "Dashboard::Gauges::AnswersBreakdown":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.answerBreakdownFilter()
        };
        break;
      case "Dashboard::Gauges::Compliance":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.complianceFilter()
        };
        break;
      case "Dashboard::Gauges::CorrectiveAction":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.correctiveActionFilter()
        };
        break;
      case "Dashboard::Gauges::CorrectiveActionResolution":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.correctiveActionResolutionFilter()
        };
        break;
      case "Dashboard::Gauges::ScheduledCompliance":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.scheduledComplianceFilter()
        };
        break;
      case "Dashboard::Gauges::AuditScore":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.auditScoreFilter()
        };
        break;
      case "Dashboard::Gauges::AuditsPerformed":
        gauge.filter_attributes = {
          ...this.defaultFilter(),
          ...this.auditsPerformedFilter()
        };
        break;
      case "Dashboard::Gauges::Embed":
        break;
      default:
        gauge.filter_attributes = this.defaultFilter();
    }
  },
  scheduledComplianceFilter() {
    return {
      interval_kind: "day",
      number_of_days_ago: 30
    };
  },
  complianceFilter() {
    return {
      number_of_days_ago: 30
    };
  },
  correctiveActionFilter() {
    return {
      display_style: "pie_chart",
      number_of_days_ago: 30,
      number_of_days_ahead: 30
    };
  },
  answerBreakdownFilter() {
    return {
      answer_breakdown_compliance_by: "pass_fail",
      show_limit: 10,
      interval_kind: "week",
      number_of_days_ago: 30
    };
  },
  correctiveActionResolutionFilter() {
    return {
      number_of_months_ago: 4
    };
  },
  auditScoreFilter() {
    return {
      how_to_calculate: "total",
      group_by: "part_number",
      is_set_to_current_week: true,
    }
  },
  auditsPerformedFilter() {
    return {
      group_by: "monthly",
      is_set_to_current_year: true
    }
  },
  defaultFilter() {
    return {
      is_set_to_current_month: false,
      number_of_days_ago: null,
      show_limit: null,
      number_of_days_ahead: null,
      display_style: null,
      answer_breakdown_compliance_by: null,
      is_set_to_current_week: false,
      is_set_to_current_week_and_previous_week: false,
      number_of_months_ago: null,
      is_set_to_current_year: false,
      interval_kind: null,
      interval: 1,
      show_empty_records: false,
      show_all_comments: false
    };
  },
  addNewGaugeIfRequired(row) {
    let gauges = row.gauges
    let numberOfRequiredGauges = this.calculateHowManyGaugesAreRequired(
      gauges
    );

    if (numberOfRequiredGauges > 0 && gauges.map(g => g.type).indexOf(null) === -1) {
      gauges.push({
        number_of_columns: {
          label:"Small",
          value: 4,
        },
        name: "",
        type: null,
        iframe_html: '',
      });
    }
  },
  getSelectAvailableSizes(row) {
    let numberOfRequiredGauges = this.calculateHowManyGaugesAreRequired(row.gauges);
    return this.selectGaugeSize().slice(0, numberOfRequiredGauges);
  },
  isArray(obj) {
    return !!obj && obj.constructor === Array;
  },
  updateRow(row) {
    if (!row.gauges) row.gauges = [];
    row.availableSizes = this.getSelectAvailableSizes(row)
    this.addNewGaugeIfRequired(row);
  },
  update(rows) {
    if (this.isArray(rows)) {
      rows.forEach(row => {
        this.updateRow(row);
      });
    } else {
      this.updateRow(rows);
    }
  }
};