import I18n from 'i18n-js'

export default function(validation) {
  const translationLookup = {
    'numeric': "must_be_number",
    'decimal': "must_be_number",
    'required': "required",
    'email': "please_enter_email",
    'emailUnique': "this_email_is_already_taken",
    'hasRoles': "please_select_role",
    'quickSignInRole': "need_to_be_assigned_the_tablet_role",
    'usedPassword': "password_used_last",
    "passwordPresentAndEqual": "password_confirmation_not_matching",
    'maxLength': "max_length_text",
    'minLength': "min_length_text",
    'maxValue': "max_value_text",
    'minValue': "min_value_text",
    'minNumberNotSmallest': "below_maximum_number",
    'maxNumberNotLargest': "above_min_number",
    'minToleranceNotSmallest': "below_maximum_tolerance",
    'maxToleranceNotLargest': "above_minimum_tolerance",
    'maxSpecNotGreatest': "spec_max_not_greatest",
    'uclNotCorrect': "ucl_not_correct",
    'nomialNotCorrect': "nominal_not_correct",
    'lclNotCorrect': "lcl_not_correct",
    'specMinNotSmallest': "spec_min_not_smallest",
    'needsOne': "needs_one",
    'recurringSameAsEnd': 'started_same_error',
    'startBeforeEnd': 'started_at_error',
    'enterOneOption': 'enter_one_option',
    'passwordsDoNotMatch': 'password_do_not_match',
    'passwordRequirements': 'password_requirements',
    'addOneSuspectCode': 'add_one_supect_code',
    'greaterThanPass': 'must_be_greater_than_pass_value',
    'addOneImageAnswer': 'add_one_image_answer',
    'isUnique': 'must_be_unique',
    "hasOneCorrectOption": "must_have_at_least_one_correct_option",
    "hasTwoOptions": "must_have_at_least_two_options",
    "optionsCantBeEmpty": "options_cant_be_empty",
    "maximumDailyHours": "cant_exceed_max_daily_hours"
  }

  return I18n.t(translationLookup[validation.type], validation)
}