import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  roles: {},
  can_edit: null,
  lessees: {},
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateRole(state, { role }) {
    Vue.set(state.roles, role.id, role)
  },
  removeRole(state, roleId) {
    Vue.delete(state.roles, roleId)
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/departments/roles`)
      .then(response => {
        commit('updateField', { path: 'roles', value: { ...response.data.roles } })
        commit('updateField', { path: 'can_edit', value: response.data.can_edit })
        commit('updateField', { path: 'lessees', value: response.data.lessees })
      })
  },
  submitRole({ commit, state }, { role }) {
    AxiosWrapper.postOrPatch('/data_api/departments/roles', { role })
      .then(response => {
        commit('updateRole', { role: response.data })
      })
  },
  deleteRole({ commit }, { role }) {
    AxiosWrapper.delete(`/data_api/departments/roles/${role.id}`)
      .then(response => {
        commit('removeRole', role.id)
      }
      );
  },
}