<template lang="pug">
  device-pages-header
</template>

<script>

import DevicePagesHeader from '~/components/Layouts/DevicePagesHeader.vue'

export default {
  components: { DevicePagesHeader },
  middleware: ['sessionHandler', 'pageRedirections']
}
</script>