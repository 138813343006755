import Vue from "vue";
import { translate } from '~/assets/javascript/localization/Localization';
import { createHelpers } from "vuex-map-fields";
import PieChartOptionsHelper from '~/components/QuestionnaireStatsSearch/Helpers/PieChartOptionsHelper.js'

const { getSummaryReportField, updateSummaryReportField } = createHelpers({
  getterType: "getSummaryReportField",
  mutationType: "updateSummaryReportField"
});

const getDefaultState = () => ({
  monthlyBreakdownBarGraphOptions: {
    title: "",
    xLabel: "",
    yLabel: translate("compliance"),
    seriesLookupLabels: {
      'compliance_percent': translate('compliance_percent'),
      'questionnaire_name': translate('questionnaire_name')
    },
    colourRanges: ["#ffdb09"],
    showLegend: true,
    yAxisShowWholeNumber: true,
    groupLookupName: "group_name",
    xLabelRotation: "-90"
  },
  lineGraphOptions: {
    yLabel: translate("compliance_percent"),
    parseFormat: "%Y-%m-%d",
    displayFormat: "%d",
    tooltipFormat: "%b %d, %Y",
    xLookupKey: "date",
    yLookupKey: "compliance",
    xLabel: translate("day"),
    xLabel: translate("compliance_percent"),
    showLegend: true,
    legendPosition: 'left',
    minimumY: 0,
    hasDivider: false,
    showTrendLine: true,
    showLine: true,
    xLabelRotation: "-45",
    legendTitleColours: [
      {
        seriesLabel: translate("compliance"),
        colour: "steelblue"
      },
    ]
  },
  monthBreakdownTableFields: [
    {
      name: "group_name",
      title: translate("questionnaire"),
      sortField: "group_name"
    },
    {
      name: "total_audits",
      title: translate("total_audits"),
      sortField: "total_audits"
    },
    {
      name: "total_passes",
      title: translate("total_pass"),
      sortField: "total_passes"
    },
    {
      name: "total_opted_outs",
      title: translate("total_opted_out"),
      sortField: "total_opted_outs"
    },
    {
      name: "total_warns",
      title: translate("total_warn"),
      sortField: "total_warns"
    },
    {
      name: "total_fails",
      title: translate("total_fail"),
      sortField: "total_fails"
    },
    {
      name: "total_warns_fails",
      title: translate("total_non_compliance"),
      sortField: "total_warns_fails"
    },
    {
      name: "total_answers",
      title: translate("total_answers"),
      sortField: "total_answers"
    },
    {
      name: "compliance",
      title: translate("compliance_percent"),
      sortField: "compliance"
    },
  ],
  answersBreakdownsTableFields: [
    {
      name: "question",
      title: translate("question"),
      sortField: "question"
    },
    {
      name: "pass",
      title: translate("pass"),
      sortField: "pass"
    },
    {
      name: "opted_out",
      title: translate("opted_out"),
      sortField: "opted_out"
    },
    {
      name: "warn",
      title: translate("warn"),
      sortField: "warn"
    },
    {
      name: "fail",
      title: translate("fail"),
      sortField: "fail"
    },
    {
      name: "total_answers",
      title: translate("total_answers"),
      sortField: "total_answers"
    },
    {
      name: "compliance_percent",
      title: translate("compliance"),
      sortField: "compliance_percent"
    },
    {
      name: "total_non_compliance",
      title: translate("total_non_compliance"),
      sortField: "total_non_compliance"
    },
  ],
  sortOrderForSummaryTable: [{
    field: 'answer_summaries_count',
    sortField: 'answer_summaries_count',
    direction: 'desc'
  }]
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateSummaryReportField,
  createBarGraphDataArray(state, months) {
    let dataArray = [];

    months.forEach(month => {
      let dataObject = {}
      month.questionnaire_sums.forEach(sum => {
        dataObject = {
          "group_name": sum.group_name,
          "compliance_percent": sum.compliance_percent
        };

        dataArray.push(dataObject);
      });

      Vue.set(month, "barGraphData", dataArray);
    });
  },
  setPieChartOptions(state, { month, options }) {
    Vue.set(month, "pieChartOptions", options);
  }
}

export const getters = {
  getSummaryReportField
}

export const actions = {
  async createBarGraphDataArray({ commit }, months) {
    commit("createBarGraphDataArray", months);
  },
  async createPieChartOptions({ commit }, months) {
    months.forEach(month => {
      let labels = [];

      for (var key in month.corrective_action_counts) {
        if (month.corrective_action_counts[key] > 0) {
          let labelObject = {
            label: PieChartOptionsHelper.getTranslatedNameFor(key),
            count: month.corrective_action_counts[key]
          };
          labels.push(labelObject);
        }
      };

      let options = {
        labels,
        title: translate('corrective_action_monthly_breakdown'),
      };
      commit("setPieChartOptions", { month, options });
    });
  }
}