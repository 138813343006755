import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';
import { getField, updateField } from 'vuex-map-fields';




AxiosWrapper.init();

const getDefaultState = () => ({
  dataLogs: [],
  types: [],
  total_size: 0,
  search: {
    start_date: "",
    end_date: "",
    type: {}
  },
  loadingMessage: translate('no_data_available'),
  sortOrder: [
    {
      field: 'created_at',
      sortField: 'created_at',
      direction: 'asc'
    }
  ],
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  init(state, getRequestData) {
    ({
      start_date: state.search.start_date,
      end_date: state.search.end_date
    } = getRequestData);

    state.types = [
      { label: translate("all"), value: '' }
    ].concat(
      getRequestData.types.map((value) => {
        return { 'label': value, 'value': value };
      }))
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    commit("resetState")

    let params = AxiosWrapper.getSearchParams();
    let searchImmediately = Object.keys(params).length > 1

    let deviceId = AxiosWrapper.getNextPathNameElement('data_logs');

    commit('updateField', {
      path: 'search.device_id',
      value: deviceId
    });

    const url = `/data_api/data_logs/${deviceId}`

    AxiosWrapper.get(url, { params })
      .then(response => {
        commit('init', response.data.search_options);
        commit('updateField', {
          path: 'search.type',
          value: state.types[0]
        });
        if (searchImmediately) {
          console.log("Searching Immediately...")
          dispatch('updateSearchSettings', params)
          dispatch('search')
        }
      })
  },
  updateSearchSettings({ dispatch, state }, searchParamsUsed) {
    console.log('updateSearchSettings')

    Object.keys(searchParamsUsed).forEach(function (key) {
      let value = searchParamsUsed[key]
      if (value) {
        //This is a primitive check.
        if (Object(state.search[key]) === state.search[key]) {
          dispatch('updateMultiSelect', { key, value })
        } else {
          dispatch('updateDate', { key, value })
        }
      }
    });
  },
  async search({ commit, state, dispatch }) {
    let params = AxiosWrapper.flattenObject(state.search)

    dispatch('prepBeforeSearch')
    dispatch('updateUrl', params);

    let deviceId = AxiosWrapper.getNextPathNameElement('data_logs');
    const searchUrl = `/data_api/data_logs/${deviceId}/search`

    AxiosWrapper.get(searchUrl, { params })
      .then(response => {
        dispatch('setResults', response.data)
        dispatch('updateSearchSettings', response.data.search_params_used)
        let params = AxiosWrapper.flattenObject(state.search)
        dispatch('updateUrl', params);
      });
  },
  prepBeforeSearch({ commit }) {
    commit('updateField', { path: 'loadingMessage', value: translate('loading') });
    commit('updateField', { path: 'total_size', value: 0 })
  },
  setResults({ commit }, results) {
    if (results.data_logs.length === 0) {
      commit('updateField', {
        path: 'loadingMessage',
        value: translate('no_data_available')
      });
    }
    commit('updateField', {
      path: 'total_size',
      value: results.total_size
    });

    commit('updateField', {
      path: 'dataLogs',
      value: results.data_logs
    });
  },
  updateUrl({ commit }, params) {
    let url = AxiosWrapper.buildPathWithSearchParams(params);
    history.replaceState(history.state, "Search DataLog", url)
  },
  updateMultiSelect({ commit }, { key, value }) {
    console.log(`updating multi-select search.${key}: ${value}`)

    commit('updateField', {
      path: `search.${key}`,
      value: { 'label': value, 'value': value }
    });
  },
  updateDate({ commit }, { key, value }) {
    console.log(`updating search.${key}: ${value}`)

    commit('updateField', { path: `search.${key}`, value });
  },
}