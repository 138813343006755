import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'
import { jobObjectForParam } from '~/components/Jobs/common/helper'
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'

const getDefaultState = () => ({
  canEdit: false,
  canDelete: false,
  comment: false,
  changesPath: '',
  isYardCampaign: null,
  allowManualBarcodeEntry: null,
  usesHeatmap: null,
  reportingModeOptions: [],
  primarySuspectReportingMode: null,
  oneSuspectPerQuadrant: null,
  active: null,
  useQrCodeSignIn: null,
  zoneId: null,
  rankId: null,
  isPersisted: null,
  startedOn: null,
  closedOn: '',
  suspectMode: null,
  allowableSuspectModes: [],
  userIsSuperuser: null,
  iFrameHtml: null,
  cameraSettings: null,
  identifier: null,
  companyName: null,
  customerIdentifier: null,
  labels: null,
  lastUid: 0,
  hidePpm: null,
  hideInspectionStats: null,
  showAllBins: null,
  locationAcquisitionOption: null,
  costOfQualitiesPath: null,
  currentTags: null,
  downtimeMessage: null,
  id: null,
  hasActiveShifts: true,
  isPersisted: false,
  workAreaIds: [],
  postIds: [],
  workAreas: [],
  originalZoneData: null
})

export const state = getDefaultState

const digestWorkAreasData = function (workAreas) {
  return workAreas.data.map(area => ({
    label: area.attributes.name,
    value: area.attributes.id,
    collapsible: true,
    items: area.attributes.posts.map(post => ({ label: post.name, value: post.id }))
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0
      })
  })).sort((a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0
  })
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitData(state, data) {
    Object.keys(data).forEach(key => {
      let storeKey = key.replace(/_([a-z])/g, (g) => { return g[1].toUpperCase() })
      state[storeKey] = data[key]
    })
    state.closedOn = data.closed_on || ''
    state['locationAcquisitionOption']['options'] = ["detailed", "unobtrusive"]
    if (data.available_work_areas) state.workAreas = digestWorkAreasData(data.available_work_areas);
    state.initialized = true
    state.originalZoneData = {
      id: state.zoneId,
      assignedWorkAreaIds: state.workAreaIds,
      assignedPostIds: state.postIds
    }
  },
  setLabel(state, { newLabel, fieldName }) {
    state['labels'][fieldName]['label'] = newLabel
  },
  setOptional(state, { value, fieldName }) {
    state['labels'][fieldName]['optional'] = value
  },
  setVerifyBarcodes(state, { value, fieldName }) {
    state['labels'][fieldName]['barcode_verification_settings']['verify_barcodes'] = value
  },
  setMatchedShippingLabel(state, { value, fieldName }) {
    state['labels'][fieldName]['barcode_verification_settings']['total_matched_shipping_labels'] = value
  },
  setMatchedPartLabel(state, { value, fieldName }) {
    state['labels'][fieldName]['barcode_verification_settings']['total_matched_part_labels'] = value
  },
  setCanSkipVerification(state, { value, fieldName }) {
    state['labels'][fieldName]['barcode_verification_settings']['can_skip_verification'] = value
  },
  setDefaultQuantity(state, value) {
    state['labels']['quantity']['default_quantity'] = value
  },
  setQuantityLabel(state, value) {
    state['labels']['quantity']['label_text'] = value
  },
  setHideQuantity(state, value) {
    state['labels']['quantity']['hide_quantity_label'] = value
  },
  setEndQuestionnaireLocationAcquisitionOff(state) {
    if (state.locationAcquisitionOption.end_questionnaire_gps_acquisition_option !== 'off') {
      state.locationAcquisitionOption.end_questionnaire_gps_acquisition_option = 'off'
    } else {
      state.locationAcquisitionOption.end_questionnaire_gps_acquisition_option = 'detailed'
    }
  },
  setStartQuestionnaireLocationAcquisitionOff(state) {
    if (state.locationAcquisitionOption.start_questionnaire_gps_acquisition_option !== 'off') {
      state.locationAcquisitionOption.start_questionnaire_gps_acquisition_option = 'off'
    } else {
      state.locationAcquisitionOption.start_questionnaire_gps_acquisition_option = 'detailed'
    }
  },
  setBinLocationAcquisitionOff(state) {
    if (state.locationAcquisitionOption.bin_gps_acquisition_option !== 'off') {
      state.locationAcquisitionOption.bin_gps_acquisition_option = 'off'
    } else {
      state.locationAcquisitionOption.bin_gps_acquisition_option = 'detailed'
    }
  },
  setBinLocationAcquisition(state, option) {
    state.locationAcquisitionOption.bin_gps_acquisition_option = option
  },
  setStartQuestionnaireLocationAcquisition(state, option) {
    state['locationAcquisitionOption']['start_questionnaire_gps_acquisition_option'] = option
  },
  setEndQuestionnaireLocationAcquisition(state, option) {
    state['locationAcquisitionOption']['end_questionnaire_gps_acquisition_option'] = option
  },
  setBinLabel(state, value) {
    state['labels']['bin_label'] = value
  },
  setUserLabel(state, value) {
    state['labels']['user'] = value
  },
  setUseSignedInUsers(state, value) {
    state['labels']['use_signed_in_users'] = value
  },
  addNewTagToSelected(state, tag) {
    state.currentTags.push(tag)
  },
  updateField
}

export const getters = {
  getField,
  displayComment(state) {
    return state.comment && state.canEdit
  },
  radioButtonCheckForBin: (state) => (option) => {
    return state.locationAcquisitionOption.bin_gps_acquisition_option == option
  },
  radioButtonCheckForEndQuestionnaire: (state) => (option) => {
    return state.locationAcquisitionOption.end_questionnaire_gps_acquisition_option == option
  },
  radioButtonCheckForStartQuestionnaire: (state) => (option) => {
    return state.locationAcquisitionOption.start_questionnaire_gps_acquisition_option == option
  },
  lastUpdatedLink(state) {
    let comment = state.comment
    if (comment) {
      if (comment.updating_user) return `${comment.updating_user}, ${comment.updated_time}`
      return `${comment.updated_time}`
    }
  }
}

export const actions = {
  async init({ commit, state, dispatch }, jobData) {
    commit('resetState')

    commit('setInitData', jobData)
    commit('cameraSettings/initializeCameraSettings', state.cameraSettings, { root: true })
    commit('cameraSettings/setDisabled', !state.canEdit, { root: true })

    let paramsMap = ConfigHelper.defaultParamsMap();
    let paramItem = {}

    if (state.id) {
      paramItem = { job: state.id, zone: state.zoneId }
    }

    if (state.currentTags) {
      if (state.currentTags.length !== 0) {
        paramItem.tag = state.currentTags.map(e => {
          return e.id
        })
      }
    }

    if (state.rankId) {
      paramItem.rank = state.rankId
    }

    dispatch("selects/chainingInit", { params: paramItem, paramsMap }, { root: true });
  },
  async deleteClicked({ state }, router) {
    if (confirm(translate('are_you_sure'))) this.$axios.delete(`/data_api/jobs/${state.id}`).then(router.push('/jobs'))
  },
  createBarcodeVerification({ state, dispatch }, { type, router }) {
    this.$axios.post(`/data_api/barcode/jobs/${state.id}/work_item_label_verifications?type=${type}`).then(response => {
      let id = response.data.id

      dispatch("job/refreshJob", { jobId: state.id }, { root: true })

      router.push(`/jobs/${state.id}/edit/barcode/${id}`)
    })
  },
  async submit({ state, rootState }) {
    if (state.id) return this.$axios.patch(`/data_api/jobs/${state.id}`, jobObjectForParam(state, rootState))
    else this.$axios.post(`/data_api/jobs`, jobObjectForParam(state, rootState))
  },
  reactToZoneChange({ commit, state }, newZoneId) {
    commit("updateField", { path: "zoneId", value: newZoneId })

    return this.$axios.get(`/data_api/zones/${state.zoneId}/work_areas`)
      .then(({ data }) => {
        commit("updateField", { path: "workAreas", value: digestWorkAreasData(data) })
        commit("updateField", {
          path: "workAreaIds",
          value: state.originalZoneData.id == newZoneId ? state.originalZoneData.assignedWorkAreaIds : []
        })
        commit("updateField", {
          path: "postIds",
          value: state.originalZoneData.id == newZoneId ? state.originalZoneData.assignedPostIds : []
        })
      })
  }
}