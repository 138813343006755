export default {
  isNew(params) {
    return params.question_id === 'new'
  },
  getUrl(params, questionType) {
    let getUrl
    if (this.isNew(params)) {
      getUrl = `/data_api/audit/questionnaires/${params.id}/${questionType}/new`;
    } else {
      getUrl = `/data_api/audit/questionnaires/${params.id}/${questionType}/${params.question_id}/edit`;
    }

    return getUrl
  },
  setState(state, response) {
    Object.keys(response).forEach(key => {
      let storeKey = this.convertToCamelCase(key)
      state[storeKey] = response[key]
    })

    return new Promise((resolve) => resolve)
  },
  setPassImageChoice(state) {
    if (state.optionalImage) state.passImageChoice = "optional"
    else if (state.requiredImage) state.passImageChoice = "required"
    else state.passImageChoice = "none"
  },
  setControlChartInstructions(state) {
    let instructions = state["controlChartInstructions"]["control_chart_instruction"]
    let limits = instructions["limit"]
    state["limitId"] = instructions["limit_id"]
    state["instructionId"] = instructions["id"]
    delete limits["id"]
    this.setState(state, limits)
  },
  setCameraSettings(state){
    state.cameraSetting = state.cameraSetting.camera_setting
  },
  convertAllKeysToSnakeCase(obj) {
    var convertedObj = {}

    Object.keys(obj).forEach(key => {
      let newKey = this.convertToSnakeCase(key)
      convertedObj[newKey] = obj[key]
    })

    return convertedObj
  },
  convertToSnakeCase(key) {
    return key.replace( /([A-Z])/g, "_$1").toLowerCase();
  },
  convertToCamelCase(key) {
    return key.replace(/_([a-z])/g, (g) => { return g[1].toUpperCase()})
  },
  getEmptyPartImageObject() {
    return {
      name: "",
      picture: {},
      preview: null,
      _destroy: false
    }
  }
}