import AxiosWrapper from "~/assets/javascript/AxiosWrapper"

export default {
  addShowParamToUrl(paramName, id, historyName) {
    let param = {}
    param[paramName] = id
    let url =  AxiosWrapper.buildPathWithSearchParams(param)
    history.replaceState(history.state, historyName, url);
  },
  removeShowParamFromUrl(paramName, historyName) {
    let cleanUrl = this.removeURLParameter(window.location.href, paramName)
    history.replaceState(history.state, historyName, cleanUrl);
  },
  removeURLParameter(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
      return url;
    } else {
      return url;
    }
  },
  removeLibrariesParams() {
    this.removeShowParamFromUrl('show_copy_downtime', 'Libraries')
    this.removeShowParamFromUrl('show_copy_recipient', 'Libraries')
  },
  questionnairesObjectForCopyRequest(groupsObject, defaultGroups) {
    let questionnairesObject = {}
    let questionnairesIdsObject = []

    groupsObject.questionnaire_groups.forEach(group => this.workflowForGroups(group, questionnairesObject, questionnairesIdsObject))
    defaultGroups.forEach(group => this.workflowForGroups(group, questionnairesObject, questionnairesIdsObject))

    return {audit_questionnaire_group: questionnairesObject, questionnaire_ids: questionnairesIdsObject}
  },
  workflowForGroups(group, questionnairesObject, questionnairesIdsObject) {
    group.questionnaires.forEach(q => {
      this.selectedQuestionnaireWorkflow(q, questionnairesObject, questionnairesIdsObject)
    })
  },
  selectedQuestionnaireWorkflow(questionnaire, questionnairesObject, questionnairesIdsObject) {
    if (questionnaire.selected) {
      let nameObject = { "name": questionnaire.name, id: questionnaire.id }
      // questionnairesObject[questionnaire.id] = nameObject
      questionnairesIdsObject.push(nameObject)
    }
  },
}