import Vue from 'vue';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';

AxiosWrapper.init();
import { getField, updateField } from 'vuex-map-fields';



const getDefaultState = () => ({
  logs: [],
  jobs: [],
  jobLogs: {},
  questionnaireLogs: {}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateJobLogs(state, {jobId, logs}){
    Vue.set(state.jobLogs, jobId, logs)
  },
  updateQuestionnaireLogs(state, {questionnaireId, logs}){
    Vue.set(state.questionnaireLogs, questionnaireId, logs)
  },
  addUnselectedOptionToJobs(state){
    state.jobs = [{id: null, identifier:translate('select')},...state.jobs]
  }
}

export const actions = {
  async init({commit}){

    let zoneId = AxiosWrapper.getNextPathNameElement('zones')

    AxiosWrapper.get(`/data_api/zones/${zoneId}/queue_logs`).then(response => {
      commit("resetState")
      commit('updateField', { path: 'logs', value: response.data.logs } )
      commit('updateField', { path: 'jobs', value: response.data.jobs } )
      commit('addUnselectedOptionToJobs')
    })
  },
  getJobLogs({commit}, jobId){
    let zoneId = AxiosWrapper.getNextPathNameElement('zones')

    AxiosWrapper.get(`/data_api/zones/${zoneId}/queue_logs/job_logs?job_id=${jobId}`)
    .then( response => {
      let logs = response.data.job_logs
      commit('updateJobLogs', {jobId, logs })
      commit('updateField', { path: `jobLogs.${jobId}.questionnaires`, value: response.data.questionnaires } )
    })
  },
  getQuestionnaireLogs({commit}, questionnaireId){
    let zoneId = AxiosWrapper.getNextPathNameElement('zones')

    AxiosWrapper.get(`/data_api/zones/${zoneId}/queue_logs/questionnaire_logs?questionnaire_id=${questionnaireId}`)
      .then( response => {
        let logs = response.data.questionnaire_logs
        commit('updateQuestionnaireLogs', { questionnaireId, logs } )
      })
  },
  deleteCaches(){
    let zoneId = AxiosWrapper.getNextPathNameElement('zones')
    
    if (confirm(translate("delete_confirm"))) {
      AxiosWrapper.delete(`/data_api/zones/${zoneId}/queue_logs/delete_caches`)
        .then( response => {
          window.location.reload();
        })
      }
    },
    clearLogs(){
      if (confirm(translate("delete_confirm"))) {
        AxiosWrapper.delete(`/data_api/zones/${zoneId}/queue_logs/clear_logs`)
        .then( response => {
          window.location.reload();
        })
    }
  },
}