import isArray from 'lodash/isArray';

export function toList(selectedValue) {
  if (isArray(selectedValue) && selectedValue.length > 0) return selectedValue.map(i => i.name).join(', ')
  return selectedValue ? selectedValue.name || I18n.translate('any') : I18n.translate('any')
}

export function formatDate(dateStr) {
  if (dateStr) {
    if (dateStr.includes("T")) {
      return (new Date(dateStr)).toLocaleString()
    } else {
      return (new Date(dateStr)).toLocaleString('en-US', { timeZone: 'UTC' })
    }
  } else {
    return I18n.translate('none')
  }
}