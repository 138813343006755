<template lang='pug'>
  .container.d-flex.flex-column
    h1 {{ 'oops_something_went_wrong' | translate }}
    p {{ error.message }}
    .align-self-center.d-flex.flex-column.align-items-center
      a.btn.btn-link(:href="supportUrl")
        h4 {{ "contact_us" | translate }}
      button.btn.btn-link(@click="$router.go(-1)") {{ "back" | translate }}
      nuxt-link.btn.btn-link(to="/") {{ "home" | translate }}
      button.btn.btn-link(@click="$router.go()") {{ "reload" | translate }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  mounted() {
    // Now that it is 2.4 the following has been commented. if no error, delete.
    // REMOVE IN VERSION 2.4
    // if (this.error && this.error.statusCode === 500
      // && /^Loading chunk [0-9]+ failed/.test(this.error.message)
      // && window.location.hash !== '#retry') {
      // the chunk might no longer be available due to a recent redeployment of the page
      // mark the page to don't trigger reload infinitely
    //   window.location.hash = '#retry'
    //   window.location.reload(true)
    // }
  },
  computed: mapGetters('login', ['supportUrl']),
  props: {
    error: {
      default: () => ({})
    }
  }
}
</script>