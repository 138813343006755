import { translate } from '~/assets/javascript/localization/Localization'
export default {
  getTranslatedNameFor(option) {
    switch (option) {
      case "open_count":
        return translate('open')
        break;
      case "closed_count":
        return translate('closed')
        break;
      case "overdue_count":
        return translate('overdue')
        break;
      case "not_assigned_count":
        return translate('not_assigned')
        break;
      default:
        return ""
    }
  }
};