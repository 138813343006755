export default {
  removeURLParameter(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
      return url;
    } else {
      return url;
    }
  },
  paramsMap() {
    return {
      zone_ids: "zone_ids",
      job_ids: "job_ids",
      work_area_ids: "work_area_ids",
      post_ids: "post_ids",
      questionnaire_group_ids: "questionnaire_group_ids",
      questionnaire_ids: "questionnaire_ids",
      shift_description_ids: "shift_description_ids",
      part_number: "part_numbers_constraint_ids",
      serial_number: "serial_numbers_constraint_ids",
      lot_number: "lot_numbers_constraint_ids",
      other1: "other1s_ids",
      other2: "other2s_ids",
    }
  }
}