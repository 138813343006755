import { getField, updateField } from "vuex-map-fields";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
const getUrl = "/data_api/reject_summary";
AxiosWrapper.init();

const getDefaultState = () => ({
  dates: null,
  summaryReport: {}
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  init(state, getRequestData) {
    ({
      dates: state.dates,
      summary_report: state.summaryReport,
    } = getRequestData);
  },
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(getUrl, null).then((response) => {
      commit("init", response.data);
    });
  }
}
