import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  job: null,
  jobRevision: null,
  isCustomer: null
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, data) {
    state.job = data.job;
    state.jobRevision = data.job_revision;
    state.isCustomer = data.is_customer
  },
  updateField
}

export const actions = {
  async init({ state, commit, dispatch }, params) {
    commit("resetState")

    AxiosWrapper.get('/data_api/defect_code_models', { params: { job_revision_id: params.job_revision_id } })
      .then(({data}) => {
        commit('init', data);
        dispatch('jobRevision/setJobRevision', data.job_revision, { root: true })
      })
  },
}