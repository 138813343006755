<template lang="pug">
  JobRevisionHeader(title="downtime")
</template>

<script>
import JobRevisionHeader from '~/components/JobRevisions/Header'
export default {
  components: { JobRevisionHeader }
}
</script>

