import { translate } from "~/assets/javascript/localization/Localization"
import { getField, updateField } from 'vuex-map-fields';

let getDefaultState = function() {
  return {
    description: '',
    job: null,
    jobRevision: null,
    initialized: false,
    saveInProgress: false
  }
}

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField
}

export const getters = {
  breadcrumbs: (state, getters, rootState, rootGetters) => {
    if(state.job && state.jobRevision){
      return [
        ...rootGetters['jobRevision/getJobRevisionBreadcrumbs'],
        { text: translate('yard'), path: `/jobs/${state.job['id']}/job_revisions/${state.jobRevision['id']}/verification_work_items`},
        { text: translate('import') }
      ]
    }
    else return []
  },
  description(state) {
    if (state.initialized) {
      let text = translate('revision') + `: ${state.jobRevision.formatted_revision_date} ${state.jobRevision.revision_number}`
      if (state.jobRevision.part_number) text = `${text} | ${translate('part_number')}: ${state.jobRevision.part_number}`
      return text
    } else {
      return null
    }
  },
  getField
}

export const actions = {
}