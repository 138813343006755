<template lang="pug">
  email
</template>

<script>

import Email from '~/components/Layouts/Email.vue'

export default {
  components: { Email },
  middleware: ['sessionHandler', 'pageRedirections']
}
</script>