import Vue from 'vue';
import Vuex from 'vuex';
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);
AxiosWrapper.init();

const getDefaultState = () => ({
  shippingMask: {},
  partMask: {},
  partMaskMode: {},
  partMaskOriginalContent: '',
  optionsForModeSelect: [],
  workItemLabelVerificationId: null
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitData(state, data) {
    ({
      shipping_mask: state.shippingMask,
      part_mask: state.partMask,
      options_for_mode_select: state.optionsForModeSelect
    } = data);
  },
  setMode(state, {mask, modeObject}) {
    Vue.set(mask, "mode", modeObject)
  },
  setModeToDefault(state, mask) {
    Vue.set(mask, "mode", state.optionsForModeSelect[0])
  },
  setPartMaskModeToDefault(state) {
    state.partMaskMode = state.optionsForModeSelect[0]
  },
  setPartMaskMode(state, mode) {
    state.partMaskMode = mode
  },
  setPartMaskOrginalContent(state) {
    state.partMaskOriginalContent = state.partMask.original_content
  },
  setOrginalContentOnPartMask(state) {
    state.partMask.original_content = state.partMaskOriginalContent
  },
  updateField,
}

export const getters = {
  getField,
  showRegexSim(state) {
    return (state.shippingMask.mode && state.partMaskMode && (state.shippingMask.mode.value == "Mask::RegularExpression" || state.partMaskMode.value == "Mask::RegularExpression"))
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    commit("resetState")

    let workItemLabelVerificationId = AxiosWrapper.getNextPathNameElement("work_item_label_verifications");
    commit('updateField', {path: 'workItemLabelVerificationId', value: workItemLabelVerificationId})

    AxiosWrapper.get(`/data_api/barcode/work_item_label_verifications/${state.workItemLabelVerificationId}/change_work_item_field_masks`).then(({ data }) => {
      commit("setInitData", data)
      commit("setPartMaskOrginalContent")

      if (state.shippingMask.mode == null) {
        commit("setModeToDefault", state.shippingMask)
      } else {
        dispatch("convertShippingMaskModeToMultiSelectObject")
      }

      if (state.partMask.mode == null) {
        commit("setPartMaskModeToDefault", state.partMaskMode)
      } else {
        dispatch("convertPartMaskModeToMultiSelectObject")
      }
    })
  },
  async convertShippingMaskModeToMultiSelectObject({ state, commit }) {
    state.optionsForModeSelect.forEach(modeObject => {
      if (state.shippingMask.mode == modeObject.value) {
        commit("setMode", {
          mask: state.shippingMask,
          modeObject
        })
      }
    });
  },
  async convertPartMaskModeToMultiSelectObject({ state, commit }) {
    state.optionsForModeSelect.forEach(modeObject => {
      if (state.partMask.mode == modeObject.value) {
        commit("setPartMaskMode", modeObject)
      }
    });
  },
  async convertMultiSelectObjectToMode({ state, commit }) {
    commit("setMode", {
      mask: state.shippingMask,
      modeObject: state.shippingMask.mode.value
    })
    commit("setMode", {
      mask: state.partMask,
      modeObject: state.partMaskMode.value
    })
  },
  async saveChanges({ state, dispatch, commit }) {
    dispatch("convertMultiSelectObjectToMode")
    commit("setOrginalContentOnPartMask")
    let barcode_part_number_label_verification = {
      id: state.workItemLabelVerificationId,
      shipping_label_mask_attributes: {
        ...state.shippingMask
      },
      part_label_mask_attributes: {
        ...state.partMask
      }
    }

    AxiosWrapper.patch(`/data_api/barcode/work_item_label_verifications/${state.workItemLabelVerificationId}/save_work_item_field_masks`, { barcode_part_number_label_verification })
  },
}