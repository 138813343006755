import { translate } from '~/assets/javascript/localization/Localization';

export default {
  rangeFilterSelected(filter) {
    return filter['number_of_days_ago'] != null && filter['number_of_days_ahead'] != null
  },
  recentZoneOrJobSelected(filter) {
    return filter['is_set_to_most_recent_zone'] != null || filter['is_set_to_most_recent_job'] != null || filter['is_set_to_date'] != null
  },
  returnDateRangesDependingOn(gauge, displayStyle) {
    const barChartDateRangeOptions = [{
      key: "is_set_to_current_year",
      value: translate('is_set_to_current_year')
    }, {
      key: "number_of_months_ago",
      value: translate('number_of_months_ago')
    }]
    const commonDateRangeOptions = [
      {
        key: "is_set_to_current_month",
        value: translate('is_set_to_current_month')
      },
      {
        key: "is_set_to_current_week",
        value: translate('is_set_to_current_week')
      },
      {
        key: "is_set_to_current_week_and_previous_week",
        value: translate('is_set_to_current_week_and_previous_week')
      }]

    switch (gauge.type) {
      case "Dashboard::Gauges::CorrectiveAction":
        if (displayStyle == 'pie_chart' || displayStyle == 'list') {
            return [
              ...commonDateRangeOptions,
              {
                key: "range",
                value: translate('range')
              },
            ]
        } else {
            return barChartDateRangeOptions
        }
      case "Dashboard::Gauges::AuditsPerformed":
        return [
          {
            key: "is_set_to_current_year",
            value: translate('is_set_to_current_year')
          },
          {
            key: "is_set_to_current_month",
            value: translate('is_set_to_current_month')
          },
          {
            key: "number_of_days_ago",
            value: translate('number_of_days_ago')
          }
        ]
      case "Dashboard::Gauges::AuditScore":
        if (displayStyle == 'radar_chart') {
          return [
            ...commonDateRangeOptions,
            {
              key: "range",
              value: translate('range')
            },
            {
              key: "is_set_to_most_recent_zone",
              value: translate('is_set_to_most_recent_zone')
            },
            {
              key: "is_set_to_most_recent_job",
              value: translate('is_set_to_most_recent_job')
            },
            {
              key: "is_set_to_date",
              value: translate('the_date')
            },
          ]
        } else {
          return [
            {
              key: "is_set_to_current_year",
              value: translate('is_set_to_current_year')
            },
            ...commonDateRangeOptions,
            {
              key: "range",
              value: translate('range')
            },
            {
              key: "is_set_to_most_recent_zone",
              value: translate('is_set_to_most_recent_zone')
            },
            {
              key: "is_set_to_most_recent_job",
              value: translate('is_set_to_most_recent_job')
            },
          ]
        }
      default:
        return [
          ...commonDateRangeOptions,
          {
            key: "number_of_days_ago",
            value: translate('number_of_days_ago')
          }
        ]
    }
  },
};