<template lang='pug'>
  #job_revision_header
    legend {{ translate('job_revision_information') }}
    .d-flex.flex-column.flex-md-row.justify-content-md-between
      label.info {{ translate('revision') + `: ${jobRevisionItem.formatted_revision_date} ${jobRevisionItem.revision_number}` }}
      label.info(v-if="jobRevisionItem.part_number") {{ translate('part_number') + `: ${jobRevisionItem.part_number}` }}
      div(v-if="isCustomer === false")
        .dropdown
          button#dropdownMenuButton.btn.btn-outline-light.dropdown-toggle(aria-expanded=false,
                                                                          aria-haspopup=true,
                                                                          data-toggle="dropdown",
                                                                          type="button")
            | {{ translate('job_revision_navigation') }}
          .dropdown-menu.dropdown-menu-right(aria-labelledby="dropdownRightAligned")
            nuxt-link.dropdown-item.job_revision_data_button(:to="jobRevisionUrl + '/edit'")
              | {{ translate('job_revision_fieldset') }}
            nuxt-link.dropdown-item(v-if="isYardJob",
                                    :to="jobRevisionUrl + '/verification_work_items'")
              | {{ translate('yard_campaign') }}
            nuxt-link.dropdown-item(v-if="canEditJobRevision",
                                    :to="jobRevisionUrl + '/random_questionnaires'")
              | {{ translate('random') }}
            nuxt-link.dropdown-item(:to="`${jobRevisionUrl}/training_results`")
              | {{ translate('training_results') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/audit/questionnaires'")
              | {{ translate('questionnaire_results') }}
            nuxt-link.dropdown-item(v-if="canEditJobRevision",
                                    :to="jobRevisionUrl + '/downtime'")
              | {{ translate('downtime') }}
            nuxt-link.dropdown-item(v-if="canEditJobRevision",
                                    :to="jobRevisionUrl + '/audit_expansions'")
              | {{ translate('audit_expansions') }}
            nuxt-link.dropdown-item(v-if="canEditJobRevision",
                                    :to="jobRevisionUrl + '/defect_code_models'")
              | {{ translate('defect_code_models') }}
            nuxt-link.dropdown-item(v-if="canEditJobRevision",
                                    :to="jobRevisionUrl + '/checklist/control_chart_instructions'")
              | {{ translate('control_chart') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/heatmaps/search'")
              | {{ translate('heatmap') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/reference_pictures'")
              | {{ translate('reference_pictures') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/stats'")
              | {{ translate('stats') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/ichart'")
              | {{ translate('i_chart') }}
            nuxt-link.dropdown-item(:to="jobRevisionUrl + '/shifts'")
              | {{ translate('shifts') }}
    .d-flex(v-if="canEditJobRevision && showBackButton")
      div
        nuxt-link.btn.btn-secondary(:to="jobRevisionUrl") {{ translate('back_to_job_revision') }}

</template>

<script>

export default {
  props: {
    jobRevisionItem:{
      type: Object,
      validator: function(value) {
        // Must have 'formatted_revision_date' to be valid
        return Boolean(value.formatted_revision_date)
      },
      default: () => ({})
    },
    jobItem:{
      type: Object
    },
    isCustomer: {
      default: false
    },
    canEditJobRevision: {
      default: false
    },
    showBackButton: {
      default: true
    }
  },
  computed:{
    isYardJob(){
      return this.jobItem.is_yard_campaign;
    },
    jobRevisionUrl() {
      return `/jobs/${this.jobItem.id}/job_revisions/${this.jobRevisionItem.id}`
    }
  }
}
</script>