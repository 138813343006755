import Vue from 'vue'
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import cloneDeep from "lodash/cloneDeep";
import PermissionsHelper from "~/components/Dashboard/helpers/GaugeEditPermissionsHelper.js";
import EditGaugeHelper from '~/components/Dashboard/helpers/EditGaugeHelper'
import { createHelpers } from "vuex-map-fields";
import DateRangeHelper from '~/components/Dashboard/helpers/DateRangeHelper.js';
import { translate } from '~/assets/javascript/localization/Localization';
import gateway from "~/assets/javascript/editGaugeGateway"

const { getEditGaugeField, updateGaugeField } = createHelpers({
  getterType: "getEditGaugeField",
  mutationType: "updateGaugeField"
});

const allDateRanges = ["is_set_to_current_month", "is_set_to_current_week",
                      "is_set_to_current_week_and_previous_week",
                      "number_of_days_ago", "number_of_days_ahead",
                      "is_set_to_current_year", "number_of_months_ago",
                      "is_set_to_most_recent_zone",
                      "is_set_to_date",
                      "is_set_to_most_recent_job"]

const getDefaultState = () => ({
  editData: null,
  gaugeCopy: null,
  selectedDateRange: '',
  userDateRangeValue: null,
  name: '',
  number_of_columns: null,
  display_style: '',
  iframe_html: '',
  answer_breakdown_compliance_by: '',
  is_set_to_current_year: false,
  is_set_to_current_month: false,
  is_set_to_current_week: false,
  is_set_to_current_week_and_previous_week: false,
  number_of_days_ago: null,
  number_of_days_ahead: null,
  number_of_months_ago: null,
  is_set_to_most_recent_zone: false,
  is_set_to_most_recent_job: false,
  is_set_to_date: false,
  cutoff_date: null,
  interval_kind: null,
  interval: null,
  show_empty_records: false,
  show_limit: null,
  show_all_comments: false,
  gateway: gateway,
  how_to_calculate: '',
  group_by: ''
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  editInit(state, getRequestData) {
    state.editData = getRequestData;
  },
  attachFilterTo(state, gauge) {
    Vue.set(gauge, "filter_attributes", state.editData.gauge_filter);
  },
  attachDisplayStyle(state, gauge) {
    Vue.set(gauge.filter_attributes, "display_style", state.display_style);
  },
  resetDateRanges(state) {
    if (state.display_style == 'bar_chart') {
      state.selectedDateRange = {
        key: "is_set_to_current_year",
        value: translate('is_set_to_current_year')
      }
      state.is_set_to_current_year = true
      state.userDateRangeValue = null
      state.is_set_to_current_month = false
      state.is_set_to_current_week = false
      state.is_set_to_current_week_and_previous_week = false
      state.number_of_days_ago = null
      state.number_of_days_ahead = null
    } else {
      state.selectedDateRange = {
        key: "is_set_to_current_month",
        value: translate('is_set_to_current_month')
      }
      state.is_set_to_current_month = true
      state.is_set_to_current_year = false
      state.number_of_days_ago = null
      state.number_of_days_ahead = null
      state.number_of_months_ago = null
    };
  },
  resetAllDatesRangesOtherThan(state, selected) {
    let toBeReset = allDateRanges.filter(r => r != selected);
    toBeReset.forEach(range => {
      if (typeof (state[range]) == typeof (true)) {
        state[range] = false
      } else {
        state[range] = null
      }
    });
  },
  setSelectedDateRange(state, filter) {
    allDateRanges.forEach(range => {
      if (filter[range] == true) {
        state.selectedDateRange = { key: range, value: translate(range) }
      }
      if ((filter[range] != null && filter[range] != false && !DateRangeHelper.rangeFilterSelected(filter))) {
        state.selectedDateRange = { key: range, value: translate(range) }
        if (filter[range] !== true) {
          state.userDateRangeValue = filter[range]
        }
      }
      if (DateRangeHelper.rangeFilterSelected(filter)) {
        state.selectedDateRange = { key: "range", value: translate('range') }
        state.number_of_days_ago = filter['number_of_days_ago']
        state.number_of_days_ahead = filter['number_of_days_ahead']
      }

      if (DateRangeHelper.recentZoneOrJobSelected(filter)) {
        state.is_set_to_most_recent_zone = filter['is_set_to_most_recent_zone']
        state.is_set_to_most_recent_job = filter['is_set_to_most_recent_job']
        state.is_set_to_date = filter['is_set_to_date']
      }
    });
  },
  setDateRange(state, { range, value }) {
    state[range] = value
  },
  attachSelectBoxParamsToFilter(state, { gauge, select_params }) {
    gauge.filter_attributes.zone_ids = select_params.zone_ids
    gauge.filter_attributes.job_ids = select_params.job_ids
    gauge.filter_attributes.part_number = select_params.part_numbers_constraint_ids
    gauge.filter_attributes.serial_number = select_params.serial_numbers_constraint_ids
    gauge.filter_attributes.lot_number = select_params.lot_numbers_constraint_ids
    gauge.filter_attributes.other1 = select_params.other1s_ids
    gauge.filter_attributes.other2 = select_params.other2s_ids
    gauge.filter_attributes.shift_description_ids = select_params.shift_description_ids
    gauge.filter_attributes.questionnaire_group_ids = select_params.questionnaire_group_ids
    gauge.filter_attributes.questionnaire_ids = select_params.questionnaire_ids
    gauge.filter_attributes.work_area_ids = select_params.work_area_ids
    gauge.filter_attributes.post_ids = select_params.post_ids
  },
  setStateFromGaugeAttributes(state, gauge) {
    let filter = gauge.filter_attributes
    state.name = gauge.name
    state.number_of_columns = gauge.number_of_columns
    state.how_to_calculate = filter.how_to_calculate
    state.display_style = filter.display_style || ''
    state.group_by = filter.group_by
    state.answer_breakdown_compliance_by = filter.answer_breakdown_compliance_by
    state.is_set_to_current_year = filter.is_set_to_current_year
    state.is_set_to_current_month = filter.is_set_to_current_month
    state.is_set_to_current_week = filter.is_set_to_current_week
    state.is_set_to_current_week_and_previous_week = filter.is_set_to_current_week_and_previous_week
    state.number_of_days_ago = filter.number_of_days_ago
    state.number_of_days_ahead = filter.number_of_days_ahead
    state.number_of_months_ago = filter.number_of_months_ago
    state.cutoff_date = filter.cutoff_date
    state.is_set_to_most_recent_zone = filter.is_set_to_most_recent_zone
    state.is_set_to_most_recent_job = filter.is_set_to_most_recent_job
    state.is_set_to_date = filter.is_set_to_date
    state.interval_kind = filter.interval_kind
    state.interval = filter.interval
    state.show_empty_records = filter.show_empty_records
    state.show_limit = filter.show_limit
    state.show_all_comments = filter.show_all_comments
  },
  setGaugeAttributesFromState(state, gauge) {
    gauge.name = state.name
    gauge.number_of_columns = state.number_of_columns
    gauge.iframe_html = state.iframe_html
    gauge.filter_attributes.answer_breakdown_compliance_by = state.answer_breakdown_compliance_by
    gauge.filter_attributes.is_set_to_current_year = state.is_set_to_current_year
    gauge.filter_attributes.is_set_to_current_month = state.is_set_to_current_month
    gauge.filter_attributes.is_set_to_current_week = state.is_set_to_current_week
    gauge.filter_attributes.is_set_to_current_week_and_previous_week = state.is_set_to_current_week_and_previous_week
    gauge.filter_attributes.number_of_days_ago = state.number_of_days_ago
    gauge.filter_attributes.number_of_days_ahead = state.number_of_days_ahead
    gauge.filter_attributes.number_of_months_ago = state.number_of_months_ago
    gauge.filter_attributes.interval_kind = state.interval_kind
    gauge.filter_attributes.interval = state.interval
    gauge.filter_attributes.show_empty_records = state.show_empty_records
    gauge.filter_attributes.show_limit = state.show_limit
    gauge.filter_attributes.show_all_comments = state.show_all_comments
    gauge.filter_attributes.how_to_calculate = state.how_to_calculate
    gauge.filter_attributes.group_by = state.group_by
    gauge.filter_attributes.cutoff_date = state.cutoff_date
    gauge.filter_attributes.is_set_to_most_recent_zone = state.is_set_to_most_recent_zone
    gauge.filter_attributes.is_set_to_most_recent_job = state.is_set_to_most_recent_job
    gauge.filter_attributes.is_set_to_date = state.is_set_to_date
  },
  setEmbedStateFromGaugeAttributes(state, gauge) {
    state.name = gauge.name
    state.number_of_columns = gauge.number_of_columns
    state.iframe_html = gauge.iframe_html
  },
  setEmbedAttributesFromState(state, gauge) {
    gauge.name = state.name
    gauge.number_of_columns = gauge.number_of_columns
    gauge.iframe_html = state.iframe_html
  },
  setGaugeType(state, { type, gauge }) {
    gauge.type = type
  },
  setGaugeSize(state, { size, gauge }) {
    gauge.number_of_columns = size
  },
  setGaugeName(state, { name, gauge }) {
    gauge.name = name
  },
  setUserInputDateRange(state, { selectedDateRange, value }) {
    state[selectedDateRange] = value
  },
  addPermissions(state, gauge){
    PermissionsHelper.addEditPermissionsTo(gauge);
  },
  updateGaugeField
}

export const getters = {
  getEditGaugeField,
  dateRangesOptions(state) {
    if (state.gaugeCopy) return DateRangeHelper.returnDateRangesDependingOn(state.gaugeCopy, state.display_style)
  },
  needsCutoffDate(state) {
    return state.selectedDateRange.key == "is_set_to_most_recent_zone" || state.selectedDateRange.key == "is_set_to_most_recent_job" || state.selectedDateRange.key == "is_set_to_date"
  },
  needsInputForDateRange(state) {
    return (state.selectedDateRange.key == "number_of_days_ago" || state.selectedDateRange.key == "number_of_days_ahead" || state.selectedDateRange.key == "number_of_months_ago")
  },
  needsRangeForDateRange(state) {
    return state.selectedDateRange.key == "range"
  },
  isInitialized(state) {
    return Boolean(state.editData)
  }
}

export const actions = {
  async init({ commit, dispatch, state }) {
    let row_id = state.gaugeCopy.row_id
    let gaugeId = state.gaugeCopy.id
    commit('addPermissions', state.gaugeCopy)
    state.gateway.axios = this.$axios
    state.gateway.init({row_id, gaugeId})
      .then(response => {
        commit("editInit", response.data);
        dispatch("attachFilterWorkflow", { row_id, gauge: state.gaugeCopy }).then(() => {
          if (state.gaugeCopy.type != "Dashboard::Gauges::Embed") {
            dispatch("selects/chainingInit", { params: state.editData.gauge_filter, paramsMap: EditGaugeHelper.paramsMap() }, { root: true });
          }
        });
      });
  },
  async attachFilterWorkflow({ commit }, { gauge }) {
    if (gauge.type != "Dashboard::Gauges::Embed") {
      commit("attachFilterTo", gauge);
      commit("setStateFromGaugeAttributes", gauge)
      commit("setSelectedDateRange", gauge.filter_attributes)
    } else {
      commit("setEmbedStateFromGaugeAttributes", gauge)
    }
  },
  async attachAttributesToGaugeWorkflow({ dispatch, commit, rootState }, gauge) {
    if (gauge.type === "Dashboard::Gauges::CorrectiveAction" || gauge.type === "Dashboard::Gauges::AuditScore") {
      commit("attachDisplayStyle", gauge);
    }
    if (gauge.type != "Dashboard::Gauges::Embed") {
      commit("attachSelectBoxParamsToFilter", { gauge, select_params: rootState.selects.params })
      dispatch("attachUserInputForDateRange")
      commit("setGaugeAttributesFromState", gauge)
    } else {
      commit("setEmbedAttributesFromState", gauge)
    }
  },
  async attachUserInputForDateRange({ state, commit, getters }) {
    if (getters.needsInputForDateRange) {
      let value = state.userDateRangeValue
      let selectedDateRange = state.selectedDateRange.key
      commit("setUserInputDateRange", { selectedDateRange, value })
    }
  },
  async updateGauge({ dispatch }, { gauge, router }) {
    dispatch("attachAttributesToGaugeWorkflow", gauge)
    AxiosWrapper.patch(`/data_api/dashboard/rows/${gauge.row_id}/gauges/${gauge.id}`, { gauge }).then(() => {
      dispatch('cleanUp')
      router.go()
    }).catch(error => {
      dispatch('errorMethods/defaultError', error, { root: true });
    })
  },
  async startEditingGauge({ commit, dispatch }, { gauge }) {
    let gaugeCopy = cloneDeep(gauge)
    commit('selects/resetState', null, { root: true })
    commit("updateGaugeField", { path: "gaugeCopy", value: gaugeCopy });
    dispatch('init')
  },
  async cleanUp({ commit }) {
    commit("updateGaugeField", { path: "gaugeCopy", value: null });
    commit("updateGaugeField", { path: "editData", value: null });
  },
  async dateRangeSelectedWorkFlow({ commit }, dateRange) {
    commit("setDateRange", { range: dateRange, value: true })
    commit("resetAllDatesRangesOtherThan", dateRange)
  },
  async dateRangeWithUserInputWorkFlow({ commit }, dateRange) {
    commit("resetAllDatesRangesOtherThan", dateRange)
  },
  async selectDateRange({ dispatch, commit }, selectedDateRange) {
    switch (selectedDateRange.key) {
      case "is_set_to_current_month":
        dispatch("dateRangeSelectedWorkFlow", "is_set_to_current_month")
        break;
      case "is_set_to_current_week":
        dispatch("dateRangeSelectedWorkFlow", "is_set_to_current_week")
        break;
      case "is_set_to_current_week_and_previous_week":
        dispatch("dateRangeSelectedWorkFlow", "is_set_to_current_week_and_previous_week")
        break;
      case "number_of_days_ago":
        dispatch("dateRangeWithUserInputWorkFlow", "number_of_days_ago")
        break;
      case "is_set_to_current_year":
        dispatch("dateRangeSelectedWorkFlow", "is_set_to_current_year")
        break;
      case "number_of_months_ago":
        dispatch("dateRangeWithUserInputWorkFlow", "number_of_months_ago")
        break;
      case "range":
        dispatch("dateRangeWithUserInputWorkFlow", "range")
        break;
      case "is_set_to_most_recent_zone":
        dispatch("dateRangeWithUserInputWorkFlow", "cutoff_date")
        commit("updateGaugeField", { path: "is_set_to_most_recent_zone", value: true })
        break;
      case "is_set_to_most_recent_job":
        dispatch("dateRangeWithUserInputWorkFlow", "cutoff_date")
        commit("updateGaugeField", { path: "is_set_to_most_recent_job", value: true })
        break;
      case "is_set_to_date":
        dispatch("dateRangeWithUserInputWorkFlow", "cutoff_date")
        commit("updateGaugeField", { path: "is_set_to_date", value: true })
        break;
      default:
        break;
    }
  }
}