<template lang="pug">
  #root.d-flex.flex-column
    .header
      nav.navbar.navbar-expand-md.navbar-dark.bg-dark
        .navbar-brand.d-flex.align-items-center.p-0
          img(src="/edn_logo_white.png" height="35" alt="logo")

    .container.body
      nuxt

    .footer.container
      a(:href='supportUrl', target="_blank") {{ 'contact_us' |  translate }}
      p {{ 'patent_pending' | translate }}
      p {{ 'copyright' | translate }}
      p {{ 'copyright2' | translate }}
</template>

<script>
import CustomerNav from '~/components/CustomerNav.vue'
import DefaultNav from '~/components/DefaultNav.vue'
import LoginNav from '~/components/LoginNav.vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { mapGetters, mapMutations } from 'vuex'

export default {
  created() {
    let params = AxiosWrapper.getSearchParams()

    if (params.locale) {
      let locale = params.locale.split("-")[0]
      this.setLocale(locale)
    }
  },
  components: { CustomerNav, DefaultNav, LoginNav },
  computed: mapGetters('login', ['isLoggedIn', 'isCustomer', 'supportUrl']),
  methods: mapMutations('deviceDashboard', ['setLocale'])
}

</script>

<style lang="scss" scoped>
@import "~/assets/stylesheets/colors.scss";
#root {
  height: 100vh;
}

.body {
  flex-grow: 1;
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  color: $gray7;
}
</style>