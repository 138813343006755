import { cloneDeep } from "lodash";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";

export const state = () => ({
  reasons: [{ justifiable: null }],
  originalReasons: []
})

export const getters = {
  getField,
  reasons(state) {
    return state.reasons
  }
}

export const mutations = {
  updateField,
  loadReasons(state, responseData) {
    state.originalReasons = responseData.reasons.data.map(reason => reason.attributes);
    state.reasons = cloneDeep(state.originalReasons);
  },
  setJustifiable(state, { reasonIndex, justifiable }) {
    Vue.set(state.reasons[reasonIndex], "justifiable", justifiable);
  }
}

export const actions = {
  loadReasons({ commit }) {
    return this.$makeApiRequest("loadLeftEarlyReasons")
      .then(({ data }) => commit("loadReasons", data));
  },
  save({ state, commit }) {
    let reasons = cloneDeep(state.reasons);
    let reasonIds = state.reasons.map(reason => reason.id);

    state.originalReasons.forEach(originalReason => {
      if (!reasonIds.includes(originalReason.id)) {
        reasons.push({ ...originalReason, _destroy: true });
      }
    });

    this.$axios.patch("/hrs/data_api/left_early_reasons/update_all", { reasons })
      .then(({ data }) => commit("loadReasons", data));
  }
}