import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  partImages: []
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question)
  },
  addEmptyPartImageObject(state) {
    state.partImages.push(QuestionsHelper.getEmptyPartImageObject())
  },
  removePartImageAt(state, index) {
    state.partImages[index]['_destroy'] = true
  },
  updateName(state, {newName, partImage}) {
    partImage["name"] = newName
  },
  setPreviewImage(state, {dataUrl, index}) {
    state.partImages[index].preview = dataUrl
  },
  setImageFile(state, {file, index}) {
    state.partImages[index].picture = file
  },
  attachPartImagesTo(state, params) {
    var attributes = {};
    state.partImages.forEach((image, index) => {
      image["preview"] = null
      attributes[index] = image;
    });
    params['part_images_attributes'] = attributes
  },
  updateField
}

export const getters = {
  getField
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_heatmap_question', name: 'heatmap'}, { root: true })
  },
  async configureInitState({ dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', true, { root: true })
  },
  async configureParams({ dispatch, commit }, params) {
    commit("questions/editQuestions/setWeightAttributesOn", params, { root: true })
    commit("questions/editQuestions/setAppliedChampionTemplateAttributes", params, { root: true })
    commit("attachPartImagesTo", params)
  },
  async imageAttached({ dispatch }, {e, index}) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    dispatch("attachImagePartImage", {file: files[0], index })

  },
  async attachImagePartImage({ commit }, {file, index }) {
    var reader = new FileReader();

    reader.onload = (e) => {
      const dataUrl = e.target.result;
      commit("setPreviewImage", {dataUrl, index})
    };

    reader.readAsDataURL(file)

    commit("setImageFile", {file, index})
  },
}