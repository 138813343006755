import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import clone from "lodash/clone";
import compact from "lodash/compact";
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';

const getDefaultState = () => ({
  tabletRolesForLessee: [],
  supervisor_role_id: null,
  billing_administrator_option_id: null,
  user: {
    first_name: null,
    last_name: null,
    email: null,
    identifier: null,
    id: null,
    time_zone: null,
    deleted: null,
    disabled: null,
    lessee_id: null,
    receive_errors: null,
    language_id: null,
    receive_release_notes: null,
    last_logged_in: null,
    quick_sign_in_role_id: null,
    daily_summary_send_time: null,
    tablet_created: null,
    type: null,
    enable_ical_feed: null,
    ical_token: null,
    security_concern_report_option: null,
    notify_on_time_sheet_submission: null,
    password: null,
    password_confirmation: null,
    can_approve_timesheets: null,
    "is_customer?": null,
    "is_admin_or_unit?": null,
    "is_corrective_action_specialist?": null,
    "persisted?": null,
    full_name: null,
    current_lessee_id: null,
    calendar_setting_attributes: {
      id: null,
      include_corrective_actions: null,
      include_scheduled_questionnaires: null
    },
    isCurrentUser: null
  },
  zones: [],
  selectableZones: [],
  selectableTabletRoles: [],
  timeZones: [],
  languages: [],
  loggedInAsAdmin: false,
  loginRoles: [],
  currentLesseeId: 0,
  calendarUrl: null,
  securityReportOptions: [],
  selectedLoginRole: null,
  currentTabletRoles: [],
  checkedTabletRoleIds: [],
  qrCodeSvg: "",
  vcardSvg: "",
  selectedZones: [],
  saving: false,
  copied: false
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitialState(state, data) {
    Object.assign(state.user, data.user)

    state.tabletRolesForLessee = data.tablet_roles_for_lessee

    state.zones = data.zones
    state.selectableTabletRoles = state.tabletRolesForLessee.map(tr => {
      return {
        label: tr.name,
        value: tr.id
      }
    })

    state.selectableZones = state.zones.map(zone => {
      return {
        label: zone.name,
        value: zone.id
      };
    });

    state.timeZones = data.time_zones;
    state.languages = data.languages
    state.loggedInAsAdmin = data.logged_in_as_admin;
    state.loginRoles = data.login_roles;
    state.currentLesseeId = data.current_lessee_id;
    state.calendarUrl = data.calendar_url;
    state.securityReportOptions = data.concern_report_options;
    state.billing_administrator_option_id = data.billing_administrator_option_id
    state.supervisor_role_id = data.supervisor_role_id

    state.timeZones = state.timeZones.map(tz => {
      return { name: tz, value: tz }
    })

    state.loginRoles = [{ name: translate('none'), id: null }, ...state.loginRoles]

    state.securityReportOptions = [{ name: translate('never'), value: null }, ...state.securityReportOptions.map(opt => {
      return { name: opt, value: opt }
    })]

    if (state.user.id) {
      state.user.isCurrentUser = data.is_current_user;
      state.selectedLoginRole = data.login_role ? data.login_role.role.id : null;
      state.currentTabletRoles = data.current_tablet_roles

      state.checkedTabletRoleIds = state.currentTabletRoles.map(role => role.id);

      state.qrCodeSvg = data.qr_code_svg;
      state.vcardSvg = data.vcard_qr_code_svg

      state.selectedZones = state.zones.filter(zone =>
        data.current_zone_ids.includes(zone.id)
      ).map(z => z.id);
    }
  },
  setUsers(state, newUsers) {
    state.users = newUsers.users
    state.permissions = newUsers.permissions
  },
  removeUser(state, userId) {
    let index = state.users.findIndex(u => u.id == userId);
    state.users.splice(index, 1);
  }
}

export const actions = {
  init({ commit }, userId) {
    commit("resetState")
    let apiUrl = `/data_api/users/new`;

    if (userId) {
      apiUrl = `/data_api/users/${userId}/edit`;
    }

    this.$axios.get(apiUrl).then(({ data }) => commit("setInitialState", data))
  },
  generateNewCalendarUrl({ state, commit }) {
    commit('updateField', { path: 'copied', value: false })

    AxiosWrapper.get(`/data_api/users/${state.user.id}/new_token`).then(r => {
      state.calendarUrl = r.data.url
    })
  },
  deleteActivityLogs({ state }) {
    AxiosWrapper.delete(`/data_api/activity_logs/remove_all_for_user/${state.user.id}`)
  },
  submit({ commit, state }) {
    let roleIds = clone(state.checkedTabletRoleIds);
    roleIds.push(state.selectedLoginRole);

    let userData = {
      user: {
        roles_users: { role_ids: compact(roleIds) },
        first_name: state.user.first_name,
        last_name: state.user.last_name,
        email: state.user.email === undefined ? "" : state.user.email,
        receive_release_notes: state.user.receive_release_notes,
        security_concern_report_option: state.user.security_concern_report_option,
        disabled: state.user.disabled,
        tablet_created: state.user.tablet_created,
        lessee_id: state.currentLesseeId,
        current_lessee_id: state.currentLesseeId,
        zone_ids: state.selectedZones,
        time_zone: state.user.time_zone,
        language_id: state.user.language_id,
        identifier: state.user.identifier,
        enable_ical_feed: state.user.enable_ical_feed,
        id: state.user.id,
        daily_summary_send_time: state.user.daily_summary_send_time,
        calendar_setting_attributes: state.user.calendar_setting_attributes,
        can_approve_timesheets: state.user.can_approve_timesheets,
        notify_on_time_sheet_submission: state.user.notify_on_time_sheet_submission
      }
    };

    if (state.user.password) {
      userData['user']['password'] = state.user.password
      userData['user']['password_confirmation'] = state.user.password_confirmation
    }

    if (state.user["persisted?"]) {
      userData.user.quick_sign_in_role_id = state.user.quick_sign_in_role_id;
    }

    commit('updateField', { path: 'saving', value: true })

    AxiosWrapper.init(this.$axios)
    AxiosWrapper.postOrPatch("/data_api/users", userData).then(
      () => {
        commit('updateField', { path: 'saving', value: false })
      }
    ).catch(commit('updateField', { path: 'saving', value: false }));
  }
}