import Vue from 'vue'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields'
import groupBy from 'lodash/groupBy'

const getDefaultState = () => ({
  videos: {},
  editing: {},
  playVideo: null,
  showEditModal: false,
  formData: null
})

export const state = getDefaultState

export const getters = {
  getField,
  videos: state => { return groupBy(state.videos, video => { return video.archive.name }) },
}

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  updateField,
  updateVideo(state, { video }) {
    Vue.set(state.videos, video.id, video)
  },
  removeVideo(state, { video }) {
    Vue.delete(state.videos, video.id)
  },
  setPlayVideo(state, video) {
    Vue.set(state, 'playVideo', video)
  }
}

export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/videos`)
      .then(response => {
        commit('updateField', { path: 'videos', value: { ...response.data.videos } })
      })
  },
  submitVideo({ commit }, { video }) {
    AxiosWrapper.postOrPatch('/data_api/videos', { video })
      .then(response => {
        commit('updateVideo', { video: response.data })
      })
  },
  deleteVideo({ commit }, video) {
    AxiosWrapper.delete(`/data_api/videos/${video.id}`)
      .then(response => {
        commit('removeVideo', { video })
      })
  },
}