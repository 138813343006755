import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper";
import { translate } from '~/assets/javascript/localization/Localization';

AxiosWrapper.init();
import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
  languages: [],
  loadingMessage: translate("no_data_available"),
  saveMode: false,
  showModal: false,
  formData: null,
  sortOrder: [
    {
      field: "updated_at",
      sortField: "updated_at",
      direction: "desc"
    }
  ],
  saveInProgress: false
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  update(state, createdLanguage ) {
    if (createdLanguage.id){
      var index = state.languages.findIndex(e => {
        return e.id === createdLanguage.id
      })

      if (index === -1) index = 0

      Vue.set(state.languages, index, createdLanguage);
    }else{
      Vue.set(state.languages, 0, createdLanguage);
    }
  },
  removeRow(state, rowData ) {
    state.saveMode = false
    state.languages.splice(state.languages.indexOf(rowData), 1);
  },
  editExistingLanguage(state, lang){
    state.formData = {
      id:lang.id,
      short:lang.short,
      long:lang.long
    }
    state.showModal = true
  }
}
export const actions = {
  async init({ commit }) {
    commit("resetState")

    AxiosWrapper.get(`/data_api/languages`).then(response => {
      commit("updateField", { path: "languages", value: response.data.languages });
    });
  },
  async submit({ commit, dispatch, state }) {
    commit('updateField', { path: 'saveMode', value: true })
    commit("updateField", { path: "saveInProgress", value: true })
      AxiosWrapper.postOrPatch("/data_api/languages", {
        language: state.formData
      }).then(response => {
        let createdLanguage = response.data.language
        commit("update", createdLanguage)
        commit("updateField", { path: "saveInProgress", value: false })
        commit('updateField', { path: 'saveMode', value: false })
      })
    commit('updateField', {path:'showModal', value:false})
  },
  deleteLanguage({ commit, state }, rowData) {
    commit("updateField", { path: "saveInProgress", value: true })
    AxiosWrapper.delete(`/data_api/languages/${rowData.id}`)
      .then(response => {
        commit("removeRow",  rowData)
        commit("updateField", { path: "saveInProgress", value: false })
      })
  },
  editLanguage({ commit }, language) {
    commit('editExistingLanguage', language)
  },
}