import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';
import { translate } from '~/assets/javascript/localization/Localization';
import gtmEvents from "~/assets/javascript/gtmEvents"

const getBase = `/data_api/dashboard/devices`

const getDefaultState = () => ({
  available_questionnaire_results: null,
  pass_count: null,
  fail_count: null,
  job_id: null,
  device: null,
  back_to_questionnaire_path: null,
  is_initializing: true,
  loadingMessage: translate('no_data_available'),
  total_pages: 0,
  total: 0,
  params: {
    page: 1,
    items_per_page: 10,
    questionnaire_id: null
  }
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  preInitDataSetup(state){
    state.job_id = AxiosWrapper.getNextPathNameElement('jobs');
    let loadedParams = AxiosWrapper.getSearchParams();
    state.device = loadedParams.device
    state.params.questionnaire_id = loadedParams.questionnaire_id
  },
  initDataFromGet(state, getRequestData) {
    ({
      available_questionnaire_results: state.available_questionnaire_results,
      pass_count: state.pass_count,
      fail_count: state.fail_count,
      back_to_questionnaire_path: state.back_to_questionnaire_path,
      total_pages: state.total_pages,
      total: state.total
    } = getRequestData);
  },
}

export const getters = {
  getField,
  backComponentTitle() {
    return translate('back')
  }
}

export const actions = {
  async init({ commit, state, dispatch }) {
    gtmEvents.reportGenerated({
      type: "Questionnaire Results",
      context: "Device Job"
    })
    commit('resetState')
    commit('preInitDataSetup')
    dispatch('setStoreParamsWithUrlParams')
    dispatch('getPage')
  },
  setStoreParamsWithUrlParams({ commit, state }) {
    let mergedParams = { ...state.params, ...AxiosWrapper.getSearchParams() }
    commit('updateField', { path: "params", value: mergedParams });
  },
  async getPage({ commit, state }) {
    var getUrlItem = `${getBase}/jobs/${state.job_id}/questionnaire_results`;

    AxiosWrapper.get(getUrlItem, { params: state.params }).then(function (response) {
      var pathWithParams = AxiosWrapper.buildPathWithSearchParams(state.params);
      history.replaceState(history.state, 'QuestionnaireResultsDevice', pathWithParams);
      commit('initDataFromGet', response.data);
      commit('updateField', {
        path: 'is_initializing',
        value: false
      });
    })
  },
  setPageAndThenGetPage({ dispatch, commit, state }, page) {
    //Not ideal.  If you find a better way let me know -LK.
    //If you remove the initialized params won't work.
    if (state.is_initializing == false) {
      commit('updateField', { path: "params.page", value: page });
      dispatch('getPage');
    }
  },
  changeNumberOfItemsPerPage({ commit, dispatch }, itemsPerPage) {
    commit('updateField', {
      path: 'params.items_per_page',
      value: itemsPerPage
    });
    dispatch('getPage');
  }
}