import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import { getField, updateField } from 'vuex-map-fields';
import UrlManipulator from '~/assets/javascript/UrlManipulator';

const getDefaultState = () => ({
  customer: null,
  customerIdValue: null,
  timeZones: [],
  languages: [],
  zonesForSelect: [],
  saveInProgress: true
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInitialState(state, data) {
    state.customer = data.customer;
    state.timeZones = data.time_zones;

    state.languages = data.languages

    data.available_zones.forEach((zone) => {
      let items = zone.jobs.map(j => { return {label: j.identifier, value: j.id} } )
      state.zonesForSelect = state.zonesForSelect.concat({ collapsible: true,
                                                          label: zone.name,
                                                          items: items })
    })

    state.saveInProgress = false
  },
}

export const actions = {
  init({ commit }) {
    commit("resetState")

    let customerIdValue = UrlManipulator.getNextPathNameElement('customers')
    let apiUrl = `/data_api/customers/new`;

    if (!isNaN(parseInt(customerIdValue))) {
      apiUrl = `/data_api/customers/${customerIdValue}/edit`
    }

    AxiosWrapper.get(apiUrl).then(response => {
      commit("setInitialState", response.data)
    })
  },
  submit({ commit, state }, router) {
    commit('updateField', { path: "saveInProgress", value: true})

    if (state.customer.id) {
      AxiosWrapper.patch(`/data_api/customers/${state.customer.id}`, { customer: state.customer })
        .then(_ => {
          router.push(`/users`)
        }).catch(_ => {
          commit('updateField', { path: "saveInProgress", value: false })
        })
    } else {
      AxiosWrapper.post(`/data_api/customers`, { customer: state.customer })
        .then(_ => {
          router.push(`/users`)
        }).catch(_ => {
          commit('updateField', { path: "saveInProgress", value: false })
        })
    }
  }
}