import { getField, updateField } from "vuex-map-fields";
import commentFormGateway from '~/assets/javascript/commentFormGateway.js'

const getDefaultState = () => ({
  commentFormGateway: commentFormGateway
})

export const state = getDefaultState

export const getters = {
  getField,
}

export const mutations = {
  updateField,
}

export const actions = {}