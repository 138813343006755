<template lang="pug">
  .d-flex.mr-2
    button.btn.btn-outline-light(@click="saveOptionsClicked") {{ "save_filters" | translate }}
    button.btn.btn-outline-light(@click="showLoadPreset = true") {{ "load_filters" | translate }}
    modal(v-if="showCreatePreset",
          :modalTitle="translate('save_selected_options')",
          @close="showCreatePreset = false")
      field-validation(name="newPresetName", :validator="$v")
        input.form-control(type='text', v-model="newPresetName", v-autofocus)
      template(slot="actions")
        button.btn.btn-outline-light(@click="showCreatePreset = false") {{ 'cancel' | translate }}
        button.btn.btn-primary(@click="saveClicked") {{ 'save' | translate }}
    modal(v-if="showLoadPreset",
          :modalTitle="translate('load_options_preset')",
          @close="showLoadPreset = false")
      table.table.table-hover
        thead
          tr
            th {{ 'name' | translate }}
            th {{ 'created_at' | translate }}
            th {{ 'actions' | translate }}
        tbody
          tr.pointer(v-for="preset in selectsPresets", @click="loadPreset(preset)")
            td {{ preset.name }}
            td {{ preset.created_at }}
            td
              button.btn.btn-outline-danger(@click.stop="deleteSelectsPreset(preset.id)")
                .fal.fa-trash-alt
      template(slot="actions")
        button.btn.btn-outline-light(@click="showLoadPreset = false") {{ 'cancel' | translate }}
</template>

<script>
import Modal from "~/components/common/Modal"
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import cloneDeep from "lodash/cloneDeep"
import FieldValidation from '~/components/common/FormElements/FieldValidation'
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    newPresetName: { required }
  },
  components: {
    Modal,
    FieldValidation
  },
  props: ["pageTitle"],
  data() {
    return {
      showCreatePreset: false,
      showLoadPreset: false,
      newPresetName: ""
    }
  },
  computed: {
    ...mapFields("login", ["selectsPresets"])
  },
  methods: {
    ...mapActions("login", ["saveSelectsPreset", "deleteSelectsPreset"]),
    saveOptionsClicked() {
      this.newPresetName = this.pageTitle;
      this.showCreatePreset = true;
    },
    saveClicked() {
      this.$v.$touch()

      if (!this.$v.$error && !this.$v.$pending) {
        this.saveSelectsPreset(this.newPresetName).then(() => this.showCreatePreset = false)
      } else {
        this.$toast.error(this.translate("there_was_a_problem"), { duration: 1000 })
      }
    },
    loadPreset(preset) {
      let params = cloneDeep(preset.select_params)

      if (this.$route.params.job_id) params.job_ids = this.$route.params.job_id
      if (this.$route.params.job_revision_id) params.job_revision_ids = this.$route.params.job_revision_id

      this.$store.dispatch("selects/reInitSelects", params)
      this.$store.dispatch("datetime/setParams", {
        start_date: preset.date_params.start_date,
        end_date: preset.date_params.end_date
      })

      if (preset.date_params.start_date === "") {
        this.$store.commit("datetime/setStartDateToEmpty")
      }

      if (preset.date_params.end_date === "") {
        this.$store.commit("datetime/setEndDateToEmpty")
      }

      this.showLoadPreset = false
    }
  }
}
</script>

<style scoped="true">
.pointer {
  cursor: pointer;
}
</style>
