import QuestionsHelper from '~/components/Questionnaires/QuestionsHelper'
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => ({
  expectedValue: null
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  restoreState(state, newState) {
    Object.assign(state, newState)
  },
  setStateFromResponse(state, { question }) {
    QuestionsHelper.setState(state, question)
  },
  updateField
}

export const getters = {
  getField,
}

export const actions = {
  async init({ dispatch }, params) {
    dispatch('questions/editQuestions/init', {params, questionControllerName: 'audit_value_compare_question', name: 'valueCompare'}, { root: true })
  },
  async configureInitState({ dispatch }) {
    dispatch('questions/editQuestions/configueCommonInit', false, { root: true })
  },
  async configureParams({ dispatch }, params) {
    dispatch("questions/editQuestions/configureCommonParams", params, { root: true })
  },
}