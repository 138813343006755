import { getField, updateField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization';
import cloneDeep from "lodash/cloneDeep"

export const state = () => ({
  jobRevision: null,
  shift: null,
  jobRevisionBreadcrumbs: []
})

export const getters = {
  getField,
  getRevisionBreadcrumb(state) {
    if (state.jobRevision) return `${translate('revision')}: ${state.jobRevision.formatted_revision_date} ${state.jobRevision.revision_number}`
  },
  geShiftBreadcrumb(state) {
    if (state.shift) return `${translate('started_at')}: ${state.shift.started_at}`
  },
  getJobRevisionBreadcrumbs(state) {
    return state.jobRevisionBreadcrumbs
  }
}

export const mutations = {
  updateField
}

export const actions = {
  setJobRevision({state, commit, rootState, getters}, value) {
    commit('updateField', { path: "jobRevision", value: cloneDeep(value)})

    let breadcrumbs = [
      { text: translate('revisions'), path: `/jobs/${rootState['job'].job.id}/job_revisions` },
    ]

    if (state.jobRevision.id) {
      let jobRevisionPath = {
        text: getters['getRevisionBreadcrumb'],
        path: `/jobs/${rootState['job'].job.id}/job_revisions/${state.jobRevision.id}`,
      }

      if (rootState['job'].job.job_revisions) {
        jobRevisionPath['pathCollection']= rootState['job'].job.job_revisions.map(jr => { return { text: jr.label, path: `/jobs/${rootState['job'].job.id}/job_revisions/${jr.id}` } })
      }

      breadcrumbs.push(jobRevisionPath)
    }

    commit('updateField', { path: "jobRevisionBreadcrumbs", value: breadcrumbs })
  },
  setJobRevisionAndShift({ state, commit, rootState, getters }, { revision, shift }) {
    commit('updateField', { path: "jobRevision", value: revision })
    commit('updateField', { path: "shift", value: shift })

    let breadcrumbs = [
      { text: translate('revisions'), path: `/jobs/${rootState['job'].job.id}/job_revisions` },
    ]

    if (state.jobRevision.id) {
      let jobRevisionPath = {
        text: getters['getRevisionBreadcrumb'],
        path: `/jobs/${rootState['job'].job.id}/job_revisions/${state.jobRevision.id}`,
      }

      if (rootState['job'].job.job_revisions) {
        jobRevisionPath['pathCollection'] = rootState['job'].job.job_revisions.map(jr => { return { text: jr.label, path: `/jobs/${rootState['job'].job.id}/job_revisions/${jr.id}` } })
      }

      breadcrumbs.push(jobRevisionPath)
    }

    commit('updateField', { path: "jobRevisionBreadcrumbs", value: breadcrumbs })
  },

}