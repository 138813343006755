import AxiosWrapper from '~/assets/javascript/AxiosWrapper'
import cloneDeep from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields';

const getDefaultState = () => ({
  users: [],
  isLoading: true,
  requestCsvUrl: null,
  confirmingCsvRequest: false,
  exportUsersUrl: `/data_api/download_csv/users`,
  exportInactiveUsersUrl: `/data_api/download_csv/users?type=inactive`,
  totalPages: 0,
  page: 1,
  totalUsers: 0,
  currentUsers: 0,
  usersPerPage: 75,
  filterType: null,
})

export const state = getDefaultState

export const getters = {
  getField,
  users: state => cloneDeep(state.users),
  isLoading: state => state.isLoading
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setUsers(state, newUsers) {
    state.users = newUsers.users || [];
    state.totalPages = newUsers.total_pages || 1;
    state.totalUsers = newUsers.total_users || 0;
    state.page = newUsers.page || 1;
    state.usersPerPage = newUsers.items_per_page || 75;
  },
  removeUser(state, userId) {
    let index = state.users.findIndex(u => u.id == userId);
    state.users.splice(index, 1);
  },
  setLoading(state, isLoading) { state.isLoading = isLoading }
}

export const actions = {
  init({commit, dispatch}, params) {
    commit("resetState")

    dispatch('getUsers', params)
  },
  getUsers({commit,state}, params) {

    commit("updateField", { path: 'users', value: []})
    commit('setLoading', true);

    commit("updateField",{path:'filterType', value:params.filter})

    let newParams = {...state.filterType,
      page: state.page,
      items_per_page: state.usersPerPage
    }

    return AxiosWrapper.get('/data_api/users', { params: newParams })
      .then(response => {
        commit('setUsers', response.data);
        commit('setLoading', false);
      });
  },
  exportUsers({state, commit}, inactive=false) {
    if (inactive) {
      commit('updateField', { path: "requestCsvUrl", value: state.exportInactiveUsersUrl })
    } else {
      commit('updateField', { path: "requestCsvUrl", value: state.exportUsersUrl })
    }

    commit('updateField', { path: "confirmingCsvRequest", value: true })
  },
  deleteUser({commit}, user) {
    commit('setLoading', true);
    let url;
    if (user.is_customer) {
      url = `/data_api/customers/${user.id}`
    } else {
      url = `/data_api/users/${user.id}`
    }
    AxiosWrapper.delete(url)
      .then(() => {
        commit('removeUser', user.id);
        commit('setLoading', false);
      })
  },
  async changePage({ dispatch, commit, state }, page) {
    commit('updateField', { path: 'page', value: page });
    dispatch('getUsers', { filter:state.filterType } )
  },
  async changeUsersPerPage({ dispatch, commit, state }, usersPerPage) {
    commit('updateField', { path: 'usersPerPage', value: usersPerPage });
    dispatch('getUsers', { filter: state.filterType })
  }
}