<template lang="pug">
  nav.navbar.navbar-expand-md.navbar-dark.bg-dark
    slot
    nuxt-link.navbar-brand.d-flex.align-items-center.p-0(to='/')
      img(src="/edn_logo_white.png" height="35" alt="logo")

    ul.navbar-nav.ml-auto(id="languages-menu")
      li.nav-item.dropdown
        .nav-link.dropdown-toggle(id="languagesDropdown",
                                  role="button",
                                  data-toggle="dropdown",
                                  aria-haspopup="true",
                                  aria-expanded="false",
                                  data-cy="languageDropdown")
          span.fal.fa-globe-americas
          |   {{ currentLanguage }}
        .dropdown-menu.dropdown-menu-right(aria-labelledby="languagesDropdown")
          .dropdown-item(v-for="l in languages",
                          @click="changeLanguage(l)",
                          :data-cy="l.short") {{ l.long }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: mapGetters('login', ['languages', 'currentLanguage']),
  methods: mapActions('login', ['init', 'changeLanguage'])
}
</script>

<style lang="scss" scoped>
@import '~/assets/stylesheets/navbar.scss';

</style>