<template lang='pug'>
  div(:class="errorClass")
    .errors(v-show="hasErrorsFor(name)", v-if="showErrorAtTop")
      .invalid(v-for="text in errorText(name, true)", :data-cy="`validation-message-${name}`") {{ text }}
    slot
    .errors(v-show="hasErrorsFor(name)", v-if="!showErrorAtTop")
      .invalid(v-for="text in errorText(name, true)", :data-cy="`validation-message-${name}`") {{ text }}
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    showErrorAtTop: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    errorClass() {
      return this.errorClassFor(this.name)
    },
    hasErrors() {
      return this.hasErrorsFor(this.name)
    }
  }
}
</script>
