import { createHelpers } from 'vuex-map-fields';

const { getErrorMethodsField, updateErrorMethodsField } = createHelpers({
  getterType: 'getErrorMethodsField',
  mutationType: 'updateErrorMethodsField',
});

const getDefaultState = () => ({
  saveInProgress: false,
  apiErrors: {},
  errorFields: [],
  hasSubmittedOnce: false, //ToDo: Very odd coupling for this var.  Need a better solution long term. -lee
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setApiErrors(state, apiErrors) {
    state.saveInProgress = false
    state.apiErrors = apiErrors
    state.errorFields = apiErrors.fields
  },
  clearAll(state) {
    state.apiErrors = {}
    state.errorFields = []
  },
  updateErrorMethodsField
}

export const getters = {
  getErrorMethodsField
}

export const actions = {
  defaultSaveSuccess({commit}, data) {
    commit('clearAll')
    commit('setApiErrors', data.data)
  },
  defaultError({commit, dispatch}, error) {
    dispatch('populateErrors', error.response.data)
  },
  populateErrors({commit}, errorPackage, scroll=true) {
    if (errorPackage.fields !== undefined) {

      commit('setApiErrors', errorPackage)

      if(scroll) {
        window.scrollTop = 0
      }
    }
  },
}