import ActionCable from 'actioncable'
import { updateField, getField } from 'vuex-map-fields'

export const state = () => ({
  jobId: false,
  firstLoggedIn: false,
  registered: false,
  afterNotification: null
})

export const getters = ({
  getField,
})

export const mutations = ({
  updateField,
  setFirstLoggedIn(state, value) {
    state.firstLoggedIn = value
  },
  setRegistered(state, value) {
    state.registered = value
  }
})

export const actions = ({
  async registerNotifications({ state, commit }, vueInstance) {
    if (!state.registered) {
      let url = 'ws://localhost:3000/cable'

      if (process.env.base) {
        url = `wss://${process.env.base}/cable`
      }

      let cable = ActionCable.createConsumer(url)

      cable.subscriptions.create('EventNotificationsChannel', {
        connected: () => {
          console.log("CONNECTED")
        },
        disconnected: () => {
          // console.log("disconnected")
        },
        received: (data) => {
          // console.log(data)
          let message = data.message

          let toastOptions = {
            type: 'success',
            position: 'top-right',
            duration: 30000,
            action: {
              text: I18n.t('go_to_activities'), onClick: (e, toastObject) => {
                if (vueInstance.$route.path === "/events/users") {
                  vueInstance.$router.go()
                } else {
                  vueInstance.$router.push('/events/users')
                }

                toastObject.goAway(0)
              }
            }
          }

          if (data.action_text && data.url) {
            toastOptions.action = {
              text: data.action_text, onClick: (e, toastObject) => {
                vueInstance.$router.push(data.url)
                toastObject.goAway(0)
              }
            }
          }

          if (data.no_action) {
            toastOptions.action = null
          }

          if (state.afterNotification) {
            state.afterNotification()
          }

          if (!data.completed) {
            toastOptions.action = null
            toastOptions.type = 'error'
          }

          vueInstance.$toast.show(message, toastOptions)
        }
      })

      commit('updateField', { path: 'registered', value: true})
    }

  },
})