import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { getField, updateField } from 'vuex-map-fields';

AxiosWrapper.init();

const getDefaultState = () => ({
  job_id: AxiosWrapper.getNextPathNameElement("jobs"),
  job: null,
  file: null,
  type: AxiosWrapper.getSearchParams()["type"],
  isUserCustomer: false,
})

export const state = getDefaultState

export const getters = {
  getField
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  init(state, getRequestedData) {
    ({
      job: state.job,
      is_user_customer: state.isUserCustomer
    } = getRequestedData)
  },
  setFile(state, file) {
    state.file = file
  }
}

export const actions = {
  async init({ commit, state }) {
    commit("resetState")
    AxiosWrapper.get(`/data_api/jobs/${state.job_id}/constraints/import`).then(({ data }) => {
      commit("init", data)
    })
  },
  async fileAttached({ dispatch }, e) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length)
      return;
    dispatch("attachFileToState", files[0])
  },
  async attachFileToState({ commit }, file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    commit("setFile", file)
  },
  async importButtonPressed({ state }, router) {
    AxiosWrapper.post(`/data_api/jobs/${state.job_id}/constraints/create_all`, { file: state.file, type: state.type, job_id: state.job_id}).then(() => {
      router.push(`/jobs/${state.job_id}/edit`)
    })
  }
}