import Vue from "vue"
import { EDN_PAGE_KEY, PAGE_TITLE_KEY } from "~/assets/javascript/constants"

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-MR4CT75')

export default () => {
  Vue.mixin({
    gtmData: {},
    mounted() {
      const gtmData = this.$options.gtmData;
      const keys = Object.keys(gtmData);

      if (keys.length > 0) {
        let event = "mounted.component"
        if (keys.includes(EDN_PAGE_KEY)) {
          event = event + ".page"
        }

        window.dataLayer.push({
          ...gtmData,
          event
        })
      }
    },
    destroyed() {
      if (Object.keys(this.$options.gtmData).includes(EDN_PAGE_KEY)) {
        window.dataLayer.push({
          [PAGE_TITLE_KEY]: null,
          [EDN_PAGE_KEY]: null,
          event: "destroyed.component.page"
        })
      }
    }
  })
}